export const DEFAULT_SECURITY_POLICY = {
    "testing": false,
    "notify_on_failure": true,
    "tags": [
      "all-scanners",
      "example",
      "special-project"
    ],
    "frequency": "Once",
    "ignore": [
      "test/*",
      "node_modules/*"
    ],
    "scanners": [
      {
        "enforced": true,
        "compliance": true
      },
      {
        "enforced": true,
        "secret": true
      },
      {
        "enforced": true,
        "pii": true
      },
      {
        "enforced": false,
        "sast": true
      },
      {
        "enforced": false,
        "sca": true
      },
      {
        "enforced": false,
        "container": true
      },
      {
        "enforced": true,
        "iac": true
      },
      {
        "enforced": true,
        "api": true
      },
      {
        "enforced": true,
        "malware": true
      }
    ],
    "raise_tickets": false,
    "jira_project_key": "",
    "ticket_body": "Was found this {{vuln}} on this file {{file}}",
    "ticket_integration": "",
    "ticket_title": "AquilaX - {{vuln}}",
    "threshold": {
      "total": 100,
      "HIGH": 30,
      "MEDIUM": 100,
      "LOW": 99999
    }
  };
  