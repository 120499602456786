import React from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Tag,
  Button,
  useColorModeValue,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetScanDetails } from "../../features/Scans/ScansSlice";

const ScanTable = ({ scans = [], orgId, groupId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentScanDetails = useSelector((state) => state.scans.scanDetails);

  const boxBg = useColorModeValue("white", "gray.800");
  const borderCol = useColorModeValue("gray.300", "gray.600");
  const mainTextColor = useColorModeValue("gray.900", "gray.100");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  const handleScanDetailsClick = (scanId) => {
    if (!currentScanDetails || currentScanDetails._id !== scanId) {
      dispatch(resetScanDetails());
    }
    navigate(`/scan/${orgId}/${scanId}/${groupId}`);
  };

  return (
    <Box
      bg={boxBg}
      borderRadius="lg"
      border="1px"
      borderColor={borderCol}
      p={6}
      mt={3}
      overflow="auto"
      maxHeight="400px"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="lg" fontWeight="bold" color={mainTextColor}>
          All Scans
        </Text>
      </Flex>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th color={subTextColor}>Initiator</Th>
            <Th color={subTextColor}>Repository</Th>
            <Th color={subTextColor}>Frequency</Th>
            <Th color={subTextColor}>Status</Th>
            <Th color={subTextColor}>Created</Th>
            <Th color={subTextColor}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {scans.length > 0 ? (
            scans.map((scan) => (
              <Tr key={scan._id}>
                <Td>
                  <Flex alignItems="center">
                    <Avatar size="sm" name={scan.user.email} mr={3} />
                    <Text color={mainTextColor}>{scan.user.email}</Text>
                  </Flex>
                </Td>
                <Td color="blue.500">{scan.git_uri}</Td>
                <Td>
                  <Tag
                    size="sm"
                    colorScheme={scan.frequency === "Once" ? "blue" : "green"}
                    fontSize="0.75rem"
                    px={2}
                    py={1}
                  >
                    {scan.frequency}
                  </Tag>
                </Td>
                <Td>
                  <Tag
                    size="sm"
                    colorScheme={
                      scan.status === "COMPLETED" ? "green" :
                      scan.status === "FAILED" ? "red" : "yellow"
                    }
                    alignItems="center"
                  >
                    <Text mr={1}>{scan.status}</Text>
                    {scan.status !== "COMPLETED" && scan.status !== "FAILED" && (
                      <Spinner size="xs" color="gray.500" />
                    )}
                  </Tag>
                </Td>
                <Td>
                  {formatDistanceToNow(new Date(scan.created_at * 1000), {
                    addSuffix: true,
                  })}
                </Td>
                <Td>
                  {scan.status === "FAILED" ? (
                    <Tooltip 
                      label="Broken Access" 
                      bg="red.500" 
                      color="white"
                      borderRadius="md"
                      hasArrow
                    >
                      <Box>
                        <Tag colorScheme="red" size="sm">
                          Access Error
                        </Tag>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="outline"
                      size="sm"
                      borderColor={useColorModeValue("#003460", "#0077b5")}
                      _hover={{
                        bg: useColorModeValue("gray.100", "gray.700"),
                      }}
                      onClick={() => handleScanDetailsClick(scan._id)}
                    >
                      Scan Details
                    </Button>
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Text></Text>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ScanTable;