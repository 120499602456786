import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Alert,
  AlertIcon,
  Text,
  Box,
  useColorModeValue,
  useClipboard,
  IconButton,
  VStack,
  HStack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { createAccessTokenAsync } from "../../features/Users/UsersAction";
import { resetCreatedToken } from "../../features/Users/UsersSlice";

const PATModal = ({ isOpen, onClose }) => {
  const [tokenName, setTokenName] = useState("");
  const [addExpiry, setAddExpiry] = useState(false);
  const [expiryDays, setExpiryDays] = useState(0);

  const dispatch = useDispatch();
  const { loading, error, createdToken } = useSelector((state) => state.user);

  const { hasCopied, onCopy } = useClipboard(createdToken || "");
  const toast = useToast();
  const hasShownToastRef = useRef(false);

  const primaryColor = useColorModeValue("#001f33", "#70a5ff");
  const bgColor = useColorModeValue("white", "gray.700");
  const inputBgColor = useColorModeValue("gray.100", "gray.600");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.100");

  const handleCreate = () => {
    if (tokenName.trim()) {
      const tokenData = {
        name: tokenName.trim(),
        expire_at: addExpiry
          ? Math.floor(Date.now() / 1000) + expiryDays * 24 * 60 * 60
          : null,
      };
      dispatch(createAccessTokenAsync(tokenData));
    }
  };

  useEffect(() => {
    if (createdToken && !hasShownToastRef.current) {
      hasShownToastRef.current = true;
      toast({
        title: "Token Created",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onCopy();
    }
  }, [createdToken, toast, onCopy]);

  const handleClose = () => {
    dispatch(resetCreatedToken());
    hasShownToastRef.current = false;
    setTokenName("");
    setAddExpiry(false);
    setExpiryDays(0);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={["sm", "md", "lg"]}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg={bgColor} color={textColor}>
        <ModalHeader>Your New Access Token</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {error && (
              <Alert
                status="error"
                borderRadius="md"
                bg={useColorModeValue("red.100", "red.700")}
              >
                <AlertIcon />
                {error.message || "An error occurred while creating the token."}
              </Alert>
            )}

            {createdToken ? (
              <Box
                p={4}
                bg={inputBgColor}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                boxShadow="sm"
              >
                <VStack spacing={3} align="stretch">
                  <HStack>
                    <Text
                      fontFamily="monospace"
                      fontSize="md"
                      fontWeight="bold"
                      wordBreak="break-all"
                      flex="1"
                    >
                      {createdToken}
                    </Text>
                    <Tooltip
                      label={hasCopied ? "Copied" : "Copy"}
                      closeOnClick={false}
                    >
                      <IconButton
                        aria-label="Copy token"
                        icon={<CopyIcon />}
                        onClick={() => {
                          onCopy();
                          toast({
                            title: hasCopied
                              ? "Token Copied"
                              : "Copied to Clipboard",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                        variant="outline"
                        colorScheme="blue"
                      />
                    </Tooltip>
                  </HStack>
                  <Text fontSize="sm" color={textColor}>
                    Please copy and store your token securely. You won’t be able
                    to view it again.
                  </Text>
                </VStack>
              </Box>
            ) : (
              <>
                <FormControl mb={4} isRequired>
                  <FormLabel>Token Name</FormLabel>
                  <Input
                    placeholder="Enter token name"
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                    bg={inputBgColor}
                    borderColor={borderColor}
                    _placeholder={{ color: useColorModeValue("gray.500", "gray.300") }}
                  />
                </FormControl>

                <Checkbox
                  isChecked={addExpiry}
                  onChange={(e) => setAddExpiry(e.target.checked)}
                  mb={4}
                  colorScheme="teal"
                >
                  Add Expiry
                </Checkbox>

                {addExpiry && (
                  <FormControl mb={4}>
                    <FormLabel>Expiry (Days from now)</FormLabel>
                    <NumberInput
                      min={1}
                      value={expiryDays}
                      onChange={(value) => setExpiryDays(Number(value))}
                    >
                      <NumberInputField
                        bg={inputBgColor}
                        borderColor={borderColor}
                      />
                    </NumberInput>
                  </FormControl>
                )}
              </>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          {!createdToken ? (
            <>
              <Button
                variant="ghost"
                onClick={handleClose}
                mr={3}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                bg={primaryColor}
                color="white"
                _hover={{ bg: useColorModeValue("#005399", "#003c70") }}
                onClick={handleCreate}
                isLoading={loading}
                disabled={
                  loading || !tokenName.trim() || (addExpiry && expiryDays < 1)
                }
              >
                Create
              </Button>
            </>
          ) : (
            <Button
              bg={primaryColor}
              color="white"
              _hover={{ bg: useColorModeValue("#005399", "#003c70") }}
              onClick={handleClose}
            >
              Close
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PATModal;
