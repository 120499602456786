import React from 'react';
import { Box, Flex, Text, Heading, useColorModeValue, Skeleton } from '@chakra-ui/react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';

const ConfirmedVulnerabilities = ({ report, loading }) => {
  const boxBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('#4A5568', '#CBD5E0');
  const gridColor = useColorModeValue('#ccc', '#4A5568');
  const tooltipBg = useColorModeValue('#fff', '#2D3748');
  const darkBlue = "#003460";
  const lightBlue = "#5A8BD4";

  const liveVulns = report?.live?.vulnerabilities || {};
  const monthVulns = report?.one_month_ago?.vulnerabilities || {};

  const chartData = Object.keys(liveVulns).map((category) => {
    const liveData = liveVulns[category] || {};
    const monthData = monthVulns[category] || {};
    const tp = liveData["True Positive"] || 0;
    const fp = liveData["False Positive"] || 0;
    const unverified = liveData["Unverified"] || 0;
    const tpChange = (monthData["True Positive"] || 0) === 0 
      ? (tp > 0 ? 100 : 0) 
      : (((tp - monthData["True Positive"]) / monthData["True Positive"]) * 100).toFixed(1);
    const fpChange = (monthData["False Positive"] || 0) === 0 
      ? (fp > 0 ? 100 : 0) 
      : (((fp - monthData["False Positive"]) / monthData["False Positive"]) * 100).toFixed(1);

    return {
      name: category,
      tp,
      unverified,
      fp,
      tpChange,
      fpChange,
    };
  });

  const totalTP = chartData.reduce((sum, item) => sum + item.tp, 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box bg={tooltipBg} p={2} borderRadius="md" boxShadow="sm">
          <Text fontWeight="bold" mb={1}>{label}</Text>
          <Text fontSize="sm">
            TP: {data.tp} ({data.tpChange}%)
          </Text>
          <Text fontSize="sm">
            FP: {data.fp} ({data.fpChange}%)
          </Text>
          <Text fontSize="sm">
            Unverified: {data.unverified}
          </Text>
        </Box>
      );
    }
    return null;
  };

  return (
    <Skeleton isLoaded={!loading}>
      <Box
        bg={boxBg}
        p={5}
        borderRadius="md"
        boxShadow="sm"
        mx="auto"
        mt={2}
        height="300px"
      >
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md" color={textColor}>
            Vulnerabilities
          </Heading>
        </Flex>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
            barSize={30}
          >
            <CartesianGrid stroke={gridColor} strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fill: textColor }}
            />
            <YAxis tick={{ fill: textColor }} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="tp" stackId="a" fill={darkBlue}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-tp-${index}`} fill={darkBlue} />
              ))}
            </Bar>
            <Bar dataKey="unverified" stackId="a" fill={lightBlue}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-unverified-${index}`} fill={lightBlue} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Skeleton>
  );
};

export default ConfirmedVulnerabilities;
