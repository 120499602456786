import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function VSCInstallPage() {
  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const orgId = orgDetails?._id;
  const bgGradient = useColorModeValue(
    "linear(to-r, blue.50, white)",
    "linear(to-r, gray.800, gray.900)"
  );
  const boxBg = useColorModeValue("white", "gray.700");

  const handleInstall = async () => {
    try {
      if (!orgId) {
        console.error("Organization ID is not available.");
        return;
      }

      const response = await fetch(
        `/api/v1/organization/${orgId}/vsc-install`
      );
      const data = await response.json();
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        console.error("No redirect URL provided in the response.");
      }
    } catch (error) {
      console.error("Error during VS Code installation", error);
    }
  };

  return (
    <Box py={20}>
      <Container maxW="4xl">
        <Box
          bg={boxBg}
          rounded="lg"
          shadow="md"
          p={10}
          width="full"
          textAlign="center"
        >
          <Center mb={8}>
            <Image
              src="https://aquilax.ai/assets/img/logos/vsc.png"
              alt="VS Code Logo"
              boxSize="80px"
              objectFit="contain"
            />
          </Center>

          <Heading as="h1" size="xl" mb={4}>
            Authorize to AquilaX VS Code
          </Heading>
          <Text fontSize="md" color="gray.500" mb={8}>
            AquilaX in Visual Studio Code:{" "}
            <Link
              href="https://marketplace.visualstudio.com/items?itemName=Aquilax.aquilax-appsec"
              color="blue.700"
              isExternal
            >
              Marketplace <ExternalLinkIcon mx="2px" />
            </Link>
          </Text>

          <Button
            bg="blue.600"
            color="white"
            _hover={{ bg: "blue.700" }}
            size="md"
            mb={8}
            onClick={handleInstall}
          >
            Install and Authorize VS Code
          </Button>

          <Text fontSize="sm" color="gray.500">
            By authorizing VS Code for AquilaX, you grant the AquilaX plugin
            read-only access to view scan results within VS Code.
            <br />
            For any questions or assistance, please contact the AquilaX support
            team.
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

export default VSCInstallPage;
