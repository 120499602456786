import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  SimpleGrid,
  Box,
  Text,
  Divider,
  Heading,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import RepositoryList from '../RepositoryList';
import { useDispatch } from 'react-redux';
import { deleteAzureDevOpsInstallationAsync } from '../../../../features/Integrations/integrationsAction';

const AzureDevOpsDetailModal = ({ isOpen, onClose, integration, repos, orgId, onUpdate }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const handleDelete = () => {
    if (!orgId) {
      toast({
        title: 'Missing orgId',
        description: 'Cannot delete Azure DevOps integration without orgId.',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    if (!integration?.installation_id) {
      toast({
        title: 'Missing installation_id',
        description: 'Cannot delete Azure DevOps integration without installation_id.',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    dispatch(deleteAzureDevOpsInstallationAsync({ orgId, installationId: integration.installation_id }))
      .unwrap()
      .then(() => {
        toast({
          title: 'Azure DevOps Disconnected',
          status: 'success',
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: 'Error Deleting Integration',
          description: err.message || 'Please try again later.',
          status: 'error',
          isClosable: true,
        });
      });
  };

  const totalRepos = repos?.length || 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">
          {integration?.repo_type || 'Azure DevOps'} Integration Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {integration ? (
            <VStack align="start" spacing={4}>
              <SimpleGrid columns={[1, 2]} spacing={4} width="100%">
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Connected Repositories:
                  </Text>
                  <Text>{totalRepos}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Integrated:
                  </Text>
                  <Text>{dayjs.unix(integration.created_at).fromNow()}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Actions:
                  </Text>
                  <HStack mt={1}>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      size="sm"
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                    <Button
                      colorScheme="blue"
                      variant="solid"
                      size="sm"
                      onClick={onUpdate}
                    >
                      Update
                    </Button>
                  </HStack>
                </Box>
              </SimpleGrid>

              <Divider />

              <Box w="100%">
                <Heading size="sm" mb={2} color="gray.600">
                  Repositories
                </Heading>
                <RepositoryList repos={repos} repoType={integration.repo_type || 'Azure DevOps'} />
              </Box>
            </VStack>
          ) : (
            <Text>No integration selected.</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AzureDevOpsDetailModal;
