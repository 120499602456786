import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useColorModeValue } from "@chakra-ui/react";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const FindingsScannerAnalysis = ({ scannerData }) => {
  const labels = scannerData.map(item => item.scanner);
  const dataValues = scannerData.map(item => item.count);

  const data = {
    labels,
    datasets: [
      {
        label: "Findings Count",
        data: dataValues,
        backgroundColor: "#7A81FF",
        borderRadius: 10,
        barThickness: 10,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#6B7280",
          font: {
            size: 10,
          },
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "#6B7280",
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `+${context.raw.toLocaleString()}`,
        },
      },
    },
  };

  const containerBg = useColorModeValue("#F9FAFB", "transparent");

  return (
    <div style={{ width: "100%", height: "150px", padding: "10px", backgroundColor: containerBg, borderRadius: "8px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default FindingsScannerAnalysis;
