import {
    FaSignInAlt,
    FaSignOutAlt,
    FaArrowRight,
    FaTrashAlt,
    FaUserCog,
    FaPlusCircle,
    FaKey,
    FaEnvelope,
    FaBuilding,
  } from "react-icons/fa";
  
  const iconMapping = {
    signin: FaSignInAlt,
    signout: FaSignOutAlt,
    update_org: FaArrowRight,
    delete_org: FaTrashAlt,
    update_member_role: FaUserCog,
    create_group: FaPlusCircle,
    delete_group: FaTrashAlt,
    key_created: FaKey,
    invite: FaEnvelope,
  };
  
  export default iconMapping;
  