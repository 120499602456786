import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Avatar,
  VStack,
  HStack,
  Icon,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FaEnvelope, FaCheck } from "react-icons/fa";
import iconMapping from "../../utils/IconMapping";
import { useDispatch, useSelector } from "react-redux";
import { readNotificationAsync } from "../../features/Users/UsersAction";
import { manageInvitationAsync } from "../../features/Orgs/OrgsAction";
import { getUserDataAsync } from "../../features/Users/UsersAction";
import { getNotificationsAsync } from "../../features/Users/UsersAction";
import { getProfileDataAsync } from "../../features/Users/UsersAction";

const NotificationModal = ({ notifications }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { manageInvitationLoading } = useSelector((state) => state.orgs);
  const { loading: readLoading } = useSelector((state) => state.user);

  const getIcon = (event_type) => {
    return iconMapping[event_type] || FaEnvelope;
  };

  const handleAccept = async (notificationId, orgId) => {
    if (!orgId) {
      toast({
        title: "Error",
        description: "Organization ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await dispatch(
        manageInvitationAsync({ organizationId: orgId, action: "accept_invite" })
      ).unwrap();
      toast({
        title: "Invitation Accepted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      await dispatch(readNotificationAsync(notificationId)).unwrap();
      dispatch(getNotificationsAsync());
      // dispatch(getUserDataAsync());
      dispatch(getProfileDataAsync());
    } catch (err) {
      console.error("Error accepting invite:", err);
      toast({
        title: "Error",
        description: err.message || "Failed to accept invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDecline = async (notificationId, orgId) => {
    if (!orgId) {
      toast({
        title: "Error",
        description: "Organization ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await dispatch(
        manageInvitationAsync({ organizationId: orgId, action: "decline_invite" })
      ).unwrap();
      toast({
        title: "Invitation Declined",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      await dispatch(readNotificationAsync(notificationId)).unwrap();
      dispatch(getNotificationsAsync());
    } catch (err) {
      console.error("Error declining invite:", err);
      toast({
        title: "Error",
        description: err.message || "Failed to decline invitation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      await dispatch(readNotificationAsync(notificationId)).unwrap();
      toast({
        title: "Notification Marked as Read",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Error marking notification as read:", err);
      toast({
        title: "Error",
        description: err.message || "Failed to mark notification as read.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      p={0}
      borderRadius="md"
      maxH="400px"
      overflowY="auto"
      mx="auto"
    >
      <Text
        fontWeight="semibold"
        mb={2}
        fontSize="sm"
        color={useColorModeValue("gray.700", "gray.200")}
      >
        Notifications
      </Text>

      <VStack spacing={2} align="stretch">
        {notifications.length === 0 ? (
          <Text fontSize="xs" color={useColorModeValue("gray.600", "gray.300")}>
            No new notifications.
          </Text>
        ) : (
          notifications.map((notification) => {
            const isUnread = !notification.read;
            const notificationBg = useColorModeValue(
              isUnread ? "gray.100" : "white",
              isUnread ? "gray.700" : "gray.800"
            );

            const orgId = notification.event?.org_id || null;

            return (
              <Box
                key={notification._id}
                w="full"
                bg={notificationBg}
                p={3}
                borderRadius="md"
                boxShadow="xs"
                border={'1px'}
                borderColor={useColorModeValue("gray.400", "gray.500")}
              >
                {/* Notification Row */}
                <Flex align="center" justify="space-between">
                  <HStack align="center" spacing={2}>
                    <Avatar
                      src={notification.from_user.picture}
                      size="xs"
                      name={notification.from_user.name}
                    />
                    <Icon
                      as={getIcon(notification.event_type)}
                      boxSize={3}
                      color="blue.500"
                    />

                    {/* Message Container */}
                    <Box>
                      <Text
                        fontWeight={isUnread ? "semibold" : "normal"}
                        fontSize="xs"
                        color={useColorModeValue("gray.700", "gray.200")}
                        cursor={
                          isUnread && notification.event_type !== "invite"
                            ? "pointer"
                            : "default"
                        }
                        onClick={() => {
                          if (isUnread && notification.event_type !== "invite") {
                            handleMarkAsRead(notification._id);
                          }
                        }}
                      >
                        {notification.from_user.name}{" "}
                        <Text
                          as="span"
                          fontSize="xs"
                          fontWeight="normal"
                          color={useColorModeValue("gray.700", "gray.300")}
                        >
                          {notification.message}
                        </Text>
                      </Text>

                      {/* Timestamp */}
                      <Text fontSize="xx-small" color="gray.500">
                        {new Date(notification.ts * 1000).toLocaleString()}
                      </Text>
                    </Box>
                  </HStack>

                  {/* Button to mark as read for non-invite notifications */}
                  {isUnread && notification.event_type !== "invite" && (
                    <Tooltip label="Mark as read" hasArrow>
                      <Button
                        size="xs"
                        variant="ghost"
                        colorScheme="blue"
                        onClick={() => handleMarkAsRead(notification._id)}
                        aria-label={`Mark notification from ${notification.from_user.name} as read`}
                        isLoading={readLoading}
                        p={1}
                        minW="auto"
                      >
                        <Icon as={FaCheck} boxSize={3} />
                      </Button>
                    </Tooltip>
                  )}
                </Flex>

                {/* Invite action buttons */}
                {notification.event_type === "invite" && (
                  <HStack spacing={2} mt={2}>
                    <Button
                      size="xs"
                      colorScheme="blue"
                      variant={'outline'}
                      onClick={() => handleAccept(notification._id, orgId)}
                      isLoading={manageInvitationLoading}
                    >
                      Accept
                    </Button>
                    <Button
                      size="xs"
                      variant={'outline'}
                      colorScheme="red"
                      onClick={() => handleDecline(notification._id, orgId)}
                      isLoading={manageInvitationLoading}
                    >
                      Decline
                    </Button>
                  </HStack>
                )}
              </Box>
            );
          })
        )}
      </VStack>
    </Box>
  );
};

export default NotificationModal;
