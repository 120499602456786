import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  Stack,
  Skeleton,
  useDisclosure,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import IntegrationCard from './Integrations/IntegrationCard';
import GitLabWizard from './Integrations/Gitlab/GitLabWizard';
import GitLabDetailModal from './Integrations/Gitlab/GitLabDetailModal';
import BitbucketWizard from './Integrations/Bitbucket/BitbucketWizard';
import BitbucketDetailModal from './Integrations/Bitbucket/BitbucketDetailModal';
import GitHubDetailModal from './Integrations/Github/GitHubDetailModal';
import JiraInstallModal from './Integrations/Jira/JiraInstallModal';
import JiraDetailModal from './Integrations/Jira/JiraDetailModal';
import AzureDevOpsWizard from './Integrations/Azure/AzureDevOpsWizard';
import AzureDevOpsDetailModal from './Integrations/Azure/AzureDevOpsDetailModal';
import { getGitIntegrationsAsync } from '../../features/Analytics/AnalyticsAction';
import { ALL_INTEGRATIONS } from '../../constants/integrations';

dayjs.extend(relativeTime);

const Integrations = ({ orgId }) => {
  const dispatch = useDispatch();
  const { gitIntegrations, gitLoading, gitError } = useSelector((state) => state.analytics);
  const gitLabWizard = useDisclosure();
  const gitLabDetailModal = useDisclosure();
  const bitbucketWizard = useDisclosure();
  const bitbucketDetailModal = useDisclosure();
  const githubDetailModal = useDisclosure();
  const jiraInstallModal = useDisclosure();
  const jiraDetailModalDisclosure = useDisclosure();
  const azureDevOpsWizard = useDisclosure();
  const azureDevOpsDetailModal = useDisclosure();
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [repos, setRepos] = useState([]);
  const [gray200] = useToken('colors', ['gray.200']);
  const borderCol = useColorModeValue("gray.300", "gray.600");

  useEffect(() => {
    if (orgId) {
      dispatch(getGitIntegrationsAsync({ orgId }));
    }
  }, [dispatch, orgId]);

  if (gitLoading) {
    return (
      <Box
        border="1px solid"
        borderColor={borderCol}
        borderRadius="md"
        p={6}
        mb={6}
        bg={useColorModeValue('gray.50', 'gray.900')}
      >
        <Heading size="md" mb={5}>
          Integrations
        </Heading>
        <Stack spacing={4}>
          {Array.from({ length: ALL_INTEGRATIONS.length }).map((_, index) => (
            <Skeleton key={index} height="50px" borderRadius="md" />
          ))}
        </Stack>
      </Box>
    );
  }

  if (gitError) {
    return (
      <Box p={4}>
        <Text color="red.500">Failed to load integrations: {gitError}</Text>
      </Box>
    );
  }

  const handleIntegrationClick = (integration) => {
    setSelectedIntegration(integration);
    const repoType = integration?.repo_type || integration?.integration_type || '';
    const normalizedRepoType = repoType.toLowerCase().replace(/[-\s]/g, '');
    if (normalizedRepoType === 'gitlab') {
      setRepos(integration.repos || []);
      gitLabDetailModal.onOpen();
    } else if (normalizedRepoType === 'bitbucket') {
      setRepos(integration.repos || []);
      bitbucketDetailModal.onOpen();
    } else if (normalizedRepoType === 'github') {
      setRepos(integration.repos || []);
      githubDetailModal.onOpen();
    } else if (normalizedRepoType === 'jira') {
      setRepos([]);
      jiraDetailModalDisclosure.onOpen();
    } else if (normalizedRepoType === 'azuredevops') {
      setRepos(integration.repos || []);
      azureDevOpsDetailModal.onOpen();
    }
  };

  const openGitLabWizard = () => {
    gitLabWizard.onOpen();
  };

  const openBitbucketWizard = () => {
    bitbucketWizard.onOpen();
  };

  const handleInstallGitHub = () => {
    const state = btoa(JSON.stringify({ orgId }));
    window.location.href = `https://github.com/apps/aquilax-ai-security/installations/select_target?state=${state}`;
  };

  const handleInstallJira = () => {
    jiraInstallModal.onOpen();
  };

  const handleInstallAzureDevOps = () => {
    azureDevOpsWizard.onOpen();
  };

  const nonNullGitIntegrations = (gitIntegrations || []).filter(Boolean);

  return (
    <Box
      border="1px solid"
      borderColor={borderCol}
      borderRadius="md"
      p={6}
      mb={6}
      bg={useColorModeValue('gray.50', 'gray.900')}
    >
      <Heading size="md" mb={5}>
        Integrations
      </Heading>
      <Stack spacing={4}>
        {ALL_INTEGRATIONS.map((integration) => {
          if (!integration) return null;
          const integrationType = (
            integration.repo_type ||
            integration.integration_type ||
            ''
          ).toLowerCase().replace(/[-\s]/g, '');
          const integrationData = nonNullGitIntegrations.find((intg) => {
            if (!intg) return false;
            const repo = (
              intg.repo_type ||
              intg.integration_type ||
              ''
            ).toLowerCase().replace(/[-\s]/g, '');
            return repo === integrationType;
          });
          const isIntegrated = !!integrationData;
          let totalRepos = 0;
          if (isIntegrated) {
            totalRepos = integrationData?.repos?.length || 0;
          }
          const integratedAt =
          isIntegrated && integrationData?.created_at
            ? dayjs.unix(integrationData.created_at).fromNow()
            : '';
          return (
            <IntegrationCard
              key={integrationType}
              integration={integration}
              integrationData={integrationData}
              isIntegrated={isIntegrated}
              totalRepos={totalRepos}
              integratedAt={integratedAt}
              onInstall={() => {
                switch (integrationType) {
                  case 'gitlab':
                    openGitLabWizard();
                    break;
                  case 'bitbucket':
                    openBitbucketWizard();
                    break;
                  case 'github':
                    handleInstallGitHub();
                    break;
                  case 'jira':
                    handleInstallJira();
                    break;
                  case 'azuredevops':
                    handleInstallAzureDevOps();
                    break;
                  default:
                    window.open(`/install-integration/${integration?.repo_type}`, '_blank');
                }
              }}
              onViewDetails={handleIntegrationClick}
            />
          );
        })}
      </Stack>
      <GitLabDetailModal
        isOpen={gitLabDetailModal.isOpen}
        onClose={gitLabDetailModal.onClose}
        integration={selectedIntegration}
        orgId={orgId}
        repos={repos}
      />
      <GitLabWizard isOpen={gitLabWizard.isOpen} onClose={gitLabWizard.onClose} orgId={orgId} />
      <BitbucketDetailModal
        isOpen={bitbucketDetailModal.isOpen}
        onClose={bitbucketDetailModal.onClose}
        integration={selectedIntegration}
        repos={repos}
        orgId={orgId}
      />
      <BitbucketWizard isOpen={bitbucketWizard.isOpen} onClose={bitbucketWizard.onClose} orgId={orgId} />
      <GitHubDetailModal
        isOpen={githubDetailModal.isOpen}
        onClose={githubDetailModal.onClose}
        integration={selectedIntegration}
        repos={repos}
        orgId={orgId}
        onUpdate={handleInstallGitHub}
      />
      <JiraInstallModal
        isOpen={jiraInstallModal.isOpen}
        onClose={jiraInstallModal.onClose}
        orgId={orgId}
      />
      <JiraDetailModal
        isOpen={jiraDetailModalDisclosure.isOpen}
        onClose={jiraDetailModalDisclosure.onClose}
        integration={selectedIntegration}
        orgId={orgId}
      />
      <AzureDevOpsWizard
        isOpen={azureDevOpsWizard.isOpen}
        onClose={azureDevOpsWizard.onClose}
        orgId={orgId}
      />
      <AzureDevOpsDetailModal
        isOpen={azureDevOpsDetailModal.isOpen}
        onClose={azureDevOpsDetailModal.onClose}
        integration={selectedIntegration}
        repos={repos}
        orgId={orgId}
        onUpdate={() => {}}
      />
    </Box>
  );
};

export default Integrations;
