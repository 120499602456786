import React, { useState, useMemo } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  FormControl,
  FormErrorMessage,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Box,
  VStack,
  HStack,
  Divider,
  List,
  ListItem,
  Tooltip,
  Icon,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { FaGithub, FaGitlab, FaBitbucket } from "react-icons/fa";
import { SiAzuredevops } from "react-icons/si";

const RepoInput = ({
  repoLink,
  setRepoLink,
  handleKeyPress,
  isDisabled,
  groupRepos,
  isInvalid,
  errorMessage,
  availableRepos = [],
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputBorderColor = useColorModeValue("gray.300", "gray.600");
  const inputHoverBorderColor = useColorModeValue("blue.400", "blue.300");
  const popoverBg = useColorModeValue("white", "gray.800");
  const listItemHoverBg = useColorModeValue("gray.100", "gray.700");

  const groupedRepos = useMemo(() => {
    return availableRepos.reduce((acc, repo) => {
      const type = (repo.repo_type || "").toLowerCase().replace(/[-\s]/g, "");
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(repo);
      return acc;
    }, {});
  }, [availableRepos]);

  const platformConfig = {
    github: {
      label: "GitHub Repositories",
      icon: FaGithub,
      color: "#181717",
    },
    gitlab: {
      label: "GitLab Projects",
      icon: FaGitlab,
      color: "#FC6D26",
    },
    bitbucket: {
      label: "Bitbucket Repositories",
      icon: FaBitbucket,
      color: "#0052CC",
    },
    azuredevops: {
      label: "Azure DevOps Repositories",
      icon: SiAzuredevops,
      color: "#0078D4",
    },
  };

  const filteredGroupedRepos = useMemo(() => {
    if (!searchTerm) return groupedRepos;
    const lowerTerm = searchTerm.toLowerCase();
    return Object.keys(groupedRepos).reduce((acc, type) => {
      const filtered = groupedRepos[type].filter((repo) => {
        const fullName = repo.full_name?.toLowerCase() || "";
        const name = repo.name?.toLowerCase() || "";
        return fullName.includes(lowerTerm) || name.includes(lowerTerm);
      });
      if (filtered.length > 0) {
        acc[type] = filtered;
      }
      return acc;
    }, {});
  }, [searchTerm, groupedRepos]);

  const handleInputChange = (e) => {
    setRepoLink(e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleRepoSelect = (repoUrl) => {
    setRepoLink(repoUrl);
    onClose();
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <Text fontSize="sm" color="gray.500" mb={1}>
        Provide the URL or repository link to scan:
      </Text>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-start"
        closeOnBlur
        closeOnEsc
        motionPreset="scale"
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              placeholder="e.g., https://github.com/username/repo"
              value={repoLink}
              onChange={handleInputChange}
              onClick={onOpen}
              onKeyDown={handleKeyPress}
              isDisabled={isDisabled}
              borderColor={inputBorderColor}
              focusBorderColor="blue.600"
              _hover={{
                borderColor: inputHoverBorderColor,
              }}
              transition="border-color 0.2s"
            />
            <InputRightElement>
              <IconButton
                icon={<FiArrowRight />}
                aria-label="Start Scan"
                bg="blue.600"
                color="white"
                _hover={{ bg: "blue.700" }}
                onClick={handleKeyPress}
                isDisabled={isDisabled}
              />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>

        <PopoverContent
          bg={popoverBg}
          width="600px"
          maxH="400px"
          overflowY="auto"
          borderRadius="md"
          boxShadow="xl"
        >
          <PopoverArrow />
          <PopoverBody>
            {groupRepos && groupRepos.length > 0 && (
              <Box mb={4}>
                <HStack mb={2}>
                  <Text fontWeight="bold" fontSize="md">
                    Group Repos
                  </Text>
                </HStack>
                <Divider mb={2} />
                <List spacing={2}>
                  {groupRepos.map((repoUrl, idx) => (
                    <ListItem
                      key={idx}
                      p={2}
                      borderRadius="md"
                      transition="background-color 0.2s"
                      _hover={{ bg: listItemHoverBg, cursor: "pointer" }}
                      onClick={() => handleRepoSelect(repoUrl)}
                    >
                      <Text fontWeight="semibold" fontSize="sm" isTruncated>
                        {repoUrl}
                      </Text>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {Object.keys(filteredGroupedRepos).length > 0 ? (
              Object.keys(filteredGroupedRepos).map((type) => {
                const platform = platformConfig[type] || {};
                return (
                  <Box key={type} mb={4}>
                    <HStack mb={2}>
                      {platform.icon && (
                        <Icon
                          as={platform.icon}
                          boxSize={5}
                          color={platform.color}
                        />
                      )}
                      <Text fontWeight="bold" fontSize="md">
                        {platform.label || "Repositories"}
                      </Text>
                    </HStack>
                    <Divider mb={2} />
                    <List spacing={2}>
                      {filteredGroupedRepos[type].map((repo) => (
                        <ListItem
                          key={repo.id}
                          p={2}
                          borderRadius="md"
                          transition="background-color 0.2s"
                          _hover={{ bg: listItemHoverBg, cursor: "pointer" }}
                          onClick={() => handleRepoSelect(repo.html_url)}
                        >
                          <HStack spacing={2}>
                            {platform.icon && (
                              <Icon
                                as={platform.icon}
                                boxSize={4}
                                color={platform.color}
                              />
                            )}
                            <VStack align="start" spacing={0}>
                              <Tooltip
                                label={repo.full_name}
                                aria-label="Repository Name Tooltip"
                              >
                                <Text
                                  fontWeight="semibold"
                                  fontSize="sm"
                                  isTruncated
                                  maxW="300px"
                                >
                                  {repo.name}
                                </Text>
                              </Tooltip>
                              {repo.description && (
                                <Text
                                  fontSize="xs"
                                  color="gray.500"
                                  isTruncated
                                  maxW="300px"
                                >
                                  {repo.description}
                                </Text>
                              )}
                            </VStack>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                );
              })
            ) : (
              <Text fontSize="sm">
                No repositories found. Please integrate a source.
              </Text>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>

      {!isInvalid ? null : <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default RepoInput;
