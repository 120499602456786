import React from 'react';
import { Box, Flex, Text, Icon, Stack, Button, IconButton } from '@chakra-ui/react';
import { BsPerson, BsGraphUp, BsFileEarmarkText } from 'react-icons/bs';
import { FiBarChart2 } from 'react-icons/fi';
import { ChevronRight } from 'lucide-react';
import { useSelector } from 'react-redux';

const Statistic = (borderCol) => {
  const { 
    intellData, 
    loading, 
    error, 
    report,
    reportLoading, 
    reportError 
  } = useSelector((state) => state.analytics);

  const lineOfCode = report?.lines_of_code;
  const totalVulns = report?.vulnerabilities;
  const tpVulns = report?.tp_vulnerabilities;

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={2}>
        Statistic
      </Text>
      <Stack spacing={2}>
        {/* First Statistic Card */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p={2}
          border={'1px'}
          borderColor={'gray.400'}
          borderRadius="md"
        >
          <Flex alignItems="center">
            <Box
              bg="blue.100"
              p={2}
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={BsPerson} boxSize={5} color="blue.500" />
            </Box>
            <Flex flexDirection="column" ml={4}>
              <Text fontSize="md" fontWeight="bold">
                {lineOfCode}
              </Text>
              <Text fontSize="sm" color="gray.500">
                Lines of Code
              </Text>
            </Flex>
          </Flex>
          <IconButton
                size="md"
                variant="ghost"
                colorScheme="blue"
                aria-label="View Details"
                icon={<Icon as={ChevronRight} />}
            />
        </Flex>

        {/* Second Statistic Card */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderRadius="md"
          border={'1px'}
          borderColor={'gray.400'}
        >
          <Flex alignItems="center">
            <Box
              bg="blue.100"
              p={2}
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={BsGraphUp} boxSize={5} color="blue.500" />
            </Box>
            <Flex flexDirection="column" ml={4}>
              <Text fontSize="md" fontWeight="bold">
              {totalVulns}
              </Text>
              <Text fontSize="sm" color="gray.500">
              Vulnerabilities
              </Text>
            </Flex>
          </Flex>
          <IconButton
                size="md"
                variant="ghost"
                colorScheme="blue"
                aria-label="View Details"
                icon={<Icon as={ChevronRight} />}
            />
        </Flex>

        {/* Third Statistic Card */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p={2}
          borderRadius="md"
          border={'1px'}
          borderColor={'gray.400'}
        >
          <Flex alignItems="center">
            <Box
              bg="blue.100"
              p={2}
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={BsFileEarmarkText} boxSize={5} color="blue.500" />
            </Box>
            <Flex flexDirection="column" ml={4}>
              <Text fontSize="md" fontWeight="bold">
                {tpVulns}
              </Text>
              <Text fontSize="sm" color="gray.500">
                True Positives
              </Text>
            </Flex>
          </Flex>
          <IconButton
                size="md"
                variant="ghost"
                colorScheme="blue"
                aria-label="View Details"
                icon={<Icon as={ChevronRight} />}
            />
        </Flex>
      </Stack>
    </Box>
  );
};

export default Statistic;
