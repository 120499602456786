import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularGraph = ({ data }) => {
  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "30px" }}>
      {data.map((item, index) => {
        const percentage = total > 0 ? Math.round((item.value / total) * 100) : 0;
        return (
          <div key={index} style={{ width: "150px", height: "150px" }}>
            <CircularProgressbarWithChildren
              value={percentage}
              styles={buildStyles({
                pathColor: item.color || getColor(item.name),
                trailColor: "#E5E5E5",
                strokeLinecap: "round",
              })}
            >
              <div style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                <div style={{ color: item.color || getColor(item.name) }}>{item.name}</div>
                <div style={{ fontSize: "16px", marginTop: "5px" }}>
                  {item.value} ({percentage}%)
                </div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        );
      })}
    </div>
  );
};

function getColor(name) {
  switch (name) {
    case 'True Positive':
      return '#FF6B6B';
    case 'False Positive':
      return '#5B8DFD';
    case 'Unverified':
      return '#FFD93D';
    default:
      return '#CCCCCC';
  }
}

export default CircularGraph;
