import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { applyLicenseAsync } from "../../features/Orgs/OrgsAction";

const LicenseKeyModal = ({ isOpen, onClose }) => {
  const [licenseKey, setLicenseKey] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const organization = useSelector((state) => state.orgs.organization);

  const handleApplyLicenseKey = async () => {
    if (!licenseKey.trim()) {
      toast({
        title: "License key is required.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const resultAction = dispatch(
          applyLicenseAsync({ organizationId: organization._id, license_key: licenseKey })
      );

      if (applyLicenseAsync.fulfilled.match(resultAction)) {
        toast({
          title: "License applied successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Failed to apply license.",
          description: resultAction.payload?.message || "An error occurred.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An unexpected error occurred.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLicenseKey("");
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add your License Key</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={3} color="gray.600" fontSize="sm">
            If you have a license key provided by AquilaX, enter it below and click “Apply” to sync your organization’s license.
          </Text>
          <Input
            type="password"
            placeholder="Enter License Key"
            value={licenseKey}
            onChange={(e) => setLicenseKey(e.target.value)}
            size="sm"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" size="sm" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bg="blue.600"
            color="white"
            _hover={{ bg: "blue.700" }}
            size="sm"
            onClick={handleApplyLicenseKey}
          >
            Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LicenseKeyModal;
