import React from 'react';
import {
  HStack,
  Input,
  Divider,
  Menu,
  MenuButton,
  Button,
  MenuList,
  CheckboxGroup,
  VStack,
  Checkbox,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const VulnerabilitiesFilterBar = ({
  searchQuery,
  handleSearchChange,
  uniqueSeverities,
  selectedSeverities,
  handleSeverityChange,
  uniqueScanners,
  selectedScanners,
  handleScannerChange,
  clearFilters,
}) => {
  return (
    <HStack spacing={1} mb={4} align="center">
      <Input
        placeholder="Search by vulnerability name..."
        size="sm"
        variant="outline"
        value={searchQuery}
        onChange={handleSearchChange}
        width="750px"
      />

      <Divider orientation="vertical" />

      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          size="sm"
          variant="outline"
          rightIcon={<ChevronDownIcon />}
        >
          Filter by Severity
        </MenuButton>
        <MenuList minWidth="240px">
          <CheckboxGroup
            colorScheme="blue"
            value={selectedSeverities}
            onChange={handleSeverityChange}
          >
            <VStack align="start" spacing={1} p={2}>
              {uniqueSeverities.map((severity) => (
                <Checkbox key={severity} value={severity}>
                  {severity}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </MenuList>
      </Menu>

      <Divider orientation="vertical" />

      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          size="sm"
          variant="outline"
          rightIcon={<ChevronDownIcon />}
        >
          Filter by Scanner
        </MenuButton>
        <MenuList minWidth="240px">
          <CheckboxGroup
            colorScheme="blue"
            value={selectedScanners}
            onChange={handleScannerChange}
          >
            <VStack align="start" spacing={1} p={2}>
              {uniqueScanners.map((scanner) => (
                <Checkbox key={scanner} value={scanner}>
                  {scanner}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </MenuList>
      </Menu>

      <Button size="sm" variant="ghost" colorScheme="red" onClick={clearFilters}>
        Clear Filters
      </Button>
    </HStack>
  );
};

export default VulnerabilitiesFilterBar;
