import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UpgradeStripePricingTable from "../components/Plans/UpgradeStripePricingTable";

const UpgradePlanPage = () => {
  const { orgId } = useParams();
  const userDetails = useSelector((state) => state.user.userData);
  const organization = { _id: orgId };

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box p={4} bg={bgColor}>
      <UpgradeStripePricingTable orgDetails={organization} userDetails={userDetails} />
    </Box>
  );
};

export default UpgradePlanPage;
