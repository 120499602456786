import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import Statistic from "./Statistic";

const StatisticsSection = () => {
  const boxBg = useColorModeValue("white", "gray.800");
  const borderCol = useColorModeValue("gray.300", "gray.600");
  const mainTextColor = useColorModeValue("gray.900", "gray.100");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  return (
    <Box
      flex="1"
      bg={boxBg}
      borderRadius="lg"
      border="1px"
      borderColor={borderCol}
      p={6}
    >
      <Statistic
        mainTextColor={mainTextColor}
        subTextColor={subTextColor}
        boxBg={boxBg}
        borderCol={borderCol}
      />
    </Box>
  );
};

export default StatisticsSection;
