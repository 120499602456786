import React, { useMemo } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import { format, subYears } from 'date-fns';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const COLOR_CLASSES = [
  'color-scale-0',
  'color-scale-1',
  'color-scale-2',
  'color-scale-3',
  'color-scale-4',
  'color-scale-5',
  'color-scale-6',
];

const ScanHeatmap = ({ rawScans = [] }) => {
  const heatmapData = useMemo(() => {
    const scansByDate = rawScans.reduce((acc, scan) => {
      const dateStr = format(new Date(scan.created_at * 1000), 'yyyy-MM-dd');
      if (!acc[dateStr]) {
        acc[dateStr] = { count: 0, statuses: {} };
      }
      acc[dateStr].count += 1;
      const { status } = scan;
      acc[dateStr].statuses[status] = (acc[dateStr].statuses[status] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(scansByDate).map(([date, info]) => ({
      date,
      count: info.count,
      statuses: info.statuses,
    }));
  }, [rawScans]);

  const getColorClass = (value) => {
    if (value === 0) {
      return COLOR_CLASSES[0]; // #EBF8FF
    } else if (value < 5) {
      return COLOR_CLASSES[1]; // #BEE3F8
    } else if (value < 20) {
      return COLOR_CLASSES[2]; // #90CDF4
    } else if (value < 50) {
      return COLOR_CLASSES[3]; // #63B3ED
    } else if (value < 100) {
      return COLOR_CLASSES[4]; // #4299E1
    } else if (value < 200) {
      return COLOR_CLASSES[5]; // #3182CE
    }
    return COLOR_CLASSES[6];   // #2B6CB0 (200+)
  };

  const customTooltipDataAttrs = (value) => {
    if (!value || !value.date) return {};

    const { date, count, statuses } = value;
    const statusDetails = statuses
      ? Object.entries(statuses)
          .map(([status, cnt]) => `${status}: ${cnt}`)
          .join(', ')
      : 'No details';

    return {
      'data-tooltip-id': 'scanHeatmapTooltip',
      'data-tooltip-content': `${date}: ${count} scans \n ${statusDetails}`,
      'data-tooltip-place': 'top',
      'data-tooltip-variant': 'info',
      'data-tooltip-multiline': true,
    };
  };

  const today = new Date();
  const lastYear = subYears(today, 1);

  const boxBg = useColorModeValue('white', 'gray.800');
  const borderCol = useColorModeValue('gray.300', 'gray.600');
  const emptyCellColor = useColorModeValue('#ebedf0', '#2d3748');

  return (
    <Box
      bg={boxBg}
      borderRadius="lg"
      border="1px"
      borderColor={borderCol}
      p={4}
      height="300px"
      width="100%"
      overflow="hidden"
    >
      <Box height="calc(100% - 40px)" width="100%" mt={16}>
        <CalendarHeatmap
          startDate={lastYear}
          endDate={today}
          values={heatmapData}
          classForValue={(value) => {
            if (!value) return COLOR_CLASSES[0];
            return getColorClass(value.count);
          }}
          tooltipDataAttrs={customTooltipDataAttrs}
        />
      </Box>

      <ReactTooltip
        id="scanHeatmapTooltip"
        multiline={true}
      />

      <style jsx global>{`
        .react-calendar-heatmap .color-scale-0 {
          fill: ${emptyCellColor}; /* either #ebedf0 or #2d3748, depending on color mode */
        }
        .react-calendar-heatmap .color-scale-1 {
          fill: #bee3f8;
        }
        .react-calendar-heatmap .color-scale-2 {
          fill: #90cdf4;
        }
        .react-calendar-heatmap .color-scale-3 {
          fill: #63b3ed;
        }
        .react-calendar-heatmap .color-scale-4 {
          fill: #4299e1;
        }
        .react-calendar-heatmap .color-scale-5 {
          fill: #3182ce;
        }
        .react-calendar-heatmap .color-scale-6 {
          fill: #2b6cb0;
        }
        /* 
         * The first array element was "#EBF8FF" but we've decided to use 
         * useColorModeValue for empty cells. If you'd prefer a fixed color instead,
         * you can replace fill: ${emptyCellColor} with fill: #EBF8FF 
         * in .color-scale-0
         */
        
        /* Add cell border or spacing if desired */
        .react-calendar-heatmap .react-calendar-heatmap-week .react-calendar-heatmap-day {
          stroke: ${useColorModeValue('#fff', '#2d3748')};
        }
      `}</style>
    </Box>
  );
};

export default ScanHeatmap;
