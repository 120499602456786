import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProjects,
  getProjectCommits,
  getCommitScans,
  updateProject,
  deleteProject,
  createProject,
  stopScheduledScan,
  startScheduledScan,
} from "../../apis/projects";

// 1. Get Projects
export const getProjectsAsync = createAsyncThunk(
  "projects/getProjects",
  async ({ orgId, groupId, page = 1, limit = 10 }, thunkAPI) => {
    const data = await getProjects(orgId, groupId, thunkAPI, page, limit);

    if (!data) {
      return {
        projects: [],
        total: 0,
        page,
        limit,
      };
    }

    if (Array.isArray(data)) {
      return {
        projects: data,
        total: data.length,
        page,
        limit,
      };
    }

    return data;
  }
);

// 2. Get Project Commits
export const getProjectCommitsAsync = createAsyncThunk(
  "projects/getProjectCommits",
  async ({ orgId, groupId, projectId }, thunkAPI) => {
    const response = await getProjectCommits(orgId, groupId, projectId, thunkAPI);
    return { projectId, commits: response, total: response.length };
  }
);

// 3. Get Commit Scans
export const getCommitScansAsync = createAsyncThunk(
  "projects/getCommitScans",
  async ({ orgId, groupId, projectId, commitSha }, thunkAPI) => {
    const response = await getCommitScans(orgId, groupId, projectId, commitSha, thunkAPI);
    return { commitSha, scans: response };
  }
);

// 4. Update Project
export const updateProjectAsync = createAsyncThunk(
  "projects/updateProject",
  async ({ orgId, groupId, projectId, projectData }, thunkAPI) => {
    const response = await updateProject(orgId, groupId, projectId, projectData, thunkAPI);
    if (response.message) {
      const state = thunkAPI.getState();
      const currentPage = state.projects.currentPage;
      const limit = state.projects.limit;
      await thunkAPI.dispatch(getProjectsAsync({ orgId, groupId, page: currentPage, limit }));
    }
    return response;
  }
);

// 5. Delete Project
export const deleteProjectAsync = createAsyncThunk(
  "projects/deleteProject",
  async ({ orgId, groupId, projectId }, thunkAPI) => {
    const response = await deleteProject(orgId, groupId, projectId, thunkAPI);
    return { projectId, ...response };
  }
);

// 6. Create Project
export const createProjectAsync = createAsyncThunk(
  "projects/createProject",
  async ({ orgId, groupId, projectData }, thunkAPI) => {
    const response = await createProject(orgId, groupId, projectData, thunkAPI);
    return response;
  }
);

// 7. Stop Scheduled Scan
export const stopScheduledScanAsync = createAsyncThunk(
  "projects/stopScheduledScan",
  async ({ orgId, groupId, projectId }, thunkAPI) => {
    const response = await stopScheduledScan(orgId, groupId, projectId, thunkAPI);
    return { projectId, ...response };
  }
);

// 8. Start Scheduled Scan
export const startScheduledScanAsync = createAsyncThunk(
  "projects/startScheduledScan",
  async ({ orgId, groupId, projectId, frequency }, thunkAPI) => {
    const response = await startScheduledScan(orgId, groupId, projectId, frequency, thunkAPI);
    return { projectId, frequency, ...response };
  }
);
