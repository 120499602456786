import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateProjectAsync } from "../../features/Projects/ProjectAction";

const EditProjectModal = ({ isOpen, onClose, project, orgId, groupId }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [name, setName] = useState(project.name);
  const [tags, setTags] = useState((project.tags || []).join(", "));
  const [frequency, setFrequency] = useState(project.frequency);

  useEffect(() => {
    if (project) {
      setName(project.name);
      setTags((project.tags || []).join(", "));
      setFrequency(project.frequency);
    }
  }, [project]);

  const handleSubmit = () => {
    if (!name.trim()) {
      toast({
        title: "Validation Error",
        description: "Project name cannot be empty.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const updatedData = {
      name: name.trim(),
      tags: tags.split(",").map((tag) => tag.trim()),
      frequency,
    };

    dispatch(
      updateProjectAsync({
        orgId,
        groupId,
        projectId: project._id,
        projectData: updatedData,
      })
    )
      .unwrap()
      .then(() => {
        toast({
          title: "Project Updated",
          description: "The project has been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Update Failed",
          description: err.message || "An error occurred while updating the project.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl id="project-name" isRequired>
              <FormLabel>Project Name</FormLabel>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter project name"
                size="sm"
              />
            </FormControl>
            <FormControl id="tags">
              <FormLabel>Tags (comma separated)</FormLabel>
              <Input
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="e.g., tag1, tag2, tag3"
                size="sm"
              />
            </FormControl>
            <FormControl id="frequency" isRequired>
              <FormLabel>Scanning Frequency</FormLabel>
              <Select
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
                placeholder="Select frequency"
                size="sm"
              >
                <option value="Once">Once</option>
                <option value="Hourly">Hourly</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} size="sm">
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} size="sm">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProjectModal;
