import React from 'react';
import { Link } from '@chakra-ui/react';

const BracketLink = ({ href, children }) => (
  <Link
    href={href}
    textDecoration="none"
    color="white"
    position="relative"
    _hover={{ textDecoration: 'underline' }}
    _before={{
      content: '"["',
      marginRight: '4px',
    }}
    _after={{
      content: '"]"',
      marginLeft: '4px',
    }}
  >
    {children}
  </Link>
);

export default BracketLink;
