import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserData, userOnboarding, getNotifications , getActivityLogs, readNotification,  getAccessTokens, createAccessToken, deleteAccessToken, changeNotificationOptInStatus, getProfileData } from "../../apis/users";

export const getUserDataAsync = createAsyncThunk(
  "user/getUserData",
  async (_, thunkAPI) => {
    const response = await getUserData(thunkAPI);
    return response;
  }
);

export const userOnboardingAsync = createAsyncThunk(
  "user/onboarding",
  async (onboardingData, thunkAPI) => {
    const response = await userOnboarding(onboardingData, thunkAPI);
    return response;
  }
);

export const getNotificationsAsync = createAsyncThunk(
  "user/getNotifications",
  async (_, thunkAPI) => {
    const response = await getNotifications(thunkAPI);
    return response;
  }
);

export const readNotificationAsync = createAsyncThunk(
  "user/readNotification",
  async (notificationId, thunkAPI) => {
    const response = await readNotification(notificationId, thunkAPI);
    return response;
  }
);

export const getActivityLogsAsync = createAsyncThunk(
  "user/getActivityLogs",
  async (_, thunkAPI) => {
    const response = await getActivityLogs(thunkAPI);
    return response;
  }
);

export const getAccessTokensAsync = createAsyncThunk(
  "user/getAccessTokens",
  async (_, thunkAPI) => {
    const response = await getAccessTokens(thunkAPI);
    return response;
  }
);

export const createAccessTokenAsync = createAsyncThunk(
  "user/createAccessToken",
  async (tokenData, thunkAPI) => {
    const response = await createAccessToken(tokenData, thunkAPI);
    return response;
  }
);

export const deleteAccessTokenAsync = createAsyncThunk(
  "user/deleteAccessToken",
  async (accessTokenId, thunkAPI) => {
    const response = await deleteAccessToken(accessTokenId, thunkAPI);
    return { ...response, accessTokenId }; 
  }
);

export const changeNotificationOptInStatusAsync = createAsyncThunk(
  "user/changeNotificationOptInStatus",
  async (notificationOptIn, thunkAPI) => {
    const response = await changeNotificationOptInStatus(notificationOptIn, thunkAPI);
    return response;
  }
);

export const getProfileDataAsync = createAsyncThunk(
  "user/getProfileData",
  async (_, thunkAPI) => {
    const response = await getProfileData(thunkAPI);
    return response;
  }
);