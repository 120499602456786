import React from "react";
import { useSelector } from "react-redux";
import { Box, HStack, Flex, useColorModeValue } from "@chakra-ui/react";
import Header from "../components/Home/Header";
import OrganizationDetails from "../components/Home/OrganizationDetails";
import LineChartCard from "../components/Home/LineChartCard";
import RadarChartComponent from "../components/Home/RadarChartComponent";
import SecuriTronIntelligence from "../components/Home/SecuriTronIntelligence";

const Dashboard = () => {
  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);


  const { 
    intellData, 
    loading, 
    error, 
    report,
    reportLoading, 
    reportError 
  } = useSelector((state) => state.analytics);

  let findingsData = {};
  if (intellData && Array.isArray(intellData.intell_data)) {
    findingsData = intellData.intell_data.find(
      (item) => item && item.total_findings !== undefined
    ) || {};
  }
  const totalFindings = findingsData.total_findings || 0;
  const currentWeekFindings = findingsData.current_week_findings || 0;
  const lastWeekFindings = findingsData.last_week_findings || 0;
  const defended = findingsData.true_positive || 0;

  const lastTotalFindings = totalFindings - currentWeekFindings;
  const totalFindingsChange = lastTotalFindings > 0 
    ? ((totalFindings - lastTotalFindings) / lastTotalFindings) * 100 
    : 0;
  const totalFindingsTrend = totalFindingsChange > 0 
    ? `+${totalFindingsChange.toFixed(1)}%` 
    : totalFindingsChange < 0 
      ? `-${Math.abs(totalFindingsChange).toFixed(1)}%` 
      : "0%";
  const totalFindingsTrendColor = totalFindingsChange > 0 
    ? "red" 
    : totalFindingsChange < 0 
      ? "green" 
      : "gray";

  const currentWeekFindingsChange = lastWeekFindings > 0 
    ? ((lastWeekFindings - currentWeekFindings) / lastWeekFindings) * 100 
    : 0;
  const currentWeekTrend = currentWeekFindingsChange > 0 
    ? `-${currentWeekFindingsChange.toFixed(1)}%` 
    : currentWeekFindingsChange < 0 
      ? `+${Math.abs(currentWeekFindingsChange).toFixed(1)}%` 
      : "0%";
  const currentWeekTrendColor = currentWeekFindingsChange > 0 
    ? "green" 
    : currentWeekFindingsChange < 0 
      ? "red" 
      : "gray";

  const currentWeekPercentageOfTotal = totalFindings > 0
    ? `${((currentWeekFindings / totalFindings) * 100).toFixed(1)}%`
    : "0%";

  const cards = [
    {
      title: "Total Findings",
      value: totalFindings,
      trend: totalFindingsTrend,
      trendColor: totalFindingsTrendColor,
    },
    {
      title: "Current Week Findings",
      value: currentWeekFindings,
      trend: currentWeekTrend,
      trendColor: currentWeekTrendColor,
      percentageOfTotal: currentWeekPercentageOfTotal,
    },
  ];

  const trendData = [
    { name: "Day 1", value: 100 },
    { name: "Day 2", value: 200 },
    { name: "Day 3", value: 150 },
    { name: "Day 4", value: 300 },
    { name: "Day 5", value: 250 },
  ];

  const pageBg = useColorModeValue("gray.100", "gray.900");

  return (
    <Box p={6}>
      <Header title="AquilaX Empowered with SecuriTron Intelligence ⭐" />
      <HStack spacing={6} align="stretch" mb={6}>
        <OrganizationDetails orgDetails={orgDetails} groupId={selectedGroup?.id || ""} />
      </HStack>

      {/* <HStack spacing={4} overflowX="auto" mb={6}>
        {cards.map((card, index) => (
          <LineChartCard key={index} card={card} trendData={trendData} />
        ))}
      </HStack> */}
    </Box>
  );
};

export default Dashboard;
