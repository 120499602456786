import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  VStack,
  SimpleGrid,
  Skeleton,
  HStack,
  useColorModeValue,
  SkeletonText,
  Icon,
} from "@chakra-ui/react";
import { Users, Group, FolderGit2, ScanSearch } from "lucide-react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import StatsCards from "../components/Report/Cards";
import ConfirmedVulnerabilities from "../components/Report/Vulns";
import { getReportAsync } from "../features/Analytics/AnalyticsAction";
import MarkdownSummary from "../components/Report/MarkdownSummary";

const url = process.env.REACT_APP_API_URL;

const calculatePercentageChange = (current, previous) => {
  if (!previous || previous === 0) return current > 0 ? 100 : 0;
  const diff = current - previous;
  const percent = ((diff / previous) * 100).toFixed(1);
  return parseFloat(percent);
};

function ReportPage() {
  const dispatch = useDispatch();
  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const orgId = orgDetails?._id;
  const plan = orgDetails?.plan;
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);
  const groupId = selectedGroup ? selectedGroup.id : null;

  const report = useSelector((state) => state.analytics.report);
  const reportLoading = useSelector((state) => state.analytics.reportLoading);
  const reportError = useSelector((state) => state.analytics.reportError);

  const pageBg = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");
  const textCol = useColorModeValue("gray.600", "gray.300");

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  useEffect(() => {
    if (!orgId || !selectedGroup?.id) return;
    dispatch(getReportAsync({ orgId, groupId: selectedGroup.id }));
  }, [orgId, selectedGroup?.id, dispatch]);

  const ExpandableSummary = ({ text, textColor }) => {
    const [step, setStep] = useState(0);
    if (!text) return null;
    if (text.length <= 100)
      return (
        <Text fontSize="sm" color={textColor}>
          {text}
        </Text>
      );

    if (step === 0) {
      const initialText = text.slice(0, 100);
      return (
        <Text fontSize="sm" color={textColor}>
          {initialText}...{" "}
          <Button
            variant="link"
            color={useColorModeValue("blue.700", "blue.700")}
            onClick={() => setStep(1)}
          >
            Read More
          </Button>
        </Text>
      );
    }
    if (step === 1) {
      const remaining = text.slice(100);
      const words = remaining.split(/\s+/);
      const nextChunk = words.slice(0, 150).join(" ");
      const shownText = text.slice(0, 100) + " " + nextChunk;
      if (words.length > 150) {
        return (
          <Text fontSize="sm" color={textColor}>
            {shownText}...{" "}
            <Button variant="link" onClick={() => setStep(2)}>
              Read More
            </Button>
          </Text>
        );
      } else {
        return (
          <Text fontSize="sm" color={textColor}>
            {shownText}
          </Text>
        );
      }
    }
    return (
      <Text fontSize="sm" color={textCol}>
        {text}
      </Text>
    );
  };

  const handleDownload = async () => {
    if (!groupId) {
      setDownloadError("Group ID is missing. Cannot download the report.");
      return;
    }

    setDownloadLoading(true);
    setDownloadError(null);
    try {
      const apiUrl = `${url}/v1/organization/${orgId}/group/${groupId}/pdf`;

      const response = await axios.get(apiUrl, {
        responseType: "blob",
        withCredentials: true,
      });

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlBlob;
      link.setAttribute("download", `${orgDetails.name}_report.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("Download failed:", error);
      setDownloadError("Failed to download the report. Please try again.");
    } finally {
      setDownloadLoading(false);
    }
  };

  const liveVulns = report?.live?.vulnerabilities || {};
  let liveTP = 0,
    liveFP = 0,
    liveUnverified = 0;
  Object.keys(liveVulns).forEach((key) => {
    liveTP += liveVulns[key]["True Positive"] || 0;
    liveFP += liveVulns[key]["False Positive"] || 0;
    liveUnverified += liveVulns[key]["Unverified"] || 0;
  });
  const totalLiveVulns = liveTP + liveFP + liveUnverified;

  const monthVulns = report?.one_month_ago?.vulnerabilities || {};
  let monthTP = 0,
    monthFP = 0,
    monthUnverified = 0;
  Object.keys(monthVulns).forEach((key) => {
    monthTP += monthVulns[key]["True Positive"] || 0;
    monthFP += monthVulns[key]["False Positive"] || 0;
    monthUnverified += monthVulns[key]["Unverified"] || 0;
  });

  const diffTP = liveTP - monthTP;
  const diffFP = liveFP - monthFP;
  const diffUnverified = liveUnverified - monthUnverified;

  const confirmedPercentage = totalLiveVulns
    ? ((liveTP / totalLiveVulns) * 100).toFixed(1)
    : 0;
  const noisePercentage = totalLiveVulns
    ? ((liveFP / totalLiveVulns) * 100).toFixed(1)
    : 0;
  const underReviewPercentage = totalLiveVulns
    ? ((liveUnverified / totalLiveVulns) * 100).toFixed(1)
    : 0;

  const renderTrend = (diff) => {
    const isIncrease = diff >= 0;
    return (
      <Flex align="center" mt={1}>
        <Icon
          as={isIncrease ? AiOutlineArrowUp : AiOutlineArrowDown}
          color={isIncrease ? "green.500" : "red.500"}
          mr={1}
          boxSize={3}
        />
        <Text
          fontSize="xs"
          color={isIncrease ? "green.500" : "red.500"}
          fontWeight="medium"
        >
          {diff > 0 ? `+${diff}` : diff}
        </Text>
      </Flex>
    );
  };

  return (
    <Box p={4} bg={pageBg} overflow="hidden">
      <Box
        bg={cardBg}
        px={6}
        py={4}
        borderBottom="1px solid"
        borderColor={borderCol}
        borderRadius="md"
        mb={4}
      >
        <Flex justify="space-between" align="center" gap={10}>
          <Box maxW="80%">
            <Heading
              size="md"
              mb={2}
              color={useColorModeValue("gray.800", "white")}
            >
              Summary
            </Heading>
            {plan === "Free" ? (
              <Text fontSize="sm" fontWeight="bold" color={textCol}>
                Unlock AI Insights: Upgrade to the Ultimate Plan for
                Intelligent, In-Depth Analysis of Your Security Posture!
              </Text>
            ) : reportLoading ? (
              <SkeletonText noOfLines={3} spacing="4" skeletonHeight="2" />
            ) : reportError ? (
              <Text fontSize="sm" color="red.500">
                {reportError.message || "Error loading summary"}
              </Text>
            ) : report?.summary ? (
              <MarkdownSummary
                text={report.summary}
                textColor={textCol}
                fontSize="sm"
              />
            ) : (
              <Text fontSize="sm" color={textCol}>
                No summary available.
              </Text>
            )}
          </Box>

          <VStack spacing={1} align="center">
            <Button
              bg="blue.600"
              color="white"
              _hover={{ bg: "blue.700" }}
              onClick={handleDownload}
              isLoading={downloadLoading}
            >
              PDF Report
            </Button>
            {downloadError && (
              <Text fontSize="xs" color="red.500">
                {downloadError}
              </Text>
            )}
          </VStack>
        </Flex>
      </Box>

      {/* Stats Overview Section */}
      <Box p={6} bg={cardBg} mb={4} borderRadius="md" boxShadow="sm">
        <StatsCards report={report} loading={reportLoading} />
      </Box>

      {/* Additional Stats Cards */}
      <Box p={6} bg={cardBg} mb={4} borderRadius="md" boxShadow="sm">
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
          <Skeleton isLoaded={!reportLoading} height="100%">
            <VStack
              spacing={3}
              align="stretch"
              p={4}
              borderWidth="1px"
              borderColor={borderCol}
              rounded="md"
              bg={useColorModeValue("gray.50", "gray.700")}
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.05)", boxShadow: "md" }}
            >
              <Text
                fontWeight="bold"
                color={useColorModeValue("gray.800", "white")}
              >
                Confirmed Vulnerabilities
              </Text>
              <Text fontSize="2xl" fontWeight="semibold" color={textCol}>
                {liveTP.toLocaleString()} {" ("}
                {confirmedPercentage}%{")"}
              </Text>
              {renderTrend(diffTP)}
            </VStack>
          </Skeleton>

          <Skeleton isLoaded={!reportLoading} height="100%">
            <VStack
              spacing={3}
              align="stretch"
              p={4}
              borderWidth="1px"
              borderColor={borderCol}
              rounded="md"
              bg={useColorModeValue("gray.50", "gray.700")}
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.05)", boxShadow: "md" }}
            >
              <Text
                fontWeight="bold"
                color={useColorModeValue("gray.800", "white")}
              >
                Under Review
              </Text>
              <Text fontSize="2xl" fontWeight="semibold" color={textCol}>
                {liveUnverified.toLocaleString()} {" ("}
                {underReviewPercentage}%{")"}
              </Text>
              {renderTrend(diffUnverified)}
            </VStack>
          </Skeleton>

          <Skeleton isLoaded={!reportLoading} height="100%">
            <VStack
              spacing={3}
              align="stretch"
              p={4}
              borderWidth="1px"
              borderColor={borderCol}
              rounded="md"
              bg={useColorModeValue("gray.50", "gray.700")}
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.05)", boxShadow: "md" }}
            >
              <Text
                fontWeight="bold"
                color={useColorModeValue("gray.800", "white")}
              >
                Noise Reduction
              </Text>
              <Text fontSize="2xl" fontWeight="semibold" color={textCol}>
                {liveFP.toLocaleString()} {" ("}
                {noisePercentage}%{")"}
              </Text>
              {renderTrend(diffFP)}
            </VStack>
          </Skeleton>
        </SimpleGrid>
      </Box>

      <ConfirmedVulnerabilities report={report} loading={reportLoading} />
    </Box>
  );
}

export default ReportPage;
