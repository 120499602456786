import React, { useMemo } from "react";
import {
  Box,
  Flex,
  Text,
  HStack,
  VStack,
  Wrap,
  WrapItem,
  useColorModeValue,
  SkeletonText,
} from "@chakra-ui/react";

const COLORS = {
  "Objective-C": "#438eff",
  "C/C++ Header": "#d1d1d1",
  "Objective-C++": "#6866fb",
  "C++": "#f34b7d",
  XML: "#0060ac",
  Swift: "#ffac45",
  Brainfuck: "#2f2530",
  Markdown: "#083fa1",
  "Bourne Shell": "#89e051",
  C: "#555555",
  Python: "#3572A5",
  JSON: "#292929",
  PHP: "#8892be",
  Java: "#b07219",
  Go: "#00ADD8",
  JavaScript: "#f1e05a",
  YAML: "#cb171e",
  Text: "#666666",
  "ANTLR Grammar": "#8A2BE2",
  HCL: "#D00000",
  PowerShell: "#012456",
  HTML: "#e34c26",
  Dockerfile: "#0db7ed",
};

const LanguageUsageChart = ({
  metadata,
  textColor: propTextColor,
  subTextColor: propSubTextColor,
}) => {
  const defaultTextColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const defaultSubTextColor = useColorModeValue("gray.500", "gray.400");
  const barBg = useColorModeValue("gray.200", "gray.700");

  const textColor = propTextColor || defaultTextColor;
  const subTextColor = propSubTextColor || defaultSubTextColor;

  const metrics = useMemo(() => {
    if (!metadata || typeof metadata !== "object") return [];

    return Object.entries(metadata)
      .filter(([key, value]) =>
        !["SUM", "branch", "git", "header", "sbom"].includes(key) &&
        value &&
        typeof value === "object" &&
        value.code !== undefined
      )
      .map(([language, value]) => ({
        language,
        code: value.code || 0,
      }));
  }, [metadata]);

  const totalCode = metrics.reduce((acc, m) => acc + (m.code || 0), 0);
  const filteredMetrics = metrics.filter((m) => m.code > 0);

  if (!totalCode || filteredMetrics.length === 0) {
    return (
      <VStack align="start" spacing={4} mt={4}>
        <Text fontSize="sm" fontWeight="bold" color={textColor}>
          Languages Used
        </Text>
        <SkeletonText mt="4" noOfLines={1} spacing="4" />
        <Text fontSize="xs" color={subTextColor}>
          No language metrics available.
        </Text>
      </VStack>
    );
  }

  return (
    <VStack align="start" spacing={4} mt={4}>
      <Text fontSize="sm" fontWeight="bold" color={textColor}>
        Languages Used
      </Text>

      <Box w="100%" h="20px" borderRadius="md" overflow="hidden" bg={barBg}>
        <Flex w="100%" h="100%">
          {filteredMetrics.map(({ language, code }) => {
            const percentage = (code / totalCode) * 100;
            return (
              <Box
                key={language}
                bg={COLORS[language] || "gray.500"}
                w={`${percentage}%`}
                h="100%"
              />
            );
          })}
        </Flex>
      </Box>

      <Wrap spacing={4}>
        {filteredMetrics.map(({ language, code }) => {
          const percentage = ((code / totalCode) * 100).toFixed(1);
          return (
            <WrapItem key={language}>
              <HStack spacing={2}>
                <Box
                  w="12px"
                  h="12px"
                  bg={COLORS[language] || "gray.500"}
                  borderRadius="full"
                />
                <Text fontSize="xs" color={textColor}>
                  {language} – {percentage}%
                </Text>
              </HStack>
            </WrapItem>
          );
        })}
      </Wrap>
    </VStack>
  );
};

export default LanguageUsageChart;
