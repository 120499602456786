import React, { useEffect, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  FormErrorMessage,
  Heading,
  useToast,
  Center,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Lottie from "react-lottie";
import successAnimation from "../../assets/lottie/success.json";
import { useDispatch, useSelector } from "react-redux";
import { createOrganizationAsync } from "../../features/Orgs/OrgsAction";
import { resetCreateState } from "../../features/Orgs/OrgsSlice";
import { getProfileDataAsync } from "../../features/Users/UsersAction";

const CreateOrganizationModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const primaryColor = "#005387";
  const hoverBgColor = useColorModeValue("#0077b5", "#0077b5");
  const containerBg = useColorModeValue("gray.50", "gray.800");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization name is required"),
    usage: Yup.string().required("Usage is required"),
    description: Yup.string(),
    business: Yup.string(),
    website: Yup.string().url("Must be a valid URL"),
    logo: Yup.string().url("Must be a valid URL"),
    licenseKey: Yup.string(),
  });

  const initialValues = {
    organizationName: "",
    description: "",
    business: "",
    website: "",
    logo: "",
    usage: "",
    licenseKey: "",
  };

  const handleCloseModal = useCallback(() => {
    onClose();
    dispatch(getProfileDataAsync());
  }, [onClose, dispatch]);

  const { createLoading, createError, createSuccess } = useSelector(
    (state) => state.orgs
  );

  useEffect(() => {
    if (createSuccess) {
      toast({
        title: "Organization Created",
        description: "Your organization was created successfully!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      dispatch(resetCreateState());
      handleCloseModal();
    }
    if (createError) {
      toast({
        title: "Failed to create organization.",
        description:
          createError.data?.message || createError.message || "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      dispatch(resetCreateState());
    }
  }, [createSuccess, createError, toast, dispatch, handleCloseModal]);

  const onSubmit = (values, actions) => {
    const payload = {
      name: values.organizationName,
      description: values.description,
      business: values.business,
      website: values.website,
      logo: values.logo,
      usage: values.usage,
      licenseKey: values.licenseKey,
    };
    dispatch(createOrganizationAsync(payload));
    actions.setSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent maxH="60vh" overflow="auto" bg={containerBg}>
        <ModalHeader>Create a New Organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            as={Formik}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="organizationName">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.organizationName &&
                        form.touched.organizationName
                      }
                      mb={4}
                    >
                      <FormLabel>Organization Name*</FormLabel>
                      <Input {...field} placeholder="Your new unique organization name" />
                      <FormErrorMessage>{form.errors.organizationName}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.description && form.touched.description}
                      mb={4}
                    >
                      <FormLabel>Description</FormLabel>
                      <Textarea
                        {...field}
                        placeholder="Short description for what this organization is created for"
                      />
                      <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="business">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.business && form.touched.business}
                      mb={4}
                    >
                      <FormLabel>Business</FormLabel>
                      <Input
                        {...field}
                        placeholder="e.g. GitHub, Inc., Example Institute, etc."
                      />
                      <FormErrorMessage>{form.errors.business}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="website">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.website && form.touched.website}
                      mb={4}
                    >
                      <FormLabel>Web Site</FormLabel>
                      <Input {...field} placeholder="e.g. https://example.com" />
                      <FormErrorMessage>{form.errors.website}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="logo">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.logo && form.touched.logo}
                      mb={4}
                    >
                      <FormLabel>Logo</FormLabel>
                      <Input {...field} placeholder="Public logo URL for the new organization" />
                      <FormErrorMessage>{form.errors.logo}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="usage">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.usage && form.touched.usage}
                      mb={4}
                    >
                      <FormLabel>Usage*</FormLabel>
                      <Select {...field} placeholder="Select...">
                        <option value="Personal">Personal</option>
                        <option value="Business">Business</option>
                        <option value="Enterprise">Enterprise</option>
                      </Select>
                      <FormErrorMessage>{form.errors.usage}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="licenseKey">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.licenseKey && form.touched.licenseKey}
                      mb={4}
                    >
                      <FormLabel>License Key (Optional)</FormLabel>
                      <Input {...field} placeholder="Enter your license key if you have one" />
                      <FormErrorMessage>{form.errors.licenseKey}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  type="submit"
                  bg={primaryColor}
                  color="white"
                  _hover={{ bg: hoverBgColor }}
                  isLoading={isSubmitting || createLoading}
                  mt={4}
                  w="full"
                >
                  Create
                </Button>
              </Form>
            )}
          </Box>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default CreateOrganizationModal;
