import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import ProfileHeader from "../components/Settings/ProfileHeader";
import ProfileCard from "../components/Settings/ProfileCard";
import ProfileSettings from "../components/Settings/ProfileSettings";
import Organizations from "../components/Settings/Organizations";
import AccountActivity from "../components/Settings/AccountActivity";
import PATTokens from "../components/Settings/PATTokens";
import IDEIntegrations from "../components/Settings/IDEIntegrations";

const SettingsPage = () => {
  const leftColumnRef = useRef(null);
  const [leftHeight, setLeftHeight] = useState("auto");

  useEffect(() => {
    const updateHeight = () => {
      if (leftColumnRef.current) {
        setLeftHeight(`${leftColumnRef.current.offsetHeight}px`);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    if (leftColumnRef.current) {
      resizeObserver.observe(leftColumnRef.current);
    }

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      if (leftColumnRef.current) {
        resizeObserver.unobserve(leftColumnRef.current);
      }
    };
  }, []);

  return (
    <Box mx="auto" p={{ base: 4, md: 6 }}>
      <ProfileHeader />
      <ProfileCard />

      <Flex
        gap={6}
        align="flex-start"
        direction={{ base: "column", md: "row" }}
      >
        <Box
          ref={leftColumnRef}
          flex={{ base: "none", md: 1 }}
          w={{ base: "100%", md: "50%" }}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <IDEIntegrations />
          <PATTokens />
        </Box>

        <Box
          flex={{ base: "none", md: 1 }}
          height={{ base: "auto", md: leftHeight }}
          display="flex"
          flexDirection="column"
          mt={{ base: 6, md: 0 }}
        >
          <AccountActivity />
        </Box>
      </Flex>
    </Box>
  );
};

export default SettingsPage;
