import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

function IDEIntegrations() {
  const bgCard = useColorModeValue("white", "gray.800");

  return (
    <Box
      p={4}
      bg={bgCard}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      shadow="sm"
    >
      <Heading as="h2" size="md" mb={4}>
        IDE Integrations
      </Heading>

      <Flex
        align="center"
        gap={{ base: 2, md: 4 }}
        direction={{ base: "column", md: "row" }}
      >
        <Image
          src="https://aquilax.ai/assets/img/logos/vsc.png"
          alt="Visual Studio Code Logo"
          boxSize="48px"
          objectFit="contain"
        />
        <Box textAlign={{ base: "center", md: "left" }}>
          <Text fontWeight="semibold" mb={1}>
            Visual Studio Code
          </Text>
          <Link
            href="/app/vsc-install"
            color="blue.500"
            fontSize="sm"
            textDecoration="underline"
            isExternal={false}
          >
            Install now <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      </Flex>
    </Box>
  );
}

export default IDEIntegrations;
