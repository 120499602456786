import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateOrganization,
  deleteOrganization,
  inviteMember,
  manageInvitation,
  removeMember,
  manageMembership,
  leaveOrganization,
  createOrganization,
  applyLicense,
} from '../../apis/orgs';

// Create Organization
export const createOrganizationAsync = createAsyncThunk(
  "orgs/createOrganization",
  async (data, thunkAPI) => {
    return await createOrganization(data, thunkAPI);
  }
);

// Update Organization
export const updateOrganizationAsync = createAsyncThunk(
  "orgs/updateOrganization",
  async ({ organizationId, data }, thunkAPI) => {
    return await updateOrganization(organizationId, data, thunkAPI);
  }
);

// Delete Organization
export const deleteOrganizationAsync = createAsyncThunk(
  "orgs/deleteOrganization",
  async ({ organizationId }, thunkAPI) => {
    return await deleteOrganization(organizationId, thunkAPI);
  }
);

// Invite Member
export const inviteMemberAsync = createAsyncThunk(
  "orgs/inviteMember",
  async ({ organizationId, invite_email, invite_role, invite_org_name }, thunkAPI) => {
    const invitationData = { invite_email, invite_role, invite_org_name };
    return await inviteMember(organizationId, invitationData, thunkAPI);
  }
);

// Manage Invitation
export const manageInvitationAsync = createAsyncThunk(
  "orgs/manageInvitation",
  async ({ organizationId, action }, thunkAPI) => {
    const updateData = { action };
    return await manageInvitation(organizationId, updateData, thunkAPI);
  }
);

// Remove Member
export const removeMemberAsync = createAsyncThunk(
  "orgs/removeMember",
  async ({ organizationId, member_email }, thunkAPI) => {
    return await removeMember(organizationId, member_email, thunkAPI);
  }
);

// Manage Membership
export const manageMembershipAsync = createAsyncThunk(
  "orgs/manageMembership",
  async ({ organizationId, action, member_email, new_role }, thunkAPI) => {
    const membershipData = { action, member_email, new_role };
    return await manageMembership(organizationId, membershipData, thunkAPI);
  }
);

// Leave Organization
export const leaveOrganizationAsync = createAsyncThunk(
  "orgs/leaveOrganization",
  async ({ organizationId }, thunkAPI) => {
    return await leaveOrganization(organizationId, thunkAPI);
  }
);

// Apply License
export const applyLicenseAsync = createAsyncThunk(
  "orgs/applyLicense",
  async ({ organizationId, license_key }, thunkAPI) => {
    const licenseData = { license_key };
    return await applyLicense(organizationId, licenseData, thunkAPI);
  }
);
