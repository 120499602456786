import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Badge,
  Image,
  VStack,
  Progress,
  Divider,
  Button,
  Tag,
  useColorModeValue,
  HStack,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { FaBitbucket, FaGithub, FaGitlab, FaJira } from "react-icons/fa";
import { SiAzuredevops } from "react-icons/si";
import { toSvg } from "jdenticon";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getGitIntegrationsAsync } from "../../features/Analytics/AnalyticsAction";
import { resetGitIntegrationsState } from "../../features/Analytics/AnalyticsSlice";

const url = process.env.REACT_APP_API_URL;

const OrganizationDetails = ({ orgDetails, groupId }) => {
  const cardBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.900", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.400");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const buttonBg = useColorModeValue("#003460", "#0076c2");
  const primaryColor = useColorModeValue("#001f33", "#005399");

  if (!orgDetails) {
    return (
      <Box
        bg={cardBg}
        p={{ base: 4, md: 6 }}
        borderRadius="lg"
        border="1px solid"
        borderColor={borderColor}
      >
        <Flex alignItems="center" gap={4} mb={4} direction={{ base: "column", md: "row" }}>
          <SkeletonCircle size={{ base: "100px", md: "150px" }} />
          <Box flex="1">
            <Skeleton height="32px" width="60%" mb={2} />
            <SkeletonText noOfLines={2} spacing="4" />
          </Box>
          <VStack align="start">
            <Skeleton height="20px" width="100px" />
            <Skeleton height="20px" width="120px" />
          </VStack>
        </Flex>
        <Divider borderColor={borderColor} />
        <Flex mt={4} justifyContent="space-between" alignItems="center" wrap="wrap" gap={2}>
          <Skeleton height="20px" width="80px" />
          <Skeleton height="20px" width="80px" />
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </Flex>
      </Box>
    );
  }

  const orgId = orgDetails._id || orgDetails.organization;
  if (!groupId) {
    console.error("Group ID is missing.");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { gitIntegrations, gitLoading, gitError } = useSelector(
    (state) => state.analytics
  );
  const { userData } = useSelector((state) => state.user);
  const computedMyProfile =
    orgDetails.users?.find((user) => user.email === userData?.email) || {};

  useEffect(() => {
    if (orgId && (!gitIntegrations || gitIntegrations.length === 0)) {
      dispatch(getGitIntegrationsAsync({ orgId }));
    }
    // return () => dispatch(resetGitIntegrationsState());
  }, [orgId, dispatch, gitIntegrations]);

  const generateIdenticon = (value, size = 150) => {
    const svg = toSvg(value, size);
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const licenseUsage =
    orgDetails.used_licenses && orgDetails.total_licenses
      ? ((orgDetails.used_licenses / orgDetails.total_licenses) * 100).toFixed(0)
      : orgDetails.licenseUsage || 0;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  const handleDownload = async () => {
    if (!groupId) {
      setDownloadError("Group ID is missing. Cannot download the report.");
      return;
    }

    setDownloadLoading(true);
    setDownloadError(null);
    try {
      const apiUrl = `${url}/v1/organization/${orgId}/group/${groupId}/pdf`;
      const response = await axios.get(apiUrl, {
        responseType: "blob",
        withCredentials: true,
      });
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${orgDetails.name}_report.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed:", error);
      setDownloadError("Failed to download the report. Please try again.");
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleAddIntegration = () => {
    if (orgId) {
      navigate(`/organization/${orgId}`);
    } else {
      console.error("Organization ID is missing.");
    }
  };

  const normalizedGitIntegrations = useMemo(() => {
    if (!gitIntegrations) return [];
    return gitIntegrations
      .map((integration) => {
        if (!integration) return null;
        return {
          ...integration,
          repo_type: integration.repo_type || integration.integration_type,
        };
      })
      .filter((integration) => integration !== null);
  }, [gitIntegrations]);

  const renderIntegrationIcon = (integration) => {
    if (!integration || !integration.repo_type) {
      return (
        <Box
          w="24px"
          h="24px"
          bg="gray.400"
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
          aria-label="Unknown Integration"
        >
          ?
        </Box>
      );
    }
    const repoType = integration.repo_type.toLowerCase().replace(/[-\s]/g, "");
    switch (repoType) {
      case "github":
        return <FaGithub size={24} color="#181717" aria-label="GitHub Integration" />;
      case "gitlab":
        return <FaGitlab size={24} color="#FC6D26" aria-label="GitLab Integration" />;
      case "bitbucket":
        return <FaBitbucket size={24} color="#0052CC" aria-label="Bitbucket Integration" />;
      case "jira":
        return <FaJira size={24} color="#0052CC" aria-label="Jira Integration" />;
      case "azuredevops":
        return <SiAzuredevops size={24} color="#0078D4" aria-label="Azure DevOps Integration" />;
      default:
        return (
          <Box
            w="24px"
            h="24px"
            bg="gray.400"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            aria-label="Unknown Integration"
          >
            ?
          </Box>
        );
    }
  };

  return (
    <Box
      bg={cardBg}
      p={{ base: 4, md: 6 }}
      borderRadius="lg"
      flex="2"
      border="1px solid"
      borderColor={borderColor}
    >
      {/* Top Section */}
      <Flex
        alignItems="center"
        gap={{ base: 4, md: 6 }}
        mb={4}
        direction={{ base: "column", md: "row" }}
      >
        <Image
          src={
            orgDetails.org_pic
              ? orgDetails.org_pic
              : generateIdenticon(orgDetails._id || orgDetails.name)
          }
          alt={orgDetails.name}
          boxSize={{ base: "100px", md: "150px" }}
          borderRadius="full"
          mx={{ base: "auto", md: "0" }}
        />
        <Box flex="1" textAlign={{ base: "center", md: "left" }}>
          <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" color={textColor}>
            {orgDetails.name}
          </Text>
          <Text fontSize="md" color={subTextColor} mt={1}>
            {orgDetails.description || "No description available"}
          </Text>
        </Box>
        <VStack align={{ base: "center", md: "start" }}>
          <Text fontSize="xs" color={textColor}>
            Integrations
          </Text>
          {gitLoading ? (
            <HStack spacing={2}>
              <SkeletonCircle size="24px" />
              <SkeletonCircle size="24px" />
              <SkeletonCircle size="24px" />
            </HStack>
          ) : gitError ? (
            <Alert status="error" variant="subtle">
              <AlertIcon />
              Failed to load integrations.
            </Alert>
          ) : normalizedGitIntegrations.length > 0 ? (
            <HStack spacing={2}>
              {normalizedGitIntegrations.map((integration) => (
                <Box
                  key={
                    integration._id ||
                    integration.installation_id ||
                    integration.username ||
                    Math.random()
                  }
                  p={2}
                  borderRadius="md"
                  bg={useColorModeValue("gray.100", "gray.700")}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {renderIntegrationIcon(integration)}
                </Box>
              ))}
            </HStack>
          ) : (
            <Button
              size="sm"
              bg={primaryColor}
              color="white"
              _hover={{ bg: useColorModeValue("#0077b5", "#0077b5") }}
              variant="solid"
              onClick={handleAddIntegration}
            >
              + Source Integration
            </Button>
          )}
        </VStack>
      </Flex>

      <Divider borderColor={borderColor} />

      {/* Bottom Section */}
      <Flex
        mt={4}
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap"
        gap={{ base: 4, md: 0 }}
      >
        {/* Plan */}
        <Box textAlign="center">
          <Text fontSize="sm" color={subTextColor} mb={1}>
            Plan
          </Text>
          <Badge colorScheme="green" fontSize="sm">
            {orgDetails.plan || "N/A"}
          </Badge>
        </Box>

        {/* Role (from profile) */}
        <Box textAlign="center">
          <Text fontSize="sm" color={subTextColor} mb={1}>
            Role
          </Text>
          <Tag colorScheme="blue" fontSize="sm">
            {computedMyProfile.role || "N/A"}
          </Tag>
        </Box>

        {/* Licenses */}
        <Box textAlign="center">
          <Text fontSize="sm" color={subTextColor} mb={1}>
            License Usage
          </Text>
          <Progress
            colorScheme="blue"
            value={licenseUsage}
            size="sm"
            borderRadius="lg"
            w="100px"
            mx="auto"
          />
          <Text fontSize="xs" mt={1} color={subTextColor}>
            {licenseUsage}% used ({orgDetails.used_licenses || 0}/{orgDetails.total_licenses || 0})
          </Text>
        </Box>

        {/* PDF Report */}
        <Box textAlign="center">
          <Text fontSize="sm" color={subTextColor} mb={1}>
            PDF Report
          </Text>
          <Button
            leftIcon={<FiDownload />}
            variant="outline"
            color={buttonBg}
            size="sm"
            onClick={handleDownload}
            isLoading={downloadLoading}
            loadingText="Downloading"
          >
            Download
          </Button>
          {downloadError && (
            <Text color="red.500" fontSize="xs" mt={2}>
              {downloadError}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default OrganizationDetails;
