import React, { useState, useRef, useEffect } from "react";
import {
  Flex,
  Textarea,
  HStack,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowForwardIcon, RepeatIcon, CloseIcon } from "@chakra-ui/icons";

const InputSection = ({
  onSend,
  onRefreshChat,
  onCancelRequest,
  isDisabled,
  isLoading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  
  useEffect(() => {
    if (!isDisabled) {
      inputRef.current?.focus();
    }
  }, [isDisabled]);

  const handleSendClick = () => {
    if (inputValue.trim() !== "" && !isDisabled) {
      onSend(inputValue);
      setInputValue("");
      inputRef.current?.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isDisabled) {
      e.preventDefault();
      handleSendClick();
    }
  };

  const inputBg = useColorModeValue("white", "gray.700");
  const borderCol = useColorModeValue("gray.200", "gray.600");
  const primaryColor = useColorModeValue("#001f33", "#005399");

  return (
    <Flex
      align="center"
      bg={inputBg}
      border="1px solid"
      borderColor={borderCol}
      borderRadius="lg"
      px={4}
      py={2}
      mt={4}
    >
      <Textarea
        ref={inputRef}
        autoFocus
        placeholder="Ask a cybersecurity question..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        variant="unstyled"
        resize="none"
        isDisabled={isDisabled}
        lineHeight="1.2"
        minH="1.5rem"
        maxH="35px"
        overflowY="auto"
      />
      <HStack spacing={1}>
        <IconButton
          icon={<RepeatIcon />}
          variant="ghost"
          aria-label="Refresh Chat"
          onClick={onRefreshChat}
          isDisabled={isDisabled}
          cursor={isDisabled ? "not-allowed" : "pointer"}
          opacity={isDisabled ? 0.6 : 1}
        />
        {isLoading ? (
          <IconButton
            icon={<CloseIcon />}
            variant="ghost"
            aria-label="Cancel Request"
            onClick={onCancelRequest}
            isDisabled={!isLoading}
            cursor={isLoading ? "pointer" : "not-allowed"}
            opacity={isLoading ? 1 : 0.6}
          />
        ) : (
          <IconButton
            icon={<ArrowForwardIcon />}
            rounded="full"
            aria-label="Send"
            bg={primaryColor}
            color="white"
            _hover={{ bg: useColorModeValue("#0077b5", "#0077b5") }}
            onClick={handleSendClick}
            isDisabled={isDisabled || inputValue.trim() === ""}
            cursor={
              isDisabled || inputValue.trim() === ""
                ? "not-allowed"
                : "pointer"
            }
            opacity={isDisabled || inputValue.trim() === "" ? 0.6 : 1}
          />
        )}
      </HStack>
    </Flex>
  );
};

export default InputSection;
