import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";

const ProfileHeader = () => {
  const bgHeader = useColorModeValue("blue.900", "blue.700");
  return (
    <Box bg={bgHeader} borderRadius="md" color="white" p={6} mb={2}>
      <Text fontFamily="monospace" fontSize="lg">
        print("Hello secure world!");
      </Text>
    </Box>
  );
};

export default ProfileHeader;
