import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Text,
  Badge,
  Flex,
  Spinner,
  VStack,
  HStack,
  Divider,
  Tag,
  Heading,
  useColorModeValue,
  SimpleGrid,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, CopyIcon } from "@chakra-ui/icons";
import { useParams, useNavigate } from "react-router-dom";
import { toSvg } from "jdenticon";
import { useDispatch } from "react-redux";

import DangerZone from "../components/Orgs/DangerZone";
import Integrations from "../components/Orgs/Integrations";
import UsersPermissions from "../components/Orgs/UsersPermissions";
import { updateOrganizationAsync } from "../features/Orgs/OrgsAction";

const OrganizationDetailsPage = () => {
  const url = process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const borderCol = useColorModeValue("gray.300", "gray.600");

  const [organization, setOrganization] = useState(null);
  const [myProfile, setMyProfile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    plan: "",
    usage: "",
    description: "",
    business_name: "",
    website: "",
    used_licenses: 0,
    total_licenses: 0,
    org_pic: "", 
  });

  const handleCopyId = () => {
    if (organization?._id) {
      navigator.clipboard.writeText(organization._id);
      toast({
        title: "Copied",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const loadOrgData = async () => {
    if (!id) return;
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${url}/v1/organization/${id}`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to load organization details");
      }
      const data = await response.json();
      setOrganization(data.org);
      setMyProfile(data.my_profile);
    } catch (err) {
      setError(err.message || "Failed to load organization details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOrgData();
  }, [id]);

  const openEditModal = () => {
    if (organization) {
      setFormData({
        name: organization.name || "",
        plan: organization.plan || "",
        usage: organization.usage || "",
        description: organization.description || "",
        business_name: organization.business_name || "",
        website: organization.website || "",
        used_licenses: organization.used_licenses || 0,
        total_licenses: organization.total_licenses || 0,
        org_pic: organization.org_pic || "",
      });
      setIsEditModalOpen(true);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "used_licenses" || name === "total_licenses") {
      setFormData((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setUpdateLoading(true);
  
    dispatch(updateOrganizationAsync({ organizationId: id, data: formData }))
      .unwrap()
      .then(() => {
        toast({
          title: "Organization updated",
          description: "Your changes have been saved successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        closeEditModal();
        loadOrgData();      
      })
      .catch((err) => {
        toast({
          title: "Error updating organization",
          description: err.message || "Something went wrong.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };
  

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Text color="red.500" fontSize="lg">
          {error}
        </Text>
      </Flex>
    );
  }

  if (!organization) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Text fontSize="lg"></Text>
      </Flex>
    );
  }

  const generateIdenticon = (value, size = 40) => {
    const svg = toSvg(value, size);
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const avatarSrc = organization.org_pic
    ? organization.org_pic
    : generateIdenticon(organization.name, 80);

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderCol}
      bg={bgColor}
      p={4}
      maxW="auto"
      mx="auto"
      width="100%"
      position="relative"
    >
      {myProfile?.role === "Owner" && (
        <HStack position="absolute" top="4" right="4" spacing={2}>
          <Tag size="md" colorScheme="gray">
            {organization._id || id}
          </Tag>
          <IconButton
            icon={<CopyIcon />}
            aria-label="Copy Organization ID"
            onClick={handleCopyId}
            size="sm"
          />
          <IconButton
            icon={<EditIcon />}
            aria-label="Edit Organization"
            onClick={openEditModal}
            size="sm"
          />
        </HStack>
      )}

      <Flex direction={{ base: "column", md: "row" }} alignItems="center">
        <Image
          src={avatarSrc}
          alt={`${organization.name} logo`}
          boxSize="100px"
          objectFit="cover"
          borderRadius="full"
          mr={{ md: 6 }}
          mb={{ base: 4, md: 0 }}
        />
        <Box flex="1">
          <HStack>
            <Heading size="md" fontWeight="semibold">
              {organization.name}
            </Heading>
            {myProfile && (
              <Tag size="sm" fontSize="xs" color="green.600" mt={1}>
                {myProfile.role}
              </Tag>
            )}
          </HStack>

          <HStack spacing={2} mt={2}>
            <Badge fontSize="0.7em" colorScheme="teal">
              {organization.plan}
            </Badge>
            <Badge fontSize="0.7em" colorScheme="purple">
              {organization.usage}
            </Badge>
          </HStack>
          <Text mt={2} fontSize="sm" color="gray.600">
            {organization.description}
          </Text>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={4} fontSize="sm">
            <Box>
              <Text fontWeight="medium">Business Name:</Text>
              <Text>{organization.business_name}</Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Website:</Text>
              <Text color="blue.600">
                <a
                  href={organization.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {organization.website}
                </a>
              </Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Licence:</Text>
              <Text>{organization.license || "N/A"}</Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Licenses:</Text>
              <Text>
                {organization.used_licenses}/{organization.total_licenses}
              </Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Created At:</Text>
              <Text>
                {new Date(organization.created_at).toLocaleDateString()}
              </Text>
            </Box>
          </SimpleGrid>

          {organization.plan === "Free" && (
            <Flex justifyContent="flex-end" mt={4}>
              <Button
                size="sm"
                bg="blue.600"
                color="white"
                _hover={{ bg: "blue.700" }}
                onClick={() => navigate(`/upgrade/${organization._id}`)}
              >
                Upgrade Plan
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>

      <Divider my={4} />

      {/* Integrations Section */}
      <Integrations orgId={id} />

      {/* Users & Permissions Section */}
      <UsersPermissions
        orgId={id}
        organization={organization}
        onOrgUpdated={loadOrgData}
        myProfile={myProfile}
      />

      {/* Danger Zone Section */}
      <DangerZone orgId={id} onOrgUpdated={loadOrgData} myProfile={myProfile} />

      {/* Edit Organization Modal */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Organization Details</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleFormSubmit}>
            <ModalBody>
              <VStack spacing={4}>
                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>

                  <FormControl id="usage" isRequired>
                    <FormLabel>Usage</FormLabel>
                    <Input
                      name="usage"
                      value={formData.usage}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl id="description" isRequired>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormControl>

                <SimpleGrid columns={2} spacing={4} width="100%">
                  <FormControl id="business_name">
                    <FormLabel>Business Name</FormLabel>
                    <Input
                      name="business_name"
                      value={formData.business_name}
                      onChange={handleInputChange}
                    />
                  </FormControl>

                  <FormControl id="website">
                    <FormLabel>Website</FormLabel>
                    <Input
                      name="website"
                      type="url"
                      value={formData.website}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </SimpleGrid>

                <FormControl id="org_pic">
                  <FormLabel>Organization Picture URL</FormLabel>
                  <Input
                    name="org_pic"
                    type="text"
                    placeholder="Enter image URL"
                    value={formData.org_pic}
                    onChange={handleInputChange}
                  />
                  {formData.org_pic && (
                    <Box mt={2}>
                      <Image
                        src={formData.org_pic}
                        alt="Organization Picture Preview"
                        boxSize="70px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </Box>
                  )}
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={closeEditModal} isDisabled={updateLoading}>
                Cancel
              </Button>
              <Button
                color="white"
                bg={useColorModeValue("#003460", "#0077b5")}
                _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
                type="submit"
                isLoading={updateLoading}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OrganizationDetailsPage;
