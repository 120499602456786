import React from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  Link,
  Icon,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useColorModeValue,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  FiFile,
  FiExternalLink,
  FiLayers,
} from "react-icons/fi";
import {
  LayoutDashboard,
  Bot,
  Package,
  ScanSearch,
  UserPlus,
  ShieldAlert,
  FileScan,
  Book as BookIcon,
  Server,
  FileChartPie,
} from "lucide-react";
import RunnersStatus from "./Others/RunnerStatus";

function MobileSidebar({
  isOpen, 
  onClose,
  orgId,
  selectedGroup,
  myProfile,
  onAddMemberClick,
}) {
  if (!isOpen) {
    return null; 
  }

  const location = useLocation();

  const bg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const navItemActiveBg = useColorModeValue("blue.100", "blue.700");
  const navItemHoverBg = useColorModeValue("gray.200", "gray.600");
  const navItemActiveColor = useColorModeValue("blue.600", "blue.200");
  const navItemHoverColor = useColorModeValue("blue.700", "blue.300");

  const getPageTitle = (pathname) => {
    if (pathname.startsWith("/home")) return "Home";
    if (pathname.startsWith("/securitron")) return "Securitron";
    if (pathname.startsWith("/new-scan")) return "New Scan";
    if (pathname.startsWith("/scans")) return "All Scans";
    if (pathname.startsWith("/projects")) return "Projects";
    if (pathname.startsWith("/report")) return "Report";
    if (pathname.startsWith("/groups")) return "Groups";
    if (pathname.startsWith("/vulnerabilities")) return "Vulnerabilities";
    return "Dashboard";
  };

  return (
    <Flex
      direction="column"
      bg={bg}
      color={textColor}
      w="220px"
      h="calc(100vh - 60px)"
      position="fixed"
      top="60px"
      left="0"
      overflowY="auto"
      overflowX="hidden"
      transition="all 0.3s"
      boxShadow="md"
      zIndex="overlay"
      sx={{
        "&::-webkit-scrollbar": { display: "none" },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      {/* Sidebar Header */}
      <Box p="4">
        <Text fontSize="lg" fontWeight="bold">
          {getPageTitle(location.pathname)}
        </Text>
      </Box>

      <Divider />

      <VStack align="stretch" spacing="1" px="2" mt="0">
        <NavSection title="Main">
          <NavItem
            to="/home"
            icon={LayoutDashboard}
            label="Home"
            isActive={location.pathname === "/home"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          <NavItem
            to="/securitron"
            icon={Bot}
            label="Securitron"
            isActive={location.pathname === "/securitron"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
        </NavSection>
        <NavSection title="App Sec">
          <NavItem
            to="/new-scan"
            icon={ScanSearch}
            label="New Scan"
            isActive={location.pathname === "/new-scan"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          {/* Example for All Scans:
            <NavItem
              to="/scans"
              icon={FileScan}
              label="All Scans"
              isActive={location.pathname === "/scans"}
              ...
            />
          */}
          <NavItem
            to="/projects"
            icon={Package}
            label="Projects"
            isActive={location.pathname === "/projects"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          <NavItem
            to="/report"
            icon={FileChartPie}
            label="Report"
            isActive={location.pathname === "/report"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          <NavItem
            to="/groups"
            icon={FiLayers}
            label="Groups"
            isActive={location.pathname === "/groups"}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          <NavItem
            to={
              orgId && selectedGroup?.id
                ? `/vulnerabilities/${orgId}/findings?group_id=${selectedGroup.id}`
                : "/vulnerabilities"
            }
            icon={ShieldAlert}
            label="Vulnerabilities"
            isActive={location.pathname.startsWith("/vulnerabilities")}
            activeBg={navItemActiveBg}
            activeColor={navItemActiveColor}
            hoverBg={navItemHoverBg}
            hoverColor={navItemHoverColor}
          />
          {(myProfile?.role === "Owner" ||
            myProfile?.role === "Security Manager") && (
            <NavItem
              to="#"
              icon={UserPlus}
              label="Add Member"
              onClick={onAddMemberClick}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
          )}
        </NavSection>

        <NavSection title="Documentation">
          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton p="2" pl="4">
                <HStack flex="1" textAlign="left" spacing="2">
                  <Icon as={BookIcon} boxSize={4} />
                  <Text fontSize="sm">Documentation</Text>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="0" pl="8" bg={useColorModeValue("gray.100", "gray.700")}>
                <NavItem
                  to="https://docs.aquilax.ai/"
                  icon={FiExternalLink}
                  label="Docs"
                  isExternal
                  activeBg={navItemActiveBg}
                  activeColor={navItemActiveColor}
                  hoverBg={navItemHoverBg}
                  hoverColor={navItemHoverColor}
                />
                <NavItem
                  to="https://aquilax.ai/docs#/"
                  icon={FiFile}
                  label="Swagger"
                  isExternal
                  activeBg={navItemActiveBg}
                  activeColor={navItemActiveColor}
                  hoverBg={navItemHoverBg}
                  hoverColor={navItemHoverColor}
                />
                <NavItem
                  to="https://aquilax.ai/redoc"
                  icon={FiFile}
                  label="Redolity"
                  isExternal
                  activeBg={navItemActiveBg}
                  activeColor={navItemActiveColor}
                  hoverBg={navItemHoverBg}
                  hoverColor={navItemHoverColor}
                />
                <NavItem
                  to="https://aquilax.featurebase.app/changelog"
                  icon={FiExternalLink}
                  label="Release Notes"
                  isExternal
                  activeBg={navItemActiveBg}
                  activeColor={navItemActiveColor}
                  hoverBg={navItemHoverBg}
                  hoverColor={navItemHoverColor}
                />
                <NavItem
                  to="https://aquilax.featurebase.app/"
                  icon={FiExternalLink}
                  label="Feature Requests"
                  isExternal
                  activeBg={navItemActiveBg}
                  activeColor={navItemActiveColor}
                  hoverBg={navItemHoverBg}
                  hoverColor={navItemHoverColor}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {/* Runner Status */}
          <Accordion allowToggle>
            <AccordionItem border="none">
              <AccordionButton p="2" pl="4">
                <HStack flex="1" textAlign="left" spacing="2">
                  <Icon as={Server} boxSize={4} />
                  <Text fontSize="sm">Runner Status</Text>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="0" pl="0" bg={useColorModeValue("gray.50", "gray.800")}>
                <RunnersStatus />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </NavSection>
      </VStack>
    </Flex>
  );
}

export default MobileSidebar;


function NavSection({ title, children }) {
  return (
    <Box>
      <Text
        fontSize="xs"
        fontWeight="bold"
        color="gray.500"
        px="4"
        mb={1}
        mt={1}
      >
        {title}
      </Text>
      <VStack align="stretch" spacing="1">
        {children}
      </VStack>
    </Box>
  );
}

function NavItem({
  to,
  icon,
  label,
  isActive,
  onClick,
  activeBg,
  activeColor,
  hoverBg,
  hoverColor,
  isExternal = false,
}) {
  const Component = isExternal ? Link : RouterLink;
  return (
    <Tooltip label={label} placement="right" isDisabled>
      <Flex
        as={Component}
        href={isExternal ? to : undefined}
        to={!isExternal ? to : undefined}
        target={isExternal ? "_blank" : undefined}
        rel={isExternal ? "noopener noreferrer" : undefined}
        align="center"
        p="2"
        borderRadius="md"
        fontSize="xs"
        bg={isActive ? activeBg : "transparent"}
        color={isActive ? activeColor : "inherit"}
        _hover={{
          bg: hoverBg,
          color: hoverColor,
        }}
        onClick={onClick}
        aria-label={label}
        w="100%"
      >
        <Icon as={icon} boxSize={4} />
        <Text ml="3">{label}</Text>
      </Flex>
    </Tooltip>
  );
}
