import React, { useEffect, useRef } from "react";
import { Box, Stack, useColorModeValue } from "@chakra-ui/react";
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

const ScansPieChart = ({ data, height = "300px", chartHeight = "250px" }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const colorMap = {
    FAILED: "#E53E3E",     // Red
    COMPLETED: "#4299E1",  // Blue
    CLONED: "#ECC94B",     // Yellow
    SCANNING: "#48BB78",   // Green
    SCANNED: "#2F855A",    // Dark Green
    STARTED: "#ED8936",    // Orange
  };

  const textColor = useColorModeValue("#000", "#fff");
  const tooltipBg = useColorModeValue("#fff", "#2D3748");
  const boxBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const dataWithColors = data.map((item) => ({
    ...item,
    itemStyle: {
      color: colorMap[item.name] || "#A0AEC0",
    },
  }));
  
  useEffect(() => {
    if (!chartRef.current) return;

    if (!chartInstanceRef.current) {
      chartInstanceRef.current = echarts.init(chartRef.current);
    }
    const chartInstance = chartInstanceRef.current;

    const option = {
      tooltip: {
        trigger: "item",
        backgroundColor: tooltipBg,
        textStyle: {
          color: textColor,
        },
        formatter: "{a} <br/>{b}: {c} ({d}%)",
        confine: true,
      },
      legend: {
        formatter: function(name) {
          return '';
        },
        data: dataWithColors.map(item => item.name),
        bottom: 0,
        itemWidth: 16,
        itemHeight: 16,
        borderWidth: 0,
        padding: 10,
        textStyle: {
          color: textColor,
        }
      },
      series: [
        {
          name: "Scans",
          type: "pie",
          radius: ["50%", "35%"], 
          labelLine: {
            length: 30,
          },
          label: {
            color: textColor,
            textBorderColor: "transparent",
            textBorderWidth: 0,
            formatter: "{b}",
          },
          data: dataWithColors,
        },
      ],
    };

    chartInstance.setOption(option);

    const handleResize = () => {
      chartInstance.resize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dataWithColors, textColor, tooltipBg]);
  
  return (
    <Box
      bg={boxBg}
      border="1px"
      borderColor={borderColor}
      p={4}
      borderRadius="lg"
      boxShadow="sm"
      height={height}
      width={{ base: "100%", md: "50%" }}
    >
      <Box ref={chartRef} width="100%" height={chartHeight} />
      
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={4}
        spacing={0}
      >
      </Stack>
    </Box>
  );
};

export default ScansPieChart;
