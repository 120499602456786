import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleBitbucketCallbackAsync,
  fetchBitbucketRepositoriesAsync,
} from '../features/Integrations/integrationsAction';

import {
  Center,
  Spinner,
  Text,
  VStack,
  CheckboxGroup,
  Checkbox,
  Progress,
  Button,
} from '@chakra-ui/react';

import Lottie from 'react-lottie';
import successAnimation from '../assets/lottie/success.json';

function BitbucketCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, callbackData } = useSelector(
    (state) => state.integrations.bitbucket
  );

  const [callbackAttempted, setCallbackAttempted] = useState(false);
  const [installSuccess, setInstallSuccess] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [installing, setInstalling] = useState(false);
  const [installError, setInstallError] = useState(null);

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (code && state) {
      setOrgId(state);

      dispatch(handleBitbucketCallbackAsync({ installationId: state, code }))
        .unwrap()
        .then(() => {
          setCallbackAttempted(true);
        })
        .catch((err) => {
          console.error('Bitbucket callback error:', err);
          setCallbackAttempted(true);
        });
    } else {
      console.warn('Missing ?code= or ?state=');
      setCallbackAttempted(true);
    }
  }, [searchParams, dispatch]);

  // Install Repositories
  const handleInstallRepos = () => {
    if (!callbackData?.installation_id) {
      setInstallError('Missing installation_id from callback');
      return;
    }
    if (!orgId) {
      setInstallError('Missing orgId from ?state=');
      return;
    }

    setInstalling(true);
    setInstallError(null);

    dispatch(
      fetchBitbucketRepositoriesAsync({
        orgId,
        data: {
          installation_id: callbackData.installation_id,
          workspaces: selectedWorkspaces,
        },
      })
    )
      .unwrap()
      .then(() => {
        setInstalling(false);
        setInstallSuccess(true);
      })
      .catch((err) => {
        console.error('Error fetching bitbucket repos:', err);
        setInstallError(err.message || 'Repo fetch failed');
        setInstalling(false);
      });
  };


  if (loading && !callbackAttempted) {
    return (
      <Center minH="50vh" flexDirection="column">
        <Spinner size="lg" mb={4} />
        <Text>Finishing Bitbucket integration...</Text>
      </Center>
    );
  }

  if (error) {
    return (
      <Center minH="50vh" flexDirection="column">
        <Text color="red.500" mb={2}>
          Error: {error.message || 'Bitbucket callback failed'}
        </Text>
        <Text>Please try again or check your Bitbucket settings.</Text>
      </Center>
    );
  }

  if (callbackAttempted && !callbackData && !loading) {
    return (
      <Center minH="50vh" flexDirection="column">
        <Text>No callback data returned. Please try again.</Text>
      </Center>
    );
  }

  if (installSuccess) {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: successAnimation,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };

    return (
      <Center minH="50vh" flexDirection="column">
        <VStack spacing={6}>
          <Lottie options={defaultOptions} height={200} width={200} />
          <Text fontSize="lg" fontWeight="bold">
            Bitbucket Repositories Integrated Successfully!
          </Text>
          {/* Start Scan Button */}
          <Button colorScheme="blue" onClick={() => navigate('/new-scan')}>
            Start Scan
          </Button>
        </VStack>
      </Center>
    );
  }

  if (callbackData) {
    const { workspaces = [], installation_id } = callbackData;

    if (workspaces.length === 0) {
      return (
        <Center minH="50vh" flexDirection="column">
          <VStack spacing={4}>
            <Text fontSize="xl" fontWeight="bold">
              Bitbucket Integration Complete!
            </Text>
            <Text>Installation ID: {installation_id}</Text>
            <Text>No workspaces found in Bitbucket.</Text>
            <Text fontWeight="bold" color="red.500">
              Please create a workspace first.
            </Text>
          </VStack>
        </Center>
      );
    }

    return (
      <Center minH="50vh" flexDirection="column">
        <VStack spacing={4} w="full" maxW="500px">
          <Text fontSize="xl" fontWeight="bold">
            Bitbucket Integration Complete!
          </Text>
          <Text>Installation ID: {installation_id}</Text>

          <Text fontSize="md" fontWeight="semibold">
            Workspaces Found:
          </Text>
          <CheckboxGroup
            value={selectedWorkspaces}
            onChange={(vals) => setSelectedWorkspaces(vals)}
          >
            <VStack align="start" spacing={2} w="full">
              {workspaces.map((ws) => (
                <Checkbox key={ws.uuid} value={ws.uuid}>
                  {ws.name} (slug: {ws.slug})
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>

          {installError && (
            <Text color="red.500" fontSize="sm">
              {installError}
            </Text>
          )}

          {installing ? (
            <VStack spacing={2}>
              <Text>Installing Repositories...</Text>
              <Progress size="xs" isIndeterminate w="full" />
            </VStack>
          ) : (
            <Button
              colorScheme="blue"
              onClick={handleInstallRepos}
              isDisabled={selectedWorkspaces.length === 0}
            >
              Install Repositories
            </Button>
          )}
        </VStack>
      </Center>
    );
  }

  return (
    <Center minH="50vh" flexDirection="column">
      <Spinner size="lg" mb={4} />
      <Text>Waiting for Bitbucket callback data...</Text>
    </Center>
  );
}

export default BitbucketCallback;
