import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Text,
  Heading,
  VStack,
  Image,
  Link,
  HStack,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import logo from "../assets/images/darklogo.png";

const login = process.env.REACT_APP_LOGIN;

const AuthPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loadingAuth } = useAuth();

  useEffect(() => {
    if (!loadingAuth && isAuthenticated) {
      navigate("/securitron");
    }
  }, [loadingAuth, isAuthenticated, navigate]);

  if (loadingAuth) {
    return (
      <Flex minH="100vh" align="center" justify="center" bg="white">
        <Spinner size="xl" color="gray.500" />
      </Flex>
    );
  }

  const APP_BASE = "https://aquilax.ai";

  const handleLogin = () => {
    window.location.href = `${APP_BASE}/${login}`;
  };

  return (
    <Flex minH="100vh" bg="white">
      {/* Left Column */}
      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="white"
      >
        <Image
          src={logo}
          alt="Auth Illustration"
          boxSize={{ base: "150px", md: "250px" }}
        />
      </Box>

      {/* Divider */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="1px"
        bg="gray.300"
        minH="60%"
      />

      {/* Right Column */}
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={8}
        bg="white"
      >
        <VStack spacing={6} w={{ base: "90%", md: "400px" }}>
          <Heading as="h1" size="lg" mb={0} color="gray.700">
            Sign in to Aquilax
          </Heading>
          <Text fontSize="md" color="gray.500">
            Start using Aquilax in your projects
          </Text>

          <Button
            w="full"
            size="sm"
            bg="#0077b5"
            color="white"
            _hover={{ bg: "#0077b5" }}
            onClick={handleLogin}
          >
            Sign in
          </Button>

          <HStack spacing={4} mt={4} w="full" justify="center">
            <Text fontSize="sm" color="gray.500">
              Want to try Aquilax?
            </Text>
            <Link href="#" color="#0077b5" fontSize="sm" fontWeight="bold">
              Book a demo
            </Link>
          </HStack>
        </VStack>
      </Box>
    </Flex>
  );
};

export default AuthPage;
