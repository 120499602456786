import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
  VStack,
  HStack,
  Text,
  Icon,
  InputGroup,
  InputLeftElement,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronDown, FiPlus, FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addGroupAsync } from "../features/Groups/GroupAction";
import GroupModal from "../components/Groups/GroupModal";

const GroupSelector = ({
  groups,
  selectedGroup,
  handleGroupSelect,
  primaryColor = "blue.500",
  menuButtonHoverBg = "gray.100",
  menuListBg = "white",
  menuItemHoverBg = "gray.200",
  isCollapsed = false,
}) => {
  const navigate = useNavigate();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const orgId = useSelector((state) => state.user.selectedOrganization?._id);

  const [modalMode, setModalMode] = useState("");
  const [currentGroup, setCurrentGroup] = useState(null);

  const filteredGroups = groups.filter((group) =>
    group?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (groups && groups.length === 0) {
      const timer = setTimeout(() => {
        navigate("/groups");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [groups, navigate, handleGroupSelect]);

  // Define a set of colors to assign based on the group's name.
  const avatarColors = [
    "red.500",
    "green.500",
    "blue.500",
    "yellow.500",
    "purple.500",
    "teal.500",
    "orange.500",
  ];
  const getAvatarColor = (name) => {
    if (!name) return "gray.500";
    const charCode = name.charCodeAt(0);
    return avatarColors[charCode % avatarColors.length];
  };
  const selectedBg = useColorModeValue("green.100", "green.700");
  const selectedTextColor = useColorModeValue("black", "white");

  const handleCreateGroup = () => {
    setModalMode("create");
    setCurrentGroup({});
    onModalOpen();
  };

  const handleSaveGroup = async (groupData) => {
    try {
      const newGroup = await dispatch(addGroupAsync({ orgId, groupData })).unwrap();
      toast({
        title: "Group Created",
        description: `Group "${newGroup.name}" has been created successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onModalClose();
      handleGroupSelect(newGroup);
    } catch (error) {
      toast({
        title: "Error",
        description: error?.message || "Failed to create group.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fullSelectedGroup = (() => {
    if (!selectedGroup) return null;
    if (selectedGroup.avatar || (selectedGroup.security_policy && selectedGroup.security_policy.avatar)) {
      return selectedGroup;
    }
    return groups.find(
      (g) => g._id === selectedGroup.id || g._id === selectedGroup._id
    ) || selectedGroup;
  })();

  const isGroupSelected = (group) => {
    return (
      (selectedGroup?.id && selectedGroup.id === group._id) ||
      (selectedGroup?._id && selectedGroup._id === group._id)
    );
  };

  return (
    <>
      <Menu>
        {({ isOpen: menuOpen }) => (
          <>
            <MenuButton
              as={Button}
              variant="ghost"
              width={isCollapsed ? "100%" : "auto"}
              justifyContent={isCollapsed ? "center" : "flex-start"}
              leftIcon={
                <Avatar
                  size="sm"
                  name={fullSelectedGroup?.name || "Select Group"}
                  src={
                    fullSelectedGroup?.avatar ||
                    fullSelectedGroup?.security_policy?.avatar ||
                    undefined
                  }
                  bg={
                    !fullSelectedGroup?.avatar &&
                    !fullSelectedGroup?.security_policy?.avatar &&
                    fullSelectedGroup
                      ? getAvatarColor(fullSelectedGroup.name)
                      : undefined
                  }
                  color="white"
                />
              }
              rightIcon={!isCollapsed && <FiChevronDown />}
              _hover={{ bg: menuButtonHoverBg }}
              aria-label="Select Group"
            >
              {!isCollapsed && (
                <Box textAlign="left" flex="1">
                  <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
                    {fullSelectedGroup?.name || "Select Group"}
                  </Text>
                </Box>
              )}
            </MenuButton>
            <MenuList bg={menuListBg} maxHeight="400px" overflowY="auto">
              {!isCollapsed && (
                <Box px="4" py="2">
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="gray.400" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search Groups"
                      size="sm"
                      variant="filled"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      aria-label="Search Groups"
                      fontSize="xs"
                    />
                  </InputGroup>
                </Box>
              )}
              <VStack spacing="0" align="stretch">
                {filteredGroups.length > 0 ? (
                  filteredGroups.map((group) => (
                    <MenuItem
                      key={group._id}
                      onClick={() => handleGroupSelect(group)}
                      _hover={{ bg: menuItemHoverBg }}
                      bg={isGroupSelected(group) ? selectedBg : "transparent"}
                      color={isGroupSelected(group) ? selectedTextColor : "inherit"}
                      aria-label={`Select ${group.name}`}
                      px={isCollapsed ? "2" : "4"}
                      py="2"
                    >
                      {!isCollapsed ? (
                        <HStack spacing="2">
                          <Avatar
                            size="xs"
                            name={group.name}
                            src={
                              group?.avatar ||
                              group?.security_policy?.avatar ||
                              undefined
                            }
                            bg={
                              !group?.avatar &&
                              !group?.security_policy?.avatar &&
                              getAvatarColor(group.name)
                            }
                            color="white"
                          />
                          <Text fontSize="xs">{group.name}</Text>
                        </HStack>
                      ) : (
                        <Avatar
                          size="xs"
                          name={group.name}
                          src={
                            group?.avatar ||
                            group?.security_policy?.avatar ||
                            undefined
                          }
                          bg={
                            !group?.avatar &&
                            !group?.security_policy?.avatar &&
                            getAvatarColor(group.name)
                          }
                          color="white"
                        />
                      )}
                    </MenuItem>
                  ))
                ) : (
                  !isCollapsed && (
                    <MenuItem disabled>
                      <Text fontSize="xs" color="gray.500">
                        No groups found
                      </Text>
                    </MenuItem>
                  )
                )}
              </VStack>
              <MenuDivider />
              {!isCollapsed && (
                <MenuItem
                  onClick={handleCreateGroup}
                  icon={<FiPlus color={primaryColor} />}
                  _hover={{ bg: menuItemHoverBg }}
                  aria-label="Create New Group"
                  px="4"
                  py="2"
                >
                  <Text fontSize="sm"> New Group</Text>
                </MenuItem>
              )}
            </MenuList>
          </>
        )}
      </Menu>

      <GroupModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        groupData={currentGroup}
        mode={modalMode}
        onSave={handleSaveGroup}
      />
    </>
  );
};

export default GroupSelector;
