import { createSlice } from "@reduxjs/toolkit";
import { 
  getUserDataAsync, 
  userOnboardingAsync, 
  getNotificationsAsync, 
  getActivityLogsAsync, 
  readNotificationAsync, 
  getAccessTokensAsync, 
  createAccessTokenAsync, 
  deleteAccessTokenAsync,
  changeNotificationOptInStatusAsync,
  getProfileDataAsync, 
} from "./UsersAction";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    organizations: [],
    selectedOrganization: null,
    notifications: [],
    activityLogs: [],
    accessTokens: [],
    notificationCount: 0,
    createdToken: null,
    loading: false,
    organizationsLoading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetUserState: (state) => {
      state.userData = null;
      state.organizations = [];
      state.selectedOrganization = null;
      state.notifications = [];
      state.activityLogs = []; 
      state.accessTokens = [];
      state.notificationCount = 0;
      state.createdToken = null;
      state.loading = false;
      state.organizationsLoading = false;
      state.error = null;
      state.success = null;
    },
    resetCreatedToken: (state) => {
      state.createdToken = null;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handlers for getUserDataAsync
    builder.addCase(getUserDataAsync.pending, (state) => {
      state.loading = true;
      state.organizationsLoading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getUserDataAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.organizationsLoading = false;
      state.userData = action.payload.userData;
      state.organizations = action.payload.organizations;
      state.selectedOrganization = action.payload.selected_organization;
      state.notificationCount = action.payload.notification_count;
      state.success = action.payload.message;
    });
    builder.addCase(getUserDataAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handlers for userOnboardingAsync
    builder.addCase(userOnboardingAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(userOnboardingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message;
      state.error = null;
    });
    builder.addCase(userOnboardingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handlers for getNotificationsAsync
    builder.addCase(getNotificationsAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getNotificationsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload.notifications;
      state.notificationCount = action.payload.notifications_count;
      state.success = "Notifications fetched successfully.";
      state.error = null;
    });
    builder.addCase(getNotificationsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Handlers for getActivityLogsAsync
    builder.addCase(getActivityLogsAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getActivityLogsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.activityLogs = action.payload;
      state.success = "Activity logs fetched successfully.";
      state.error = null;
    });
    builder.addCase(getActivityLogsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(readNotificationAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(readNotificationAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message || "Notification marked as read.";

      const updatedNotification = action.payload.notification;
      if (updatedNotification) {
        const index = state.notifications.findIndex(
          (notif) => notif.id === updatedNotification.id
        );
        if (index !== -1) {
          state.notifications[index] = updatedNotification;
        }
      }

      if (action.payload.decrementCount) {
        state.notificationCount = Math.max(state.notificationCount - 1, 0);
      }

      state.error = null;
    });
    builder.addCase(readNotificationAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getAccessTokensAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getAccessTokensAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.accessTokens = action.payload;
      state.success = "Access tokens fetched successfully.";
      state.error = null;
    });
    builder.addCase(getAccessTokensAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(createAccessTokenAsync.fulfilled, (state, action) => {
      state.loading = false;
    
      const newTokenObj = action.payload.token.token_obj;
    
      if (newTokenObj._id && !newTokenObj.id) {
        newTokenObj.id = newTokenObj._id;
      }

      state.accessTokens.push(newTokenObj);
      state.createdToken = action.payload.token.token;
      state.success = "Access token created successfully.";
      state.error = null;
    });

    builder.addCase(deleteAccessTokenAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(deleteAccessTokenAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { accessTokenId, message } = action.payload;
      state.accessTokens = state.accessTokens.filter(token => token.id !== accessTokenId);
      state.success = message || "Access token deleted successfully.";
      state.error = null;
    });
    builder.addCase(deleteAccessTokenAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(changeNotificationOptInStatusAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(changeNotificationOptInStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      if (state.userData) {
        state.userData.notification_opt_in = action.payload.notification_opt_in;
      }
      state.success = action.payload.message || "Notification opt in status updated successfully.";
    });
    builder.addCase(changeNotificationOptInStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getProfileDataAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getProfileDataAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.accessTokens = action.payload.access_tokens;
      state.activityLogs = action.payload.activity_logs;
      state.notifications = action.payload.notifications;
      state.notificationCount = action.payload.notification_count;
      state.organizations = action.payload.organizations;
      state.userData = action.payload.userData;
      state.success = "Profile data fetched successfully.";
    });
    builder.addCase(getProfileDataAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetUserState, resetCreatedToken, setSelectedOrganization  } = userSlice.actions;

export default userSlice.reducer;
