import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BillingSubscriptionPage from "../components/Plans/BillingSubscriptionPage";
import Lottie from "lottie-react";
import { WarningIcon } from "@chakra-ui/icons";
import successTickAnimation from "../assets/lottie/successTick.json";
import CryptoJS from "crypto-js";

const UpgradePlanModal = ({ isOpen, onClose, details, isSuccess }) => {
  const navigate = useNavigate();
  const { user: userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (isSuccess && userData?._id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: details.sub_id || "N/A",
          value: parseFloat(details.price) || 0,
          currency: "USD",
          tax: 0.0,
          coupon: details.coupon || "N/A",
          items: [
            {
              item_name: details.plan || "N/A",
              item_id: details.plan_id || "N/A",
              price: parseFloat(details.price) || 0,
              item_brand: "aquilax",
              item_variant: "monthly",
              discount: parseFloat(details.discount) || 0,
              quantity: parseInt(details.quantity, 10) || 1,
              category: "subscription",
            },
          ],
        },
        user_id: CryptoJS.SHA256(userData._id).toString(CryptoJS.enc.Hex),
        org_id: CryptoJS.SHA256(details.org_id || "").toString(CryptoJS.enc.Hex),
        plan_type: "Paid",
      });
    }
  }, [isSuccess, details, userData]);

  const handleViewOrg = () => {
    if (details.org_id) {
      navigate(`/organization/${details.org_id}`);
    }
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {isSuccess ? "Plan Upgrade Successful" : "Plan Upgrade Failed"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="center">
            <Box boxSize="150px">
              {isSuccess ? (
                <Lottie animationData={successTickAnimation} loop={false} />
              ) : (
                <WarningIcon w={150} h={150} color="red.500" />
              )}
            </Box>
            {isSuccess ? (
              <Text>Your plan has been upgraded successfully!</Text>
            ) : (
              <Text>
                Unfortunately, your plan upgrade failed. Please try again or
                contact support.
              </Text>
            )}
            <Text>
              <strong>Organization ID:</strong> {details.org_id || "N/A"}
            </Text>
            <Text>
              <strong>Plan:</strong> {details.plan || "N/A"}
            </Text>
            <Text>
              <strong>Quantity:</strong> {details.quantity || "N/A"}
            </Text>
            <Text>
              <strong>Subscription ID:</strong> {details.sub_id || "N/A"}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button size={"sm"} color={"blue.600"} onClick={onClose}>
            Close
          </Button>
          <Button size={"sm"} colorScheme="green" ml={3} onClick={handleViewOrg}>
            View Organization
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function Plans() {
  const [searchParams] = useSearchParams();
  const response = searchParams.get("response");
  const org_id = searchParams.get("org_id");
  const quantity = searchParams.get("quantity");
  const plan = searchParams.get("plan");
  const sub_id = searchParams.get("sub_id");
  const price = searchParams.get("price");
  const discount = searchParams.get("discount");
  const coupon = searchParams.get("coupon");
  const plan_id = searchParams.get("plan_id");

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (response === "success" || response === "failed") {
      setIsModalOpen(true);
    }
  }, [response]);

  const modalDetails = { org_id, quantity, plan, sub_id, price, discount, coupon, plan_id };
  const isSuccess = response === "success";

  return (
    <>
      <BillingSubscriptionPage />
      <UpgradePlanModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        details={modalDetails}
        isSuccess={isSuccess}
      />
    </>
  );
}

export default Plans;
