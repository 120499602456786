import axios from "axios";

const url = process.env.REACT_APP_API_URL;

// -----------------------------------------------------------------------------
// GITLAB
// -----------------------------------------------------------------------------

export const installGitLab = async (installationId, data, thunkAPI) => {
  try {
    const formData = new URLSearchParams();
    formData.append("git_name", data.git_name);
    formData.append("git_instance", data.git_instance);
    formData.append("git_access_token", data.git_access_token);

    const response = await axios.post(
      `${url}/v1/gitlab/install/${installationId}`,
      formData, 
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );
    return response.data; 
  } catch (error) {
    console.error("Error installing GitLab:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const fetchGitLabRepositories = async (installationId, data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/gitlab/repositories/${installationId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data; 
  } catch (error) {
    console.error("Error fetching GitLab repositories:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// -----------------------------------------------------------------------------
// BITBUCKET
// -----------------------------------------------------------------------------

export const initiateBitbucketInstall = async (installationId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/bitbucket/install/${installationId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error initiating Bitbucket installation:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const handleBitbucketCallback = async (installationId, code, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/bitbucket/callback/${installationId}`,
      {
        params: { code, state: installationId },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error handling Bitbucket callback:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const fetchBitbucketRepositories = async (orgId, data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${url}/v1/bitbucket/repositories/${orgId}`, 
        data, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

export const deleteBitbucketInstallation = async ({ orgId, installationId }, thunkAPI) => {
    try {
      const formData = new URLSearchParams();
      formData.append('installation_id', installationId); 
  
      const response = await axios.delete(
        `${url}/v1/bitbucket/installation/${orgId}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: formData, 
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting Bitbucket installation:', error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

  export const installGitHub = async (orgId, params, thunkAPI) => {
    try {
      const { code, installation_id, setup_action } = params;
  
      const response = await axios.get(
        `${url}/v1/github/installation/${orgId}`,
        {
          params: { code, installation_id, setup_action },
          withCredentials: true,
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error installing GitHub:", error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

  export const installJira = async (orgId, data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${url}/v1/install-jira/${orgId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data; 
    } catch (error) {
      console.error("Error installing Jira:", error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };
  
  export const uninstallJira = async (orgId, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${url}/v1/uninstall-jira/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data; 
    } catch (error) {
      console.error("Error uninstalling Jira:", error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

  export const getTicketIntegration = async (orgId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${url}/v1/get-ticket-integration/${orgId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Ticket Integration:", error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

// -----------------------------------------------------------------------------
// AZURE DEVOPS
// -----------------------------------------------------------------------------

export const installAzureDevOps = async (orgId, azureData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/azuredevops/install/${orgId}`,
      azureData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error installing Azure DevOps:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const deleteAzureDevOpsInstallation = async ({ orgId, installationId }, thunkAPI) => {
  try {
    const formData = new URLSearchParams();
    formData.append("installation_id", installationId);
    const response = await axios.delete(
      `${url}/v1/azuredevops/installation/${orgId}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting Azure DevOps installation:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const deleteRepoInstallation = async ({ orgId, installationId }, thunkAPI) => {
  try {
    const formData = new URLSearchParams();
    formData.append("installation_id", installationId);

    const response = await axios.delete(
      `${url}/v1/repos-installation/${orgId}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting repository installation:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const updateGitLabInstallation = async (orgId, installationId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/gitlab/installation/${orgId}/update/${installationId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating GitLab installation:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};