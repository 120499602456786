import React from "react";
import { Box, Flex, Text, Button, Skeleton, useColorModeValue } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const NotificationBanner = ({ rawScans = [], loading = false }) => {
  const navigate = useNavigate();
  const boxBg = useColorModeValue("white", "gray.800");
  const borderCol = useColorModeValue("gray.300", "gray.600");
  const mainTextColor = useColorModeValue("gray.900", "gray.100");

  const totalScans = rawScans.length;

  const handleViewClick = () => {
    navigate("/report");
  };

  const renderText = () => {
    if (loading) {
      return <Skeleton height="20px" width="200px" />;
    }

    return (
      <Text fontSize="md" color={mainTextColor}>
        {totalScans > 0 ? (
          <>Checkout your {totalScans} scans report here.</>
        ) : (
          <>Checkout your scans report here.</>
        )}
      </Text>
    );
  };

  return (
    <Box
      bg={boxBg}
      borderRadius="lg"
      border="1px"
      borderColor={borderCol}
      p={4}
      mb={2}
    >
      <Flex justifyContent="space-between" alignItems="center">
        {renderText()}
        <Button
          size="sm"
          bg="blue.600"
          color="white"
          _hover={{ bg: "blue.700" }}
          rightIcon={<FiArrowRight />}
          fontWeight="medium"
          borderRadius="md"
          onClick={handleViewClick}
          isDisabled={loading}
        >
          Check it out
        </Button>
      </Flex>
    </Box>
  );
};

export default NotificationBanner;
