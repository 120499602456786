import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import {
  Box,
  IconButton,
  Text,
  Container,
  VStack,
  Flex,
  HStack,
  Avatar,
  Button,
  Tooltip,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  FiPlus,
  FiGlobe,
  FiMoreHorizontal,
  FiArrowUp,
  FiCopy,
  FiThumbsUp,
  FiThumbsDown,
  FiRefreshCw,
  FiDownload,
} from "react-icons/fi";
import TextareaAutosize from "react-textarea-autosize";
import Aquilax from "../assets/images/aquilax.avif";
import { postSecuritronAsync } from "../features/Securitron/securitronAction";
import { resetSecuritronState } from "../features/Securitron/securitronSlice";
import { addGroupAsync } from "../features/Groups/GroupAction";
import GroupModal from "../components/Groups/GroupModal";
import CreateOrganizationModal from "../components/Orgs/CreateOrganizationModal";
import AddMemberModal from "./AddMemberModal";
import ScanWizardModal from "../components/NewScans/ScanWizardModal";

const initialPrompts = ["welcome", "intel data?"];

const generateSessionId = (email) =>
  (email || "session") + "_" + Math.random().toString(36).substr(2, 9);

export default function ChatApp() {
  const { colorMode } = useColorMode();
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();

  const securitronState = useSelector((state) => state.securitron);
  const { streamResponse, response, loading } = securitronState;

  const orgDetails =
    useSelector((state) => state.user.selectedOrganization) || {};
  const selectedGroup =
    useSelector((state) => state.groups.selectedGroup) || {};
  const userDetails = useSelector((state) => state.user.userData) || {};
  const groupId = selectedGroup.id || "";
  const orgId = orgDetails._id || "";

  const [sessionId, setSessionId] = useState(() =>
    generateSessionId(userDetails?.email)
  );

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [abortController, setAbortController] = useState(null);
  const [initialPromptsLoaded, setInitialPromptsLoaded] = useState(false);

  const [feedback, setFeedback] = useState({});

  const [isInputFocused, setIsInputFocused] = useState(false);

  const activeResponseRef = useRef(null);
  const responseCompletedRef = useRef(false);
  const currentPromptQuestionRef = useRef("");

  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const prevMessagesLengthRef = useRef(messages.length);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textAreaRef = useRef(null);

  const [isScanWizardOpen, setIsScanWizardOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [pendingPrompt, setPendingPrompt] = useState("");

  const bgColor = colorMode === "dark" ? "transparent" : "gray.50";
  const inputBgColor = colorMode === "dark" ? "#2D3748" : "white";
  const inputBorderColor = colorMode === "dark" ? "#4A5568" : "#E2E8F0";
  const focusedInputBorderColor = colorMode === "dark" ? "#63B3ED" : "#3182CE";
  const botBgColor = colorMode === "dark" ? "gray.800" : "gray.100";
  const userBgColor = colorMode === "dark" ? "transparent" : "white";

  const getQuestionForBotMessage = (msg) => {
    return currentPromptQuestionRef.current || "";
  };

  const handleFeedback = (msg, feedbackType) => {
    if (feedback[msg.id]) return;

    setFeedback((prev) => ({
      ...prev,
      [msg.id]: feedbackType,
    }));

    const question = getQuestionForBotMessage(msg);


    const response =
      typeof msg.payload === "string" ? msg.payload : msg.text || "";

    const model = ["qna", "sql", "tags", "security_assistant"].includes(
      msg.type
    )
      ? msg.type
      : "security_assistant";


    fetch("/api/v1/ai/securitron-feedback-by-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question,
        response,
        feedback: feedbackType === "up",
        model,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Feedback API call failed");
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title:
            feedbackType === "up"
              ? "Thank you for the feedback!"
              : "We'll improve based on your feedback",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Feedback error:", err);
        toast({
          title: "Feedback submission failed",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };


  useEffect(() => {
    if (!loading && textAreaRef.current) {

      textAreaRef.current.focus();
    }
  }, [loading]);


  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 30;


      if (!isAtBottom) {
        setUserHasScrolled(true);
      } else {
        setUserHasScrolled(false);
      }
    }
  };


  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);


  useEffect(() => {

    if (messages.length !== prevMessagesLengthRef.current) {

      if (messages.length > 0 && !messages[messages.length - 1].isBot) {
        scrollToBottom();
        setUserHasScrolled(false);
      }
      else if (userHasScrolled) {
      }
      else {
        scrollToBottom();
      }

      prevMessagesLengthRef.current = messages.length;
    } else if (streamResponse && !userHasScrolled) {
      scrollToBottom();
    }
  }, [messages, streamResponse, userHasScrolled]);

  useEffect(() => {
    if (orgId && groupId && !initialPromptsLoaded) {
      setInitialPromptsLoaded(true);
      sendInitialPrompts(sessionId);
    }
  }, [orgId, groupId, initialPromptsLoaded, sessionId]);

  const sendInitialPrompts = async (currentSessionId) => {
    if (initialPromptsLoaded) return;
  
    for (let i = 0; i < initialPrompts.length; i++) {
      const prompt = initialPrompts[i];
  
      currentPromptQuestionRef.current = prompt;
  
      const msgId = Date.now() + "_" + i;
      setMessages((prev) => [
        ...prev,
        {
          id: msgId,
          text: "",
          isBot: true,
          isTemporary: true,
          isMarkdown: true,
          isInitialPrompt: true,
        },
      ]);
  
      activeResponseRef.current = i;
  
      try {
        await dispatch(
          postSecuritronAsync({
            organizationId: orgId,
            question: prompt,
            group_id: groupId,
            session_id: currentSessionId, 
          })
        ).unwrap();
  
        if (i < initialPrompts.length - 1) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      } catch (err) {
        console.error("Error sending initial prompt:", err);
      }
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === "#new-scan") {
        setIsScanWizardOpen(true);
      }
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  useEffect(() => {
    const handleGroupHashChange = () => {
      if (window.location.hash === "#new-group") {
        setIsGroupModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleGroupHashChange);
    return () =>
      window.removeEventListener("hashchange", handleGroupHashChange);
  }, []);

  useEffect(() => {
    if (location.hash === "#new-org") {
      setIsOrgModalOpen(true);
    }
  }, [location.hash]);

  useEffect(() => {
    const handleOrgHashChange = () => {
      if (window.location.hash === "#new-org") {
        setIsOrgModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleOrgHashChange);
    return () => window.removeEventListener("hashchange", handleOrgHashChange);
  }, []);

  useEffect(() => {
    const handleMembersHashChange = () => {
      if (window.location.hash === "#new-members") {
        setIsMemberModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleMembersHashChange);
    return () =>
      window.removeEventListener("hashchange", handleMembersHashChange);
  }, []);

  useEffect(() => {
    if (location.hash.startsWith("#promt:")) {
      const promptText = decodeURIComponent(location.hash.substring(7));
      if (promptText) {
        setPendingPrompt(promptText);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (pendingPrompt && orgId && groupId) {
      handleSubmit(null, pendingPrompt);
      setPendingPrompt("");
      clearHash();
    }
  }, [pendingPrompt, orgId, groupId]);

  const clearHash = () => {
    window.history.replaceState(
      null,
      "",
      "/app" + location.pathname + location.search
    );
  };

  const handleScanWizardClose = () => {
    setIsScanWizardOpen(false);
    clearHash();
  };

  const handleGroupModalClose = () => {
    setIsGroupModalOpen(false);
    clearHash();
  };

  const handleOrgModalClose = () => {
    setIsOrgModalOpen(false);
    clearHash();
  };

  const handleMemberModalClose = () => {
    setIsMemberModalOpen(false);
    clearHash();
  };

  const handleSaveGroup = async (groupData) => {
    try {
      const newGroup = await dispatch(
        addGroupAsync({ orgId, groupData })
      ).unwrap();
      toast({
        title: "Group Created",
        description: `Group "${newGroup.name}" has been created successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      handleGroupModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error?.message || "Failed to create group.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (
      streamResponse &&
      activeResponseRef.current !== null &&
      !responseCompletedRef.current
    ) {
      setMessages((prev) => {
        const newMessages = [...prev];
        const activeIndex = activeResponseRef.current;

        if (newMessages[activeIndex]) {
          newMessages[activeIndex] = {
            ...newMessages[activeIndex],
            text: streamResponse,
            isTemporary: false,
          };
        }
        return newMessages;
      });
    }
  }, [streamResponse]);

  useEffect(() => {
    if (response && activeResponseRef.current !== null && !loading) {
      setMessages((prev) => {
        const newMessages = [...prev];
        const activeIndex = activeResponseRef.current;

        if (newMessages[activeIndex]) {
          newMessages[activeIndex] = {
            ...newMessages[activeIndex],
            id: newMessages[activeIndex].id || Date.now(),
            text: response.payload || "",
            isTemporary: false,
            headers: response.headers || {},
            type: response.type || null,
            payload: response.payload || "",
          };
        }
        return newMessages;
      });

      responseCompletedRef.current = true;
    }
  }, [response, loading]);

  useEffect(() => {
    if (!loading && activeResponseRef.current !== null) {
      if (streamResponse) {
        setMessages((prev) => {
          const newMessages = [...prev];
          const activeIndex = activeResponseRef.current;

          if (newMessages[activeIndex]) {
            newMessages[activeIndex] = {
              ...newMessages[activeIndex],
              id: newMessages[activeIndex].id || Date.now(),
              text: streamResponse,
              isTemporary: false,
              headers: response?.headers || {},
              type: response?.type || null,
              payload: streamResponse || "",
            };
          }
          return newMessages;
        });
      } else if (response?.payload) {
        setMessages((prev) => {
          const newMessages = [...prev];
          const activeIndex = activeResponseRef.current;

          if (newMessages[activeIndex]) {
            newMessages[activeIndex] = {
              ...newMessages[activeIndex],
              id: newMessages[activeIndex].id || Date.now(),
              text: response.payload,
              isTemporary: false,
              headers: response.headers || {},
              type: response.type || null,
              payload: response.payload || "",
            };
          }
          return newMessages;
        });
      }

      responseCompletedRef.current = true;
    }
  }, [loading, streamResponse, response]);

  const handleSubmit = (e, forcedPrompt = null) => {
    if (e) e.preventDefault();

    const userInput = forcedPrompt || input;
    const trimmed = userInput.trim();

    if (!trimmed) return;
    if (!orgId || !groupId) {
      toast({
        title: "Missing Information",
        description: "Organization ID or Group ID is missing.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    currentPromptQuestionRef.current = trimmed;

    if (abortController) {
      abortController.abort();
    }

    responseCompletedRef.current = false;

    const isCode = trimmed.startsWith("```") && trimmed.endsWith("```");

    setMessages((prev) => {
      const newMessages = [
        ...prev,
        {
          id: Date.now(),
          text: trimmed,
          isBot: false,
          isCode,
          isMarkdown: false,
        },
        {
          id: Date.now() + 1,
          text: "",
          isBot: true,
          isTemporary: true,
          isMarkdown: true,
        },
      ];

      activeResponseRef.current = newMessages.length - 1;

      return newMessages;
    });

    if (!forcedPrompt) setInput("");

    setUserHasScrolled(false);
    scrollToBottom();

    const controller = new AbortController();
    setAbortController(controller);

    dispatch(
      postSecuritronAsync({
        organizationId: orgId,
        question: trimmed,
        group_id: groupId,
        session_id: sessionId,
        signal: controller.signal,
      })
    ).catch((error) => {
      toast({
        title: "Error",
        description: "Failed to get response from Securitron",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleNewChat = () => {
    if (abortController) {
      abortController.abort();
    }
  
    dispatch(resetSecuritronState());
    setMessages([]);
    setFeedback({});
    activeResponseRef.current = null;
    responseCompletedRef.current = false;
    setUserHasScrolled(false);
    
    const newSessionId = generateSessionId(userDetails?.email);
    setSessionId(newSessionId);
  
    setInitialPromptsLoaded(false);
  
    setTimeout(() => {
      if (orgId && groupId) {
        setInitialPromptsLoaded(true);
        sendInitialPrompts(newSessionId);
      }
    }, 100);
  };

  return (
    <Box
      height="86vh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      bgColor={bgColor}
    >
      {/* Messages container with scroll */}
      <Box flex="1" overflow="auto" ref={chatContainerRef}>
        <VStack spacing={0} align="stretch">
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              msg={msg}
              botBgColor={botBgColor}
              userBgColor={userBgColor}
              colorMode={colorMode}
              userDetails={userDetails}
              isLoading={loading && index === activeResponseRef.current}
              onFeedback={handleFeedback}
              feedbackGiven={feedback[msg.id]}
            />
          ))}
          <div ref={messagesEndRef} />
        </VStack>
      </Box>

      {/* Fixed input area */}
      <Box
        borderTop="1px solid"
        borderColor={colorMode === "dark" ? "gray.700" : "gray.200"}
        py={4}
        px={4}
        bgColor={bgColor}
      >
        <Container maxW="container.md">
          <form onSubmit={handleSubmit}>
            <Flex position="relative" alignItems="center">

              {/* Text area */}
              <TextareaAutosize
                ref={textAreaRef}
                minRows={1}
                maxRows={5}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                placeholder="Reply to Securitron"
                style={{
                  resize: "none",
                  borderRadius: "24px",
                  paddingLeft: "3rem",
                  paddingRight: "12rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  width: "100%",
                  backgroundColor: inputBgColor,
                  border: `1px solid ${
                    isInputFocused ? focusedInputBorderColor : inputBorderColor
                  }`,
                  outline: "none",
                  transition: "border-color 0.2s ease-in-out",
                  boxShadow: isInputFocused
                    ? colorMode === "dark"
                      ? "0 0 0 1px #63B3ED"
                      : "0 0 0 1px #3182CE"
                    : "none",
                }}
                disabled={loading}
              />

              {/* Right-side icons */}
              <HStack
                spacing={2}
                position="absolute"
                right={3}
                top="50%"
                transform="translateY(-50%)"
              >
                <IconButton
                  icon={<FiGlobe />}
                  aria-label="Search"
                  variant="ghost"
                  size="sm"
                  onClick={() =>
                    window.open(
                      "https://docs.aquilax.ai/user-manual",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
                <Tooltip label="Start New Chat" placement="top">
                  <Button
                    variant="outline"
                    size="sm"
                    borderRadius="md"
                    onClick={handleNewChat}
                  >
                    New Chat
                  </Button>
                </Tooltip>
                <IconButton
                  icon={<FiArrowUp />}
                  aria-label="Send"
                  colorScheme="blue"
                  borderRadius="full"
                  type="submit"
                  isDisabled={!input.trim() || loading}
                  size="sm"
                />
              </HStack>
            </Flex>
          </form>
          <Text mt={2} textAlign="center" fontSize="xs" color="gray.500">
            Securitron can make mistakes. Check important info.
          </Text>
        </Container>
      </Box>

      {/* Modals */}
      {isScanWizardOpen && (
        <ScanWizardModal
          isOpen={isScanWizardOpen}
          onClose={handleScanWizardClose}
          orgId={orgId}
        />
      )}

      {isGroupModalOpen && (
        <GroupModal
          isOpen={isGroupModalOpen}
          onClose={handleGroupModalClose}
          mode="create"
          groupData={null}
          onSave={handleSaveGroup}
          onDelete={() => {}}
        />
      )}

      {isOrgModalOpen && (
        <CreateOrganizationModal
          isOpen={isOrgModalOpen}
          onClose={handleOrgModalClose}
        />
      )}

      {isMemberModalOpen && (
        <AddMemberModal
          isOpen={isMemberModalOpen}
          onClose={handleMemberModalClose}
          organizationId={orgId}
        />
      )}

      {/* Styles */}
      <style jsx global>{`
        .dot-animation {
          position: relative;
          width: 30px;
          display: inline-flex;
          align-items: center;
          height: 20px;
        }

        .dot-animation::after {
          content: "...";
          position: absolute;
          animation: dots 1.5s infinite;
          display: inline-block;
          width: 20px;
          text-align: left;
          line-height: 1;
        }

        @keyframes dots {
          0%,
          20% {
            content: ".";
          }
          40% {
            content: "..";
          }
          60%,
          100% {
            content: "...";
          }
        }

        .markdown-content {
          width: 100%;
        }

        .markdown-content img {
          max-width: 100%;
          height: auto;
        }

        .markdown-content h1,
        .markdown-content h2,
        .markdown-content h3,
        .markdown-content h4,
        .markdown-content h5,
        .markdown-content h6 {
          font-weight: bold;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }

        .markdown-content h1 {
          font-size: 1.8rem;
        }
        .markdown-content h2 {
          font-size: 1.6rem;
        }
        .markdown-content h3 {
          font-size: 1.4rem;
        }
        .markdown-content h4 {
          font-size: 1.2rem;
        }
        .markdown-content h5 {
          font-size: 1.1rem;
        }

        .markdown-content p {
          margin-bottom: 0.75rem;
        }

        .markdown-content ul,
        .markdown-content ol {
          margin-left: 1.5rem;
          margin-bottom: 0.75rem;
        }

        .markdown-content li {
          margin-bottom: 0.25rem;
        }

        .markdown-content pre {
          background-color: ${colorMode === "dark" ? "#0c142e" : "#0c142e"};
          padding: 1rem;
          border-radius: 0.375rem;
          overflow-x: auto;
          margin-bottom: 0.75rem;
          color: ${colorMode === "dark" ? "inherit" : "#f4f4f5"};
        }

        .markdown-content code {
          font-family: monospace;
          padding: 0.2rem 0.4rem;
          background-color: ${colorMode === "dark" ? "#0c142e" : "#0c142e"};
          border-radius: 0.25rem;
          color: ${colorMode === "dark" ? "inherit" : "#f4f4f5"};
        }

        .markdown-content pre code {
          padding: 0;
          background-color: transparent;
        }

        .markdown-content hr {
          margin: 1rem 0;
          border: 0;
          border-top: 1px solid ${colorMode === "dark" ? "#4A5568" : "#E2E8F0"};
        }

        .markdown-content a {
          color: #3182ce;
          text-decoration: underline;
        }

        .markdown-content strong {
          font-weight: bold;
        }

        /* Fix for broken Markdown rendering common issues */
        .markdown-content p > img:only-child {
          display: block;
          margin: 0 auto;
        }
      `}</style>
    </Box>
  );
}

function MessageBubble({
  msg,
  botBgColor,
  userBgColor,
  colorMode,
  userDetails,
  isLoading,
  onFeedback,
  feedbackGiven,
}) {
  const borderColor = colorMode === "dark" ? "gray.700" : "gray.200";
  const toast = useToast();
  const contentRef = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(msg.text);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const shouldShowPdfDownload =
    msg.headers && msg.headers["x-aquilax-download"] === "pdf";

  const shouldShowFeedback =
    msg.isBot && !msg.isInitialPrompt && !isLoading && msg.text;

  const handleDownloadPdf = () => {
    try {
      const doc = new jsPDF();

      doc.setFontSize(16);
      doc.text("Securitron Report", 20, 20);

      doc.setFontSize(10);
      doc.text(`Generated: ${new Date().toLocaleString()}`, 20, 30);

      doc.setLineWidth(0.5);
      doc.line(20, 35, 190, 35);

      // Converting markdown to plain text
      const plainText = msg.text
        .replace(/#{1,6}\s(.*)/g, "$1\n")
        .replace(/\*\*(.*?)\*\*/g, "$1")
        .replace(/\*(.*?)\*/g, "$1")
        .replace(/`(.*?)`/g, "$1")
        .replace(/```[\s\S]*?```/g, (content) => content.replace(/```/g, ""))
        .replace(/\[([^\]]+)\]\(([^)]+)\)/g, "$1 ($2)");

      doc.setFontSize(12);

      const lines = doc.splitTextToSize(plainText, 170);
      doc.text(lines, 20, 45);

      const fileName = `securitron-response-${new Date()
        .toISOString()
        .substring(0, 10)}.pdf`;
      doc.save(fileName);

      toast({
        title: "PDF Downloaded",
        description: `Saved as ${fileName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating PDF:", error);
      toast({
        title: "Download Failed",
        description: "Could not create the PDF file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      p={6}
      w="full"
      bgColor={msg.isBot ? botBgColor : userBgColor}
      borderBottom="1px solid"
      borderColor={borderColor}
    >
      <Container maxW="container.md">
        <Flex alignItems="flex-start">
          {msg.isBot ? (
            <Avatar
              size="sm"
              name="Aquilax"
              src={Aquilax}
              bg="teal.500"
              mr={4}
            />
          ) : (
            <Avatar
              size="sm"
              name={userDetails?.name || "User"}
              src={userDetails?.picture}
              bg="blue.500"
              mr={4}
            />
          )}
          <VStack align="start" spacing={3} flex={1}>
            {msg.isCode ? (
              <Box
                as="pre"
                bg={colorMode === "dark" ? "gray.800" : "gray.900"}
                p={3}
                borderRadius="md"
                overflowX="auto"
                width="100%"
              >
                {msg.text.replace(/^```|```$/g, "")}
              </Box>
            ) : msg.isMarkdown ? (
              <Box width="100%" className="markdown-content" ref={contentRef}>
                {isLoading && (!msg.text || msg.isTemporary) ? (
                  <Flex alignItems="center">
                    <Text fontWeight="medium" mr={2}>
                      Thinking
                    </Text>
                    <Box className="dot-animation" />
                  </Flex>
                ) : (
                  <>
                    {msg.text ? (
                      <ReactMarkdown>{msg.text}</ReactMarkdown>
                    ) : (
                      <Box>
                        <Text fontWeight="medium" mr={2}>
                          Thinking
                        </Text>
                        <Box className="dot-animation" />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            ) : (
              <Text whiteSpace="pre-wrap" wordBreak="break-word">
                {msg.text}
              </Text>
            )}

            {/* Feedback/action buttons - only show for non-initial bot messages */}
            {shouldShowFeedback && (
              <HStack spacing={2} mt={2}>
                <IconButton
                  aria-label="Copy"
                  icon={<FiCopy />}
                  size="sm"
                  variant="ghost"
                  onClick={handleCopy}
                />
                <IconButton
                  aria-label="Thumbs up"
                  icon={<FiThumbsUp />}
                  size="sm"
                  variant="ghost"
                  colorScheme={feedbackGiven === "up" ? "green" : "gray"}
                  onClick={() => onFeedback(msg, "up")}
                  isDisabled={!!feedbackGiven}
                />
                <IconButton
                  aria-label="Thumbs down"
                  icon={<FiThumbsDown />}
                  size="sm"
                  variant="ghost"
                  colorScheme={feedbackGiven === "down" ? "red" : "gray"}
                  onClick={() => onFeedback(msg, "down")}
                  isDisabled={!!feedbackGiven}
                />
                {shouldShowPdfDownload && (
                  <Tooltip label="Download as PDF" placement="top">
                    <IconButton
                      aria-label="Download PDF"
                      icon={<FiDownload />}
                      size="sm"
                      variant="ghost"
                      onClick={handleDownloadPdf}
                      colorScheme="blue"
                    />
                  </Tooltip>
                )}
              </HStack>
            )}
          </VStack>
        </Flex>
      </Container>
    </Flex>
  );
}
