import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Divider,
  Button,
} from "@chakra-ui/react";

const MarkdownSummary = ({
  text,
  textColor = "gray.600",
  fontSize = "sm",
  headingColor,
  maxChars = 290,
}) => {
  const [expanded, setExpanded] = useState(false);

  if (!text) return null;

  const isTruncated = text.length > maxChars;
  const displayText = expanded || !isTruncated ? text : text.slice(0, maxChars) + "...";

  return (
    <Box>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Heading as="h1" size="lg" color={headingColor || textColor} my={4} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <Heading as="h2" size="md" color={headingColor || textColor} my={4} {...props} />
          ),
          h3: ({ node, ...props }) => (
            <Heading as="h3" size="sm" color={headingColor || textColor} my={3} {...props} />
          ),
          p: ({ node, ...props }) => (
            <Text fontSize={fontSize} color={textColor} my={2} {...props} />
          ),
          strong: ({ node, ...props }) => (
            <Text as="strong" fontWeight="bold" {...props} />
          ),
          ul: ({ node, ...props }) => (
            <UnorderedList pl={6} my={2} {...props} />
          ),
          li: ({ node, ...props }) => (
            <ListItem {...props} />
          ),
          hr: ({ node, ...props }) => <Divider my={4} {...props} />,
        }}
      >
        {displayText}
      </ReactMarkdown>
      {isTruncated && (
        <Button
          variant="link"
          color="blue.700"
          onClick={() => setExpanded((prev) => !prev)}
          mt={2}
        >
          {expanded ? "Show Less" : "Read More"}
        </Button>
      )}
    </Box>
  );
};

export default MarkdownSummary;
