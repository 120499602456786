import axios from "axios";

const url = process.env.REACT_APP_API_URL;

// 1. GET Projects with Pagination
export const getProjects = async (orgId, groupId, thunkAPI, page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `${url}/v2/projects?org=${orgId}&group=${groupId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching projects:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 2. GET Project Commits
export const getProjectCommits = async (orgId, groupId, projectId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/commits`,
      {
        withCredentials: true,
      }
    );
    return response.data; // Assuming this is an array of commit objects
  } catch (error) {
    console.error("Error fetching project commits:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 3. GET Commit Scans
export const getCommitScans = async (orgId, groupId, projectId, commitSha, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/commit/${commitSha}/scan`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching commit scans:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 4. PUT Update Project
export const updateProject = async (orgId, groupId, projectId, projectData, thunkAPI) => {
  try {
    const response = await axios.put(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}`,
      projectData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating project:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 5. DELETE Project
export const deleteProject = async (orgId, groupId, projectId, thunkAPI) => {
  try {
    const response = await axios.delete(
      `${url}/v2/project/${projectId}?org=${orgId}&group=${groupId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting project:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 6. POST Create Project
export const createProject = async (orgId, groupId, projectData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group/${groupId}/project`,
      projectData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 7. STOP Scheduled Scan
export const stopScheduledScan = async (orgId, groupId, projectId, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/stop-scan`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error stopping scheduled scan:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

// 8. START Scheduled Scan
export const startScheduledScan = async (orgId, groupId, projectId, frequency, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/start-scan`,
      { frequency },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error starting scheduled scan:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};
