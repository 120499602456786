import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Badge,
  Flex,
  VStack,
  Text,
  Divider,
  Tag,
  Tooltip,
  Link,
  HStack,
  useColorModeValue,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { getReviewFindingAsync } from "../../features/Securitron/securitronAction";

const RepositoryDrawer = ({ isOpen, onClose, selectedItem, onSave, constructRepoURL }) => {
  if (!selectedItem) return null;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.user.selectedOrganization);
  const scanDetails = useSelector((state) => state.scans.scanDetails);
  const plan = organization?.plan || "Free";

  const repoURL = constructRepoURL({
    gitUri: scanDetails?.scan?.git_uri || "",
    commitSha: scanDetails?.scan?.metadata?.git?.sha || "",
    path: selectedItem.path || "",
    lineNumber: selectedItem.line_start || 0,
  });

  const drawerHeaderBorderColor = useColorModeValue("gray.200", "gray.600");
  const detailTextColor = useColorModeValue("gray.500", "gray.300");
  const detailValueColor = useColorModeValue("gray.800", "gray.100");
  const boxBg = useColorModeValue("gray.50", "gray.700");
  const boxBorderColor = useColorModeValue("gray.200", "gray.600");
  const sectionHeadingColor = useColorModeValue("gray.700", "gray.200");

  const [isTitleExpanded, setIsTitleExpanded] = useState(false);
  const maxTitleLength = 150; 

  const [status, setStatus] = useState(selectedItem.status || "Unverified");
  const [reason, setReason] = useState(selectedItem.reason || "");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isMessageExpanded, setIsMessageExpanded] = useState(false);
  const maxLength = 300;

  const [reviewResult, setReviewResult] = useState(null);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [reviewAsked, setReviewAsked] = useState(false);
  const [remediation, setRemediation] = useState(
    selectedItem.remediation || selectedItem.remediation_action || ""
  );

  useEffect(() => {
    setReason(selectedItem.reason || "");
    setRemediation(selectedItem.remediation || selectedItem.remediation_action || "");
    setStatus(selectedItem.status || "Unverified");
  }, [selectedItem]);

  useEffect(() => {
    if (status === "True Positive" || status === "False Positive") {
      setShowAdditionalFields(true);
    } else {
      setShowAdditionalFields(false);
      setReason("");
      setRemediation("");
    }
  }, [status]);

  const handleSave = () => {
    const updatedData = {
      ...selectedItem,
      status,
      reason: reason.trim() || null,
      remediation: remediation.trim(),
    };

    if (onSave) {
      onSave(updatedData);
    }

    onClose();
  };

  const handleAskSecuritronReview = async () => {
    setReviewAsked(true);

    const orgId = scanDetails?.scan?.organization;
    const scanId = scanDetails?.scan?._id;
    const findingId = selectedItem.id;

    if (!orgId || !scanId || !findingId) {
      return;
    }

    setReviewLoading(true);
    try {
      const resultAction = await dispatch(
        getReviewFindingAsync({
          org_id: orgId,
          scan_id: scanId,
          finding_id: findingId,
        })
      );
      const result = resultAction.payload;
      setReviewResult(result);
    } catch (err) {
      console.error("Review Finding error:", err);
    }
    setReviewLoading(false);
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={useColorModeValue("white", "gray.800")}>
        <DrawerCloseButton />
        <DrawerHeader
          fontSize="lg"
          fontWeight="bold"
          borderBottomWidth="1px"
          borderColor={drawerHeaderBorderColor}
          color={sectionHeadingColor}
        >
          <Flex align="center">
            <Text>
              {isTitleExpanded || selectedItem.title.length <= maxTitleLength
                ? selectedItem.title
                : `${selectedItem.title.slice(0, maxTitleLength)}...`}
            </Text>
            {selectedItem.title.length > maxTitleLength && !isTitleExpanded && (
              <Button
                size="xs"
                variant="link"
                onClick={() => setIsTitleExpanded(true)}
                ml={2}
                color={useColorModeValue("blue.700", "blue.700")}
              >
                Read more
              </Button>
            )}
            {isTitleExpanded && selectedItem.title.length > maxTitleLength && (
              <Button
                size="xs"
                variant="link"
                onClick={() => setIsTitleExpanded(false)}
                ml={2}
                color={useColorModeValue("blue.700", "blue.700")}
              >
                Read less
              </Button>
            )}
          </Flex>
        </DrawerHeader>

        <DrawerBody py={4} px={4}>
          {/* Vulnerability Details Section */}
          <VStack align="stretch" spacing={3} mb={4}>
            <HStack>
              <Text
                fontSize="md"
                fontWeight="bold"
                color={sectionHeadingColor}
              >
                Vulnerability Details
              </Text>
              <Tag size="sm">{selectedItem.rule_id}</Tag>
            </HStack>
            <Box>
              <Text fontSize="sm" color={detailValueColor} lineHeight="1.5">
                {isMessageExpanded || selectedItem.message.length <= maxLength
                  ? selectedItem.message
                  : `${selectedItem.message.slice(0, maxLength)}...`}
              </Text>
              {selectedItem.message.length > maxLength && !isMessageExpanded && (
                <Button
                  size="xs"
                  variant="link"
                  onClick={() => setIsMessageExpanded(true)}
                  p={0}
                  mt={1}
                  color={useColorModeValue("blue.700", "blue.700")}
                >
                  Read more
                </Button>
              )}
            </Box>
          </VStack>

          <Divider mb={4} borderColor={drawerHeaderBorderColor} />

          {/* CWEs Section */}
          {selectedItem.tag && (
            <VStack align="stretch" spacing={3} mb={4}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
              >
                CWEs
              </Text>
              <HStack spacing={2} flexWrap="wrap">
                {selectedItem.tag.split(", ").map((cwe, index) => (
                  <Tag key={index} size="sm" colorScheme="purple">
                    {cwe}
                  </Tag>
                ))}
              </HStack>
            </VStack>
          )}

          {selectedItem.tag && (
            <Divider mb={4} borderColor={drawerHeaderBorderColor} />
          )}

          {/* Code Snippet Section */}
          {selectedItem.codeSnippet && (
            <VStack align="stretch" spacing={3} mb={4}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
              >
                Code Snippet
              </Text>
              <HStack spacing={1} alignItems="center">
              <Text fontSize="xs">Path:</Text>
              <Tooltip fontSize="xs" label="Open in new tab" aria-label="Open in new tab">
                <Link
                  href={repoURL}
                  isExternal
                  color={useColorModeValue("blue.700", "blue.700")}
                  textDecoration="underline"
                  fontSize="xs"
                  onClick={(e) => e.stopPropagation()}
                >
                  {selectedItem.path}
                </Link>
              </Tooltip>
              <Text fontSize="xs">[{selectedItem.line_start} - {selectedItem.line_end}]</Text>
            </HStack>
              <Box
                bg={boxBg}
                border="1px solid"
                borderColor={boxBorderColor}
                borderRadius="md"
                p={3}
                maxH="200px"
                overflowY="auto"
              >
                <div style={{ fontSize: "12px", lineHeight: "1.4" }}>
                  <CopyBlock
                    text={selectedItem.codeSnippet}
                    language="javascript"
                    showLineNumbers={false}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                  />
                </div>
              </Box>
            </VStack>
          )}

          {selectedItem.codeSnippet && (
            <Divider mb={4} borderColor={drawerHeaderBorderColor} />
          )}

          <VStack align="stretch" spacing={3} mb={4}>
            <Text
              fontSize="md"
              fontWeight="bold"
              color={sectionHeadingColor}
            >
              Recommendation
            </Text>
            <Tag size="sm">{selectedItem?.recommendation}</Tag>
          </VStack>

          <VStack align="stretch" spacing={4} mb={4}>
            <Button
              size="sm"
              onClick={handleAskSecuritronReview}
              isLoading={reviewLoading}
              color="blue.700"
            >
              Ask Securitron
            </Button>
            {reviewAsked && (
              <Box p={2} borderWidth="1px" borderRadius="md">
                {reviewResult && reviewResult.class ? (
                  <HStack spacing={2}>
                    {reviewResult.class === "TRUE_POSITIVE" ? (
                      <FaCheckCircle color="green" size={20} />
                    ) : reviewResult.class === "FALSE_POSITIVE" ? (
                      <FaTimesCircle color="red" size={20} />
                    ) : null}
                    <Text
                      fontWeight="bold"
                      color={
                        reviewResult.class === "TRUE_POSITIVE"
                          ? "green.500"
                          : reviewResult.class === "FALSE_POSITIVE"
                          ? "red.500"
                          : "gray.500"
                      }
                    >
                      {reviewResult.class === "TRUE_POSITIVE"
                        ? "True Positive"
                        : reviewResult.class === "FALSE_POSITIVE"
                        ? "False Positive"
                        : "Hey I'm not sure about this yet!"}
                    </Text>
                  </HStack>
                ) : (
                  <Text fontSize={'14px'} fontWeight="bold" color="gray.500">
                    Hey I'm not sure about this yet!
                  </Text>
                )}
              </Box>
            )}
          </VStack>

          {/* Status and Optional Fields */}
          <VStack align="stretch" spacing={4} mb={4}>
            <FormControl id="status">
              <FormLabel
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
              >
                Status
              </FormLabel>
              <HStack spacing={4}>
                <Button
                  size="sm"
                  variant={status === "True Positive" ? "solid" : "outline"}
                  onClick={() => setStatus("True Positive")}
                  colorScheme="red"
                >
                  True Positive
                </Button>
                <Button
                  size="sm"
                  variant={status === "Unverified" ? "solid" : "outline"}
                  onClick={() => setStatus("Unverified")}
                  colorScheme="blue"
                >
                  Unverified
                </Button>
                <Button
                  size="sm"
                  variant={status === "False Positive" ? "solid" : "outline"}
                  onClick={() => setStatus("False Positive")}
                  colorScheme="gray"
                >
                  False Positive
                </Button>
              </HStack>
            </FormControl>

            {showAdditionalFields && (
              <>
                <FormControl id="reason">
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    color={sectionHeadingColor}
                  >
                    Reason (Optional)
                  </FormLabel>
                  <Textarea
                    placeholder="Enter reason"
                    size="sm"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </FormControl>

                <FormControl id="remediation">
                  <FormLabel
                    fontSize="sm"
                    fontWeight="bold"
                    color={sectionHeadingColor}
                  >
                    Remediation Action (Optional)
                  </FormLabel>
                  <Textarea
                    placeholder="Enter remediation action"
                    size="sm"
                    value={remediation}
                    onChange={(e) => setRemediation(e.target.value)}
                  />
                </FormControl>
              </>
            )}

            {plan === "Free" ? (
              <Button
                size="sm"
                bg="blue.600"
                color="white"
                _hover={{ bg: 'blue.700' }}
                onClick={() => navigate("/plans")}
              >
                Upgrade to Update Status
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={handleSave}
                color="white"
                bg={useColorModeValue("#003460", "#0077b5")}
                _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
              >
                Save Changes
              </Button>
            )}
          </VStack>

          <Divider my={4} borderColor={drawerHeaderBorderColor} />

          {selectedItem.properties &&
            Object.keys(selectedItem.properties).length > 0 && (
              <VStack align="stretch" spacing={3}>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={sectionHeadingColor}
                  mb={3}
                >
                  Properties
                </Text>
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  spacing={0}
                  borderWidth="1px"
                  borderColor={boxBorderColor}
                  borderRadius="md"
                  overflow="hidden"
                >
                  {Object.entries(selectedItem.properties).map(([label, value]) => (
                    <Box
                      key={label}
                      p={4}
                      borderBottomWidth="1px"
                      borderRightWidth={{ base: "0px", md: "1px" }}
                      borderColor={boxBorderColor}
                      bg={
                        label === "Severity" && value === "HIGH"
                          ? useColorModeValue("red.50", "red.900")
                          : useColorModeValue("gray.50", "gray.700")
                      }
                      _hover={{
                        bg: useColorModeValue("gray.100", "gray.600"),
                      }}
                    >
                      <HStack spacing={2} align="center">
                        <Text fontSize="sm" fontWeight="bold" color={detailTextColor}>
                          {label}
                        </Text>
                      </HStack>
                      <HStack spacing={2} align="center" mt={2} wrap="wrap">
                        {label === "CVES" ? (
                          <Tag size="sm" colorScheme="red" cursor="pointer">
                            {value}
                          </Tag>
                        ) : label === "Severity" ||
                          label === "Impact" ||
                          label === "Likelihood" ? (
                          <Badge
                            colorScheme={
                              value === "HIGH"
                                ? "red"
                                : value === "MEDIUM"
                                ? "orange"
                                : "green"
                            }
                          >
                            {value}
                          </Badge>
                        ) : label === "CVSS_Vector" ? (
                          <Tooltip
                            fontSize="10px"
                            label="Copy to clipboard"
                            aria-label="Copy to clipboard"
                            hasArrow
                          >
                            <Text
                              fontSize="10px"
                              cursor="pointer"
                              whiteSpace="normal"
                              overflowWrap="anywhere"
                              maxW="200px"
                              border="1px solid"
                              borderColor={boxBorderColor}
                              borderRadius="md"
                              p={1}
                              onClick={() => navigator.clipboard.writeText(value)}
                              _hover={{
                                bg: useColorModeValue("gray.100", "gray.600"),
                              }}
                            >
                              {value}
                            </Text>
                          </Tooltip>
                        ) : label === "Date" ? (
                          <Text fontSize="md" fontWeight="semibold" color={detailValueColor}>
                            {value}
                          </Text>
                        ) : (
                          <Text fontSize="xl" fontWeight="semibold" color={detailValueColor}>
                            {value}
                          </Text>
                        )}
                      </HStack>
                    </Box>
                  ))}
                </SimpleGrid>
              </VStack>
            )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default RepositoryDrawer;
