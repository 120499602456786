import React from 'react';
import {
  Flex,
  Text,
  Image,
  HStack,
  VStack,
  Badge,
  Button,
  useToken,
  useColorModeValue,
} from '@chakra-ui/react';

const IntegrationCard = ({
  integration,
  integrationData,
  isIntegrated,
  totalRepos,
  integratedAt,
  onInstall,
  onViewDetails,
}) => {
  const [gray600, teal500] = useToken('colors', ['gray.600', 'teal.500']);
  const [gray300, gray200] = useToken('colors', ['gray.300', 'gray.200']);
  const cardBg = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const borderBg = useColorModeValue('gray.200', 'gray.600');

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={4}
      border="1px solid"
      borderColor={borderBg}
      borderRadius="md"
      bg={cardBg}
      transition="background-color 0.2s"
      _hover={{
        bg: isIntegrated ? hoverBg : cardBg,
        cursor: isIntegrated ? 'pointer' : 'default',
      }}
      onClick={() => isIntegrated && onViewDetails(integrationData)}
    >
      <HStack spacing={4}>
        <Image
          src={integration.icon}
          alt={`${integration.name} Logo`}
          boxSize="24px"
        />
        <VStack align="start" spacing={0}>
          <Text fontWeight="semibold">{integration.name}</Text>
          {isIntegrated ? (
            <HStack spacing={2}>
              <Badge colorScheme="green">Connected</Badge>
              <Text color="gray.500" fontSize="xs">
                {`Integrated ${integratedAt}`}
              </Text>
            </HStack>
          ) : (
            <Badge colorScheme="red">Not Installed</Badge>
          )}
        </VStack>
      </HStack>
      {isIntegrated ? (
        <Text color={gray600} fontWeight="medium">
          {`${totalRepos} ${totalRepos === 1 ? 'Project' : 'Projects'}`}
        </Text>
      ) : (
        <Button
          color="white"
          bg="blue.600"
          _hover={{ bg: 'blue.700' }}
          variant="solid"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onInstall();
          }}
        >
          Install Now
        </Button>
      )}
    </Flex>
  );
};

export default IntegrationCard;
