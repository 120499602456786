import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Box, Text } from '@chakra-ui/react';

const COLORS = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    return (
      <Box bg="white" p={2} borderRadius="md" shadow={'sm'}>
        <Text fontSize="10px" color={'black'}>
          <strong>{name}</strong>
        </Text>
      </Box>
    );
  }
  return null;
};

const PieChartWithPaddingAngle = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Text>No CWE data available.</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text fontSize="xs" mb={0} textAlign="center">
        Findings by CWE
      </Text>
    <PieChart width={250} height={140}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={40}
        outerRadius={60}
        labelLine={false}
        paddingAngle={5}
        activeIndex={-1}
        isAnimationActive={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} cursor="pointer" />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
    </Box>
  );
};

export default PieChartWithPaddingAngle;
