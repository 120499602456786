import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/atom-one-dark.css";

const QnACard = ({ answer }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.400", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Box
      borderWidth="0px"
      borderRadius="md"
      borderColor={borderColor}
      bg={bgColor}
      p={0} 
      fontSize="sm"
      color={textColor}
      overflow="hidden"
    >
      <ReactMarkdown
        children={answer}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeHighlight]}
        components={{

          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <Box
                as="pre"
                p={1}
                borderRadius="sm"
                overflowX="auto"
                fontSize="0.7rem"
                {...props}
              >
                <code className={className}>{children}</code>
              </Box>
            ) : (
              <code
                className={className}
                bg={useColorModeValue("gray.200", "gray.600")}
                px={1}
                borderRadius="sm"
                fontSize="0.7rem"
                {...props}
              >
                {children}
              </code>
            );
          },
        }}
      />
    </Box>
  );
};

export default QnACard;
