import axios from "axios";
import qs from "qs";

const url = process.env.REACT_APP_API_URL;


export const getUserData = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/get-user-data`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const userOnboarding = async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/onboarding`,
      qs.stringify(data),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in onboarding:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getNotifications = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/profile/notifications`, {
      withCredentials: true,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching notifications:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const readNotification = async (notificationId, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/profile/notifications`,
      qs.stringify({ notification_id: notificationId }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error reading notification:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getActivityLogs = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/profile/activity-logs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching activity logs:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getAccessTokens = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/auth/access-token`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching access tokens:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const createAccessToken = async (tokenData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/auth/access-token`,
      tokenData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating access token:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const deleteAccessToken = async (accessTokenId, thunkAPI) => {
  try {
    const response = await axios.delete(`${url}/v1/auth/access-token`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({ access_token_id: accessTokenId }),
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting access token:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const changeNotificationOptInStatus = async (notificationOptIn, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${url}/v1/user/change_notification_opt_in_status`,
      qs.stringify({ notification_opt_in: notificationOptIn }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating notification opt in status:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getProfileData = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/profile`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching profile data:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};