import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/Users/UsersSlice";
import orgsReducer from "./features/Orgs/OrgsSlice";
import groupsReducer from "./features/Groups/GroupsSlice";
import scansReducer from "./features/Scans/ScansSlice";
import securitronReducer from "./features/Securitron/securitronSlice"
import projectsReducer from "./features/Projects/ProjectSlice";
import analyticsReducer from "./features/Analytics/AnalyticsSlice";
import integrationsReducer from "./features/Integrations/integrationsSlice";
import plansReducer from "./features/Plans/plansSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    orgs: orgsReducer,
    groups: groupsReducer,
    scans: scansReducer,
    securitron: securitronReducer,
    projects: projectsReducer,
    analytics: analyticsReducer,
    integrations: integrationsReducer,
    plans: plansReducer,
  },
});

export default store; 