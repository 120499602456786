import React, { useEffect, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Text,
  Box,
  VStack,
  Spinner,
  Center,
  useColorModeValue,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
  Badge,
  Heading,
  Icon,
  Divider,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Code as ChakraCode,
} from '@chakra-ui/react';
import { MdSecurity } from 'react-icons/md';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const url = process.env.REACT_APP_API_URL;

const API_BASE_URL = `${url}/v1/ai`;

const questionOptions = [
  {
    label: 'Remediation Details',
    value: 'Why is this a vulnerability?',
  },
  {
    label: 'Fix & Code',
    value: 'How to fix this?',
  },
  {
    label: 'More Info',
    value: 'What happens if I don’t fix it?',
  },
];

const SecuritronDrawer = ({ isOpen, onClose, selectedFinding }) => {
  const toast = useToast();
  const [securitronResponse, setSecuritronResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(
    'Why is this a vulnerability?'
  );
  const scanDetails = useSelector((state) => state.scans.scanDetails);
  const drawerBg = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColorDefault = useColorModeValue('gray.200', 'gray.600');
  const activeBorderColor = 'blue.600';

  useEffect(() => {
    const controller = new AbortController();
    if (isOpen && selectedFinding) {
      fetchSecuritronResponse(selectedFinding, selectedQuestion, controller.signal);
    } else {
      setSecuritronResponse('');
      setError(null);
    }
    return () => {
      controller.abort();
    };
  }, [isOpen, selectedFinding, selectedQuestion]);

  const fetchSecuritronResponse = async (finding, question, signal) => {
    setLoading(true);
    setError(null);
    setSecuritronResponse('');
    try {
      const org_id = scanDetails?.scan?.organization;
      const scan_id = scanDetails?.scan?._id;
      const finding_id = finding.id;
      const url = `${API_BASE_URL}/organization/${org_id}/scan/${scan_id}/finding/${finding_id}/ask-security-assistant/stream?question=${encodeURIComponent(
        question
      )}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        signal, 
      });

      if (!response.ok || !response.body) {
        throw new Error(`Network error: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let buffer = '';

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (!value) continue;
        buffer += decoder.decode(value, { stream: !done });
        const segments = buffer.split('\n\n');
        buffer = segments.pop() || '';
        for (let seg of segments) {
          seg = seg.trim();
          if (!seg) continue;
          const lines = seg.split('\n');
          for (let line of lines) {
            if (line.startsWith('data:')) {
              const jsonStr = line.slice(5).trim();
              if (!jsonStr) continue;
              try {
                const parsed = JSON.parse(jsonStr);
                if (parsed.token) {
                  setSecuritronResponse((prev) => prev + parsed.token);
                }
              } catch (err) {
                console.error('Error parsing SSE JSON:', err, jsonStr);
              }
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Streaming SSE error:', err);
        setError('Error generating response');
      }
      setLoading(false);
    }
  };

  const handleQuestionSelect = (questionValue) => {
    if (loading) {
      toast({
        description: 'Please wait for the current response to finish.',
        status: 'info',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setSelectedQuestion(questionValue);
  };

  const markdownComponents = {
    code({ inline, children, className, ...props }) {
      if (inline) {
        return (
          <ChakraCode fontSize="0.85em" px={1} colorScheme="yellow" {...props}>
            {children}
          </ChakraCode>
        );
      }
      return (
        <Box
          bg={useColorModeValue('gray.100', 'gray.700')}
          borderRadius="md"
          p={3}
          my={2}
        >
          <ChakraCode
            display="block"
            w="100%"
            textAlign="left"
            whiteSpace="pre-wrap"
            colorScheme="yellow"
            {...props}
          >
            {children}
          </ChakraCode>
        </Box>
      );
    },
    strong({ children }) {
      return (
        <Text as="strong" fontWeight="bold">
          {children}
        </Text>
      );
    },
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={drawerBg}>
        <DrawerHeader
          borderBottomWidth="1px"
          borderColor={borderColorDefault}
          display="flex"
          alignItems="center"
        >
          <Icon as={MdSecurity} w={6} h={6} color="teal.400" mr={2} />
          <Heading size="md">Remediation Details</Heading>
        </DrawerHeader>

        <DrawerBody p={4}>
          <VStack align="stretch" spacing={5}>
            <Card bg={cardBg} borderColor={borderColorDefault} borderWidth="1px">
              <CardHeader pb={2}>
                <Text fontWeight="bold" fontSize="lg">
                  Vulnerability Details
                </Text>
              </CardHeader>
              <CardBody pt={0}>
                <Box mb={0}>
                  <Text>
                    <strong>Title:</strong> {selectedFinding?.vuln || 'N/A'}
                  </Text>
                  {selectedFinding?.severity && (
                    <Badge
                      colorScheme="red"
                      variant="solid"
                      mt={2}
                      fontSize="0.8em"
                    >
                      Severity: {selectedFinding.severity}
                    </Badge>
                  )}
                </Box>
              </CardBody>
            </Card>

            <Box>
              <Text fontWeight="bold" mb={2} fontSize="lg">
                Securitron Response
              </Text>
              <Box
                p={4}
                borderWidth="1px"
                borderColor={borderColorDefault}
                borderRadius="md"
                bg={useColorModeValue('gray.50', 'gray.700')}
                minHeight="150px"
              >
                {error ? (
                  <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                ) : loading && !securitronResponse ? (
                  <Center>
                    <Spinner size="sm" mr={2} color="blue.700" />
                    <Text fontStyle="italic" color="gray.500">
                      Reasoning
                    </Text>
                  </Center>
                ) : (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={markdownComponents}
                  >
                    {securitronResponse}
                  </ReactMarkdown>
                )}
              </Box>
            </Box>

            <Card bg={cardBg} borderColor={borderColorDefault} borderWidth="1px">
              <CardHeader>
                <Text fontWeight="bold" fontSize="lg">
                  Select a Prompt
                </Text>
              </CardHeader>
              <CardBody>
                <VStack spacing={2}>
                  {questionOptions.map((option) => {
                    const isSelected = selectedQuestion === option.value;
                    return (
                      <Card
                        key={option.value}
                        variant="outline"
                        borderWidth="2px"
                        cursor="pointer"
                        borderColor={
                          isSelected ? activeBorderColor : borderColorDefault
                        }
                        onClick={() => handleQuestionSelect(option.value)}
                        _hover={{ borderColor: activeBorderColor }}
                        w="100%"
                      >
                        <CardBody>
                          <Text fontWeight="semibold">{option.label}</Text>
                        </CardBody>
                      </Card>
                    );
                  })}
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px" borderColor={borderColorDefault}>
          <Button size="sm" variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SecuritronDrawer;
