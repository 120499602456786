import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  VStack,
  HStack,
  Skeleton,
  Badge,
  Flex,
  Stack,
  Input,
  Select,
  Link,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import { InfoIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { FaGithub, FaGitlab, FaCodeBranch, FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetScanDetails } from "../../features/Scans/ScansSlice";

dayjs.extend(relativeTime);

const ProjectDataModal = ({
  isOpen,
  onClose,
  projectId,
  orgId,
  groupId,
  projectName,
  repoUrl,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shaSearch, setShaSearch] = useState("");
  const [timeFilter, setTimeFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
          const baseUrl = process.env.REACT_APP_API_URL;
          const url = `${baseUrl}/v2/project/${projectId}?org=${orgId}&group=${groupId}`;
          const response = await fetch(url, {
            method: "GET",
            credentials: "include", 
          });
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }
          const jsonData = await response.json();
          if (!jsonData) {
            throw new Error("No data received from the server.");
          }
          setData(jsonData);
        } catch (err) {
          console.error("Error fetching project data:", err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [isOpen, projectId, orgId, groupId]);

  // Filtering logic
  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const sha = item.metadata?.git?.sha || "";
      const matchesSha = sha.toLowerCase().includes(shaSearch.toLowerCase());

      const createdAt = dayjs(item.created_at * 1000);
      let matchesTime = true;
      if (timeFilter === "Today") {
        matchesTime = createdAt.isSame(dayjs(), "day");
      } else if (timeFilter === "Yesterday") {
        matchesTime = createdAt.isSame(dayjs().subtract(1, "day"), "day");
      } else if (timeFilter === "Last 1 Week") {
        matchesTime = createdAt.isAfter(dayjs().subtract(1, "week"));
      } else if (timeFilter === "Last 3 Months") {
        matchesTime = createdAt.isAfter(dayjs().subtract(3, "month"));
      } else if (timeFilter === "Last 6 Months") {
        matchesTime = createdAt.isAfter(dayjs().subtract(6, "month"));
      } else if (timeFilter === "Last 1 Year") {
        matchesTime = createdAt.isAfter(dayjs().subtract(1, "year"));
      }

      const matchesStatus =
        statusFilter === "All" || item.status === statusFilter;

      return matchesSha && matchesTime && matchesStatus;
    });
  }, [data, shaSearch, timeFilter, statusFilter]);

  const handleScanDetailsClick = (scanId) => {
    dispatch(resetScanDetails());
    navigate(`/scan/${orgId}/${scanId}/${groupId}`);
  };

  const getRepoIcon = (url) => {
    if (url && url.includes("github.com")) {
      return <FaGithub />;
    } else if (url && url.includes("gitlab.com")) {
      return <FaGitlab />;
    }
    return <FaExternalLinkAlt />;
  };

  const ScanCard = ({ item }) => {
    const commitId = item.metadata?.git?.sha;
    const branchName = item.branch || "";
    const scanId = item._id;
    
    const statusColor = 
      item.status === "COMPLETED" ? "green" :
      item.status === "FAILED" ? "red" : "yellow";
    
    return (
      <Box p={3} borderWidth="1px" borderRadius="md" boxShadow="sm">
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box>
            <HStack>
              <Text fontSize="sm" fontWeight="bold">
                Created:
              </Text>
              <Text fontSize="sm">
                {dayjs(item.created_at * 1000).fromNow()}
              </Text>
            </HStack>
            
            <Box mt={2}>
              {commitId && (
                <Text fontSize="xs" color="gray.600">
                  SHA: {commitId}
                </Text>
              )}
              {scanId && (
                <Text fontSize="xs" color="gray.600">
                  ID: {scanId}
                </Text>
              )}
            </Box>
          </Box>
          
          <Flex direction="column" align="flex-end">
            <Badge
              fontSize="xs"
              colorScheme={statusColor}
              mb={2}
            >
              {item.status}
            </Badge>
            
            {item.status !== "FAILED" && (
              <Button
                size="xs"
                bg="blue.600"
                color="white"
                _hover={{ bg: "blue.700" }}
                onClick={() => handleScanDetailsClick(item._id)}
                mb={2}
              >
                Scan Details
              </Button>
            )}
            
            {branchName && (
              <Flex align="center">
                <Icon as={FaCodeBranch} color="gray.500" mr={1} />
                <Text fontSize="xs" color="gray.600">
                  {branchName}
                </Text>
              </Flex>
            )}
          </Flex>
        </HStack>
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex direction="column">
            <Flex align="center">
              <InfoIcon color="blue.500" mr={2} />
              <Text fontSize="lg" fontWeight="bold">
                Scans of {projectName}
              </Text>
              {repoUrl && (
                <Link href={repoUrl} isExternal ml={3}>
                  <Flex align="center" color="blue.500">
                    {getRepoIcon(repoUrl)}
                    <Text ml={1} fontSize="sm">
                      View
                    </Text>
                  </Flex>
                </Link>
              )}
            </Flex>
            <Text fontSize="sm" color="gray.500">
              Use the filters below to search scans
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction={["column", "row"]} spacing={2} mb={4} align="center">
            <Input
              placeholder="Search by SHA..."
              size="sm"
              value={shaSearch}
              onChange={(e) => setShaSearch(e.target.value)}
              width={["100%", "200px"]}
            />
            <Select
              size="sm"
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              width={["100%", "200px"]}
            >
              <option value="All">All Time</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="Last 1 Week">Last 1 Week</option>
              <option value="Last 3 Months">Last 3 Months</option>
              <option value="Last 6 Months">Last 6 Months</option>
              <option value="Last 1 Year">Last 1 Year</option>
            </Select>
            <Select
              size="sm"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              width={["100%", "200px"]}
            >
              <option value="All">All Statuses</option>
              <option value="COMPLETED">COMPLETED</option>
              <option value="FAILED">FAILED</option>
            </Select>
          </Stack>

          {loading ? (
            <VStack spacing={3} align="stretch">
              {Array.from({ length: 3 }).map((_, idx) => (
                <Box key={idx} p={3} borderWidth="1px" borderRadius="md">
                  <Skeleton height="20px" mb={2} />
                  <Skeleton height="20px" mb={2} />
                </Box>
              ))}
            </VStack>
          ) : error ? (
            <Box p={3} borderWidth="1px" borderRadius="md" bg="red.50">
              <Text color="red.600" fontWeight="bold">
                {error}
              </Text>
              <Text mt={2}>Something broken.</Text>
            </Box>
          ) : filteredData && filteredData.length > 0 ? (
            <VStack spacing={3} align="stretch">
              {filteredData.map((item) => (
                <ScanCard key={item._id} item={item} />
              ))}
            </VStack>
          ) : (
            <Text>No data available.</Text>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectDataModal;
