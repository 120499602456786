import React, { useState } from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  Text,
  Badge,
  Button,
  Link,
  Divider,
  Avatar,
  useColorModeValue,
  Spinner,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { Sparkles } from "lucide-react";
import { FiExternalLink, FiDownload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { generateExecutiveSummaryAsync } from "../../features/Scans/ScansAction";
import { getScanPdfReportAsync } from '../../features/Scans/ScansAction';
import AdditionalDetailsAccordion from "./AdditionalDetailsAccordion";

const ScanTopSection = ({
  scanDetails,
  borderColor,
  cardBg,
  textColor,
  subTextColor,
  criticalColor,
  allFindingsCount,
  handleDownloadSBOM,
  isSBOMLoading,
  handleDownloadCSV,
  isCSVLoading,
  onExecutiveSummaryRequest,
  orgId,
  scanId
}) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { loadingExecutiveSummary } = useSelector((state) => state.scans);
  const organization = useSelector((state) => state.user.selectedOrganization);
  const plan = organization?.plan || "Free";
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue("gray.50", "gray.900");

  const handleGenerateReport = async () => {
    setLoading(true);
    try {
      const resultAction = await dispatch(
        getScanPdfReportAsync({ orgId, scanId })
      );
      if (getScanPdfReportAsync.fulfilled.match(resultAction)) {
        const pdfUrl = resultAction.payload;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "scan-report.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Failed to generate report:", resultAction.payload);
      }
    } catch (error) {
      console.error("Error generating report:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateExecutiveSummary = () => {
    const orgId = scanDetails?.scan?.organization;
    const scanId = scanDetails?.scan?._id;

    if (!orgId || !scanId) {
      toast({
        description: "Organization ID or Scan ID is missing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // If the plan is Free, do nothing (button is disabled)
    if (plan === "Free") {
      return;
    }

    dispatch(generateExecutiveSummaryAsync({ orgId, scanId }));
    onExecutiveSummaryRequest();
  };

  return (
    <Box
      bg={cardBg}
      p={4}
      rounded="md"
      borderWidth="1px"
      borderColor={borderColor}
      mb={4}
    >
      <Flex justify="space-between" align="center" mb={2}>
        <VStack align="start" spacing={0}>
          {scanDetails?.scan?.git_uri ? (
            <Link
              href={scanDetails.scan.git_uri}
              isExternal
              _hover={{ textDecoration: "underline" }}
            >
              <HStack mb={1} spacing={1}>
                <Text fontSize="md" fontWeight="bold" color={textColor}>
                  {scanDetails.scan.git_uri.replace(/\/$/, "").split("/").pop()}
                </Text>
                <FiExternalLink size={14} />
              </HStack>
            </Link>
          ) : (
            <Text fontSize="md" fontWeight="bold" color={textColor}>
              Loading...
            </Text>
          )}
          <HStack spacing={2}>
            <Badge
              colorScheme={
                scanDetails?.scan?.status === "FAILED" ? "red" : "green"
              }
              fontSize="xs"
            >
              {scanDetails?.scan?.status}
            </Badge>
            {/* <Text color={subTextColor} fontSize="xs">
              #{scanDetails?.scan?.id?.slice(-5)}
            </Text>
            <Text color={subTextColor} fontSize="xs">
              {scanDetails?.scan?.created_at
                ? new Date(scanDetails.scan.created_at).toLocaleDateString()
                : ''}
            </Text> */}
          </HStack>
        </VStack>
        <HStack spacing={2}>
          {plan === "Free" ? (
            <Tooltip
              fontSize={"12px"}
              label="Unlock AI Insights: Upgrade to the Ultimate Plan for Intelligent, In-Depth Analysis of Your Security Posture!"
              placement="top"
              hasArrow
            >
              <span>
                <Button
                  color="white"
                  bg={useColorModeValue("#003460", "#0077b5")}
                  _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
                  leftIcon={<Sparkles size="15px" />}
                  size="sm"
                  onClick={handleGenerateExecutiveSummary}
                  isLoading={loadingExecutiveSummary}
                  loadingText="Generating"
                  disabled
                >
                  AI Executive Summary
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              color="white"
              bg={useColorModeValue("#003460", "#0077b5")}
              _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
              leftIcon={<Sparkles size="15px" />}
              size="sm"
              onClick={handleGenerateExecutiveSummary}
              isLoading={loadingExecutiveSummary}
              loadingText="Generating"
            >
              AI Executive Summary
            </Button>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={handleGenerateReport}
            isLoading={loading}
            leftIcon={<FiDownload size={16} />}
          >
            PDF
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleDownloadSBOM}
            isLoading={isSBOMLoading}
            loadingText="Downloading"
            leftIcon={<FiDownload size={16} />}
          >
            SBOM
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleDownloadCSV}
            isLoading={isCSVLoading}
            loadingText="Downloading"
            leftIcon={<FiDownload size={16} />}
          >
            CSV
          </Button>
        </HStack>
      </Flex>

      <Divider my={2} />

      <AdditionalDetailsAccordion
        scanDetails={scanDetails}
        borderColor={borderColor}
        bgColor={bgColor}
        textColor={textColor}
        subTextColor={subTextColor}
      />

      {/* <HStack spacing={4} justify="space-between">
        <Box
          textAlign="center"
          p={2}
          border="1px solid"
          borderColor={borderColor}
          rounded="md"
          width="150px"
        >
          <Text fontSize="md" color={criticalColor}>
            {allFindingsCount}
          </Text>
          <Text fontSize="xs" color={subTextColor}>
            Vulnerabilities Found
          </Text>
        </Box>
        <Box textAlign="center">
          <Text fontSize="md" fontWeight="bold" color={textColor}>
            {scanDetails?.scan && scanDetails.scan.results
              ? scanDetails.scan.results
                  .flatMap((r) => r.findings)
                  .filter((f) => f.severity?.toLowerCase() === 'high').length
              : 0}
          </Text>
          <Text fontSize="xs" color={subTextColor}>
            High Severity Vulnerabilities
          </Text>
        </Box>
        <Box textAlign="center">
          <Text fontSize="md" fontWeight="bold" color={textColor}>
            {scanDetails?.scan?.branch}
          </Text>
          <Text fontSize="xs" color={subTextColor}>
            Branch
          </Text>
        </Box>
        <HStack spacing={2}>
          <Text fontSize="xs" color={subTextColor}>
            Auditors
          </Text>
          <Avatar size="xs" name={scanDetails?.scan?.user?.email || "User"} />
          <Button size="xs" variant="outline">
            Details
          </Button>
        </HStack>
      </HStack> */}
    </Box>
  );
};

export default ScanTopSection;
