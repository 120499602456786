import React, { useState, useEffect, useCallback, useMemo, forwardRef } from "react";
import {
  Box,
  Text,
  Flex,
  VStack,
  Button,
  IconButton,
  Divider,
  Badge,
  Input,
  Grid,
  GridItem,
  HStack,
  Tag,
  useDisclosure,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Virtuoso } from "react-virtuoso";
import { FiSearch, FiMoreVertical } from "react-icons/fi";
import { ShieldCheck } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import RepositoryDrawer from "../components/VulnPage/Drawer";
import VulnerabilitiesFilterBar from "../components/VulnPage/VulnerabilitiesFilterBar";
import { getAllFindingsAsync, updateFindingStatusAsync } from "../features/Scans/ScansAction";

const VulnerabilityColumnSkeleton = () => {
  const bg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  return (
    <VStack
      bg={bg}
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      spacing={2}
      p={3}
      w="100%"
      align="stretch"
      boxShadow="sm"
    >
      <Skeleton height="20px" width="50%" />
      <Divider />
      {Array.from({ length: 5 }).map((_, idx) => (
        <Skeleton key={idx} height="80px" width="100%" borderRadius="md" />
      ))}
    </VStack>
  );
};

const COLUMN_HEIGHT = 600;

const VulnerabilitiesPage = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.user.selectedOrganization?._id);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);
  const findings = useSelector((state) => state.scans.allFindings);
  const loading = useSelector((state) => state.scans.loadingAllFindings);
  const error = useSelector((state) => state.scans.errorAllFindings);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState({
    unverified: [],
    "false-positive": [],
    "true-positive": [],
    solved: [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [selectedScanners, setSelectedScanners] = useState([]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchQuery]);
  useEffect(() => {
    if (orgId && selectedGroup?.id) {
      dispatch(getAllFindingsAsync({ orgId, groupId: selectedGroup.id }));
    }
  }, [dispatch, orgId, selectedGroup]);

  const uniqueScanners = useMemo(() => {
    if (!Array.isArray(findings)) return [];
    const scannersSet = new Set();
    findings.forEach((item) => {
      if (item.scanner) scannersSet.add(item.scanner);
    });
    return Array.from(scannersSet);
  }, [findings]);
  
  const uniqueSeverities = useMemo(() => {
    if (!Array.isArray(findings)) return [];
    const severitiesSet = new Set();
    findings.forEach((item) => {
      if (item.severity) severitiesSet.add(item.severity);
    });
    return Array.from(severitiesSet);
  }, [findings]);
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSeverityChange = (values) => {
    setSelectedSeverities(values);
  };

  const handleScannerChange = (values) => {
    setSelectedScanners(values);
  };

  const clearFilters = () => {
    setSearchQuery("");
    setSelectedSeverities([]);
    setSelectedScanners([]);
  };

  const filteredFindings = useMemo(() => {
    if (!Array.isArray(findings)) return [];
    return findings.filter((item) => {
      const queryMatch =
        !debouncedSearchQuery ||
        (item.vuln &&
          item.vuln.toLowerCase().includes(debouncedSearchQuery.toLowerCase()));
      const severityMatch =
        selectedSeverities.length === 0 ||
        selectedSeverities.includes(item.severity);
      const scannerMatch =
        selectedScanners.length === 0 ||
        selectedScanners.includes(item.scanner);
      return queryMatch && severityMatch && scannerMatch;
    });
  }, [findings, debouncedSearchQuery, selectedSeverities, selectedScanners]);

  const categorizedData = useMemo(() => {
    return {
      unverified: filteredFindings.filter((item) => item.status === "Unverified"),
      "false-positive": filteredFindings.filter((item) => item.status === "False Positive"),
      "true-positive": filteredFindings.filter((item) => item.status === "True Positive"),
      solved: filteredFindings.filter((item) => item.status === "Solved"),
    };
  }, [filteredFindings]);

  useEffect(() => {
    setData(categorizedData);
  }, [categorizedData]);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) return;
      if (source.droppableId === destination.droppableId) {
        const column = Array.from(data[source.droppableId]);
        const [movedItem] = column.splice(source.index, 1);
        const clonedItem = { ...movedItem };
        column.splice(destination.index, 0, clonedItem);
        setData((prev) => ({
          ...prev,
          [source.droppableId]: column,
        }));
      } else {
        const sourceColumn = Array.from(data[source.droppableId]);
        const [movedItem] = sourceColumn.splice(source.index, 1);
        const statusDisplayMap = {
          unverified: "Unverified",
          "false-positive": "False Positive",
          "true-positive": "True Positive",
          solved: "Solved",
        };
        const updatedItem = { ...movedItem, status: statusDisplayMap[destination.droppableId] };
        const destinationColumn = Array.from(data[destination.droppableId]);
        destinationColumn.splice(destination.index, 0, updatedItem);
        setData((prev) => ({
          ...prev,
          [source.droppableId]: sourceColumn,
          [destination.droppableId]: destinationColumn,
        }));
        dispatch(
          updateFindingStatusAsync({
            orgId,
            scanId: updatedItem.scan_id,
            findingId: updatedItem.id,
            statusData: {
              status: updatedItem.status,
              reason: updatedItem.reason || "",
              remediation: updatedItem.remediation || "",
            },
          })
        );
      }
    },
    [data, dispatch, orgId]
  );
  const toggleVerification = useCallback(
    (columnId, itemId) => {
      setData((prevData) => {
        const newData = { ...prevData };
        for (const col in newData) {
          const index = newData[col].findIndex((item) => item.id === itemId);
          if (index !== -1) {
            const item = newData[col][index];
            const newStatus = item.status === "Solved" ? "Unverified" : "Solved";
            const updatedItem = { ...item, status: newStatus };
            newData[col].splice(index, 1);
            const normalizedStatus = newStatus.toLowerCase().replace(" ", "-");
            newData[normalizedStatus] = newData[normalizedStatus] || [];
            newData[normalizedStatus].push(updatedItem);
            dispatch(
              updateFindingStatusAsync({
                orgId,
                scanId: updatedItem.scan_id,
                findingId: updatedItem.id,
                statusData: {
                  status: updatedItem.status,
                  reason: updatedItem.reason || "",
                  remediation: updatedItem.remediation || "",
                },
              })
            );
            break;
          }
        }
        return newData;
      });
    },
    [dispatch, orgId]
  );
  const handleCardClick = useCallback(
    (item) => {
      setSelectedItem(item);
      onOpen();
    },
    [onOpen]
  );
  const pageBg = useColorModeValue("gray.100", "gray.800");

  if (error) {
    return (
      <Box minH="100vh" p={6}>
        <Alert status="error">
          <AlertIcon />
          {error.message || "An error occurred while fetching vulnerabilities."}
        </Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box p={4}>
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Skeleton height="24px" width="200px" />
        </Flex>
        <VulnerabilitiesFilterBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          uniqueSeverities={uniqueSeverities}
          selectedSeverities={selectedSeverities}
          handleSeverityChange={handleSeverityChange}
          uniqueScanners={uniqueScanners}
          selectedScanners={selectedScanners}
          handleScannerChange={handleScannerChange}
          clearFilters={clearFilters}
        />
        <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={4} mt={4}>
          {["unverified", "false-positive", "true-positive", "solved"].map(
            (col) => (
              <GridItem key={col}>
                <VulnerabilityColumnSkeleton />
              </GridItem>
            )
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Text fontSize="2xl" fontWeight="bold">
          Vulnerabilities
        </Text>
      </Flex>
      
      <VulnerabilitiesFilterBar
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        uniqueSeverities={uniqueSeverities}
        selectedSeverities={selectedSeverities}
        handleSeverityChange={handleSeverityChange}
        uniqueScanners={uniqueScanners}
        selectedScanners={selectedScanners}
        handleScannerChange={handleScannerChange}
        clearFilters={clearFilters}
      />
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={4} mt={4}>
          {Object.entries(data).map(([columnId, items]) => (
            <GridItem key={columnId}>
              <VirtualizedVulnerabilityColumn
                columnId={columnId}
                items={items}
                toggleVerification={toggleVerification}
                onCardClick={handleCardClick}
              />
            </GridItem>
          ))}
        </Grid>
      </DragDropContext>
      <RepositoryDrawer isOpen={isOpen} onClose={onClose} selectedItem={selectedItem} />
    </Box>
  );
};

const VirtualizedVulnerabilityColumn = React.memo(
  ({ columnId, items, toggleVerification, onCardClick }) => {
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const headerTextColor = useColorModeValue("gray.800", "gray.100");
    const displayNameMap = {
      unverified: "Unverified",
      "false-positive": "False Positive",
      "true-positive": "True Positive",
      solved: "Solved",
    };
    return (
      <VStack
        bg={useColorModeValue("white", "gray.700")}
        border="1px solid"
        borderColor={borderColor}
        borderRadius="md"
        spacing={2}
        p={3}
        w="100%"
        align="stretch"
        boxShadow="sm"
        transition="box-shadow 0.2s"
        _hover={{ boxShadow: "md" }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="sm" fontWeight="bold" color={headerTextColor}>
            {displayNameMap[columnId]} ({items.length})
          </Text>
          <IconButton size="xs" variant="ghost" icon={<FiMoreVertical />} aria-label="Options" />
        </Flex>
        <Divider borderColor={borderColor} />
        <Droppable droppableId={columnId} mode="virtual" renderClone={(provided, snapshot, rubric) => (
          <DraggableCard
            item={items[rubric.source.index]}
            index={rubric.source.index}
            columnId={columnId}
            toggleVerification={toggleVerification}
            onCardClick={onCardClick}
            provided={provided}
          />
        )}>
          {(droppableProvided) =>
            items.length === 0 ? (
              <Box
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                style={{ height: COLUMN_HEIGHT }}
              >
                {droppableProvided.placeholder}
              </Box>
            ) : (
              <Virtuoso
                style={{ height: COLUMN_HEIGHT }}
                data={items}
                components={{
                  List: forwardRef((props, ref) => (
                    <VStack
                      ref={(instance) => {
                        droppableProvided.innerRef(instance);
                        if (ref) {
                          if (typeof ref === "function") ref(instance);
                          else ref.current = instance;
                        }
                      }}
                      {...props}
                      spacing={2}
                      align="stretch"
                    />
                  )),
                }}
                itemContent={(index, item) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DraggableCard
                          item={item}
                          index={index}
                          columnId={columnId}
                          toggleVerification={toggleVerification}
                          onCardClick={onCardClick}
                        />
                      </Box>
                    )}
                  </Draggable>
                )}
              />
            )
          }
        </Droppable>
      </VStack>
    );
  }
);

const DraggableCard = React.memo(
  ({ item, index, columnId, toggleVerification, onCardClick, provided }) => {
    const cardBg = useColorModeValue("gray.50", "gray.700");
    const cardBorderColor = useColorModeValue("gray.300", "gray.600");
    const hoverBg = useColorModeValue("gray.100", "gray.600");
    const titleColor = useColorModeValue("gray.800", "gray.100");
    const scoreColor = useColorModeValue("gray.600", "gray.300");
    const cweNumbers =
      Array.isArray(item.cwe) && item.cwe.length > 0
        ? item.cwe.map((cwe) => cwe.split(":")[0]).join(", ")
        : "N/A";
    const content = (
      <Box
        bg={cardBg}
        border="1px solid"
        borderColor={cardBorderColor}
        borderRadius="md"
        p={3}
        w="100%"
        _hover={{ bg: hoverBg, cursor: "pointer" }}
        boxShadow="sm"
        transition="background-color 0.2s, box-shadow 0.2s"
        onClick={() => onCardClick(item)}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="xs" fontWeight="bold" color={titleColor}>
            {item.vuln || "No Title Provided"}
          </Text>
          <Badge size="xs" variant="solid" colorScheme={getBadgeColor(item.severity)}>
            {item.severity}
          </Badge>
        </Flex>
        <HStack justifyContent="space-between" mt={2}>
          <Text fontSize="xs" color={scoreColor}>
            {item.confidence}
          </Text>
          <Tag size="sm">{cweNumbers}</Tag>
          <IconButton
            size="xs"
            variant={item.status === "Solved" ? "solid" : "outline"}
            colorScheme="blue"
            icon={<ShieldCheck size={16} />}
            aria-label={item.status === "Solved" ? "Mark as Unverified" : "Mark as Solved"}
            onClick={(e) => {
              e.stopPropagation();
              toggleVerification(columnId, item.id);
            }}
          />
        </HStack>
        <Divider mt={2} mb={1} />
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="xs" color={titleColor}>
            {item.path || "No path Provided"}
          </Text>
        </Flex>
      </Box>
    );
    if (provided) {
      return (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {content}
        </Box>
      );
    }
    return content;
  }
);

const getBadgeColor = (severity) => {
  switch (severity.toLowerCase()) {
    case "critical":
      return "red";
    case "high":
      return "orange";
    case "medium":
      return "yellow";
    case "low":
      return "blue";
    default:
      return "gray";
  }
};

export default VulnerabilitiesPage;
