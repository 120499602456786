import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Divider,
  Text,
  useToast,
  HStack,
  Button,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  Checkbox,
  Tooltip,
  Box,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { startNewScanAsync, getScansByGroupIdAsync } from "../../features/Scans/ScansAction";
import { parseRepoLink } from "../../utils/parseRepoLink";
import RepoInput from "./RepoInput";
import ActionButtons from "./ActionButtons";
import { getGitIntegrationsAsync } from "../../features/Analytics/AnalyticsAction";
import { resetGitIntegrationsState } from "../../features/Analytics/AnalyticsSlice";
import RepoAutocomplete from "./RepoAutoComplete";

const url = process.env.REACT_APP_API_URL;

const FileUpload = ({ selectedFile, setSelectedFile, loading }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
          setSelectedFile(acceptedFiles[0]);
        }
      },
      [setSelectedFile]
    ),
    accept: {
      "application/zip": [".zip"],
      "application/x-zip-compressed": [".zip"],
    },
    multiple: false,
    disabled: loading,
  });

  return (
    <Box
      {...getRootProps()}
      border="2px dashed"
      borderColor="gray.300"
      borderRadius="md"
      p={5}
      textAlign="center"
      cursor="pointer"
    >
      <input {...getInputProps()} />
      <Box mb={2}>
        <FiUpload size={28} color="#718096" />
      </Box>
      {selectedFile ? (
        <Text>Selected File: {selectedFile.name}</Text>
      ) : isDragActive ? (
        <Text>Drop the zip file here...</Text>
      ) : (
        <Text>Drag & drop a zip file here, or click to select one</Text>
      )}
    </Box>
  );
};

const ScanWizardModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const userData = useSelector((state) => state.user.userData);
  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const myProfile = orgDetails?.users?.find(
    (user) => user.email === userData?.email
  );
  const isViewer = myProfile?.role === "Viewer";

  const [repoLink, setRepoLink] = useState("");
  const [selectedScanners, setSelectedScanners] = useState(["PII", "SECRET"]);
  const [branch, setBranch] = useState("main");
  const [loading, setLoading] = useState(false);
  const [isValidRepo, setIsValidRepo] = useState(true);
  const [isOrgLevelScan, setIsOrgLevelScan] = useState(false);
  const [scanType, setScanType] = useState("repo");
  const [selectedFile, setSelectedFile] = useState(null);

  const orgId = orgDetails?._id;
  const plan = orgDetails?.plan;
  const groupId = JSON.parse(localStorage.getItem("selectedGroup"))?.id;
  const gitIntegrations = useSelector((state) => state.analytics.gitIntegrations);
  const groupDetails = useSelector((state) => state.groups.groupDetails);
  const groupRepos = groupDetails?.security_policy?.repos || [];

  const boxBgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const primaryColor = useColorModeValue("#003460", "#0076c2");
  const buttonHoverColor = useColorModeValue("#002240", "#005ea3");

  const allScanners = useMemo(
    () => [
      "SCA",
      "SAST",
      "SECRET",
      "PII",
      "COMPLIANCE",
      "IAC",
      "CONTAINER",
      "IMAGE",
      "MALWARE",
      "API",
    ],
    []
  );

  const allowedScanners = useMemo(() => {
    if (!plan) return [];
    switch (plan.toUpperCase()) {
      case "FREE":
        return ["COMPLIANCE", "PII", "SECRET"];
      case "PREMIUM":
      case "ULTIMATE":
        return allScanners;
      default:
        return [];
    }
  }, [plan, allScanners]);

  const isFileScanAllowed =
    plan && ["PREMIUM", "ULTIMATE"].includes(plan.toUpperCase());

  useEffect(() => {
    if (scanType === "file" && !isFileScanAllowed) {
      setScanType("repo");
    }
  }, [scanType, isFileScanAllowed]);

  useEffect(() => {
    setSelectedScanners((prev) =>
      prev.filter((scanner) => allowedScanners.includes(scanner))
    );
  }, [allowedScanners]);

  const availableRepos = useMemo(() => {
    if (!gitIntegrations) return [];
    const repos = [];
    gitIntegrations.forEach((integration) => {
      if (!integration) return;
      const { repo_type, repos: integrationRepos } = integration;
      if (!integrationRepos || !Array.isArray(integrationRepos)) return;
      integrationRepos.forEach((repo) => {
        repos.push({
          id: repo.id || repo.web_url || repo.name,
          name: repo.name,
          full_name: repo.full_name || repo.name,
          html_url: repo.web_url,
          repo_type,
          description: repo.description || "",
          default_branch: repo.default_branch || "main",
        });
      });
    });
    return repos;
  }, [gitIntegrations]);

  useEffect(() => {
    if (orgId) {
      dispatch(resetGitIntegrationsState());
      dispatch(getGitIntegrationsAsync({ orgId }));
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    if (!repoLink) {
      setIsValidRepo(true);
      setIsOrgLevelScan(false);
      return;
    }
    
    try {
      const parsed = parseRepoLink(repoLink);
      if (parsed) {
        if (parsed.owner && !parsed.repo) {
          setIsValidRepo(true);
          setIsOrgLevelScan(true);
        } else if (parsed.owner && parsed.repo) {
          setIsValidRepo(true);
          setIsOrgLevelScan(false);
        } else {
          setIsValidRepo(false);
          setIsOrgLevelScan(false);
        }
      } else {
        setIsValidRepo(false);
        setIsOrgLevelScan(false);
      }
    } catch (error) {
      if (repoLink.length > 10) {
        setIsValidRepo(false);
      }
      setIsOrgLevelScan(false);
    }
  }, [repoLink]);

  useEffect(() => {
    const matchingRepo = availableRepos.find(
      (repo) => repo.html_url === repoLink || repo.full_name === repoLink
    );
    if (matchingRepo) {
      setBranch(matchingRepo.default_branch);
    } else {
      setBranch("main");
    }
  }, [repoLink, availableRepos]);

  const handleStartScan = async () => {
    if (scanType === "repo") {
      const trimmedRepo = repoLink.trim();
      if (!trimmedRepo || !orgId || !groupId) {
        toast({
          title: "Error",
          description: "Please fill out the required fields.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      let finalRepoLink = trimmedRepo;
      if (
        trimmedRepo.includes("gitlab") &&
        !isOrgLevelScan &&
        !trimmedRepo.endsWith(".git")
      ) {
        finalRepoLink = trimmedRepo + ".git";
      }
      if (isViewer) {
        toast({
          title: "Permission Denied",
          description: "You do not have permission to start new scans.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const scanData = {
        git_uri: finalRepoLink,
        branch: isOrgLevelScan ? "main" : branch,
      };
      try {
        const response = await dispatch(
          startNewScanAsync({ orgId, groupId, scanData })
        ).unwrap();
        const scanUrl =
          isOrgLevelScan
            ? `/app/projects`
            : `/app/scan/${orgId}/${response.scan_id}/${groupId}`;
        toast({
          duration: 6000,
          isClosable: true,
          render: () => (
            <Box
              bg={primaryColor}
              color="white"
              p={4}
              borderRadius="md"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              boxShadow="xl"
            >
              <HStack spacing={4}>
                <Text fontWeight="bold">Scan Started Successfully!</Text>
                <Button as="a" href={scanUrl} size="sm" colorScheme="white" variant="outline">
                  {isOrgLevelScan ? "Go to Projects" : "Review Scan"}
                </Button>
              </HStack>
            </Box>
          ),
        });
        setRepoLink("");
        setSelectedScanners(["PII", "SECRET"]);
        setBranch("main");
        dispatch(getScansByGroupIdAsync({ orgId, groupId }));
        onClose();
      } catch (error) {
        toast({
          title: "Error Starting Scan",
          description: error.message || "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    } else if (scanType === "file") {
      if (!orgId || !groupId) {
        toast({
          title: "Error",
          description: "Organization or Group ID is missing.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (!selectedFile) {
        toast({
          title: "Error",
          description: "Please select a zip file to scan.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (isViewer) {
        toast({
          title: "Permission Denied",
          description: "You do not have permission to start new scans.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const allowedSelectedScanners = selectedScanners.filter((scanner) =>
        allowedScanners.includes(scanner)
      );
      formData.append(
        "scanners",
        JSON.stringify(
          allowedSelectedScanners.reduce((acc, scanner) => {
            acc[scanner.toLowerCase() + "_scanner"] = true;
            return acc;
          }, {})
        )
      );
      try {
        const response = await fetch(
          `${url}/v1/organization/${orgId}/group/${groupId}/file-scan`,
          {
            method: "POST",
            credentials: "include",
            body: formData,
          }
        );
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "File scan failed.");
        }
        const scanUrl = `/app/scan/${orgId}/${data.scan_id}/${groupId}`;
        toast({
          duration: 6000,
          isClosable: true,
          render: () => (
            <Box
              bg={primaryColor}
              color="white"
              p={4}
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              boxShadow="xl"
            >
              <HStack spacing={4}>
                <Text fontWeight="bold">File Scan Started Successfully!</Text>
                <Button as="a" href={scanUrl} size="sm" colorScheme="white" variant="outline">
                  Review Scan
                </Button>
              </HStack>
            </Box>
          ),
        });
        setSelectedFile(null);
        setSelectedScanners(["PII", "SECRET"]);
      } catch (error) {
        toast({
          title: "Error Starting File Scan",
          description: error.message || "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="95%" width="600px">
        <ModalHeader>Start a New Scan</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} overflowY="visible">
          <VStack spacing={6} align="stretch" width="100%">
            <FormControl>
              <FormLabel>Scan Type</FormLabel>
              <ChakraSelect
                value={scanType}
                onChange={(e) => {
                  setScanType(e.target.value);
                  setRepoLink("");
                  setSelectedFile(null);
                }}
                isDisabled={loading}
              >
                <option value="repo">Repository Scan</option>
                <Tooltip
                  label="Available in Premium & Ultimate plans only"
                  isDisabled={isFileScanAllowed}
                  placement="right"
                >
                  <option value="file" disabled={!isFileScanAllowed}>
                    File Scan
                  </option>
                </Tooltip>
              </ChakraSelect>
            </FormControl>

            {scanType === "repo" ? (
              <>
                <RepoAutocomplete
                  repoLink={repoLink}
                  setRepoLink={setRepoLink}
                  handleKeyPress={(e) => {
                    if (e.key === "Enter" && !loading) {
                      handleStartScan();
                    }
                  }}
                  isDisabled={loading}
                  isInvalid={!isValidRepo}
                  errorMessage="Invalid repository URL."
                  availableRepos={availableRepos}
                  groupRepos={groupRepos}
                />
                <FormControl>
                  <FormLabel>Branch</FormLabel>
                  <Input
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    isDisabled={loading}
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FileUpload
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  loading={loading}
                />
                <Box width="100%">
                  <Text fontWeight="medium" mb={2}>
                    Select Scanners for File Scan:
                  </Text>
                  <Wrap spacing={3}>
                    {allowedScanners.map((scanner) => (
                      <WrapItem key={scanner}>
                        <Checkbox
                          isChecked={selectedScanners.includes(scanner)}
                          onChange={() =>
                            setSelectedScanners((prev) =>
                              prev.includes(scanner)
                                ? prev.filter((s) => s !== scanner)
                                : [...prev, scanner]
                            )
                          }
                          size="sm"
                          colorScheme="blue"
                        >
                          {scanner}
                        </Checkbox>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </>
            )}

            <Divider borderColor={borderColor} />
            <ActionButtons
              handleStartScan={handleStartScan}
              isDisabled={loading || isViewer}
              viewerTooltip={isViewer ? "Viewers cannot start new scans" : undefined}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ScanWizardModal;