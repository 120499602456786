import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Select,
  useColorModeValue,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  inviteMemberAsync,
  removeMemberAsync,
  manageMembershipAsync,
  getOrganizationDetailsAsync,
} from "../../features/Orgs/OrgsAction";
import {
  resetInviteState,
  resetRemoveMemberState,
  resetManageMembershipState,
} from "../../features/Orgs/OrgsSlice";

const UsersPermissions = ({ orgId, organization, myProfile }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const userEmail = myProfile?.email;
  const userRole = myProfile?.role;
  const canManageMembers = ["Owner", "Security Manager"].includes(userRole);
  const borderCol = useColorModeValue("gray.300", "gray.600");

  const {
    inviteLoading,
    inviteSuccess,
    inviteError,
    removeMemberLoading,
    removeMemberSuccess,
    removeMemberError,
    manageMembershipLoading,
    manageMembershipSuccess,
    manageMembershipError,
  } = useSelector((state) => state.orgs);

  const orgid = organization?._id;

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("");

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);

  const [roleChanges, setRoleChanges] = useState({});

  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };
  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
    setInviteEmail("");
    setInviteRole("");
  };

  const openRemoveModal = (member) => {
    setMemberToRemove(member);
    setIsRemoveModalOpen(true);
  };
  const closeRemoveModal = () => {
    setIsRemoveModalOpen(false);
    setMemberToRemove(null);
  };

  const handleInviteSubmit = (e) => {
    e.preventDefault();
    if (inviteEmail && inviteRole) {
      dispatch(
        inviteMemberAsync({
          organizationId: orgId,
          invite_email: inviteEmail,
          invite_role: inviteRole,
        })
      );
    } else {
      toast({
        title: "Incomplete Fields",
        description: "Please fill all the required fields.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveMember = () => {
    if (memberToRemove && memberToRemove.email) {
      dispatch(
        removeMemberAsync({
          organizationId: orgId, 
          member_email: memberToRemove.email,
        })
      );
    }
  };

  const handleRoleChange = (email, newRole) => {
    setRoleChanges((prev) => ({
      ...prev,
      [email]: newRole,
    }));
  };

  const handleRoleUpdate = (email) => {
    const newRole = roleChanges[email];
    if (newRole) {
      dispatch(
        manageMembershipAsync({
          organizationId: orgId,
          action: "update_role",
          member_email: email,
          new_role: newRole,
        })
      );
    }
  };

  useEffect(() => {
    if (inviteSuccess) {
      toast({
        title: "Invitation Sent",
        description: "An invitation has been sent to the member.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetInviteState());
      closeInviteModal();
    }

    if (inviteError) {
      const errorMessage =
        inviteError.message || "There was an error sending the invitation.";
      toast({
        title: "Invitation Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetInviteState());
    }
  }, [inviteSuccess, inviteError, toast, dispatch]);

  useEffect(() => {
    if (removeMemberSuccess) {
      toast({
        title: "Member Removed",
        description: "The member has been successfully removed.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetRemoveMemberState());
      closeRemoveModal();
    }

    if (removeMemberError) {
      const errorMessage =
        removeMemberError.message || "There was an error removing the member.";
      toast({
        title: "Remove Member Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetRemoveMemberState());
    }
  }, [removeMemberSuccess, removeMemberError, toast, dispatch]);

  useEffect(() => {
    if (manageMembershipSuccess) {
      toast({
        title: "Role Updated",
        description: "The member's role has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetManageMembershipState());
      setRoleChanges((prev) => {
        const updated = { ...prev };
        const updatedEmail = Object.keys(updated)[0];
        if (updatedEmail) {
          delete updated[updatedEmail];
        }
        return updated;
      });
    }

    if (manageMembershipError) {
      const errorMessage =
        manageMembershipError.message ||
        "There was an error updating the member's role.";
      toast({
        title: "Update Role Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetManageMembershipState());
    }
  }, [manageMembershipSuccess, manageMembershipError, toast, dispatch]);

  const members = organization?.users || [];

  if (!organization) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner size="lg" />
      </Flex>
    );
  }

  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box
      border="1px solid"
      borderColor={borderCol}
      borderRadius="md"
      p={4}
      mb={4}
      bg={bgColor}
    >
      <Heading size="md" mb={2}>
        Users and Permissions
      </Heading>

      <Text fontSize="sm" color="gray.600" mb={4}>
        Invite, remove, and oversee individuals with permissions to access this organization.{" "}
        <Link
          color="blue.500"
          href="https://docs.aquilax.ai/user-manual/roles"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help
        </Link>
      </Text>

      {/* Users Table */}
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Invite Status</Th>
            <Th>Role</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.length > 0 ? (
            members.map((member) => {
              const isSelf = member.email === userEmail;
              const disableRoleUpdate =
                isSelf || (userRole === "Security Manager" && member.role === "Owner");

              return (
                <Tr key={member.email}>
                  <Td>{member.email}</Td>
                  <Td>{member.invite_status || "Completed"}</Td>

                  <Td width="180px">
                    {canManageMembers ? (
                      userRole === "Security Manager" && member.role === "Owner" ? (
                        <Text>Owner</Text>
                      ) : (
                        <>
                          <Select
                            size="sm"
                            defaultValue={member.role}
                            onChange={(e) =>
                              handleRoleChange(member.email, e.target.value)
                            }
                            isDisabled={disableRoleUpdate}
                          >
                            {userRole === "Security Manager" ? (
                              <>
                                <option value="Security Manager">
                                  Security Manager
                                </option>
                                <option value="Security Engineer">
                                  Security Engineer
                                </option>
                                <option value="Developer">Developer</option>
                                <option value="Viewer">Viewer</option>
                              </>
                            ) : (
                              <>
                                <option value="Owner">Owner</option>
                                <option value="Security Manager">
                                  Security Manager
                                </option>
                                <option value="Security Engineer">
                                  Security Engineer
                                </option>
                                <option value="Developer">Developer</option>
                                <option value="Viewer">Viewer</option>
                              </>
                            )}
                          </Select>
                          <Button
                            size="xs"
                            colorScheme="blue"
                            mt={2}
                            onClick={() => handleRoleUpdate(member.email)}
                            isLoading={manageMembershipLoading}
                            isDisabled={disableRoleUpdate}
                          >
                            Update Role
                          </Button>
                        </>
                      )
                    ) : (
                      <Text>{member.role}</Text>
                    )}
                  </Td>

                  <Td>
                    {canManageMembers &&
                      !isSelf &&
                      (member.role !== "Owner" || userRole === "Owner") && (
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => openRemoveModal(member)}
                          isLoading={removeMemberLoading}
                        >
                          Remove
                        </Button>
                      )}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan="4">No members found.</Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      <Flex justifyContent="space-between" alignItems="center" mt={4}>
        <Text fontSize="sm">
          {members.length > 0
            ? `1 to ${members.length} of ${members.length}`
            : "0 to 0 of 0"}{" "}
          —{" "}
          <Link color="blue.500" fontSize="sm">
            View all
          </Link>
        </Text>

        {canManageMembers && (
          <Button
            size="sm"
            color={"white"}
            bg={useColorModeValue("#003460", "#0077b5")}
            _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
            onClick={openInviteModal}
            leftIcon={<i className="fas fa-user-plus"></i>}
          >
            + Add a member
          </Button>
        )}
      </Flex>

      {/* Invite Member Modal */}
      <Modal isOpen={isInviteModalOpen} onClose={closeInviteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite Member</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleInviteSubmit}>
            <ModalBody>
              <VStack spacing={4}>
                <FormControl id="invite_email" isRequired>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    placeholder="member@example.com"
                  />
                </FormControl>

                <FormControl id="invite_role" isRequired>
                  <FormLabel>Role</FormLabel>
                  <Select
                    placeholder="Select role"
                    value={inviteRole}
                    onChange={(e) => setInviteRole(e.target.value)}
                  >
                    {userRole === "Security Manager" ? (
                      <>
                        <option value="Security Manager">
                          Security Manager
                        </option>
                        <option value="Security Engineer">
                          Security Engineer
                        </option>
                        <option value="Developer">Developer</option>
                        <option value="Viewer">Viewer</option>
                      </>
                    ) : (
                      <>
                        <option value="Owner">Owner</option>
                        <option value="Security Manager">
                          Security Manager
                        </option>
                        <option value="Security Engineer">
                          Security Engineer
                        </option>
                        <option value="Developer">Developer</option>
                        <option value="Viewer">Viewer</option>
                      </>
                    )}
                  </Select>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                onClick={closeInviteModal}
                isDisabled={inviteLoading}
              >
                Cancel
              </Button>
              <Button
                color={"white"}
                bg={useColorModeValue("#003460", "#0077b5")}
                _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
                type="submit"
                size={"sm"}
                isLoading={inviteLoading}
              >
                Send Invitation
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* Remove Member Confirmation Modal */}
      <Modal isOpen={isRemoveModalOpen} onClose={closeRemoveModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to remove{" "}
              {memberToRemove ? memberToRemove.email : "this member"} from the organization?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeRemoveModal}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleRemoveMember}
              isLoading={removeMemberLoading}
            >
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UsersPermissions;
