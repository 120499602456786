import React from "react";
import { Flex, Icon, Text, Box, useColorModeValue } from "@chakra-ui/react";

const ActivityLogItem = ({ icon, activity, description, time }) => {
  const bgHover = useColorModeValue("gray.100", "gray.600");
  const bgCard = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const timeColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Flex
      position="relative"
      p={3}
      bg={bgCard}
      borderRadius="md"
      border="1px solid"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      align="center"
      mb={3}
      _hover={{ bg: bgHover }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        bg={useColorModeValue("blue.100", "blue.700")}
        borderRadius="full"
        boxSize="40px"
        mr={3}
      >
        <Icon as={icon} color="blue.500" boxSize={5} />
      </Flex>
      <Box>
        <Text fontWeight="bold" fontSize="sm" color={textColor}>
          {activity}
        </Text>
        <Text fontSize="xs" color={textColor} mt={1}>
          {description}
        </Text>
        <Text fontSize="xs" color={timeColor} mt={1}>
          {time}
        </Text>
      </Box>
    </Flex>
  );
};

export default ActivityLogItem;
