import React from "react";
import {
  Box,
  Badge,
  Text,
  Link,
  VStack,
  HStack,
  Progress,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const RunningScanCard = ({ data }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.400", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.200");

  const progress = data.progress || 50;

  return (
    <Box
      borderRadius="md"
      borderColor={borderColor}
      bg={bgColor}
      p={2}
      fontSize="xs" 
      color={textColor}
      overflow="hidden"
    >
      <VStack align="start" spacing={1}>
        <HStack>
          <Text fontWeight="bold">Organization:</Text>
          <Text fontSize="xs">{data.org_name}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Scan ID:</Text>
          <Link
            href={`/scan/${data.scan_id}`}
            color="blue.300"
            isExternal
            fontSize="xs"
          >
            {data.scan_id} <ExternalLinkIcon mx="1px" />
          </Link>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Git URI:</Text>
          <Link href={data.scan_request.git_uri} color="blue.300" isExternal fontSize="xs">
            {data.scan_request.git_uri} <ExternalLinkIcon mx="1px" />
          </Link>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Frequency:</Text>
          <Text fontSize="xs">{data.scan_request.frequency}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Tags:</Text>
          {data.scan_request.tags.map((tag) => (
            <Badge key={tag} colorScheme="blue" fontSize="0.65rem">
              {tag}
            </Badge>
          ))}
        </HStack>
        <Box width="100%">
          <Text fontWeight="bold" mb={1} fontSize="xs">
            Scan Progress
          </Text>
          <Progress value={progress} colorScheme="green" size="sm" />
        </Box>
      </VStack>
    </Box>
  );
};

export default RunningScanCard;
