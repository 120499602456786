import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Button,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useColorModeValue,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrganizationAsync,
  manageMembershipAsync,
} from "../../features/Orgs/OrgsAction";
import {
  resetDeleteState,
  resetLeaveState,
} from "../../features/Orgs/OrgsSlice";
import { useNavigate } from "react-router-dom";
import { getProfileDataAsync, getUserDataAsync } from "../../features/Users/UsersAction";

const DangerZone = ({ orgId , myProfile}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const borderCol = useColorModeValue("gray.300", "gray.600");

  const {
    deleteLoading,
    deleteSuccess,
    deleteError,
    leaveLoading,
    leaveSuccess,
    leaveError,
  } = useSelector((state) => state.orgs);


  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteOrganization = () => {
    if (orgId) {
      dispatch(deleteOrganizationAsync({ organizationId: orgId }));
    } else {
      toast({
        title: "Organization ID not found.",
        description: "Unable to delete organization without an ID.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      closeDeleteModal();
    }
  };

  const openLeaveModal = () => {
    setIsLeaveModalOpen(true);
  };

  const closeLeaveModal = () => {
    setIsLeaveModalOpen(false);
  };

  const handleLeaveOrganization = () => {
    if (orgId) {
      dispatch(
        manageMembershipAsync({
          organizationId: orgId,
          action: "leave_org",
        })
      );
    } else {
      toast({
        title: "Organization ID not found.",
        description: "Unable to leave organization without an ID.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      closeLeaveModal();
    }
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast({
        title: "Organization Deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetDeleteState());
      dispatch(getProfileDataAsync());
      closeDeleteModal();
      navigate("/organization");
    }

    if (deleteError) {
      const errorMessage =
        typeof deleteError === "string"
          ? deleteError
          : deleteError?.message || "There was an error deleting the organization.";
      toast({
        title: "Delete Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetDeleteState());
      closeDeleteModal();
    }
  }, [deleteSuccess, deleteError, toast, dispatch, navigate]);

  useEffect(() => {
    if (leaveSuccess) {
      toast({
        title: "Left Organization",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetLeaveState());
      // dispatch(getUserDataAsync());
      dispatch(getProfileDataAsync());
      closeLeaveModal();
      navigate("/organization");
    }

    if (leaveError) {
      const errorMessage =
        typeof leaveError === "string"
          ? leaveError
          : leaveError?.message || "There was an error leaving the organization.";
      toast({
        title: "Leave Failed",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch(resetLeaveState());
      closeLeaveModal();
    }
  }, [leaveSuccess, leaveError, toast, dispatch, navigate]);

  const userRole = myProfile.role;

  return (
    <Box
      border="1px solid"
      borderColor={borderCol}
      borderRadius="md"
      p={4}
      mb={4}
      bg={useColorModeValue("gray.50", "gray.900")}
    >
      <Heading size="md" mb={4}>
        Danger Zone
      </Heading>

      <VStack align="stretch" spacing={6}>
        <Box>
          <Text fontSize="sm" mb={2}>
            Leaving the organization implies a loss of access, with all data
            retained, and re-entry can only occur if invited by other members.
          </Text>
          <Button
            size="xs"
            colorScheme="orange"
            onClick={openLeaveModal}
            isLoading={leaveLoading}
          >
            Leave Organization
          </Button>
        </Box>

        <Box>
          <Text fontSize="sm" mb={2}>
            Once you delete an organization, there is no going back. Please be
            certain.
          </Text>
          <Button
            size="xs"
            isDisabled={userRole !== "Owner"}
            colorScheme="red"
            onClick={openDeleteModal}
            isLoading={deleteLoading}
          >
            Delete Organization
          </Button>
        </Box>
      </VStack>

      {/* Leave Confirmation Modal */}
      <Modal isOpen={isLeaveModalOpen} onClose={closeLeaveModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to leave this organization? You will lose
              access to its resources.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeLeaveModal}>
              Cancel
            </Button>
            <Button
              colorScheme="orange"
              onClick={handleLeaveOrganization}
              isLoading={leaveLoading}
            >
              Leave
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this organization? This action is
              irreversible.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteOrganization}
              isLoading={deleteLoading}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DangerZone;
