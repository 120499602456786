import React, { useEffect, useState } from "react";
import {
  Box,
  Spinner,
  Center,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tag,
  Tooltip,
  ButtonGroup,
  Button,
  useColorModeValue,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getCommitScansAsync } from "../features/Projects/ProjectAction";
import { useParams, useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import { Shield } from "lucide-react";

const CommitScans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, groupId, projectId, commitSha } = useParams();

  const { commitScans, loading, error } = useSelector((state) => state.projects);

  const [scans, setScans] = useState([]);

  useEffect(() => {
    if (orgId && groupId && projectId && commitSha) {
      dispatch(getCommitScansAsync({ orgId, groupId, projectId, commitSha }));
    }
  }, [dispatch, orgId, groupId, projectId, commitSha]);

  useEffect(() => {
    const fetchedScans = commitScans[commitSha] || [];
    setScans(fetchedScans);
  }, [commitScans, commitSha]);

  const handleScanDetailsClick = (scanId) => {
    navigate(`/scan/${orgId}/${scanId}/${groupId}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const boxBg = useColorModeValue("gray.50", "gray.800");
  const tableHeaderBg = useColorModeValue("gray.200", "gray.700");
  const tableHoverBg = useColorModeValue("gray.100", "gray.600");
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");

  return (
    <Box p={4} bg={boxBg}>
      {/* Header Section */}
      <Flex justifyContent="space-between" alignItems="center" mb={4} flexWrap="wrap">
        <Box>
          <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" color={textColor}>
            Scans for commit: {commitSha}
          </Text>
        </Box>
        <Button size="sm" onClick={handleBack}>
          Back
        </Button>
      </Flex>

      {/* Loading State */}
      {loading && (
        <Center my={10}>
          <Spinner size="lg" />
        </Center>
      )}

      {/* Error State */}
      {error && (
        <Center my={10}>
          <Text color="red.500" fontSize="md">
            Error: {error}
          </Text>
        </Center>
      )}

      {/* Scans Table */}
      {!loading && !error && (
        <>
          <Box overflowX="auto">
            <Table
              variant="striped"
              size="sm"
              border="1px"
              borderColor={tableHeaderBg}
              minW="600px"
            >
              <Thead bg={tableHeaderBg}>
                <Tr>
                  <Th fontSize="xs">Scan ID</Th>
                  <Th fontSize="xs">User Email</Th>
                  <Th fontSize="xs">Created At</Th>
                  <Th fontSize="xs">Status</Th>
                  <Th fontSize="xs">Scanners</Th>
                  <Th fontSize="xs">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {scans.length > 0 ? (
                  scans.map((scan) => (
                    <Tr key={scan._id} _hover={{ bg: tableHoverBg }}>
                      {/* Scan ID */}
                      <Td fontSize="xs">
                        <Tooltip label="View Details">
                          <Text
                            as="button"
                            color="blue.500"
                            textDecoration="underline"
                            onClick={() => handleScanDetailsClick(scan._id)}
                            cursor="pointer"
                            fontSize="xs"
                          >
                            {scan._id}
                          </Text>
                        </Tooltip>
                      </Td>

                      {/* User Email */}
                      <Td fontSize="xs">{scan.user.email}</Td>

                      {/* Created At */}
                      <Td fontSize="xs">
                        {new Date(scan.created_at * 1000).toLocaleString()}
                      </Td>

                      {/* Status */}
                      <Td fontSize="xs">
                        <Tag
                          size="sm"
                          colorScheme={
                            scan.status === "COMPLETED"
                              ? "green"
                              : scan.status === "NEW"
                              ? "yellow"
                              : "red"
                          }
                        >
                          {scan.status}
                        </Tag>
                      </Td>

                      {/* Scanners */}
                      <Td fontSize="xs">
                        <HStack spacing={1}>
                          {Object.entries(scan.scanners)
                            .filter(([_, enabled]) => enabled)
                            .slice(0, 2)
                            .map(([scanner, _]) => (
                              <Tag key={scanner} size="sm" colorScheme="blue">
                                {scanner.replace("_", " ").toUpperCase()}
                              </Tag>
                            ))}
                          {Object.entries(scan.scanners).filter(([_, enabled]) => enabled).length > 2 && (
                            <Tag size="sm" colorScheme="gray">
                              +{Object.entries(scan.scanners).filter(([_, enabled]) => enabled).length - 2}
                            </Tag>
                          )}
                        </HStack>
                      </Td>

                      {/* Actions */}
                      <Td fontSize="xs">
                      <ButtonGroup size="xs" isAttached variant="outline">
                          <Button
                            onClick={() => handleScanDetailsClick(scan._id)}
                          >
                            View Details
                          </Button>
                          <IconButton
                            aria-label="View Details"
                            onClick={() => handleScanDetailsClick(scan._id)}
                            icon={<Shield size={"14px"} />}
                          />
                        </ButtonGroup>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={6}>
                      <Center>
                        <Text fontSize="xs">No scans found for this commit.</Text>
                      </Center>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CommitScans;
