import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { installAzureDevOpsAsync } from '../../../../features/Integrations/integrationsAction';

const AzureDevOpsWizard = ({ isOpen, onClose, orgId }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [accessToken, setAccessToken] = useState('');
  const [organization, setOrganization] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    if (!accessToken || !organization) {
      toast({
        title: 'Missing fields',
        description: 'Please provide both access token and organization name.',
        status: 'warning',
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    dispatch(
      installAzureDevOpsAsync({
        orgId,
        azureData: { access_token: accessToken, organization },
      })
    )
      .unwrap()
      .then(() => {
        toast({
          title: 'Azure DevOps Installed',
          description: 'Azure DevOps integration installed successfully.',
          status: 'success',
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: 'Installation Error',
          description: error.message || 'Failed to install Azure DevOps integration.',
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Install Azure DevOps Integration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Access Token</FormLabel>
            <Input
              placeholder="Enter your Azure DevOps access token"
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Organization</FormLabel>
            <Input
              placeholder="Enter your Azure DevOps organization name"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isSubmitting}>
            Install
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AzureDevOpsWizard;
