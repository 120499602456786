import React, { useState } from "react";
import {
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Progress,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  initiateBitbucketInstallAsync,
  handleBitbucketCallbackAsync,
  fetchBitbucketRepositoriesAsync,
} from "../../../../features/Integrations/integrationsAction";
import Lottie from "react-lottie";
import successAnimation from "../../../../assets/lottie/success.json";

const BitbucketWizard = ({ isOpen, onClose, orgId }) => {
  const dispatch = useDispatch();

  const {
    loading: bitbucketLoading,
    error: bitbucketError,
    authUrl,
    callbackData,
  } = useSelector((state) => state.integrations.bitbucket);

  const [wizardStep, setWizardStep] = useState(1);
  const [callbackCode, setCallbackCode] = useState("");
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  const handleNext = async () => {
    try {
      if (wizardStep === 1) {
        const resp = await dispatch(
          initiateBitbucketInstallAsync(orgId)
        ).unwrap();
        setWizardStep(2);
      } else if (wizardStep === 2) {
        await dispatch(
          handleBitbucketCallbackAsync({
            installationId: orgId,
            code: callbackCode,
          })
        ).unwrap();
        setWizardStep(3);
      } else if (wizardStep === 3) {
        await dispatch(
          fetchBitbucketRepositoriesAsync({
            installationId: orgId,
            workspaces: selectedWorkspaces,
          })
        ).unwrap();
        setWizardStep(4);
      } else if (wizardStep === 4) {
        onClose();
      }
    } catch (err) {
      console.error("Bitbucket wizard error:", err);
    }
  };

  const handleBack = () => {
    if (wizardStep > 1) {
      setWizardStep((prev) => prev - 1);
    } else {
      onClose();
    }
  };

  const renderContent = () => {
    if (bitbucketLoading) {
      return (
        <VStack spacing={4}>
          <Text>Processing...</Text>
          <Progress size="sm" isIndeterminate />
        </VStack>
      );
    }

    switch (wizardStep) {
      case 1:
        return (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Step 1: Initiate Bitbucket Install
            </Text>
            <Text>Click "Next" to generate the Bitbucket OAuth URL.</Text>
          </VStack>
        );
      case 2:
        return (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Step 2: Authorize
            </Text>
            {authUrl && (
              <Button
                color="white"
                bg="blue.600"
                _hover={{ bg: "blue.700" }}
                onClick={() => window.open(authUrl, "_blank")}
              >
                Open OAuth in New Tab
              </Button>
            )}
            {bitbucketError && (
              <Text color="red.500">
                {bitbucketError?.message || "Error in callback"}
              </Text>
            )}
          </VStack>
        );
      case 3:
        const workspaces = callbackData?.workspaces || [];
        return (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Step 3: Select Workspaces
            </Text>
            {workspaces.length === 0 ? (
              <Text>No workspaces found or user not in any workspace.</Text>
            ) : (
              <CheckboxGroup
                value={selectedWorkspaces}
                onChange={(vals) => setSelectedWorkspaces(vals)}
              >
                <VStack align="start">
                  {workspaces.map((w) => (
                    <Checkbox key={w.uuid} value={w.uuid}>
                      {w.name} ({w.slug})
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            )}
          </VStack>
        );
      case 4:
        return (
          <VStack spacing={6} align="center">
            <Lottie options={defaultOptions} height={200} width={200} />
            <Text fontSize="lg" fontWeight="bold">
              Bitbucket Integrated Successfully!
            </Text>
          </VStack>
        );
      default:
        return null;
    }
  };

  const renderFooter = () => {
    if (wizardStep === 4) return null; // done
    return (
      <>
        {wizardStep > 1 && wizardStep < 4 && (
          <Button variant="ghost" mr={3} onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
            color="white"
                    bg="blue.600"
                    _hover={{ bg: 'blue.700' }}
          onClick={handleNext}
          isDisabled={
            (wizardStep === 2 && !callbackCode) ||
            (wizardStep === 3 && selectedWorkspaces.length === 0)
          }
        >
          {wizardStep === 1
            ? "Next"
            : wizardStep === 2
            ? "Continue"
            : wizardStep === 3
            ? "Finish"
            : "Close"}
        </Button>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Install Bitbucket</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderContent()}</ModalBody>
        <ModalFooter>{renderFooter()}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BitbucketWizard;
