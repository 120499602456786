import React from "react";
import { Box, Heading, Text, Link, useColorModeValue } from "@chakra-ui/react";

const Header = () => {
  return (
    <Box maxW="600px">
      <Heading as="h2" size="md" mb={1} color={useColorModeValue("gray.800", "white")}>
        Billing & Subscription
      </Heading>
    </Box>
  );
};

export default Header;
