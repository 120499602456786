import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  SimpleGrid,
  Box,
  Text,
  Divider,
  Heading,
  HStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { deleteRepoInstallationAsync } from "../../../../features/Integrations/integrationsAction";
import { getGitIntegrationsAsync } from "../../../../features/Analytics/AnalyticsAction";
import RepositoryList from "../RepositoryList";

const GitHubDetailModal = ({
  isOpen,
  onClose,
  integration,
  repos,
  orgId,
  onUpdate,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    if (!orgId) {
      toast({
        title: "Missing orgId",
        description: "Cannot delete integration without orgId.",
        status: "error",
        isClosable: true,
      });
      return;
    }
    if (!integration?.installation_id) {
      toast({
        title: "Missing installation_id",
        description: "Cannot delete integration without installation_id.",
        status: "error",
        isClosable: true,
      });
      return;
    }

    setIsDeleting(true);
    dispatch(
      deleteRepoInstallationAsync({
        orgId,
        installationId: integration.installation_id,
      })
    )
      .unwrap()
      .then(() => {
        toast({
          title: "Integration deleted successfully.",
          status: "success",
          isClosable: true,
        });
        dispatch(getGitIntegrationsAsync({ orgId }));
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error deleting integration",
          description: err.message || "Please try again later.",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const totalRepos = repos?.length || 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">
          {integration?.repo_type} Integration Settings
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {integration ? (
            <VStack align="start" spacing={4}>
              <SimpleGrid columns={[1, 2]} spacing={4} width="100%">
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Connected Repositories:
                  </Text>
                  <Text>{totalRepos}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Integrated:
                  </Text>
                  <Text>{dayjs.unix(integration.created_at).fromNow()}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Actions:
                  </Text>
                  <HStack mt={1}>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      size="sm"
                      onClick={handleDelete}
                      isLoading={isDeleting}
                    >
                      Delete
                    </Button>
                    <Button
                      colorScheme="blue"
                      variant="solid"
                      size="sm"
                      onClick={onUpdate}
                    >
                      Update
                    </Button>
                  </HStack>
                </Box>
              </SimpleGrid>

              <Divider />

              <Box w="100%">
                <Heading size="sm" mb={2} color="gray.600">
                  Repositories
                </Heading>
                <RepositoryList repos={repos} repoType={integration.repo_type} />
              </Box>
            </VStack>
          ) : (
            <Text>No integration selected.</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GitHubDetailModal;
