import { createSlice } from "@reduxjs/toolkit";
import {
  getGroupsByOrgIdAsync,
  getGroupByIdAsync,
  updateGroupAsync,
  deleteGroupAsync,
  addGroupAsync,
} from "./GroupAction";

const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [], 
    groupDetails: null, 
    selectedGroup: null,
    loading: false, 
    error: null, 
  },
  reducers: {
    resetGroupState: (state) => {
      state.groups = [];
      state.groupDetails = null;
      state.selectedGroup = null;
      state.loading = false;
      state.error = null;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload; 
    },
  },
  extraReducers: (builder) => {

    // Fetch groups
    builder
      .addCase(getGroupsByOrgIdAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupsByOrgIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload; 
      })
      .addCase(getGroupsByOrgIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });

    // Fetch a group by ID
    builder
      .addCase(getGroupByIdAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groupDetails = action.payload; 
      })
      .addCase(getGroupByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });

    // Update group
    builder
      .addCase(updateGroupAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        const updatedGroupIndex = state.groups.findIndex(
          (group) => group._id === action.payload._id
        );
        if (updatedGroupIndex !== -1) {
          state.groups[updatedGroupIndex] = action.payload; 
        }
      })
      .addCase(updateGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });

    // Delete group
    builder
      .addCase(deleteGroupAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = state.groups.filter(
          (group) => group._id !== action.payload 
        );
      })
      .addCase(deleteGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });

    // Add group
    builder
      .addCase(addGroupAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups.push(action.payload);
      })
      .addCase(addGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetGroupState, setSelectedGroup } = groupsSlice.actions;
export default groupsSlice.reducer;
