// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const styles = {
  global: (props) => ({
    body: {
      bg: props.colorMode === "dark" ? "gray.800" : "gray.100",
      color: props.colorMode === "dark" ? "gray.100" : "gray.800",
    },
  }),
};

const fonts = {
  heading: "'Satoshi', sans-serif", 
  body: "'Satoshi', sans-serif",
};

const theme = extendTheme({ config, styles, fonts });

export default theme;
