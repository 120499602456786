import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Icon,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

const calculatePercentageChange = (current, previous) => {
  if (previous === 0) return current > 0 ? 100 : 0;
  const diff = current - previous;
  const percent = ((diff / previous) * 100).toFixed(1);
  return parseFloat(percent);
};

const StatsCards = ({ report, loading }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const cardBorder = useColorModeValue('gray.200', 'gray.700');

  const live = report?.live;
  const oneMonthAgo = report?.one_month_ago;

  const statsData = [
    {
      title: 'Projects',
      current: live?.projects || 0,
      previous: oneMonthAgo?.projects || 0,
    },
    {
      title: 'Scans',
      current: live?.scans?.count || 0,
      previous: oneMonthAgo?.scans?.count || 0,
    },
    {
      title: 'Files',
      current: live?.scans?.files || 0,
      previous: oneMonthAgo?.scans?.files || 0,
    },
    {
      title: 'Lines',
      current: live?.scans?.lines || 0,
      previous: oneMonthAgo?.scans?.lines || 0,
    },
  ];

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
      {statsData.map((stat, index) => {
        const percentage = calculatePercentageChange(stat.current, stat.previous);
        const isIncrease = percentage >= 0;

        return (
          <Box
            key={index}
            bg={cardBg}
            border="1px solid"
            borderColor={cardBorder}
            borderRadius="md"
            p={4}
          >
            <Skeleton isLoaded={!loading}>
              <Flex justify="space-between" align="start" mb={2}>
                <Text fontSize="sm" color="gray.500">
                  {stat.title}
                </Text>
              </Flex>

              <Text fontSize="2xl" fontWeight="semibold" mb={1}>
                {stat.current.toLocaleString()}
              </Text>

              <Text fontSize="sm" color="gray.400">
                vs last month
              </Text>

              <Flex align="center" mt={2}>
                <Icon
                  as={isIncrease ? AiOutlineArrowUp : AiOutlineArrowDown}
                  color={isIncrease ? 'green.500' : 'red.500'}
                  mr={1}
                />
                <Text
                  fontSize="sm"
                  color={isIncrease ? 'green.500' : 'red.500'}
                  fontWeight="medium"
                >
                  {percentage}%
                </Text>
              </Flex>
            </Skeleton>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default StatsCards;
