export function transformScansByStatus(scans) {
  const statusMap = {};
  scans.forEach((scan) => {
    const { status } = scan;
    statusMap[status] = (statusMap[status] || 0) + 1;
  });
  return Object.entries(statusMap).map(([statusName, count]) => ({
    name: statusName,
    value: count,
  }));
}
