import { createSlice } from '@reduxjs/toolkit';
import {
  postSecuritronAsync,
  getSecurityAssistantStreamAsync,
  getReviewFindingAsync,
} from './securitronAction';

const securitronSlice = createSlice({
  name: 'securitron',
  initialState: {
    response: null,
    loading: false,
    error: null,

    streamResponse: "",
    streamLoading: false,
    streamError: null,

    reviewResponse: null,
    reviewLoading: false,
    reviewError: null,
  },
  reducers: {
    resetSecuritronState: (state) => {
      state.response = null;
      state.loading = false;
      state.error = null;
      state.streamResponse = "";
      state.streamLoading = false;
      state.streamError = null;
      state.reviewResponse = null;
      state.reviewLoading = false;
      state.reviewError = null;
    },
    updateStreamResponse: (state, action) => {
      state.streamResponse = (state.streamResponse || "") + action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSecuritronAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
        state.streamResponse = "";
      })
      .addCase(postSecuritronAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.streaming) {
          state.response = action.payload;
        }
      })
      .addCase(postSecuritronAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getSecurityAssistantStreamAsync.pending, (state) => {
        state.streamLoading = true;
        state.streamError = null;
        state.streamResponse = "";
      })
      .addCase(getSecurityAssistantStreamAsync.fulfilled, (state, action) => {
        state.streamLoading = false;
        state.streamResponse = action.payload;
      })
      .addCase(getSecurityAssistantStreamAsync.rejected, (state, action) => {
        state.streamLoading = false;
        state.streamError = action.payload;
      });

    builder
      .addCase(getReviewFindingAsync.pending, (state) => {
        state.reviewLoading = true;
        state.reviewError = null;
        state.reviewResponse = null;
      })
      .addCase(getReviewFindingAsync.fulfilled, (state, action) => {
        state.reviewLoading = false;
        state.reviewResponse = action.payload;
      })
      .addCase(getReviewFindingAsync.rejected, (state, action) => {
        state.reviewLoading = false;
        state.reviewError = action.payload;
      });
  },
});

export const { resetSecuritronState, updateStreamResponse  } = securitronSlice.actions;
export default securitronSlice.reducer;
