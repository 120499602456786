import axios from 'axios';
import qs from 'qs';

const url = process.env.REACT_APP_API_URL;

const defaultConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const createOrganization = async (data, thunkAPI) => {
  try {
    const serializedData = qs.stringify(data);
    const config = {
      ...defaultConfig,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const response = await axios.post(`${url}/v1/organization`, serializedData, config);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const updateOrganization = async (organizationId, data, thunkAPI) => {
  try {
    const serializedData = qs.stringify(data);
    const config = {
      ...defaultConfig,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const response = await axios.patch(
      `${url}/v1/organization/${organizationId}`,
      serializedData,
      config
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const deleteOrganization = async (organizationId, thunkAPI) => {
  try {
    const response = await axios.delete(
      `${url}/v1/organization/${organizationId}`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const inviteMember = async (organizationId, invitationData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${organizationId}/invitation`,
      invitationData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const manageInvitation = async (organizationId, updateData, thunkAPI) => {
  try {
    const response = await axios.put(
      `${url}/v1/organization/${organizationId}/invitation`,
      updateData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const removeMember = async (organizationId, memberEmail, thunkAPI) => {
  try {
    const updateData = {
      action: 'remove_member',
      member_email: memberEmail,
    };
    const response = await axios.put(
      `${url}/v1/organization/${organizationId}/membership`,
      updateData,
      defaultConfig
    );
    return { member_email: memberEmail };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const manageMembership = async (organizationId, membershipData, thunkAPI) => {
  try {
    const response = await axios.put(
      `${url}/v1/organization/${organizationId}/membership`,
      membershipData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const leaveOrganization = async (organizationId, thunkAPI) => {
  try {
    const updateData = {
      action: 'leave_org',
    };
    const response = await axios.put(
      `${url}/v1/organization/${organizationId}/membership`,
      updateData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const applyLicense = async (orgId, licenseData, thunkAPI) => {
  try {
    const serializedData = qs.stringify(licenseData);
    const config = {
      ...defaultConfig,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/apply-license`,
      serializedData,
      config
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};
