import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  installGitLab,
  fetchGitLabRepositories,
  initiateBitbucketInstall,
  handleBitbucketCallback,
  fetchBitbucketRepositories,
  deleteBitbucketInstallation,
  installGitHub,
  installJira,
  uninstallJira,
  getTicketIntegration,
  installAzureDevOps,
  deleteAzureDevOpsInstallation,
  deleteRepoInstallation,
  updateGitLabInstallation,
} from "../../apis/integrations";

export const installGitLabAsync = createAsyncThunk(
  "integrations/installGitLab",
  async ({ installationId, data }, thunkAPI) => {
    // data = { git_name, git_instance, git_access_token }
    const response = await installGitLab(installationId, data, thunkAPI);
    return response;
  }
);

export const fetchGitLabRepositoriesAsync = createAsyncThunk(
  "integrations/fetchGitLabRepositories",
  async ({ installationId, data }, thunkAPI) => {
    const response = await fetchGitLabRepositories(installationId, data, thunkAPI);
    return response;
  }
);

export const initiateBitbucketInstallAsync = createAsyncThunk(
  "integrations/initiateBitbucketInstall",
  async (installationId, thunkAPI) => {
    const response = await initiateBitbucketInstall(installationId, thunkAPI);
    return response;
  }
);

export const handleBitbucketCallbackAsync = createAsyncThunk(
  "integrations/handleBitbucketCallback",
  async ({ installationId, code }, thunkAPI) => {
    const response = await handleBitbucketCallback(installationId, code, thunkAPI);
    return response;
  }
);

export const fetchBitbucketRepositoriesAsync = createAsyncThunk(
    'integrations/fetchBitbucketRepositories',
    async ({ orgId, data }, thunkAPI) => {
      const response = await fetchBitbucketRepositories(orgId, data, thunkAPI);
      return response;
    }
  );

export const deleteBitbucketInstallationAsync = createAsyncThunk(
    'integrations/deleteBitbucketInstallation',
    async ({ orgId, installationId }, thunkAPI) => {
      const response = await deleteBitbucketInstallation({ orgId, installationId }, thunkAPI);
      return response;
    }
  );

  export const installGitHubAsync = createAsyncThunk(
    "integrations/installGitHub",
    async ({ orgId, code, installation_id, setup_action }, thunkAPI) => {
      const response = await installGitHub(orgId, { code, installation_id, setup_action }, thunkAPI);
      return response;
    }
  );

  export const installJiraAsync = createAsyncThunk(
    "integrations/installJira",
    async ({ orgId, data }, thunkAPI) => {
      const response = await installJira(orgId, data, thunkAPI);
      return response;
    }
  );
  
  export const uninstallJiraAsync = createAsyncThunk(
    "integrations/uninstallJira",
    async ({ orgId }, thunkAPI) => {
      const response = await uninstallJira(orgId, thunkAPI);
      return response;
    }
  );

  export const getTicketIntegrationAsync = createAsyncThunk(
    "integrations/getTicketIntegration",
    async ({ orgId }, thunkAPI) => {
      const response = await getTicketIntegration(orgId, thunkAPI);
      return response;
    }
  );

  // -----------------------------------------------------------------------------
// AZURE DEVOPS
// -----------------------------------------------------------------------------

export const installAzureDevOpsAsync = createAsyncThunk(
  "integrations/installAzureDevOps",
  async ({ orgId, azureData }, thunkAPI) => {
    const response = await installAzureDevOps(orgId, azureData, thunkAPI);
    return response;
  }
);

export const deleteAzureDevOpsInstallationAsync = createAsyncThunk(
  "integrations/deleteAzureDevOpsInstallation",
  async ({ orgId, installationId }, thunkAPI) => {
    const response = await deleteAzureDevOpsInstallation({ orgId, installationId }, thunkAPI);
    return response;
  }
);

export const deleteRepoInstallationAsync = createAsyncThunk(
  "integrations/deleteRepoInstallation",
  async ({ orgId, installationId }, thunkAPI) => {
    const response = await deleteRepoInstallation({ orgId, installationId }, thunkAPI);
    return response;
  }
);

export const updateGitLabInstallationAsync = createAsyncThunk(
  "integrations/updateGitLabInstallation",
  async ({ orgId, installationId }, thunkAPI) => {
    const response = await updateGitLabInstallation(orgId, installationId, thunkAPI);
    return response;
  }
);