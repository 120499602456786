import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Grid,
  GridItem,
  Flex,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";

import successTickAnimation from "./../assets/lottie/successTick.json";
import confettiAnimation from "./../assets/lottie/confetti.json";

const ConfirmPage = () => {
  const [searchParams] = useSearchParams();
  const responseStatus = searchParams.get("response");
  const orgId = searchParams.get("org_id");
  const quantity = searchParams.get("quantity");
  const plan = searchParams.get("plan");
  const subId = searchParams.get("sub_id");
  const isSuccess = responseStatus === "success";

  const bgGradient = useColorModeValue(
    "linear(to-br, #f0f4ff, #ffffff)",
    "linear(to-br, #1a202c, #2d3748)"
  );
  const cardBg = useColorModeValue("white", "gray.800");
  const headingColor = isSuccess
    ? useColorModeValue("green.600", "green.300")
    : useColorModeValue("red.600", "red.300");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const detailLabelColor = useColorModeValue("gray.600", "gray.400");

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={-1}
        opacity={0.2}
      >
        <Lottie
          animationData={confettiAnimation}
          loop
          autoplay
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <Box
        w="90%"
        borderRadius="xl"
        border={'1px'}
        borderColor={'gray.300'}
        h={"550px"}
        p={8}
      >
        <VStack spacing={4} align="stretch">
          <Flex direction="column" align="center">
            <Heading size="lg" color={headingColor} textAlign="center">
              {isSuccess ? "Plan Upgrade Successful" : "Upgrade Failed"}
            </Heading>
            {isSuccess && (
              <Box
                mt={2}
                width={{ base: "80px", md: "100px" }}
                height={{ base: "80px", md: "100px" }}
              >
                <Lottie
                  animationData={confettiAnimation}
                  loop={false}
                  autoplay
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            )}
          </Flex>

          <Divider />

          <Text fontSize="md" color={textColor} textAlign="center">
            {isSuccess
              ? "Congratulations! Your plan has been upgraded successfully. Enjoy your new features and enhanced security."
              : "Unfortunately, something went wrong while upgrading your plan. Please try again or contact support."}
          </Text>

          <Box p={4} borderWidth="1px" borderRadius="md" borderColor="gray.300">
            <Heading
              as="h4"
              size="md"
              mb={3}
              textAlign="center"
              color={headingColor}
            >
              Upgrade Details
            </Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem>
                <Text fontWeight="bold" fontSize="sm" color={detailLabelColor}>
                  Org ID:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">{orgId || "N/A"}</Text>
              </GridItem>
              <GridItem>
                <Text fontWeight="bold" fontSize="sm" color={detailLabelColor}>
                  Plan:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">{plan || "N/A"}</Text>
              </GridItem>
              <GridItem>
                <Text fontWeight="bold" fontSize="sm" color={detailLabelColor}>
                  Quantity:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">{quantity || "N/A"}</Text>
              </GridItem>
              <GridItem>
                <Text fontWeight="bold" fontSize="sm" color={detailLabelColor}>
                  Subscription:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm">{subId || "N/A"}</Text>
              </GridItem>
            </Grid>
          </Box>

          <Button
            colorScheme="blue"
            size="md"
            onClick={() => navigate("/home")}
            alignSelf="center"
          >
            Go to Dashboard
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default ConfirmPage;
