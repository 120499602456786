import React, { useState } from "react";
import { Box, Flex, Text, IconButton } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";

const PaginatedCards = ({ items, renderItem, itemsPerPage = 1 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentItems = items.slice(startIdx, startIdx + itemsPerPage);

  return (
    <Box>
      {currentItems.map((item) => (
        <Box key={item.id || item.scan_id}>{renderItem(item)}</Box>
      ))}

      {totalPages > 1 && (
        <Flex mt={2} justify="center" align="center" gap={2}>
          <IconButton
            aria-label="Previous Page"
            icon={<ArrowLeftIcon />}
            onClick={handlePrevious}
            isDisabled={currentPage === 1}
            size="sm"
            variant="ghost"
          />
          <Text fontSize="xs">
            Page {currentPage} of {totalPages}
          </Text>
          <IconButton
            aria-label="Next Page"
            icon={<ArrowRightIcon />}
            onClick={handleNext}
            isDisabled={currentPage === totalPages}
            size="sm"
            variant="ghost"
          />
        </Flex>
      )}
    </Box>
  );
};

PaginatedCards.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number,
};

export default PaginatedCards;
