import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import ReactMarkdown from 'react-markdown';

import { getScanByIdAsync, updateFindingStatusAsync, generateExecutiveSummaryAsync } from '../features/Scans/ScansAction';
import { constructRepoURL } from '../utils/gitUtils';
import { downloadJSON, downloadCSV } from '../utils/downloadUtils';

import ScanHeader from '../components/ScanDetail/ScanHeader';
import ScanTopSection from '../components/ScanDetail/ScanTopSection';
import AdditionalDetailsAccordion from '../components/ScanDetail/AdditionalDetailsAccordion';
import VulnerabilitiesFilterBar from '../components/ScanDetail/VulnerabilitiesFilterBar';
import VulnerabilitiesList from '../components/ScanDetail/VulnerabilitiesList';
import TicketModal from '../components/ScanDetail/TicketModal';
import RepositoryDrawer from '../components/ScanDetail/RepositoryDrawer';
import AllCharts from '../components/ScanDetail/AllCharts';
import SecuritronDrawer from '../components/Others/SecuritronDrawer';

const ScanPage = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const subTextColor = useColorModeValue('gray.500', 'gray.400');
  const criticalColor = useColorModeValue('red.500', 'red.300');

  const { orgid: orgIdParam, scanid: scanIdParam, groupid: groupIdParam } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const {
    scanDetails,
    loading,
    error,
    executiveSummary,
    loadingExecutiveSummary,
    errorExecutiveSummary,
  } = useSelector((state) => state.scans);

  const [allFindings, setAllFindings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [selectedScanners, setSelectedScanners] = useState([]);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedFinding, setSelectedFinding] = useState(null);

  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

  const [isSBOMLoading, setIsSBOMLoading] = useState(false);
  const [isCSVLoading, setIsCSVLoading] = useState(false);

  const [isSecuritronDrawerOpen, setIsSecuritronDrawerOpen] = useState(false);
  const [selectedFindingForSecuritron, setSelectedFindingForSecuritron] = useState(null);

  const [isExecutiveSummaryRequested, setIsExecutiveSummaryRequested] = useState(false);




  useEffect(() => {
    if (orgIdParam && scanIdParam && groupIdParam) {
      dispatch(getScanByIdAsync({ orgId: orgIdParam, scanId: scanIdParam, groupId: groupIdParam }));
    }
  }, [dispatch, orgIdParam, scanIdParam, groupIdParam]);

  useEffect(() => {
    if (scanDetails?.scan?.results) {
      const findings = scanDetails.scan.results.flatMap((result) =>
        result.findings.map((finding) => ({
          id: finding.id,
          scanner: result.scanner,
          ...finding,
        }))
      );
      setAllFindings(findings);
    }
  }, [scanDetails]);


  const mapFindingToDrawer = (finding) => {
    return {
      ...finding,
      title: finding.vuln || "Vulnerability Details",
      message: finding.message || "No message provided.",
      tag: finding.cwe ? finding.cwe.join(", ") : "CWE-Unknown",
      codeSnippet: finding.code || "No code snippet available.",
      severity: finding.severity || "Unknown",
      scanners: finding.scanner || "Unknown",
      recommendation: finding.recommendation || "No recommendation provided.",
      properties: {
        Severity: finding.severity || "Unknown",
        Scanner: finding.scanner || "Unknown",
        Confidence: finding.confidence || "Unknown",
        Impact: finding.impact || "Unknown",
        Likelihood: finding.likelihood || "Unknown",
        CVES: finding.cves && finding.cves.length > 0 ? finding.cves.join(", ") : "None",
        CVSS_Score: finding.cvss_score || "N/A",
        CVSS_Vector: finding.cvss_vector || "N/A",
        Date: scanDetails?.scan?.created_at
          ? new Date(scanDetails.scan.created_at).toLocaleDateString()
          : "N/A",
      },
      status: finding.status || "Unverified",
    };
  };

  const filteredFindings = useMemo(() => {
    return allFindings.filter((finding) => {
      const matchesSearch = finding.vuln
        ?.toLowerCase()
        .includes(debouncedSearchQuery.toLowerCase());
      const matchesSeverity =
        selectedSeverities.length === 0 ||
        selectedSeverities.includes(finding.severity);
      const matchesScanner =
        selectedScanners.length === 0 ||
        selectedScanners.includes(finding.scanner);
      return matchesSearch && matchesSeverity && matchesScanner;
    });
  }, [allFindings, debouncedSearchQuery, selectedSeverities, selectedScanners]);

  const cweData = useMemo(() => {
    const cweCount = {};
    filteredFindings.forEach((finding) => {
      if (finding.cwe && finding.cwe.length > 0) {
        finding.cwe.forEach((cwe) => {
          cweCount[cwe] = (cweCount[cwe] || 0) + 1;
        });
      } else {
        cweCount['CWE-Unknown'] = (cweCount['CWE-Unknown'] || 0) + 1;
      }
    });
    return Object.entries(cweCount).map(([name, value]) => ({ name, value }));
  }, [filteredFindings]);

  const tfpData = useMemo(() => {
    const vulnCount = { 'True Positive': 0, 'False Positive': 0, 'Unverified': 0 };
    filteredFindings.forEach((finding) => {
      if (finding.status === 'True Positive') {
        vulnCount['True Positive'] += 1;
      } else if (finding.status === 'False Positive') {
        vulnCount['False Positive'] += 1;
      } else {
        vulnCount['Unverified'] += 1;
      }
    });
    return [
      { name: 'True Positive', value: vulnCount['True Positive'] },
      { name: 'False Positive', value: vulnCount['False Positive'] },
      { name: 'Unverified', value: vulnCount['Unverified'] },
    ];
  }, [filteredFindings]);

  const scannerData = useMemo(() => {
    const scannerCount = {};
    filteredFindings.forEach((finding) => {
      if (finding.scanner) {
        scannerCount[finding.scanner] = (scannerCount[finding.scanner] || 0) + 1;
      }
    });
    return Object.entries(scannerCount).map(([scanner, count]) => ({ scanner, count }));
  }, [filteredFindings]);

  const uniqueSeverities = useMemo(
    () => [...new Set(allFindings.map((f) => f.severity))].sort(),
    [allFindings]
  );
  const uniqueScanners = useMemo(
    () => [...new Set(allFindings.map((f) => f.scanner))].sort(),
    [allFindings]
  );

  const handleRaiseTicket = (e, finding) => {
    e.stopPropagation();
    setSelectedFinding(mapFindingToDrawer(finding));
    setIsTicketModalOpen(true);
  };

  const handleAskSecuritron = (e, finding) => {
    e.stopPropagation();
    openSecuritronDrawer(finding);
  };

  const openSecuritronDrawer = (finding) => {
    setSelectedFindingForSecuritron(mapFindingToDrawer(finding));
    setIsSecuritronDrawerOpen(true);
  };

  const closeSecuritronDrawer = () => {
    setIsSecuritronDrawerOpen(false);
    setSelectedFindingForSecuritron(null);
  };

  const getSeverityColor = (severity) => {
    switch (severity?.toLowerCase()) {
      case 'high':
        return 'red';
      case 'critical':
        return 'purple';
      case 'medium':
        return 'orange';
      case 'low':
        return 'yellow';
      default:
        return 'gray';
    }
  };

  const handleExecutiveSummaryRequest = () => {
    setIsExecutiveSummaryRequested(true);
  };

  const openDrawer = (finding) => {
    setSelectedFinding(mapFindingToDrawer(finding));
    setIsDrawerOpen(true);
    navigate(`#${finding.id}`);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedFinding(null);
    window.history.replaceState(null, '', window.location.pathname + window.location.search);
  };

  const handleSaveStatus = async (updatedFinding) => {
    const orgId = scanDetails?.scan?.organization;
    const scanId = scanDetails?.scan?._id;
    if (!orgId || !scanId) {
      toast({
        description: "Cannot update status without organization and scan IDs.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await dispatch(
        updateFindingStatusAsync({
          orgId,
          scanId,
          findingId: updatedFinding.id,
          statusData: {
            status: updatedFinding.status,
            reason: updatedFinding.reason || "",
            remediation: updatedFinding.remediation || "",
          },
        })
      ).unwrap();

      toast({
        title: "Status Updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setAllFindings((prevFindings) =>
        prevFindings.map((f) =>
          f.id === updatedFinding.id ? updatedFinding : f
        )
      );
    } catch (err) {
      console.error("Error updating status:", err);
      toast({
        title: "Failed to Update Status",
        description:
          typeof err === "string"
            ? err
            : "An error occurred while updating the finding status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDownloadSBOM = async () => {
    setIsSBOMLoading(true);
    try {
      const sbomData = scanDetails?.scan?.metadata?.sbom;
      if (!sbomData) {
        throw new Error('SBOM data not available.');
      }
      downloadJSON(sbomData, 'SBOM.json');
      toast({
        description: 'SBOM has been downloaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error downloading SBOM:', error);
      toast({
        title: 'Download Failed',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSBOMLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    setIsCSVLoading(true);
    try {
      const findingsData = allFindings;
      if (!findingsData || !findingsData.length) {
        throw new Error('No findings available for CSV download.');
      }
      downloadCSV(findingsData, 'Findings.csv');
      toast({
        description: 'Findings CSV has been downloaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error downloading CSV:', error);
      toast({
        title: 'Download Failed',
        description: 'Unable to download Findings CSV.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsCSVLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSeverityChange = (values) => {
    setSelectedSeverities(values);
  };

  const handleScannerChange = (values) => {
    setSelectedScanners(values);
  };

  const clearFilters = () => {
    setSearchQuery('');
    setSelectedSeverities([]);
    setSelectedScanners([]);
  };

  useEffect(() => {
    if (allFindings.length > 0) {
      const hash = window.location.hash;
      if (hash) {
        const findingId = hash.slice(1);
        const finding = allFindings.find((f) => f.id.toString() === findingId);
        if (finding) {
          openDrawer(finding);
        }
      }
    }
  }, [allFindings]);

  return (
    <Box bg={bgColor} minH="100vh" p={4}>
      <ScanTopSection
        scanDetails={scanDetails}
        borderColor={borderColor}
        cardBg={cardBg}
        textColor={textColor}
        subTextColor={subTextColor}
        criticalColor={criticalColor}
        allFindingsCount={allFindings.length}
        handleDownloadSBOM={handleDownloadSBOM}
        isSBOMLoading={isSBOMLoading}
        handleDownloadCSV={handleDownloadCSV}
        isCSVLoading={isCSVLoading}
        onExecutiveSummaryRequest={handleExecutiveSummaryRequest}
        orgId={scanDetails?.scan?.organization}
        scanId={scanDetails?.scan?._id} 
      />

      {isExecutiveSummaryRequested && (
        <Accordion allowToggle mb={4} defaultIndex={[0]}>
          <AccordionItem
            border="1px solid"
            borderColor={borderColor}
            borderRadius="md"
          >
            <h2>
              <AccordionButton>
                <Box
                  flex="1"
                  textAlign="left"
                  fontSize="sm"
                  fontWeight="bold"
                  color={textColor}
                >
                  AI Executive Summary
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} bg={bgColor}>
              {loadingExecutiveSummary ? (
                <Flex justify="center" align="center" py={4}>
                  <Spinner />
                </Flex>
              ) : errorExecutiveSummary ? (
                <Text color="red.500">
                  {errorExecutiveSummary.message ||
                    "Failed to load executive summary."}
                </Text>
              ) : executiveSummary ? (
                <Box p={4} mx="auto" fontSize={'14px'}>
                  <ReactMarkdown>{executiveSummary}</ReactMarkdown>
                </Box>
              ) : (
                <Text>No executive summary available.</Text>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      <AllCharts
        cweData={cweData}
        tfpData={tfpData}
        scannerData={scannerData}
      />

      <VulnerabilitiesFilterBar
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        uniqueSeverities={uniqueSeverities}
        selectedSeverities={selectedSeverities}
        handleSeverityChange={handleSeverityChange}
        uniqueScanners={uniqueScanners}
        selectedScanners={selectedScanners}
        handleScannerChange={handleScannerChange}
        clearFilters={clearFilters}
      />

      <Flex mb={2}>
        <Text fontSize="xs" color={subTextColor}>
          {loading ? "Loading scan details..." : ""}
          {error ? `Error: ${error.detail || error.message || "An error occurred."}` : ""}
        </Text>
      </Flex>

      <VulnerabilitiesList
        filteredFindings={filteredFindings}
        subTextColor={subTextColor}
        textColor={textColor}
        borderColor={borderColor}
        cardBg={cardBg}
        getSeverityColor={getSeverityColor}
        openDrawer={openDrawer}
        handleRaiseTicket={handleRaiseTicket}
        handleAskSecuritron={handleAskSecuritron}
        scanDetails={scanDetails}
        constructRepoURL={constructRepoURL}
      />

      <RepositoryDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        selectedItem={selectedFinding}
        onSave={handleSaveStatus}
        constructRepoURL={constructRepoURL}
      />

      <TicketModal
        isOpen={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
      />

      <SecuritronDrawer
        isOpen={isSecuritronDrawerOpen}
        onClose={closeSecuritronDrawer}
        selectedFinding={selectedFindingForSecuritron}
        onSave={handleSaveStatus}
      />


      {error && (
        <Flex justify="center" align="center" mt={4}>
          <Text color="red.500">
            {error.detail || (error.message ? error.message : "An error occurred.")}
          </Text>
        </Flex>
      )}

      {loading && (
        <Flex justify="center" align="center" mt={4}>
          <Text>Loading scan details...</Text>
        </Flex>
      )}
    </Box>
  );
};

export default ScanPage;
