import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import LanguageUsageChart from "./LanguageUsageChart";

const AdditionalDetailsAccordion = ({
  scanDetails,
  borderColor,
  bgColor,
  textColor,
  subTextColor,
}) => {
  if (!scanDetails) {
    return null;
  }

  const { scan_metrics } = scanDetails;

  const formatCreatedAt = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  return (
    <Accordion allowToggle mb={0}>
      <AccordionItem
        border="1px solid"
        borderColor={borderColor}
        borderRadius="md"
      >
        <AccordionButton px={4} py={2}>
          <Box
            flex="1"
            textAlign="left"
            fontSize="sm"
            fontWeight="bold"
            color={textColor}
          >
            Additional Scan Details
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={4} py={2} bg={bgColor}>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Branch:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.branch || "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Commit:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.git?.sha || "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Total Execution Time:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.header?.total_exec_time || "N/A"}{" "}
                seconds
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Number of Files:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.header?.n_files || "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Number of Lines:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.header?.n_lines || "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Last Commit Author:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.git?.last_commit_author || "N/A"}{" "}
                on{" "}
                {scanDetails?.scan?.metadata?.git?.last_commit_date
                  ? new Date(
                      scanDetails.scan.metadata.git.last_commit_date
                    ).toLocaleDateString()
                  : "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Commit Message:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {scanDetails?.scan?.metadata?.git?.last_commit_message || "N/A"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" color={subTextColor}>
                Scanned On:
              </Text>
              <Text fontSize="xs" fontWeight="bold" color={textColor}>
                {formatCreatedAt(scanDetails?.scan?.created_at)}
              </Text>
            </Box>
          </SimpleGrid>
          <VStack align="start" spacing={4}>
          <LanguageUsageChart metadata={scanDetails?.scan?.metadata} />
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AdditionalDetailsAccordion;
