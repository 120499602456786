import React, { useEffect, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  FormErrorMessage,
  Heading,
  useToast,
  Center,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import successAnimation from "../assets/lottie/success.json";
import { resetCreateState } from "../features/Orgs/OrgsSlice";
import { createOrganizationAsync } from "../features/Orgs/OrgsAction";
import { getUserDataAsync, getProfileDataAsync } from "../features/Users/UsersAction";

const CreateOrganizationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    createLoading,
    createError,
    createSuccess,
  } = useSelector((state) => state.orgs);

  const primaryColor = "#005387"; 
  const hoverBgColor = useColorModeValue("#0077b5", "#0077b5");
  const containerBg = useColorModeValue("gray.50", "gray.800");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization name is required"),
    usage: Yup.string().required("Usage is required"),
    description: Yup.string(),
    business: Yup.string(),
    website: Yup.string().url("Must be a valid URL"),
    logo: Yup.string().url("Must be a valid URL"),
    licenseKey: Yup.string(),
  });

  const initialValues = {
    organizationName: "",
    description: "",
    business: "",
    website: "",
    logo: "",
    usage: "",
    licenseKey: "",
  };

  const handleCloseModal = useCallback(() => {
    onClose();
    // dispatch(getUserDataAsync());
    dispatch(getProfileDataAsync());
    navigate("/organization");
  }, [onClose, dispatch, navigate]);


  useEffect(() => {
    if (createSuccess) {
      onOpen();
      dispatch(resetCreateState());
    }

    if (createError) {
      toast({
        title: "Failed to create organization.",
        description:
          createError.data?.message || createError.message || "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      dispatch(resetCreateState());
    }
  }, [createSuccess, createError, onOpen, dispatch, toast]);

  // Submit form
  const onSubmit = (values, actions) => {
    const payload = {
      name: values.organizationName,
      description: values.description,
      business: values.business,
      website: values.website,
      logo: values.logo,
      usage: values.usage,
      licenseKey: values.licenseKey,
    };

    dispatch(createOrganizationAsync(payload));
    actions.setSubmitting(false);
  };

  return (
    <Box
      w="full"
      minH="100vh"
      bg={containerBg}
      display="flex"
      flexDirection="column"
    >
      {/* FORM HEADER */}
      <Center>
        <Heading mt={6} size="lg">
          Create a New Organization
        </Heading>
      </Center>

      {/* Modal for success */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Organization Created</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" alignItems="center">
            <Box w="200px" h="200px">
              <Lottie options={defaultOptions} height={200} width={200} />
            </Box>
            <Heading size="sm" mt={4}>
              Your organization was created successfully!
            </Heading>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal} bg={primaryColor} color="white">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* FORM */}
      <Box
        as={Formik}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={8} 
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="organizationName">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.organizationName && form.touched.organizationName
                  }
                  mb={4}
                >
                  <FormLabel>Organization Name*</FormLabel>
                  <Input
                    {...field}
                    id="organizationName"
                    placeholder="Your new unique organization name"
                  />
                  <FormErrorMessage>
                    {form.errors.organizationName}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.description && form.touched.description}
                  mb={4}
                >
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    {...field}
                    id="description"
                    placeholder="Short description for what this organization is created for"
                  />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="business">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.business && form.touched.business}
                  mb={4}
                >
                  <FormLabel>Business</FormLabel>
                  <Input
                    {...field}
                    id="business"
                    placeholder="e.g. GitHub, Inc., Example Institute, etc."
                  />
                  <FormErrorMessage>{form.errors.business}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="website">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.website && form.touched.website}
                  mb={4}
                >
                  <FormLabel>Web Site</FormLabel>
                  <Input
                    {...field}
                    id="website"
                    placeholder="e.g. https://example.com"
                  />
                  <FormErrorMessage>{form.errors.website}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="logo">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.logo && form.touched.logo}
                  mb={4}
                >
                  <FormLabel>Logo</FormLabel>
                  <Input
                    {...field}
                    id="logo"
                    placeholder="Public logo URL for the new organization"
                  />
                  <FormErrorMessage>{form.errors.logo}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="usage">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.usage && form.touched.usage}
                  mb={4}
                >
                  <FormLabel>Usage*</FormLabel>
                  <Select {...field} id="usage" placeholder="Select...">
                    <option value="Personal">Personal</option>
                    <option value="Business">Business</option>
                    <option value="Enterprise">Enterprise</option>
                  </Select>
                  <FormErrorMessage>{form.errors.usage}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="licenseKey">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.licenseKey && form.touched.licenseKey}
                  mb={4}
                >
                  <FormLabel>License Key (Optional)</FormLabel>
                  <Input
                    {...field}
                    id="licenseKey"
                    placeholder="Enter your license key if you have one"
                  />
                  <FormErrorMessage>{form.errors.licenseKey}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              type="submit"
              bg={primaryColor}
              color="white"
              _hover={{ bg: hoverBgColor }}
              isLoading={isSubmitting || createLoading}
              mt={4}
              w="full"
            >
              Create
            </Button>
          </Form>
        )}
      </Box>
    </Box>
  );
};

export default CreateOrganizationPage;
