import React, { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { installGitHubAsync } from '../features/Integrations/integrationsAction';
import { Spinner, Flex, Text, Box } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

const GitHubWebhook = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const orgDetails = useSelector((state) => state.orgs.organization);
  const orgIdFromStore = orgDetails?._id;

  const { github } = useSelector((state) => state.integrations);

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const code = searchParams.get('code');
    const installation_id = searchParams.get('installation_id');
    const setup_action = searchParams.get('setup_action');
    const stateParam = searchParams.get('state');

    let decodedOrgId = orgIdFromStore;
    if (stateParam) {
      try {
        const decodedState = JSON.parse(atob(stateParam));
        decodedOrgId = decodedState.orgId || orgIdFromStore;
      } catch (error) {
        console.error('Failed to decode state parameter:', error);
      }
    }

    if (code && installation_id && setup_action && decodedOrgId) {
      dispatch(
        installGitHubAsync({
          orgId: decodedOrgId,
          code,
          installation_id: parseInt(installation_id, 10),
          setup_action,
        })
      )
        .unwrap()
        .then(() => {
          toast({
            title: 'GitHub Integration Successful',
            description: 'GitHub has been integrated successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          navigate(`/organization/${decodedOrgId}`);
        })
        .catch((error) => {
          console.error('GitHub installation failed:', error);
          toast({
            title: 'GitHub Integration Failed',
            description: error.message || 'An error occurred during GitHub integration.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          navigate('/error', { state: { message: 'GitHub integration failed.' } });
        });
    } else {
      console.error('Missing parameters in GitHub callback.');
      toast({
        title: 'Invalid Callback',
        description: 'Missing or invalid parameters in GitHub callback.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      navigate('/error', { state: { message: 'Invalid GitHub callback parameters.' } });
    }
  }, [dispatch, searchParams, navigate, toast, orgIdFromStore]);

  return (
    <Flex justifyContent="center" alignItems="center" height="80vh">
      {github.loading ? (
        <>
          <Spinner size="lg" />
          <Text ml={4}>Completing GitHub installation...</Text>
        </>
      ) : github.error ? (
        <Box>
          <Text color="red.500" fontSize="lg">
            Failed to install GitHub integration.
          </Text>
          <Text color="red.500">{github.error.message}</Text>
        </Box>
      ) : (
        <Text>Installation completed. Redirecting...</Text>
      )}
    </Flex>
  );
};

export default GitHubWebhook;
