import React, { useEffect, useState } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Button,
  MenuDivider,
  Box,
  Image,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  IconButton,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useBreakpointValue,
} from "@chakra-ui/react";
import { UserCog, LogOut, SunDim, Moon, Building2, Bell, Layers } from "lucide-react";
import { FiMenu } from "react-icons/fi";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDataAsync } from "../features/Users/UsersAction";
import { resetUserState } from "../features/Users/UsersSlice";
import { resetGitIntegrationsState } from "../features/Analytics/AnalyticsSlice";
import OrganizationsDropdown from "./Others/OrganizationsDropdown";
import GroupSelector from "./GroupSelector";
import LogoLight from "../assets/images/lightlogo.png";
import LogoDark from "../assets/images/darklogo.png";
import SelectOrgModal from "./Others/SelectOrgModal";
import NotificationModal from "./Others/NotificationModal";
import { useAuth } from "../context/AuthContext";
import { setSelectedOrganization } from "../features/Users/UsersSlice";
import MobileSidebar from "./MobileSidebar";

const Topbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const location = useLocation();
  const { logout } = useAuth();

  const { userData, organizations, notifications, loading } = useSelector(
    (state) => state.user
  );
  const notificationsCount = notifications ? notifications.length : 0;
  
  const groups = useSelector((state) => state.groups.groups);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);

  const primaryColor = useColorModeValue("blue.700", "blue.500");
  const menuButtonHoverBg = useColorModeValue("gray.200", "gray.600");
  const menuListBg = useColorModeValue("white", "gray.700");
  const menuItemHoverBg = useColorModeValue("gray.200", "gray.600");

  const [currentOrganization, setCurrentOrganization] = useState(() => {
    const saved = localStorage.getItem("selectedOrganization");
    return saved ? JSON.parse(saved) : null;
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isMobileSidebarOpen,
    onOpen: onMobileSidebarOpen,
    onClose: onMobileSidebarClose,
  } = useDisclosure();

  const toggleMobileSidebar = () => {
    if (isMobileSidebarOpen) {
      onMobileSidebarClose();
    } else {
      onMobileSidebarOpen();
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    dispatch(getProfileDataAsync());
  }, [dispatch]);

  useEffect(() => {
    if (!currentOrganization) {
      onOpen();
    }
  }, [currentOrganization, onOpen]);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(setSelectedOrganization(currentOrganization));
    }
  }, [currentOrganization, dispatch]);

  const handleGroupSelect = (group) => {
    const newSel = { id: group._id, name: group.name };
    dispatch({ type: "groups/setSelectedGroup", payload: newSel });
    localStorage.setItem("selectedGroup", JSON.stringify(newSel));
  };

  const handleOrganizationSelect = (org) => {
    setCurrentOrganization(org);
    localStorage.setItem("selectedOrganization", JSON.stringify(org));
    localStorage.removeItem("selectedGroup");

    dispatch(resetGitIntegrationsState());
    dispatch(setSelectedOrganization(org));
    onClose();
  };

  const handleLogout = () => {
    document.cookie =
      "AQUILAX-AUTH=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    dispatch(resetUserState());

    // setCurrentOrganization(null);
    window.location.replace("https://aquilax.ai/");
    // localStorage.removeItem("selectedOrganization");
    // localStorage.removeItem("selectedGroup");
  };

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding="0.75rem 1.5rem"
        bg={colorMode === "light" ? "white" : "gray.800"}
        color={colorMode === "light" ? "gray.800" : "white"}
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="10"
        boxShadow="sm"
        height="64px"
      >
        {isMobile && (
          <IconButton
            icon={<FiMenu />}
            onClick={toggleMobileSidebar}
            aria-label="Toggle Sidebar"
            variant="ghost"
            display={{ base: "flex", md: "none" }}
            mr="2"
          />
        )}

        <Box as={RouterLink} to="/home">
          <Image src={colorMode === "light" ? LogoDark : LogoLight} alt="Logo" height="40px" />
        </Box>

        <Flex gap={3} align="center">
          <GroupSelector
            groups={groups}
            selectedGroup={selectedGroup}
            handleGroupSelect={handleGroupSelect}
            primaryColor={primaryColor}
            menuButtonHoverBg={menuButtonHoverBg}
            menuListBg={menuListBg}
            menuItemHoverBg={menuItemHoverBg}
            isCollapsed={false}
          />

          <OrganizationsDropdown
            organizations={organizations || []}
            selectedOrganization={currentOrganization}
            onSelect={handleOrganizationSelect}
          />

          <Popover placement="bottom-end" isLazy>
            <PopoverTrigger>
              <Box position="relative">
                <IconButton
                  icon={<Bell size={20} />}
                  variant="ghost"
                  aria-label="Notifications"
                />
                {notificationsCount > 0 && (
                  <Badge
                    colorScheme="blue"
                    borderRadius="full"
                    position="absolute"
                    top="-1"
                    right="-1"
                    fontSize="0.7em"
                  >
                    {notificationsCount}
                  </Badge>
                )}
              </Box>
            </PopoverTrigger>
            <PopoverContent
              w={{ base: "300px", md: "400px" }}
              maxH="400px"
              overflowY="auto"
              zIndex="20"
              bg={colorMode === "light" ? "white" : "gray.800"}
              boxShadow="md"
              borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <NotificationModal notifications={notifications} />
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Menu>
            <MenuButton as={Button} rounded="full" variant="ghost">
              <Avatar
                size="sm"
                name={userData?.email || "Guest"}
                src={userData?.picture || ""}
              />
            </MenuButton>
            <MenuList
              bg={colorMode === "light" ? "white" : "gray.700"}
              borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
              boxShadow="lg"
            >
              <MenuItem>{userData ? userData.email : "Not logged in"}</MenuItem>
              <MenuDivider />
              <MenuItem as={RouterLink} to="/organization" icon={<Building2 size={18} />}>
                Your Organizations
              </MenuItem>
              <MenuItem as={RouterLink} to="/groups" icon={<Layers size={18} />}>
                Your Groups
              </MenuItem>
              <MenuItem as={RouterLink} to="/profile" icon={<UserCog size={18} />}>
                Settings
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={colorMode === "light" ? <Moon size={18} /> : <SunDim size={18} />}
                onClick={toggleColorMode}
              >
                {colorMode === "light" ? "Dark Mode" : "Light Mode"}
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={<LogOut size={18} />} onClick={handleLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <SelectOrgModal
        isOpen={isOpen}
        onClose={onClose}
        organizations={organizations}
        currentOrganization={currentOrganization}
        onSelect={handleOrganizationSelect}
        isLoading={loading}
      />

      {isMobile && (
        <MobileSidebar isOpen={isMobileSidebarOpen} onClose={onMobileSidebarClose} />
      )}
    </>
  );
};

export default Topbar;
