import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, useToast, useColorModeValue, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { postSecuritronAsync } from "../features/Securitron/securitronAction";
import { resetSecuritronState } from "../features/Securitron/securitronSlice";
import ChatMessagesSection from "../components/ChatPage/ChatMessagesSection";
import InputSection from "../components/ChatPage/InputSection";
import ScanWizardModal from "../components/NewScans/ScanWizardModal";
import { addGroupAsync } from "../features/Groups/GroupAction";
import GroupModal from "../components/Groups/GroupModal";
import CreateOrganizationModal from "../components/Orgs/CreateOrganizationModal";
import AddMemberModal from "./AddMemberModal";

const generateSessionId = (email) => (email || "session") + "_" + Math.random().toString(36).substr(2, 9);

const initialPrompts = ["welcome", "intel data?"];

const AIChatPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();

  const { loading, error, response, streamResponse } = useSelector(
    (state) => state.securitron
  );
  const orgDetails = useSelector((state) => state.user.selectedOrganization) || {};
  const selectedGroup = useSelector((state) => state.groups.selectedGroup) || {};
  const userDetails = useSelector((state) => state.user.userData) || {};

  const groupId = selectedGroup.id || "";
  const orgId = orgDetails._id || "";
  const [sessionId, setSessionId] = useState(generateSessionId(userDetails.email));
  const [messages, setMessages] = useState([]);
  const [controller, setController] = useState(null);
  const messagesEndRef = useRef(null);
  const [chatStarted, setChatStarted] = useState(false);
  const prevIdsRef = useRef({ orgId: null, groupId: null });
  const isRefreshingRef = useRef(false);

  const [isScanWizardOpen, setIsScanWizardOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);

  const [pendingPrompt, setPendingPrompt] = useState("");

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === "#new-scan") {
        setIsScanWizardOpen(true);
      }
    };
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  useEffect(() => {
    const handleGroupHashChange = () => {
      if (window.location.hash === "#new-group") {
        setIsGroupModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleGroupHashChange);
    return () => window.removeEventListener("hashchange", handleGroupHashChange);
  }, []);

  useEffect(() => {
    if (location.hash === "#new-org") {
      setIsOrgModalOpen(true);
    }
  }, [location.hash]);

  useEffect(() => {
    const handleOrgHashChange = () => {
      if (window.location.hash === "#new-org") {
        setIsOrgModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleOrgHashChange);
    return () => window.removeEventListener("hashchange", handleOrgHashChange);
  }, []);

  useEffect(() => {
    const handleMembersHashChange = () => {
      if (window.location.hash === "#new-members") {
        setIsMemberModalOpen(true);
      }
    };
    window.addEventListener("hashchange", handleMembersHashChange);
    return () =>
      window.removeEventListener("hashchange", handleMembersHashChange);
  }, []);

  useEffect(() => {
    if (location.hash.startsWith("#promt:")) {
      const promptText = decodeURIComponent(location.hash.substring(7));
      if (promptText) {
        setPendingPrompt(promptText);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (pendingPrompt && orgId && groupId) {
      handleSend(pendingPrompt);
      setPendingPrompt("");
      clearHash();
    }
  }, [pendingPrompt, orgId, groupId]);

  const clearHash = () => {
    window.history.replaceState(null, "", "/app" + location.pathname + location.search);
  };

  const handleScanWizardClose = () => {
    setIsScanWizardOpen(false);
    window.history.replaceState(null, "", "/app" + location.pathname + location.search);
  };

  const handleGroupModalClose = () => {
    setIsGroupModalOpen(false);
    clearHash();
  };

  const handleOrgModalClose = () => {
    setIsOrgModalOpen(false);
    clearHash();
  };

  const handleMemberModalClose = () => {
    setIsMemberModalOpen(false);
    clearHash();
  };

  const handleSaveGroup = async (groupData) => {
    try {
      const newGroup = await dispatch(addGroupAsync({ orgId, groupData })).unwrap();
      toast({
        title: "Group Created",
        description: `Group "${newGroup.name}" has been created successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      handleGroupModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error?.message || "Failed to create group.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const sendInitialPrompts = async () => {
    for (const prompt of initialPrompts) {
      const placeholderId = Date.now() + "_" + prompt;
      setMessages((prev) => [
        ...prev,
        {
          id: placeholderId,
          sender: "bot",
          type: "streaming",
          payload: "",
          hideFeedback: true,
        },
      ]);
      try {
        await dispatch(
          postSecuritronAsync({
            organizationId: orgId,
            question: prompt,
            group_id: groupId,
            session_id: sessionId,
          })
        ).unwrap();
      } catch (err) {
        setMessages((prev) => [
          ...prev,
          {
            id: Date.now(),
            sender: "bot",
            text: err?.message || "Error sending the request.",
          },
        ]);
      }
    }
  };

  const handleRefreshChat = async () => {
    isRefreshingRef.current = true;

    if (controller) {
      controller.abort();
      setController(null);
    }

    setSessionId(generateSessionId(userDetails.email));
    setMessages([]);
    setChatStarted(false);
    await sendInitialPrompts();
    dispatch(resetSecuritronState());

    setTimeout(() => {
      isRefreshingRef.current = false;
    }, 100);
  };

  useEffect(() => {
    if (!orgId || !groupId) return;
    if (
      orgId !== prevIdsRef.current.orgId &&
      groupId === prevIdsRef.current.groupId
    ) {
      return;
    }

    if (
      orgId !== prevIdsRef.current.orgId ||
      groupId !== prevIdsRef.current.groupId
    ) {
      prevIdsRef.current = { orgId, groupId };
      handleRefreshChat();
    }
  }, [orgId, groupId]);

  useEffect(() => {
    if (streamResponse && streamResponse !== "") {
      const formatted = streamResponse.replace(/\n/g, "");
      setMessages((prev) => {
        const newMsgs = [...prev];
        const lastMsg = newMsgs[newMsgs.length - 1];
        if (lastMsg && lastMsg.type === "streaming") {
          newMsgs[newMsgs.length - 1] = { ...lastMsg, payload: formatted };
        }
        return newMsgs;
      });
    }
  }, [streamResponse]);

  useEffect(() => {
    if (response && !loading) {
      if (!response.streaming) {
        const { type, payload, headers } = response;
        setMessages((prev) => {
          const newMsgs = [...prev];
          let hideFeedback = false;
          if (
            newMsgs.length > 0 &&
            newMsgs[newMsgs.length - 1].type === "streaming"
          ) {
            const placeholder = newMsgs.pop();
            hideFeedback = placeholder.hideFeedback || false;
          }
          if (type && payload) {
            newMsgs.push({
              id: Date.now(),
              sender: "bot",
              type,
              payload,
              hideFeedback,
              headers,
            });
          } else {
            newMsgs.push({
              id: Date.now(),
              sender: "bot",
              text: payload || "No message received.",
              hideFeedback,
              headers, 
            });
          }
          return newMsgs;
        });
      }
      setController(null);
      dispatch(resetSecuritronState());
    }
  }, [response, loading, dispatch]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || "An unknown error occurred.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const handleSend = (userInput) => {
    const trimmed = userInput.trim();
    if (!trimmed) return;
    setChatStarted(true);

    setMessages((prev) => [
      ...prev,
      { id: Date.now(), sender: "user", text: trimmed },
    ]);

    if (!orgId || !groupId) {
      toast({
        title: "Missing Information",
        description: "Organization ID or Group ID is missing.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    setMessages((prev) => [
      ...prev,
      { id: Date.now(), sender: "bot", type: "streaming", payload: "" },
    ]);

    const abortCtrl = new AbortController();
    setController(abortCtrl);
    dispatch(
      postSecuritronAsync({
        organizationId: orgId,
        question: trimmed,
        group_id: groupId,
        session_id: sessionId,
        signal: abortCtrl.signal,
      })
    )
      .unwrap()
      .catch((err) => {

        setMessages((prev) => {
          const newMsgs = [...prev];
          if (
            newMsgs.length > 0 &&
            newMsgs[newMsgs.length - 1].type === "streaming"
          ) {
            newMsgs.pop();
          }
          newMsgs.push({
            id: Date.now(),
            sender: "bot",
            text: err?.message || "Error sending the request.",
          });
          return newMsgs;
        });
        setController(null);
      });
  };

  const handleCancelRequest = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
  };

  const pageBg = useColorModeValue("gray.50", "transparent");
  const containerBorderColor = useColorModeValue("gray.200", "gray.700");
  const textColorLocal = useColorModeValue("gray.800", "gray.50");

  if (!orgId || !groupId) {
    return (
      <Flex
        direction="column"
        height="100vh"
        align="center"
        justify="center"
        bg={pageBg}
        color={textColorLocal}
      >
        <Box textAlign="center">
          <Text>Loading..</Text>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        key={sessionId}
        direction="column"
        height="88vh"
        bg={pageBg}
        color={textColorLocal}
      >
        <Box flex="1" overflowY="auto" px={{ base: 4, md: 6 }} py={4}>
          <ChatMessagesSection
            messages={messages}
            userDetails={userDetails}
            loading={loading}
          />
          <div ref={messagesEndRef} />
        </Box>
        <Box
          position="sticky"
          borderTop="1px solid"
          borderColor={containerBorderColor}
          bg={pageBg}
        >
          <InputSection
            onSend={handleSend}
            onRefreshChat={handleRefreshChat}
            onCancelRequest={handleCancelRequest}
            isDisabled={loading}
            isLoading={loading}
          />
        </Box>
      </Flex>
      {isScanWizardOpen && (
        <ScanWizardModal
          isOpen={isScanWizardOpen}
          onClose={handleScanWizardClose}
          orgId={orgId}
        />
      )}
      {isGroupModalOpen && (
        <GroupModal
          isOpen={isGroupModalOpen}
          onClose={handleGroupModalClose}
          mode="create"
          groupData={null}       
          onSave={handleSaveGroup}
          onDelete={() => {
          }}
        />
      )}
      {isOrgModalOpen && (
        <CreateOrganizationModal
          isOpen={isOrgModalOpen}
          onClose={handleOrgModalClose}
        />
      )}
      {isMemberModalOpen && (
        <AddMemberModal
          isOpen={isMemberModalOpen}
          onClose={handleMemberModalClose}
          organizationId={orgId}
        />
      )}
    </>
  );
};

export default AIChatPage;
