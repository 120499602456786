import {
    fetchGroupsByOrgId,
    fetchGroupById,
    updateGroupById,
    deleteGroupById,
    addGroup,
  } from "../../apis/groups";
  import { createAsyncThunk } from "@reduxjs/toolkit";
  
  // Fetch groups by organization ID
  export const getGroupsByOrgIdAsync = createAsyncThunk(
    "groups/getGroupsByOrgId",
    async (orgId, thunkAPI) => {
      return await fetchGroupsByOrgId(orgId, thunkAPI);
    }
  );
  
  // Fetch group by group ID
  export const getGroupByIdAsync = createAsyncThunk(
    "groups/getGroupById",
    async ({ orgId, groupId }, thunkAPI) => {
      return await fetchGroupById(orgId, groupId, thunkAPI);
    }
  );
  
  // Update group by group ID
  export const updateGroupAsync = createAsyncThunk(
    "groups/updateGroup",
    async ({ orgId, groupId, groupData }, thunkAPI) => {
      return await updateGroupById(orgId, groupId, groupData, thunkAPI);
    }
  );
  
  // Delete group by group ID
  export const deleteGroupAsync = createAsyncThunk(
    "groups/deleteGroup",
    async ({ orgId, groupId }, thunkAPI) => {
      return await deleteGroupById(orgId, groupId, thunkAPI);
    }
  );
  
  // Add a new group
  export const addGroupAsync = createAsyncThunk(
    "groups/addGroup",
    async ({ orgId, groupData }, thunkAPI) => {
      return await addGroup(orgId, groupData, thunkAPI);
    }
  );  