import React, { useState } from "react";
import {
  Center,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getUserDataAsync, userOnboardingAsync } from "../features/Users/UsersAction";
import { getProfileDataAsync } from "../features/Users/UsersAction";
import Lottie from "react-lottie";
import successAnimation from "../assets/lottie/success.json";

const validationSchema = Yup.object({
  org_name: Yup.string().required("Organization name is required"),
  group_name: Yup.string().required("Group name is required"),
  referral: Yup.string().required("Referral is required"),
  invite_emails: Yup.string()
    .required("Invite emails are required")
    .matches(
      /^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+,?\s*)+$/,
      "Enter valid email addresses separated by commas"
    ),
  license_key: Yup.string().optional(),
});

const Onboarding = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, success, user } = useSelector((state) => state.user);

  const nextStep = (step) => setCurrentTab(step);
  const prevStep = (step) => setCurrentTab(step);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleOnboarding = async (values) => {
    const response = await dispatch(userOnboardingAsync(values));
    if (response.meta.requestStatus === "fulfilled") {
      const responseData = response.payload;
      const { org_id, group_id } = responseData;
      const emails = values.invite_emails;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
        method: user && user.access_via ? user.access_via : "unknown",
        user_id: responseData.user_id
          ? responseData.user_id
          : user && user._id
          ? user._id
          : "unknown",
        org_id: org_id,
        group_id: group_id,
        plan_type: "free",
        members: emails
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email).length,
        referral_source: values.referral,
      });

      onOpen();
    }
  };

  const handleSubmit = () => {
    onClose();
    localStorage.removeItem("selectedGroup");
    localStorage.removeItem("selectedOrganization");
    // dispatch(getUserDataAsync())
    dispatch(getProfileDataAsync())
    
    navigate("/home");
  };

  return (
    <Center
      flex={1}
      minHeight="100vh"
      px={4}
      bg={colorMode === "dark" ? "#003460" : "#f0f4f8"}
    >
      <Stack
        width={{ base: "100%", sm: "90vw", md: "60vw", lg: "50vw" }}
        spacing={6}
        bg={colorMode === "dark" ? "#001f3f" : "white"}
        p={6}
        borderRadius="md"
        border="1px solid"
        borderColor="#0076c2"
      >
        <Tabs
          isLazy
          index={currentTab}
          onChange={(index) => setCurrentTab(index)}
          variant="unstyled"
        >
          <TabList mb={6} gap={4} borderBottom="1px solid #0076c2">
            <Tab
              isDisabled={currentTab !== 0}
              fontSize="sm"
              fontWeight="600"
              color={currentTab === 0 ? "#0076c2" : "gray.500"}
              _selected={{ color: "#0076c2", borderBottom: "2px solid #0076c2" }}
            >
              Create Organization
            </Tab>
            <Tab
              isDisabled={currentTab !== 1}
              fontSize="sm"
              fontWeight="600"
              color={currentTab === 1 ? "#0076c2" : "gray.500"}
              _selected={{ color: "#0076c2", borderBottom: "2px solid #0076c2" }}
            >
              Create Group
            </Tab>
            <Tab
              isDisabled={currentTab !== 2}
              fontSize="sm"
              fontWeight="600"
              color={currentTab === 2 ? "#0076c2" : "gray.500"}
              _selected={{ color: "#0076c2", borderBottom: "2px solid #0076c2" }}
            >
              Invite Members
            </Tab>
            <Tab
              isDisabled={currentTab !== 3}
              fontSize="sm"
              fontWeight="600"
              color={currentTab === 3 ? "#0076c2" : "gray.500"}
              _selected={{ color: "#0076c2", borderBottom: "2px solid #0076c2" }}
            >
              About Us
            </Tab>
          </TabList>

          <Formik
            initialValues={{
              org_name: "",
              group_name: "",
              referral: "",
              invite_emails: "",
              license_key: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleOnboarding}
          >
            {({ isSubmitting, values, errors }) => (
              <Form>
                <TabPanels>
                  {/* Step 1: Create Organization */}
                  <TabPanel>
                    <Heading size="md" mb={4} color="#0076c2">
                      Create Organization
                    </Heading>
                    <Field name="org_name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.org_name && form.touched.org_name}
                        >
                          <FormLabel>Organization Name</FormLabel>
                          <Input {...field} placeholder="Enter organization name" />
                          <FormErrorMessage>{form.errors.org_name}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      color="blue.700"
                      onClick={() => nextStep(1)}
                      isDisabled={!values.org_name || !!errors.org_name}
                    >
                      Continue
                    </Button>
                  </TabPanel>

                  {/* Step 2: Create Group */}
                  <TabPanel>
                    <Heading size="md" mb={4} color="#0076c2">
                      Create Group
                    </Heading>
                    <Field name="group_name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.group_name && form.touched.group_name}
                        >
                          <FormLabel>Group Name</FormLabel>
                          <Input {...field} placeholder="Enter group name" />
                          <FormErrorMessage>{form.errors.group_name}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="gray"
                      onClick={() => prevStep(0)}
                    >
                      Go Back
                    </Button>
                    <Button
                      mt={4}
                      ml={2}
                      color="blue.700"
                      onClick={() => nextStep(2)}
                      isDisabled={!values.group_name || !!errors.group_name}
                    >
                      Continue
                    </Button>
                  </TabPanel>

                  {/* Step 3: Invite Members */}
                  <TabPanel>
                    <Heading size="md" mb={4} color="#0076c2">
                      Invite Members
                    </Heading>
                    <Field name="invite_emails">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.invite_emails && form.touched.invite_emails}
                        >
                          <FormLabel>Invite Members</FormLabel>
                          <Input
                            {...field}
                            placeholder="Enter emails separated by commas"
                          />
                          <FormErrorMessage>
                            {form.errors.invite_emails}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      color="gray"
                      onClick={() => prevStep(1)}
                    >
                      Go Back
                    </Button>
                    <Button
                      mt={4}
                      ml={2}
                      color="blue.700"
                      onClick={() => nextStep(3)}
                      isDisabled={!values.invite_emails || !!errors.invite_emails}
                    >
                      Continue
                    </Button>
                  </TabPanel>

                  {/* Step 4: About Us */}
                  <TabPanel>
                    <Heading size="md" mb={4} color="#0076c2">
                      About Us
                    </Heading>
                    <Field name="referral">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.referral && form.touched.referral}
                        >
                          <FormLabel>Referral</FormLabel>
                          <Select {...field}>
                            <option value="">Select referral source</option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Friends">Friends</option>
                            <option value="Other">Other</option>
                          </Select>
                          <FormErrorMessage>
                            {form.errors.referral}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="license_key">
                      {({ field }) => (
                        <FormControl mt={4}>
                          <FormLabel>License Key (Optional)</FormLabel>
                          <Input
                            {...field}
                            placeholder="Enter license key (optional)"
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="gray"
                      onClick={() => prevStep(2)}
                    >
                      Go Back
                    </Button>
                    <Button
                      mt={4}
                      ml={2}
                      color="blue.700"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                  </TabPanel>
                </TabPanels>
              </Form>
            )}
          </Formik>
        </Tabs>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Onboarding Complete!</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" alignItems="center">
              <Lottie options={defaultOptions} height={150} width={150} />
              <Text mt={4}>Your onboarding process is complete.</Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleSubmit}>Home</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </Center>
  );
};

export default Onboarding;
