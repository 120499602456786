import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  postSecuritron,
  getSecurityAssistantStream,
  getReviewFinding,
} from '../../apis/securitron';

export const postSecuritronAsync = createAsyncThunk(
  'securitron/postSecuritron',
  async ({ organizationId, question, group_id, session_id, signal }, thunkAPI) => {
    return await postSecuritron(
      organizationId,
      question,
      group_id,
      session_id,
      thunkAPI,
      signal
    );
  }
);

export const getSecurityAssistantStreamAsync = createAsyncThunk(
  'securitron/getSecurityAssistantStream',
  async ({ org_id, scan_id, finding_id, question }, thunkAPI) => {
    return await getSecurityAssistantStream(
      org_id,
      scan_id,
      finding_id,
      question,
      thunkAPI
    );
  }
);

export const getReviewFindingAsync = createAsyncThunk(
  'securitron/getReviewFinding',
  async ({ org_id, scan_id, finding_id }, thunkAPI) => {
    return await getReviewFinding(
      org_id,
      scan_id,
      finding_id,
      thunkAPI
    );
  }
);
