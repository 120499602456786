import React from 'react';
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Grid,
  GridItem,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { toSvg } from 'jdenticon';
import { useNavigate } from 'react-router-dom';

function SelectOrgModal({
  isOpen,
  onClose,
  organizations,
  currentOrganization,
  onSelect,
  isLoading,
}) {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const handleCreateOrg = () => {
    navigate('/onboarding');
  };

  const generateIdenticon = (value, size = 40) => {
    const svg = toSvg(value, size);
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {organizations && organizations.length > 0
            ? 'Select an Organization'
            : 'No Organizations Found'}
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <>
              <Spinner size="sm" />
              <Text mt={2}>Loading organizations...</Text>
            </>
          ) : organizations && organizations.length > 0 ? (
            <>
              <Text fontSize="sm" mb={4}>
                Please select an organization you want to start with.
              </Text>
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                {organizations.map((org) => {
                  const identiconSrc = generateIdenticon(org._id || org.name);
                  return (
                    <GridItem
                      key={org._id}
                      p={1}
                      textAlign="center"
                      cursor="pointer"
                      borderRadius="md"
                      bg={
                        org._id === currentOrganization?._id
                          ? colorMode === 'light'
                            ? 'green.100'
                            : 'green.600'
                          : 'transparent'
                      }
                      _hover={{
                        bg: colorMode === 'light' ? 'gray.100' : 'gray.600',
                      }}
                      onClick={() => onSelect(org)}
                    >
                      <Image
                        src={org.org_pic || identiconSrc}
                        alt={org.name}
                        boxSize="35px"
                        mx="auto"
                        mb={1}
                        borderRadius="full"
                      />
                      <Text fontSize="xs" fontWeight="medium" noOfLines={1}>
                        {org.name}
                      </Text>
                      {org._id === currentOrganization?._id && (
                        <CheckIcon color="green.500" mt={1} />
                      )}
                    </GridItem>
                  );
                })}
              </Grid>
            </>
          ) : (
            <>
              <Text fontSize="sm" mb={4}>
                You have no organization. Please create a new organization.
              </Text>
              <Button
                colorScheme="blue"
                width="100%"
                onClick={handleCreateOrg}
              >
                New Organization
              </Button>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {/* {organizations && organizations.length > 0 && !isLoading && (
            <Button size={'xs'} onClick={onClose}>Cancel</Button>
          )} */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SelectOrgModal;
