import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { inviteMemberAsync } from "../features/Orgs/OrgsAction";

const AddMemberModal = ({ isOpen, onClose, organizationId }) => {
  const dispatch = useDispatch();
  const { inviteLoading } = useSelector((state) => state.orgs);

  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("Developer");

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    if (inviteEmail && inviteRole && organizationId) {
      try {
        await dispatch(
          inviteMemberAsync({
            organizationId,
            invite_email: inviteEmail,
            invite_role: inviteRole,
          })
        ).unwrap();
        setInviteEmail("");
        setInviteRole("Developer");
        onClose();
      } catch (error) {
        console.error("Invitation failed:", error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Member</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleInviteSubmit}>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <FormControl id="invite_email" isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="email"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  placeholder="member@example.com"
                />
              </FormControl>
              <FormControl id="invite_role" isRequired>
                <FormLabel>Role</FormLabel>
                <Select
                  value={inviteRole}
                  onChange={(e) => setInviteRole(e.target.value)}
                >
                  <option value="Owner">Owner</option>
                  <option value="Security Manager">Security Manager</option>
                  <option value="Security Engineer">Security Engineer</option>
                  <option value="Developer">Developer</option>
                  <option value="Viewer">Viewer</option>
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              bg="blue.600"
              color="white"
              _hover={{ bg: "blue.700" }}
              type="submit"
              isLoading={inviteLoading}
            >
              Invite
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;
