const PLATFORM_MAPPING = {
  github: "GitHub",
  gitlab: "GitLab",
  bitbucket: "Bitbucket",
};
export const parseRepoLink = (repoLink) => {
  try {
    if (!repoLink || typeof repoLink !== "string") {
      throw new Error("Repository link must be a non-empty string.");
    }

    if (!repoLink.includes('://') && !repoLink.includes('.')) {
      return null;
    }

    let urlToCheck = repoLink;
    if (!urlToCheck.startsWith('http://') && !urlToCheck.startsWith('https://')) {
      urlToCheck = 'https://' + urlToCheck;
    }

    const url = new URL(urlToCheck);
    const hostname = url.hostname.toLowerCase();
    if (hostname.endsWith("dev.azure.com")) {
      const pathSegments = url.pathname.split("/").filter(Boolean);
      if (pathSegments.length < 4 || pathSegments[2].toLowerCase() !== "_git") {
        throw new Error("Invalid Azure DevOps repository URL format.");
      }
      const organization = pathSegments[0];
      const project = pathSegments[1];
      const repository = pathSegments[3].replace(".git", "");
      return {
        platform: "Azure DevOps",
        owner: organization,
        project,
        repo: repository,
        git_uri: repoLink,
      };
    }

    const rawPlatform = url.hostname.split(".").shift().toLowerCase();
    const platform = PLATFORM_MAPPING[rawPlatform];
    if (!platform) {
      throw new Error(`Unsupported platform: ${rawPlatform}`);
    }
    const pathSegments = url.pathname.split("/").filter(Boolean);
    if (pathSegments.length === 0) {
      throw new Error("Invalid repository URL format.");
    }
    const owner = pathSegments[0];
    const repo = pathSegments[1] ? pathSegments[1].replace(".git", "") : null;
    return {
      platform,
      owner,
      repo,
      git_uri: repoLink,
    };
  } catch (error) {
    return null;
  }
};
