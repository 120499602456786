import React from "react";
import { Avatar, Box, Button, Flex, Heading, Icon, Text, useColorModeValue, Stack } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileCard = () => {
  const textColor = useColorModeValue("gray.600", "gray.400");
  const bgCard = useColorModeValue("white", "gray.800");

  const organizations = useSelector((state) => state.user.organizations);
  const userData = useSelector((state) => state.user.userData);
  
  const primaryOrg = organizations && organizations.length > 0 ? organizations[0] : null;
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate("/organization");
  };
  
  return (
    <Flex
      p={{ base: 4, md: 6 }}
      bg={bgCard}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      direction={{ base: "column", md: "row" }}
      align={{ base: "center", md: "center" }}
      justify="space-between"
      mb={6}
      gap={{ base: 4, md: 0 }}
    >
      <Flex 
        align="center" 
        direction={{ base: "column", sm: "row" }}
        textAlign={{ base: "center", sm: "left" }}
        w="full"
      >
        <Avatar 
          size={{ base: "lg", md: "xl" }} 
          name={userData?.name} 
          src={userData?.picture} 
          bg="purple.600" 
          color="white" 
        />
        <Box ml={{ base: 0, sm: 6 }} mt={{ base: 4, sm: 0 }}>
          <Flex 
            align="center" 
            mb={1}
            justify={{ base: "center", sm: "flex-start" }}
          >
            <Heading fontSize={{ base: "lg", md: "xl" }}>{userData?.name}</Heading>
            <Icon as={FaCheckCircle} color="blue.500" ml={2} />
          </Flex>
          <Text fontSize="sm" color={textColor}>
            AquilaX Member
          </Text>
          <Text fontSize="sm" color={textColor}>
            {userData?.email}
          </Text>
        </Box>
      </Flex>
      
      <Button 
        size="sm" 
        variant="outline" 
        onClick={handleClick}
        w={{ base: "full", md: "auto" }}
      >
        Your Organizations ({organizations ? organizations.length : 0})
      </Button>
    </Flex>
  );
};

export default ProfileCard;