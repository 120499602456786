import React, { useEffect, useState } from "react";
import {
  Box,
  Spinner,
  Center,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tag,
  ButtonGroup,

  Tooltip,
  Button,
  useColorModeValue,
  Flex,
  Input,
  HStack,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectCommitsAsync } from "../features/Projects/ProjectAction";
import { useParams, useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import { Radar } from "lucide-react";

const ProjectScans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, groupId, projectId } = useParams();

  const { commits, loading, error } = useSelector((state) => state.projects);

  const [filteredCommits, setFilteredCommits] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBranch, setFilterBranch] = useState("All");

  useEffect(() => {
    if (orgId && groupId && projectId) {
      dispatch(getProjectCommitsAsync({ orgId, groupId, projectId }));
    }
  }, [dispatch, orgId, groupId, projectId]);

  useEffect(() => {
    const projectCommits = commits[projectId] ? commits[projectId].commits : [];
    let filtered = projectCommits;

    if (searchTerm) {
      filtered = filtered.filter((commit) =>
        commit.sha.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterBranch !== "All") {
      filtered = filtered.filter((commit) => commit.branch === filterBranch);
    }

    setFilteredCommits(filtered);
  }, [commits, projectId, searchTerm, filterBranch]);

  const handleViewScans = (commitSha) => {
    navigate(`/commit-scans/${orgId}/${groupId}/${projectId}/${commitSha}`);
  };

  const boxBg = useColorModeValue("gray.50", "gray.800");
  const tableHeaderBg = useColorModeValue("gray.200", "gray.700");
  const tableHoverBg = useColorModeValue("gray.100", "gray.600");
  const inputBg = useColorModeValue("white", "gray.700");
  const inputBorderColor = useColorModeValue("gray.300", "gray.600");
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");

  const branches = ["All", ...new Set(commits[projectId]?.commits.map((commit) => commit.branch) || [])];

  return (
    <Box p={4} bg={boxBg}>
      {/* Header Section */}
      <Flex justifyContent="space-between" alignItems="center" mb={4} flexWrap="wrap">
        <Box>
          <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" color={textColor}>
            Project Commits
          </Text>
        </Box>
      </Flex>

      {/* Filters */}
      <Flex mb={4} justifyContent="space-between" flexWrap="wrap">
        <HStack spacing={4} mb={{ base: 4, md: 0 }}>
          <Input
            placeholder="Search by SHA"
            size="sm"
            bg={inputBg}
            borderColor={inputBorderColor}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            maxW={{ base: "100%", md: "250px" }}
            fontSize="sm"
          />
          <Select
            placeholder="Filter by Branch"
            size="sm"
            bg={inputBg}
            borderColor={inputBorderColor}
            value={filterBranch}
            onChange={(e) => setFilterBranch(e.target.value)}
            maxW={{ base: "100%", md: "200px" }}
            fontSize="sm"
          >
            {branches.map((branch) => (
              <option key={branch} value={branch}>
                {branch}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>

      {/* Loading State */}
      {loading && (
        <Center my={10}>
          <Spinner size="lg" />
        </Center>
      )}

      {/* Error State */}
      {error && (
        <Center my={10}>
          <Text color="red.500" fontSize="md">
            Error: {error}
          </Text>
        </Center>
      )}

      {/* Commits Table */}
      {!loading && !error && (
        <>
          <Box overflowX="auto">
            <Table
              variant="striped"
              size="sm"
              border="1px"
              borderColor={tableHeaderBg}
              minW="600px"
            >
              <Thead bg={tableHeaderBg}>
                <Tr>
                  <Th fontSize="xs">Branch</Th>
                  <Th fontSize="xs">Repo</Th>
                  <Th fontSize="xs">SHA</Th>
                  <Th fontSize="xs">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCommits.length > 0 ? (
                  filteredCommits.map((commit) => (
                    <Tr key={commit._id} _hover={{ bg: tableHoverBg }}>
                      {/* Branch */}
                      <Td fontSize="xs">{commit.branch}</Td>

                      {/* Repo */}
                      <Td fontSize="xs">
                        <Button
                          size="xs"
                          variant="link"
                          colorScheme="blue"
                          as="a"
                          href={commit.git_uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Repo
                        </Button>
                      </Td>

                      {/* SHA */}
                      <Td fontSize="xs">
                        <Tooltip label="View Scans">
                          <Text
                            as="button"
                            color="blue.500"
                            textDecoration="underline"
                            onClick={() => handleViewScans(commit.sha)}
                            cursor="pointer"
                            fontSize="xs"
                          >
                            {commit.sha.substring(0, 7)} {/* Displaying short SHA */}
                          </Text>
                        </Tooltip>
                      </Td>

                      {/* Actions */}
                      <Td fontSize="xs">
                        <ButtonGroup size="xs" isAttached variant="outline">
                        <Button onClick={() => handleViewScans(commit.sha)}>
                          View Scans
                        </Button>
                        <IconButton
                          aria-label="View Details"
                          onClick={() => handleViewScans(commit.sha)}
                          icon={<Radar size={"14px"} />}
                        />
                      </ButtonGroup>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={4}>
                      <Center>
                        <Text fontSize="xs">No commits found for this project.</Text>
                      </Center>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProjectScans;