import React from 'react';
import {
  Box,
  Text,
  HStack,
  Badge,
  Button,
  useColorModeValue,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { Virtuoso } from 'react-virtuoso';
import { useSelector } from 'react-redux';

const VulnerabilitiesList = ({
  filteredFindings,
  subTextColor,
  textColor,
  borderColor,
  cardBg,
  getSeverityColor,
  openDrawer,
  handleRaiseTicket,
  handleAskSecuritron,
  scanDetails,
  constructRepoURL,
}) => {
  const organization = useSelector((state) => state.user.selectedOrganization);
  const plan = organization?.plan || "Free";

  const getStatusButtonStyles = (status) => {
    if (status === "True Positive") {
      return {
        borderColor: "red.500",
        color: "red.600",
        _hover: { bg: "red.100" },
      };
    } else if (status === "False Positive") {
      return {
        borderColor: "gray.500",
        color: "gray.500",
        _hover: { bg: "gray.100" },
      };
    } else {
      return {
        borderColor: "blue.600",
        color: "blue.600",
        _hover: { bg: "blue.100" },
      };
    }
  };

  const renderItem = (index) => {
    const finding = filteredFindings[index];
    const key = finding.id ? `${finding.id}-${index}` : `finding-${index}`;

    const repoURL = constructRepoURL({
      gitUri: scanDetails?.scan?.git_uri || '',
      commitSha: scanDetails?.scan?.metadata?.git?.sha || '',
      path: finding.path || '',
      lineNumber: finding.line_start || 0,
    });

    const isLastItem = index === filteredFindings.length - 1;
    const spacing = isLastItem ? 0 : 3;

    const status = finding.status || "Unverified";

    return (
      <Box
        key={key}
        w="100%"
        p={3}
        borderWidth="1px"
        borderRadius="md"
        borderColor={borderColor}
        bg={cardBg}
        cursor="pointer"
        onClick={() => openDrawer(finding)}
        _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }}
        mb={spacing}
      >
        <HStack justify="space-between" mb={2}>
          <HStack spacing={2}>
            <Badge colorScheme={getSeverityColor(finding.severity)} fontSize="xs">
              {finding.severity}
            </Badge>
            <Text fontSize="xs" fontWeight="bold" color={textColor}>
              {finding.vuln}
            </Text>
          </HStack>
          <Badge colorScheme="purple" fontSize="xs">
            {Array.isArray(finding.scanners) && finding.scanners.length > 0
              ? finding.scanners[0]
              : finding.scanner}
          </Badge>
        </HStack>
        <Text fontSize="xs" color={subTextColor} mb={1}>
          {finding.message}
        </Text>
        <HStack spacing={1} alignItems="center">
          <Text fontSize="xs" color={subTextColor}>
            Path:
          </Text>
          <Tooltip fontSize="xs" label="Open in new tab" aria-label="Open in new tab">
            <Link
              href={repoURL}
              isExternal
              color={useColorModeValue('blue.500', 'blue.300')}
              textDecoration="underline"
              fontSize="xs"
              onClick={(e) => e.stopPropagation()}
            >
              {finding.path}
            </Link>
          </Tooltip>
          <Text fontSize="xs" color={subTextColor}>
            | Line: {finding.line_start}
          </Text>
        </HStack>

        <HStack spacing={2} mt={2}>
          <Button
            size="xs"
            color="white"
            bg={useColorModeValue("#003460", "#0077b5")}
            _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
            variant="solid"
            onClick={(e) => handleRaiseTicket(e, finding)}
          >
            Raise Ticket
          </Button>

          <Button
            size="xs"
            variant="outline"
            onClick={(e) => e.stopPropagation()}
            {...getStatusButtonStyles(status)}
          >
            Status: {status}
          </Button>

          {plan === "Free" ? (
            <Tooltip
              fontSize={'12px'}
              label="Upgrade your plan to use Ask Securitron"
              placement="top"
              hasArrow
            >
              <span>
                <Button
                  size="xs"
                  colorScheme="purple"
                  variant="outline"
                  disabled
                  onClick={(e) => handleAskSecuritron(e, finding)}
                >
                  Ask Securitron
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              size="xs"
              colorScheme="purple"
              variant="outline"
              onClick={(e) => handleAskSecuritron(e, finding)}
            >
              Ask Securitron
            </Button>
          )}
        </HStack>
      </Box>
    );
  };

  return (
    <Box>
      <Text fontWeight="bold" fontSize="sm" mb={2} color={textColor}>
        Vulnerabilities ({filteredFindings.length})
      </Text>
      {filteredFindings.length === 0 && (
        <Text fontSize="xs" color={subTextColor}>
          No vulnerabilities found matching the criteria.
        </Text>
      )}

      {filteredFindings.length > 0 && (
        <Box height="600px" overflow="auto">
          <Virtuoso
            totalCount={filteredFindings.length}
            itemContent={(index) => renderItem(index)}
            initialItemCount={Math.min(filteredFindings.length, 20)}
            increaseViewportBy={200}
          />
        </Box>
      )}
    </Box>
  );
};

export default VulnerabilitiesList;
