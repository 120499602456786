import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Tooltip,
  useColorModeValue,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  FiActivity,
  FiCircle,
  FiAlertCircle,
  FiCheckCircle,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getRunnersAsync } from "../../features/Analytics/AnalyticsAction";

const RunnersStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRunnersAsync());
  }, [dispatch]);

  const { runners, runnersLoading, runnersError } = useSelector(
    (state) => state.analytics
  );

  const {
    live_runners = 0,
    idle_runners = 0,
    zombie_runners = 0,
    active_runners = [],
  } = runners || {};

  const totalActiveRunners = active_runners.reduce(
    (acc, runner) => acc + runner.count,
    0
  );

  const activeRunnersTooltip = active_runners.length
    ? active_runners
        .map((runner) => `${runner._id}: ${runner.count}`)
        .join(", ")
    : "No Active Runners";

  const bg = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.150", "gray.600");
  const iconSize = "14px";
  const numberFontSize = "xs";

  const runnerStatuses = [
    {
      label: "Live Runners",
      count: live_runners,
      icon: FiActivity,
      color: "green.500",
      bgColor: useColorModeValue("green.100", "green.800"),
      borderColor: useColorModeValue("green.300", "green.600"),
    },
    {
      label: "Idle Runners",
      count: idle_runners,
      icon: FiCircle,
      color: "yellow.500",
      bgColor: useColorModeValue("yellow.100", "yellow.800"),
      borderColor: useColorModeValue("yellow.300", "yellow.600"),
    },
    {
      label: "Zombie Runners",
      count: zombie_runners,
      icon: FiAlertCircle,
      color: "red.500",
      bgColor: useColorModeValue("red.100", "red.800"),
      borderColor: useColorModeValue("red.300", "red.600"),
    },
  ];

  return (
    <Box
      p="2"
      bg={bg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="md"
      mb="2"
      width="100%"
    >
      {runnersLoading ? (
        <Flex justify="center" align="center" py="2">
          <Spinner size="xs" />
          <Text
            fontSize="xs"
            ml="1"
            color={useColorModeValue("gray.600", "gray.300")}
          >
            Loading...
          </Text>
        </Flex>
      ) : runnersError ? (
        <Flex direction="column" align="center" py="2">
          <Text fontSize="xs" color="red.500" textAlign="center">
            {typeof runnersError === "string"
              ? runnersError
              : "Error loading runners"}
          </Text>
        </Flex>
      ) : (
        <Flex justify="space-between" align="center">
          {runnerStatuses.map((status) => (
            <Tooltip
              key={status.label}
              label={status.label}
              aria-label={status.label}
              fontSize="xs"
            >
              <Flex
                direction="column"
                align="center"
                p="1"
                bg={status.bgColor}
                borderRadius="md"
                borderWidth="1px"
                borderColor={status.borderColor}
                _hover={{
                  bg: useColorModeValue("gray.300", "gray.600"),
                  cursor: "pointer",
                }}
                transition="background 0.3s"
              >
                <Icon
                  as={status.icon}
                  w={iconSize}
                  h={iconSize}
                  color={status.color}
                />
                <Text
                  fontSize={numberFontSize}
                  fontWeight="bold"
                  color={useColorModeValue(
                    "gray.800",
                    "whiteAlpha.900"
                  )}
                >
                  {status.count}
                </Text>
              </Flex>
            </Tooltip>
          ))}

          {/* Active Runners */}
          <Tooltip
            label={
              activeRunnersTooltip ||
              "No Active Runners"
            }
            aria-label="Active Runners"
            fontSize="12px"
          >
            <Flex
              direction="column"
              align="center"
              p="1"
              bg={useColorModeValue("blue.100", "blue.800")}
              borderRadius="md"
              borderWidth="1px"
              borderColor={useColorModeValue("blue.300", "blue.600")}
              _hover={{
                bg: useColorModeValue("gray.300", "gray.600"),
                cursor: "pointer",
              }}
              transition="background 0.3s"
            >
              <Icon
                as={FiCheckCircle}
                w={iconSize}
                h={iconSize}
                color="blue.500"
              />
              <Text
                fontSize={numberFontSize}
                fontWeight="bold"
                color={useColorModeValue(
                  "gray.800",
                  "whiteAlpha.900"
                )}
              >
                {totalActiveRunners}
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      )}
    </Box>
  );
};

export default RunnersStatus;
