import { createSlice } from "@reduxjs/toolkit";
import {
    fetchUpgradeAsync,
  confirmBillingAsync,
  stripeWebhookAsync,
} from "./plansAction";

const plansSlice = createSlice({
  name: "plans",
  initialState: {
    upgradeData: null,
    billingConfirmation: null,
    stripeWebhookResponse: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetPlansState: (state) => {
      state.upgradeData = null;
      state.billingConfirmation = null;
      state.stripeWebhookResponse = null;
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpgradeAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(fetchUpgradeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.upgradeData = action.payload;
      });
      builder.addCase(fetchUpgradeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder.addCase(confirmBillingAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(confirmBillingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.billingConfirmation = action.payload;
      state.success = "Billing confirmed successfully.";
      state.error = null;
    });
    builder.addCase(confirmBillingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(stripeWebhookAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(stripeWebhookAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.stripeWebhookResponse = action.payload;
      state.success = "Stripe webhook processed successfully.";
      state.error = null;
    });
    builder.addCase(stripeWebhookAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetPlansState } = plansSlice.actions;
export default plansSlice.reducer;
