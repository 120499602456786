import React, { createContext, useState, useEffect, useContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    // Read the cookie on mount
    const cookieString = document.cookie || "";
    let aquilaxValue = "";

    // Parse out the AQUILAX-AUTH cookie value
    cookieString.split(";").forEach((cookie) => {
      const [key, val] = cookie.trim().split("=");
      if (key === "AQUILAX-AUTH") {
        aquilaxValue = val;
      }
    });

    const unquotedValue = aquilaxValue.replace(/^"|"$/g, "");
    const hasToken = unquotedValue.trim().length > 0;

    setIsAuthenticated(hasToken);
    setLoadingAuth(false);
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    setLoadingAuth(false);
  };

  const logout = () => {
    document.cookie =
      "AQUILAX-AUTH=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";

    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loadingAuth, login, logout, setIsAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);