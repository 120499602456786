import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  Grid,
  GridItem,
  Image,
  Text,
  Box,
  useColorMode,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { BiGridAlt } from 'react-icons/bi';
import { toSvg } from 'jdenticon';

const OrganizationsDropdown = ({ organizations, selectedOrganization, onSelect }) => {
  const { colorMode } = useColorMode();

  // Generate identicon SVG for a given string
  const generateIdenticon = (value, size = 40) => {
    const svg = toSvg(value, size);
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<BiGridAlt size="20px" />}
        variant="ghost"
        aria-label="Organizations Menu"
      />
      <MenuList
        bg={colorMode === 'light' ? 'white' : 'gray.700'}
        borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
        boxShadow="lg"
        borderRadius="md"
        p={2}
        maxH="320px"
        overflowY="auto"
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          {organizations.map((org) => {
            const identiconSrc = generateIdenticon(org._id || org.name);
            return (
              <GridItem
                key={org._id}
                p={1}
                textAlign="center"
                cursor="pointer"
                borderRadius="md"
                bg={
                  org._id === selectedOrganization?._id
                    ? colorMode === 'light'
                      ? 'green.100'
                      : 'green.600'
                    : 'transparent'
                }
                _hover={{
                  bg: colorMode === 'light' ? 'gray.100' : 'gray.600',
                }}
                onClick={() => onSelect(org)}
              >
                <Image
                  src={org.org_pic || identiconSrc}
                  alt={org.name}
                  boxSize="35px"
                  mx="auto"
                  mb={1}
                  borderRadius="full"
                />
                <Text fontSize="xs" fontWeight="medium" noOfLines={1}>
                  {org.name}
                </Text>
                {org._id === selectedOrganization?._id && <CheckIcon color="green.500" mt={1} />}
              </GridItem>
            );
          })}
        </Grid>
      </MenuList>
    </Menu>
  );
};

export default OrganizationsDropdown;
