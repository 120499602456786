import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  IconButton,
  Tooltip,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import PATModal from "./PATModal";
import { deleteAccessTokenAsync } from "../../features/Users/UsersAction";
import { resetCreatedToken } from "../../features/Users/UsersSlice";

const PATTokens = () => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const cancelRef = useRef();
  const toast = useToast();
  const dispatch = useDispatch();
  const { accessTokens, loading, error } = useSelector((state) => state.user);
  const [tokenToDelete, setTokenToDelete] = useState(null);

  const tokenToDeleteObj = accessTokens?.find(
    (token) => token.id === tokenToDelete
  );

  useEffect(() => {
    return () => {
      dispatch(resetCreatedToken());
    };
  }, [dispatch]);

  const handleDeleteClick = (accessTokenId) => {
    setTokenToDelete(accessTokenId);
    onAlertOpen();
  };

  const confirmDelete = () => {
    if (tokenToDelete) {
      dispatch(deleteAccessTokenAsync(tokenToDelete))
        .unwrap()
        .then(() => {
          toast({
            title: "Token Deleted",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((err) => {
          toast({
            title: "Deletion Failed",
            description: err.message || "Unable to delete the token.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      setTokenToDelete(null);
    }
    onAlertClose();
  };

  const bgCard = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const tableBg = useColorModeValue("gray.50", "gray.700");
  const headerBg = useColorModeValue("gray.100", "gray.700");
  const tableVariant = useBreakpointValue({ base: "simple", md: "striped" });
  const primaryColor = useColorModeValue("#001f33", "#005399");

  return (
    <Box
      p={{ base: 3, md: 4 }}
      bg={bgCard}
      borderRadius="md"
      border="1px solid"
      borderColor={borderColor}
      boxShadow="sm"
      width="100%"
    >
      {/* Header */}
      <Flex
        justify="space-between"
        align="center"
        mb={{ base: 3, md: 4 }}
        flexWrap="wrap"
      >
        <Heading as="h2" size="md" color={textColor} mb={{ base: 2, md: 0 }}>
          Personal Access Tokens (PAT)
        </Heading>
        <Button
          bg={primaryColor}
          color="white"
          _hover={{ bg: useColorModeValue("#0077b5", "#0077b5") }}
          size="sm"
          onClick={onModalOpen}
        >
          New Access Token
        </Button>
      </Flex>

      {/* Loading Spinner */}
      {loading && (
        <Flex justify="center" align="center" my={4}>
          <Spinner size="lg" />
        </Flex>
      )}

      {/* Error Alert */}
      {error && !loading && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          {error.message || "An error occurred."}
        </Alert>
      )}

      {/* Responsive Table Container */}
      <Box overflowX="auto">
        <Table
          variant={tableVariant}
          size="sm"
          bg={tableBg}
          width="100%"
          borderRadius="md"
          overflow="hidden"
        >
          <Thead position="sticky" top="0" bg={headerBg} zIndex="1">
            <Tr>
              <Th color={textColor}>Token Name</Th>
              <Th color={textColor}>Created</Th>
              <Th color={textColor}>Expires</Th>
              <Th color={textColor} isNumeric>
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {accessTokens && accessTokens.length > 0 ? (
              accessTokens.map((token) => (
                <Tr key={token.id}>
                  <Td color={textColor}>{token.name}</Td>
                  <Td color={textColor}>
                    {new Date(token.created_at * 1000).toLocaleDateString()}
                  </Td>
                  <Td color={textColor}>
                    {token.expire_at
                      ? new Date(token.expire_at * 1000).toLocaleDateString() +
                        " " +
                        new Date(token.expire_at * 1000).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : "Never"}
                  </Td>
                  <Td isNumeric>
                    <Tooltip label="Delete Token" aria-label="Delete Token">
                      <IconButton
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteClick(token.id)}
                        aria-label="Delete Token"
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4} textAlign="center">
                  {loading ? "Loading..." : "No Access Tokens Found."}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Access Token
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete{" "}
              {tokenToDeleteObj ? (
                <strong>"{tokenToDeleteObj.name}"</strong>
              ) : (
                "this access token"
              )}{" "}
              ? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button size="sm" ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button size="sm" colorScheme="red" onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <PATModal isOpen={isModalOpen} onClose={onModalClose} />
    </Box>
  );
};

export default PATTokens;
