import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  Divider,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { uninstallJiraAsync } from '../../../../features/Integrations/integrationsAction';
import { getGitIntegrationsAsync } from '../../../../features/Analytics/AnalyticsAction';
import { useToast } from '@chakra-ui/react';

const JiraDetailModal = ({ isOpen, onClose, integration, orgId }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const handleUninstall = async () => {
    try {
      await dispatch(uninstallJiraAsync({ orgId })).unwrap();
      toast({
        title: 'Jira Uninstalled',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      dispatch(getGitIntegrationsAsync({ orgId }));
    } catch (error) {
      console.error('Jira uninstallation failed:', error);
      toast({
        title: 'Error Uninstalling Jira',
        description: error.message || 'An error occurred during Jira uninstallation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Jira Integration Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {integration ? (
            <VStack align="start" spacing={4}>
              <Box>
                <Text fontWeight="bold">Integration Type:</Text>
                <Text>{integration.repo_type}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Installation ID:</Text>
                <Text>{integration.installation_id}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Jira Host URL:</Text>
                <Text>{integration.url}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Username:</Text>
                <Text>{integration.username}</Text>
              </Box>
              <Divider />
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={handleUninstall}
                  isLoading={false}
                  loadingText="Uninstalling"
                >
                  Uninstall Jira Integration
                </Button>
              </HStack>
            </VStack>
          ) : (
            <VStack align="center" spacing={4} py={10}>
              <Text>No Jira integration details found.</Text>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JiraDetailModal;
