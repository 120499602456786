export const ALL_INTEGRATIONS = [
    {
      name: 'GitHub',
      repo_type: 'GitHub',
      icon: 'https://www.svgrepo.com/show/303615/github-icon-1-logo.svg',
    },
    {
      name: 'GitLab',
      repo_type: 'GitLab',
      icon: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/144_Gitlab_logo_logos-512.png',
    },
    {
      name: 'Azure DevOps',
      repo_type: 'Azure-DevOps',
      icon: 'https://aquilax.ai/assets/img/logos/azure-devops.png',
    },
    {
      name: 'BitBucket',
      repo_type: 'BitBucket',
      icon: 'https://aquilax.ai/assets/img/logos/bitbucket.png',
    },
    {
      name: 'Jira',
      repo_type: 'Jira',
      icon: 'https://aquilax.ai/assets/img/logos/jira.png',
    },
  ];
  