import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Icon,
  Button,
  Container,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import BracketLink from '../components/Utils/Brackets';
import { FiAlertTriangle, FiHome, FiArrowLeft } from 'react-icons/fi';
import { motion } from 'framer-motion';

const scanAnimation = keyframes`
  0% { background-position: 0 -100vh; }
  35%, 100% { background-position: 0 100vh; }
`;

const MotionBox = motion(Box);

const ErrorPage = () => {
  const bg = useColorModeValue('black', 'black');
  const textColor = useColorModeValue('green.400', 'green.300');

  return (
    <Box 
      position="relative" 
      minH="100vh" 
      bg={bg} 
      overflow="hidden" 
      fontFamily="mono"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {/* Background Gradient */}
      <Box
        position="absolute"
        inset="0"
        bgGradient="radial(#11581E, #041607)"
        zIndex="0"
      />

      {/* Noise Layer */}
      <Box
        position="absolute"
        inset="0"
        backgroundImage="url('https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        opacity="0.02"
        pointerEvents="none"
        zIndex="1"
      />

      {/* Scan Effect */}
      <Box
        position="absolute"
        inset="0"
        backgroundImage="repeating-linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0.3) 50%,
          rgba(0, 0, 0, 0) 100%
        )"
        backgroundSize="auto 4px"
        pointerEvents="none"
        zIndex="2"
        _before={{
          content: '""',
          position: 'absolute',
          inset: '0',
          backgroundImage: `
            linear-gradient(
              0deg,
              transparent 0%,
              rgba(32, 128, 32, 0.2) 2%,
              rgba(32, 128, 32, 0.8) 3%,
              rgba(32, 128, 32, 0.2) 3%,
              transparent 100%
            )
          `,
          backgroundRepeat: 'no-repeat',
          animation: `${scanAnimation} 7.5s linear infinite`,
        }}
      />

      {/* Content Container */}
      <Container
        maxW="container.lg"
        position="relative"
        zIndex="3"
        px={4}
        centerContent
      >
        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          textAlign="center"
          w="full"
        >
          <VStack spacing={8} align="center">
            {/* Error Icon and Heading */}
            <VStack spacing={4}>
              <Icon 
                as={FiAlertTriangle} 
                w={16} 
                h={16} 
                color="green.500"
                filter="drop-shadow(0 0 8px rgba(51, 255, 51, 0.4))"
              />
              <Heading 
                as="h1" 
                size="2xl" 
                color="green.400" 
                textShadow="0 0 10px rgba(51, 255, 51, 0.4)"
              >
                Error <Text as="span" color="white">404</Text>
              </Heading>
            </VStack>

            {/* Error Messages */}
            <VStack spacing={6} maxW="600px">
              <Text
                color="green.400"
                textShadow="0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)"
                fontSize={{ base: "md", md: "lg" }}
              >
                The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
              </Text>
              <Text
                color="green.400"
                textShadow="0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)"
                fontSize={{ base: "md", md: "lg" }}
              >
                Please try to{' '}
                <BracketLink href="#">go back</BracketLink>{' '}
                or{' '}
                <BracketLink href="/">return to the homepage</BracketLink>.
              </Text>
              <Text
                color="green.400"
                textShadow="0 0 1px rgba(51, 255, 51, 0.4), 0 0 2px rgba(255, 255, 255, 0.8)"
                fontSize={{ base: "md", md: "lg" }}
              >
                Good luck.
              </Text>
            </VStack>

            {/* Action Buttons */}
            <VStack spacing={4} pt={4}>
              <Button
                leftIcon={<FiArrowLeft />}
                colorScheme="green"
                variant="solid"
                onClick={() => window.history.back()}
                size="lg"
                _hover={{ bg: 'green.600' }}
                w={{ base: "full", md: "auto" }}
              >
                Go Back
              </Button>
              <Button
                leftIcon={<FiHome />}
                colorScheme="green"
                variant="outline"
                onClick={() => window.location.href = '/'}
                size="lg"
                _hover={{ bg: 'green.600', color: 'white' }}
                w={{ base: "full", md: "auto" }}
              >
                Home Page
              </Button>
            </VStack>
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
};

export default ErrorPage;