import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export const getIntellData = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/organization/${orgId}/group/${groupId}/intell-data`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching intelligence data:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getGitIntegrations = async (orgId, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/v2/organization/${orgId}/integrations?org=${orgId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Git integrations:", error.response || error.message);
  
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

export const getReport = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/report?org=${orgId}&group=${groupId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching report data:", error.response || error.message);

    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getRunners = async (thunkAPI) => {
    try {
      const response = await axios.get(`${url}/v2/heartbeat/runners`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching runners data:", error.response || error.message);
  
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };