import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  HStack,
  Divider,
  List,
  ListItem,
  FormControl,
  FormErrorMessage,
  Icon,
  Tooltip,
  Portal,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";
import { FaGithub, FaGitlab, FaBitbucket } from "react-icons/fa";
import { SiAzuredevops } from "react-icons/si";

const RepoAutocomplete = ({
  repoLink,
  setRepoLink,
  handleKeyPress,
  isDisabled,
  groupRepos,
  isInvalid,
  errorMessage,
  availableRepos = [],
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchTimeout = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Colors
  const inputBorderColor = useColorModeValue("gray.300", "gray.600");
  const inputHoverBorderColor = useColorModeValue("blue.400", "blue.300");
  const dropdownBg = useColorModeValue("white", "gray.800");
  const itemHoverBg = useColorModeValue("gray.100", "gray.700");
  const boxShadow = useColorModeValue("0 4px 6px rgba(0,0,0,0.1)", "0 4px 6px rgba(0,0,0,0.3)");

  // Custom outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        inputRef.current && 
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const platformConfig = {
    github: {
      label: "GitHub Repositories",
      icon: FaGithub,
      color: "#181717",
    },
    gitlab: {
      label: "GitLab Projects",
      icon: FaGitlab,
      color: "#FC6D26",
    },
    bitbucket: {
      label: "Bitbucket Repositories",
      icon: FaBitbucket,
      color: "#0052CC",
    },
    azuredevops: {
      label: "Azure DevOps Repositories",
      icon: SiAzuredevops,
      color: "#0078D4",
    },
  };

  const groupedRepos = useMemo(() => {
    return availableRepos.reduce((acc, repo) => {
      const type = (repo.repo_type || "").toLowerCase().replace(/[-\s]/g, "");
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(repo);
      return acc;
    }, {});
  }, [availableRepos]);

  const filteredGroupedRepos = useMemo(() => {
    if (!searchTerm) return groupedRepos;
    const lowerTerm = searchTerm.toLowerCase();
    return Object.keys(groupedRepos).reduce((acc, type) => {
      const filtered = groupedRepos[type].filter((repo) => {
        const fullName = repo.full_name?.toLowerCase() || "";
        const name = repo.name?.toLowerCase() || "";
        return fullName.includes(lowerTerm) || name.includes(lowerTerm);
      });
      if (filtered.length > 0) {
        acc[type] = filtered;
      }
      return acc;
    }, {});
  }, [searchTerm, groupedRepos]);

  const filteredGroupRepos = useMemo(() => {
    if (!searchTerm || !groupRepos) return groupRepos;
    const lowerTerm = searchTerm.toLowerCase();
    return groupRepos.filter(
      (repoUrl) => repoUrl.toLowerCase().includes(lowerTerm)
    );
  }, [searchTerm, groupRepos]);

  const updateDropdownPosition = () => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    setRepoLink(value);
    
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      setSearchTerm(value);
    }, 300);
    
    if (value && !isDropdownOpen) {
      updateDropdownPosition();
      setIsDropdownOpen(true);
    } else if (!value) {
      setIsDropdownOpen(false);
    }
  };

  const handleRepoSelect = (repoUrl) => {
    setRepoLink(repoUrl);
    setIsDropdownOpen(false);
    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputFocus = () => {
    if (repoLink && !isDropdownOpen) {
      updateDropdownPosition();
      setIsDropdownOpen(true);
    }
  };

  const handleDoubleClick = () => {
    setSearchTerm("");
    updateDropdownPosition();
    setIsDropdownOpen(true);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsDropdownOpen(false);
    } else if (e.key === "ArrowDown" && isDropdownOpen) {
      e.preventDefault();
    } else if (handleKeyPress) {
      handleKeyPress(e);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (isDropdownOpen) {
        updateDropdownPosition();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  return (
    <FormControl isInvalid={isInvalid}>
      <Text fontSize="sm" color="gray.500" mb={1}>
        Provide the URL or repository link to scan:
      </Text>

      <InputGroup position="relative">
        <Input
          ref={inputRef}
          placeholder="e.g., https://github.com/username/repo (double-click to show all)"
          value={repoLink}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyDown={handleInputKeyDown}
          onClick={handleInputFocus}
          onDoubleClick={handleDoubleClick}
          isDisabled={isDisabled}
          borderColor={inputBorderColor}
          focusBorderColor="blue.600"
          _hover={{
            borderColor: inputHoverBorderColor,
          }}
          transition="border-color 0.2s"
          autoComplete="off"
        />
        <InputRightElement>
          <IconButton
            icon={<FiArrowRight />}
            aria-label="Start Scan"
            bg="blue.600"
            color="white"
            _hover={{ bg: "blue.700" }}
            onClick={handleKeyPress}
            isDisabled={isDisabled}
          />
        </InputRightElement>
      </InputGroup>

      {isDropdownOpen && (
        <Portal>
          <Box
            ref={dropdownRef}
            position="absolute"
            zIndex={1500}
            top={`${dropdownPosition.top}px`}
            left={`${dropdownPosition.left}px`}
            width={`${dropdownPosition.width}px`}
            bg={dropdownBg}
            borderRadius="md"
            boxShadow={boxShadow}
            maxH="400px"
            overflowY="auto"
            mt={1}
          >
            <Box p={4}>
              {filteredGroupRepos && filteredGroupRepos.length > 0 && (
                <Box mb={4}>
                  <HStack mb={2}>
                    <Text fontWeight="bold" fontSize="md">
                      Group Repos
                    </Text>
                  </HStack>
                  <Divider mb={2} />
                  <List spacing={2}>
                    {filteredGroupRepos.map((repoUrl, idx) => (
                      <ListItem
                        key={idx}
                        p={2}
                        borderRadius="md"
                        transition="background-color 0.2s"
                        _hover={{ bg: itemHoverBg, cursor: "pointer" }}
                        onClick={() => handleRepoSelect(repoUrl)}
                      >
                        <Text fontWeight="semibold" fontSize="sm" isTruncated>
                          {repoUrl}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}

              {Object.keys(filteredGroupedRepos).length > 0 ? (
                Object.keys(filteredGroupedRepos).map((type) => {
                  const platform = platformConfig[type] || {};
                  return (
                    <Box key={type} mb={4}>
                      <HStack mb={2}>
                        {platform.icon && (
                          <Icon
                            as={platform.icon}
                            boxSize={5}
                            color={platform.color}
                          />
                        )}
                        <Text fontWeight="bold" fontSize="md">
                          {platform.label || "Repositories"}
                        </Text>
                      </HStack>
                      <Divider mb={2} />
                      <List spacing={2}>
                        {filteredGroupedRepos[type].map((repo) => (
                          <ListItem
                            key={repo.id}
                            p={2}
                            borderRadius="md"
                            transition="background-color 0.2s"
                            _hover={{ bg: itemHoverBg, cursor: "pointer" }}
                            onClick={() => handleRepoSelect(repo.html_url)}
                          >
                            <HStack spacing={2}>
                              {platform.icon && (
                                <Icon
                                  as={platform.icon}
                                  boxSize={4}
                                  color={platform.color}
                                />
                              )}
                              <Tooltip
                                label={repo.full_name}
                                aria-label="Repository Name Tooltip"
                              >
                                <Text
                                  fontWeight="semibold"
                                  fontSize="sm"
                                  isTruncated
                                  maxW="500px"
                                >
                                  {repo.name}
                                </Text>
                              </Tooltip>
                            </HStack>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })
              ) : (
                <Text fontSize="sm">
                  No repositories found. Please integrate a source.
                </Text>
              )}
            </Box>
          </Box>
        </Portal>
      )}

      {!isInvalid ? null : <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export default RepoAutocomplete;