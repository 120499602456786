import React from "react";
import {
  Box,
  Flex,
  Text,
  Link,
  Divider,
  Button,
  Stack,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";

const getDaysAgo = (timestamp) => {
  if (!timestamp) return "N/A";
  const createdDate = new Date(timestamp * 1000);
  const diffInMs = Date.now() - createdDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays;
};

const PlanDetailsCard = ({ planData, orgDetails }) => {
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const headingColor = useColorModeValue("gray.600", "gray.200");
  const subTextColor = useColorModeValue("gray.500", "gray.400");
  const orgNameColor = useColorModeValue("black", "white");

  if (!planData) {
    return (
      <Box
        p={6}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
        bg={cardBg}
        borderColor={borderColor}
      >
        <Stack spacing={4}>
          {/* Top row skeletons */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align={{ base: "flex-start", md: "center" }}
            mb={2}
          >
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box flex="1">
              <Skeleton height="20px" />
            </Box>
          </Flex>

          <Divider borderColor={borderColor} />

          {/* Bottom row skeletons */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align={{ base: "flex-start", md: "center" }}
          >
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box mr={8} mb={{ base: 3, md: 0 }} flex="1">
              <Skeleton height="20px" />
            </Box>
            <Box flex="1">
              <Skeleton height="40px" />
            </Box>
          </Flex>
        </Stack>
      </Box>
    );
  }

  const { subscription, manage_subscription } = planData;
  const orgName = orgDetails?.name;
  const orgPlan = orgDetails?.plan;
  const OrgsLicense = orgDetails?.license;

  const nowSeconds = Date.now() / 1000;
  const daysLeft =
    subscription.current_period_end && subscription.current_period_end > nowSeconds
      ? Math.floor((subscription.current_period_end - nowSeconds) / (60 * 60 * 24))
      : 0;

  const amountPerMonth = subscription.plan?.amount
    ? (subscription.plan.amount / 100).toFixed(2)
    : "0.00";

  const licenseId = subscription.id || "N/A";
  const daysAgo = getDaysAgo(subscription.created);
  const manageUrl = manage_subscription?.url || "#";

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      bg={cardBg}
      borderColor={borderColor}
    >
      <Stack spacing={4}>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align={{ base: "flex-start", md: "center" }}
          mb={2}
        >
          {/* Organization */}
          <Box mr={8} mb={{ base: 3, md: 0 }}>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Organization
            </Text>
            <Link
              color={orgNameColor}
              fontWeight="bold"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              {orgName}
            </Link>
          </Box>

          {/* Active Plan */}
          <Box mr={8} mb={{ base: 3, md: 0 }}>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Active Plan
            </Text>
            <Text color="green.400" fontWeight="bold" fontSize="lg">
              {orgPlan}
            </Text>
          </Box>

          {/* Renewal */}
          <Box mr={8} mb={{ base: 3, md: 0 }}>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Renewal
            </Text>
            <Text fontWeight="bold" color={headingColor}>
              {daysLeft > 0 ? `in ${daysLeft}d` : "Expired"}
            </Text>
          </Box>

          {/* Licenses */}
          <Box>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Licenses
            </Text>
            <Text>
              {OrgsLicense}{" "}
              <Text as="span" color={subTextColor} fontSize="sm">
                (100% Used)
              </Text>
            </Text>
          </Box>
        </Flex>

        <Divider borderColor={borderColor} />

        {/* Bottom row: Amount per month, License ID, Created, Manage Subscription */}
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
        >
          {/* Amount per Month */}
          <Box mr={8} mb={{ base: 3, md: 0 }}>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Amount per month
            </Text>
            <Text fontWeight="bold" fontSize="lg" color={headingColor}>
              {amountPerMonth} (USD)
            </Text>
          </Box>

          {/* License ID */}
          <Box mr={8} mb={{ base: 3, md: 0 }}>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              License ID
            </Text>
            <Text maxW="230px" isTruncated fontWeight="bold" color={headingColor}>
              {licenseId}
            </Text>
          </Box>

          {/* Created */}
          <Box>
            <Text fontSize="sm" color={subTextColor} mb={1}>
              Created
            </Text>
            <Text color={headingColor}>{daysAgo}d ago</Text>
          </Box>
        </Flex>

        <Flex justify="center" mt={4}>
          <Button
            bg="blue.600"
            color="white"
            _hover={{ bg: "blue.700" }}
            size="md"
            onClick={() => window.open(manageUrl, "_blank")}
          >
            Manage your subscription
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};

export default PlanDetailsCard;
