import React from "react";
import { HStack, Button, Spinner, Tooltip } from "@chakra-ui/react";

const ActionButtons = ({ handleStartScan, isDisabled, viewerTooltip }) => {
  return (
    <HStack justify="flex-end" width="100%">
      <Tooltip
        label={viewerTooltip}
        isDisabled={!viewerTooltip}
        hasArrow
        placement="top"
      >
        <Button
          bg="blue.600"
          color="white"
          _hover={{ bg: "blue.700" }}
          onClick={handleStartScan}
          isDisabled={isDisabled}
          leftIcon={isDisabled && !viewerTooltip ? <Spinner size="sm" /> : null}
        >
          Start Scan
        </Button>
      </Tooltip>
    </HStack>
  );
};

export default ActionButtons;
