import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  useColorModeValue,
  Spinner,
  Stack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { FaKey } from "react-icons/fa";
import Header from "./Header";
import LicenseKeyModal from "./LicenseKeyModal";
import { useSelector, useDispatch } from "react-redux";
import { fetchUpgradeAsync } from "../../features/Plans/plansAction";
import PlanDetailsCard from "./PlanDetailsCard";
import StripePricingTable from "./StripePayment";

const PlansContent = ({ orgDetails, userDetails }) => {
  const dispatch = useDispatch();
  const { upgradeData, loading, error } = useSelector((state) => state.plans);
  const orgId = orgDetails?._id;
  const orgName = orgDetails?.name;

  useEffect(() => {
    if (orgId) {
      dispatch(fetchUpgradeAsync({ orgId }));
    }
  }, [dispatch, orgId]);

  if (loading) {
    return (
      <Box p={2}>
        <Stack spacing={2}>
          <Skeleton height="30px" />
          <Skeleton height="30px" />
          <Skeleton height="30px" />
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Text color="red.500">

      </Text>
    );
  }

  if (!upgradeData) {
    return <Text> ,</Text>;
  }

  const hasActivePlan =
    upgradeData &&
    !(upgradeData.subscription === null && upgradeData.manage_subscription === null);

  return (
    <Box p={2}>
      {hasActivePlan ? (
        <PlanDetailsCard orgDetails={orgDetails} planData={upgradeData} />
      ) : (
        <StripePricingTable orgDetails={orgDetails} userDetails={userDetails} />
      )}
    </Box>
  );
};

const BillingSubscriptionPage = () => {
  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const userDetails = useSelector((state) => state.user.userData);

  const myProfile = orgDetails?.users?.find(
    (user) => user.email === userDetails?.email
  );
  const isOwner = myProfile?.role === "Owner";
  const isLoading = useSelector((state) => state.user.isLoading);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isLoading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="lg" />
      </Box>
    );
  }

  return (
    <Box p={4} bg={useColorModeValue("gray.50", "gray.900")} minH="100vh">
      <Flex justify="space-between" align="center" mb={4} direction={{ base: "column", md: "row" }}>
        <Header />
        <Button
          leftIcon={<FaKey />}
          bg="blue.600"
          color="white"
          _hover={{ bg: "blue.700" }}
          size="sm"
          mt={{ base: 4, md: 0 }}
          onClick={onOpen}
        >
          Add License Key
        </Button>
      </Flex>

      <PlansContent orgDetails={orgDetails} userDetails={userDetails} />

      {!isOwner && (
        <Box mt={6} p={4} borderWidth="1px" borderColor="gray.300" borderRadius="md">
          <Text>
            You are not authorized to access this billing information for this organization.
          </Text>
        </Box>
      )}

      <LicenseKeyModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default BillingSubscriptionPage;
