import React, { useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Flex,
  Text,
  useColorModeValue,
  Skeleton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import HeatmapSection from "../components/AllScans/HeatmapSection";
import ScansPieChart from "../components/ProjectsPage/ScansPieChart";
import ProjectsTable from "../components/ProjectsPage/ProjectsTable";
import NotificationBanner from "../components/AllScans/NotificationBanner";

import { getProjectsAsync } from "../features/Projects/ProjectAction";
import {
  getCurrentYearScansReportAsync,
  getScansAnalyticsAsync,
} from "../features/Scans/ScansAction";
import { setPage, setLimit } from "../features/Projects/ProjectSlice";
import { transformScansByStatus } from "../utils/dataTransform";

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.user.selectedOrganization?._id);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);

  const {
    currentYearScansReport,
    loadingCurrentYearScansReport,
    errorCurrentYearScansReport,
  } = useSelector((state) => state.scans);

  const {
    projects,
    loading: projectsLoading,
    error: projectsError,
    totalProjects,
    currentPage,
    limit,
  } = useSelector((state) => state.projects);


  const pieData = useMemo(() => {
    if (!currentYearScansReport) return [];
    return transformScansByStatus(currentYearScansReport);
  }, [currentYearScansReport]);

  useEffect(() => {
    if (!orgId || !selectedGroup?.id) return;
    dispatch(getProjectsAsync({ orgId, groupId: selectedGroup.id }));
    dispatch(getCurrentYearScansReportAsync({ orgId, groupId: selectedGroup.id }));
  }, [selectedGroup?.id, dispatch]);

  if (!orgId || !selectedGroup?.id) {
    return <Center minH="100vh" />;
  }

  if (projectsError || errorCurrentYearScansReport) {
    return (
      <Center p={6} flexDirection="column">
        <Text color="red.500" fontSize="md">
          Error:{" "}
          {projectsError?.message ||
            errorCurrentYearScansReport?.message ||
            "Something went wrong."}
        </Text>
      </Center>
    );
  }

  return (
    <Box p={6}>
      <NotificationBanner rawScans={currentYearScansReport || []} />
      <Flex direction={{ base: "column", lg: "row" }} gap={6} mb={4}>
        {loadingCurrentYearScansReport ? (
          <Skeleton height="300px" width="100%" rounded="md" />
        ) : (
          <HeatmapSection rawScans={currentYearScansReport || []} />
        )}

        {loadingCurrentYearScansReport ? (
          <Skeleton height="300px" width="100%" rounded="md" />
        ) : (
          <ScansPieChart data={pieData} />
        )}
      </Flex>

      <ProjectsTable
        orgId={orgId}
        groupId={selectedGroup.id}
        total={totalProjects}
        currentPage={currentPage}
        limit={limit}
        onPageChange={(newPage) => {

        }}
        onLimitChange={(newLimit) => {
          // ...
        }}
      />
    </Box>
  );
};

export default ProjectsPage;
