import React, { useEffect, useMemo } from "react";
import { Box, Flex, Text, useToast, useColorModeValue } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getScansByGroupIdAsync } from "../features/Scans/ScansAction";
import NotificationBanner from "../components/AllScans/NotificationBanner";
import StatisticsSection from "../components/AllScans/StatisticsSection";
import HeatmapSection from "../components/AllScans/HeatmapSection";
import ScanTable from "../components/AllScans/ScanTable";
import Lottie from "react-lottie";
import loadingAnimation from "../assets/lottie/loading.json";

const AllScansPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const orgId = useSelector((state) => state.user.selectedOrganization?._id);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);

  const {
    scans,
    loadingScans,
    errorScans,
    lastFetchedOrgId,
    lastFetchedGroupId,
  } = useSelector((state) => state.scans);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // useEffect(() => {
  //   if (!orgId || !selectedGroup?.id) return;
  //   const alreadyFetchedSameOrgGroup =
  //     lastFetchedOrgId === orgId && lastFetchedGroupId === selectedGroup.id;
  //   if (!alreadyFetchedSameOrgGroup) {
  //     dispatch(getScansByGroupIdAsync({ orgId, groupId: selectedGroup.id }))
  //       .unwrap()
  //       .catch((err) => {
  //         toast({
  //           title: "Error fetching scans",
  //           description: err?.message || "Something went wrong.",
  //           status: "error",
  //           duration: 3000,
  //           isClosable: true,
  //         });
  //       });
  //   }
  // }, [orgId, selectedGroup?.id, lastFetchedOrgId, lastFetchedGroupId, dispatch, toast]);

  const currentYear = new Date().getFullYear();
  const pageBg = useColorModeValue("gray.50", "gray.900");

  const heatmapData = useMemo(() => {
    const dateCounts = {};
    scans.forEach((scan) => {
      const date = new Date(scan.created_at * 1000);
      if (date.getFullYear() === currentYear) {
        const dateString = date.toISOString().split("T")[0];
        dateCounts[dateString] = (dateCounts[dateString] || 0) + 1;
      }
    });
    return Object.keys(dateCounts).map((day) => ({
      day,
      value: dateCounts[day],
    }));
  }, [scans, currentYear]);

  return (
    <Box bg={pageBg} p={6}>
      <NotificationBanner />
      <Flex direction={{ base: "column", lg: "row" }} gap={6}>
        <StatisticsSection />
        <HeatmapSection heatmapData={heatmapData} />
      </Flex>
      {loadingScans ? (
        <Flex justifyContent="center" alignItems="center" minH="50vh">
          <Lottie options={defaultOptions} height={200} width={200} />
        </Flex>
      ) : errorScans ? (
        <Text color="red.500">
          Error:{" "}
          {typeof errorScans === "object"
            ? JSON.stringify(errorScans)
            : errorScans}
        </Text>
      ) : (
        <ScanTable
          scans={scans}
          orgId={orgId}
          groupId={selectedGroup?.id || ""}
        />
      )}
    </Box>
  );
};

export default AllScansPage;
