import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { useEffect } from "react";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import AIChatPage from "./pages/AIChatPage";
import ScansPage from "./pages/ScansPage";
import NewScansPage from "./pages/NewScansPage";
import GroupPage from "./pages/GroupPage";
import ProfilePage from "./pages/ProfilePage";
import ProjectPage from "./pages/Projects";
import DashboardLayout from "./components/DashboardLayout";
import StartHere from "./pages/Documentation/StartHere";
import Swagger from "./pages/Documentation/Swagger";
import Redocly from "./pages/Documentation/Redocly";
import VulnPage from "./pages/VulnPage";
import FAQ from "./pages/Documentation/FAQ";
import AllScansPage from "./pages/AllScansPage";
import OrgsPage from "./pages/OrgsPage";
import OrgDetailsPage from "./pages/OrgDetailsPage";
import CommitScans from "./pages/CommitScans";
import ReleaseNotes from "./pages/Documentation/ReleaseNotes";
import Onboarding from "./pages/Onboarding";
import ProjectsScans from "./pages/ProjectsScans";
import CreateOrganizationPage from "./pages/CreateOrganizationPage";
import BitbucketCallback from "./pages/BitbucketCallback";
import GitHubWebhook from "./pages/GitHubWebhook";
import Plans from "./pages/Plans";
import ErrorPage from "./pages/ErrorPage";
import { useAuth, AuthProvider } from "./context/AuthContext";
import { useDispatch } from "react-redux";
import { Flex, Spinner } from "@chakra-ui/react";
import { getUserDataAsync } from "./features/Users/UsersAction";
import { getProfileDataAsync } from "./features/Users/UsersAction";
import ReportPage from "./pages/ReportPage";
import VSCInstallPage from "./pages/VSCPage";
import ConfirmPage from "./pages/ConfirmPage";
import UpgradePlanPage from "./pages/UpgradePlanPage";
import Chatv2 from "./pages/Chatv2";

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated, loadingAuth } = useAuth();

  useEffect(() => {
    if (!loadingAuth) {
      // dispatch(getUserDataAsync());
      // dispatch(getProfileDataAsync());
    }
  }, [loadingAuth, dispatch]);

  if (loadingAuth) {
    return (
      <Flex minH="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }
  return isAuthenticated ? children : <Navigate to="/" replace />;
}

function App() {
  return (
    <Router basename="/app">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <HomePage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/securitron"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <AIChatPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/chatv2"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Chatv2/>
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/bitbucket/callback"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <BitbucketCallback />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/scan/:orgid/:scanid/:groupid"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ScansPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-scan"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <NewScansPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/scans"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <AllScansPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ReportPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/groups"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GroupPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/organization"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <OrgsPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/organizations/create"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CreateOrganizationPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/organization/:id"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <OrgDetailsPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            }
          />
          <Route
            path="/projects"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ProjectPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/organization/:orgId/group/:groupId/project/:projectId/scans"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ProjectsScans />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/commit-scans/:orgId/:groupId/:projectId/:commitSha"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <CommitScans />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ProfilePage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/plans"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Plans />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/github/webhook"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <GitHubWebhook />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/vulnerabilities/:orgid/findings"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <VulnPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/vulnerabilities"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <VulnPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/vsc-install"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <VSCInstallPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/confirm"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ConfirmPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade/:orgId"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <UpgradePlanPage />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentation/start-here"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <StartHere />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentation/swagger"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Swagger />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentation/redocly"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Redocly />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentation/faq"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <FAQ />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/documentation/release-notes"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <ReleaseNotes />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
