import { createSlice } from "@reduxjs/toolkit";
import {
  startNewScanAsync,
  getScansByGroupIdAsync,
  getScanByIdAsync,
  getScansByProjectAsync,
  deleteScanAsync,
  getPendingScansAsync,
  getScansAnalyticsAsync,
  updateFindingStatusAsync,
  getRepoBranchesAsync,
  getScanPdfReportAsync,
  getFindingsByGroupIdAsync,
  generateExecutiveSummaryAsync,
  generateExecutiveSummaryOrgAsync,
  getAllFindingsAsync,
  stopScheduledScanAsync,        
  startScheduledScanAsync, 
  getCurrentYearScansReportAsync,
} from "./ScansAction";

const initialState = {
  scans: [],
  scanDetails: null,
  pendingScans: [],
  branches: [],
  pdfReportUrl: null,
  findings: [],
  analytics: null,
  loadingScans: false,
  loadingAnalytics: false,
  errorScans: null,
  errorAnalytics: null,
  lastFetchedOrgId: null,
  lastFetchedGroupId: null,
  analyticsLastOrgId: null,
  analyticsLastGroupId: null,
  executiveSummary: null,          
  loadingExecutiveSummary: false,   
  errorExecutiveSummary: null,

  executiveSummaryOrg: null,
  loadingExecutiveSummaryOrg: null,
  errorExecutiveSummaryOrg: null,

  allFindings: [],
  loadingAllFindings: false,
  errorAllFindings: null,

  currentYearScansReport: [],
  loadingCurrentYearScansReport: false,
  errorCurrentYearScansReport: null,
};

const scansSlice = createSlice({
  name: "scans",
  initialState,
  reducers: {
    resetScansState: (state) => {
      state.scans = [];
      state.scanDetails = null;
      state.pendingScans = [];
      state.branches = [];
      state.findings = [];
      state.pdfReportUrl = null;
      state.analytics = null;
      state.loadingScans = false;
      state.errorScans = null;
      state.loadingAnalytics = false;
      state.errorAnalytics = null;
      state.lastFetchedOrgId = null;
      state.lastFetchedGroupId = null;
      state.analyticsLastOrgId = null;
      state.analyticsLastGroupId = null;
      state.executiveSummary = null;          
      state.loadingExecutiveSummary = false;
      state.errorExecutiveSummary = null; 
      
      state.executiveSummaryOrg = null;          
      state.loadingExecutiveSummaryOrg = false;
      state.errorExecutiveSummaryOrg = null;  

      state.allFindings = [];
      state.loadingAllFindings = false;
      state.errorAllFindings = null;

      state.currentYearScansReport = null;
      
    },
    resetScanDetails: (state) => {
      state.scanDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startNewScanAsync.pending, (state) => {
        state.loadingScans = true;
        state.errorScans = null;
      })
      .addCase(startNewScanAsync.fulfilled, (state, action) => {
        state.loadingScans = false;
        state.scans.push(action.payload);
      })
      .addCase(startNewScanAsync.rejected, (state, action) => {
        state.loadingScans = false;
        state.errorScans = action.payload;
      })

      .addCase(getScansByGroupIdAsync.pending, (state) => {
        state.loadingScans = true;
        state.errorScans = null;
      })
      .addCase(getScansByGroupIdAsync.fulfilled, (state, action) => {
        state.loadingScans = false;
        state.scans = action.payload;
        state.lastFetchedOrgId = action.meta.arg.orgId;
        state.lastFetchedGroupId = action.meta.arg.groupId;
      })
      .addCase(getScansByGroupIdAsync.rejected, (state, action) => {
        state.loadingScans = false;
        state.errorScans = action.payload;
      })

      .addCase(getScanByIdAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScanByIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.scanDetails = action.payload.scan
          ? action.payload
          : { scan: action.payload };
      })
      .addCase(getScanByIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getScansByProjectAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScansByProjectAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.scans = action.payload;
      })
      .addCase(getScansByProjectAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(deleteScanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteScanAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.scans = state.scans.filter(
          (scan) => scan._id !== action.meta.arg.scanId
        );
      })
      .addCase(deleteScanAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getPendingScansAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingScansAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingScans = action.payload;
      })
      .addCase(getPendingScansAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getScansAnalyticsAsync.pending, (state) => {
        state.loadingAnalytics = true;
        state.errorAnalytics = null;
      })
      .addCase(getScansAnalyticsAsync.fulfilled, (state, action) => {
        state.loadingAnalytics = false;
        state.analytics = action.payload;
        const { orgId, groupId } = action.meta.arg;
        state.analyticsLastOrgId = orgId;
        state.analyticsLastGroupId = groupId;
      })
      .addCase(getScansAnalyticsAsync.rejected, (state, action) => {
        state.loadingAnalytics = false;
        state.errorAnalytics = action.payload;
      })

      .addCase(updateFindingStatusAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFindingStatusAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { findingId, status } = action.payload;
        if (state.scanDetails && state.scanDetails.findings) {
          const findingIndex = state.scanDetails.findings.findIndex(
            (f) => f._id === findingId
          );
          if (findingIndex !== -1) {
            state.scanDetails.findings[findingIndex].status = status;
          }
        }
      })
      .addCase(updateFindingStatusAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getRepoBranchesAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRepoBranchesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.branches = action.payload;
      })
      .addCase(getRepoBranchesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getScanPdfReportAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScanPdfReportAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.pdfReportUrl = action.payload;
      })
      .addCase(getScanPdfReportAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getFindingsByGroupIdAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFindingsByGroupIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.findings = action.payload;
      })
      .addCase(getFindingsByGroupIdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(generateExecutiveSummaryAsync.pending, (state) => {
        state.loadingExecutiveSummary = true;
        state.errorExecutiveSummary = null;
      })
      .addCase(generateExecutiveSummaryAsync.fulfilled, (state, action) => {
        state.loadingExecutiveSummary = false;
        state.executiveSummary = action.payload;
      })
      .addCase(generateExecutiveSummaryAsync.rejected, (state, action) => {
        state.loadingExecutiveSummary = false;
        state.errorExecutiveSummary = action.payload;
      })

      .addCase(generateExecutiveSummaryOrgAsync.pending, (state) => {
        state.loadingExecutiveSummaryOrg = true;
        state.errorExecutiveSummaryOrg = null;
      })
      .addCase(generateExecutiveSummaryOrgAsync.fulfilled, (state, action) => {
        state.loadingExecutiveSummaryOrg = false;
        state.executiveSummaryOrg = action.payload;
      })
      .addCase(generateExecutiveSummaryOrgAsync.rejected, (state, action) => {
        state.loadingExecutiveSummaryOrg = false;
        state.errorExecutiveSummaryOrg = action.payload;
      })

      .addCase(getAllFindingsAsync.pending, (state) => {
        state.loadingAllFindings = true;
        state.errorAllFindings = null;
      })
      .addCase(getAllFindingsAsync.fulfilled, (state, action) => {
        state.loadingAllFindings = false;
        state.allFindings = action.payload;
      })
      .addCase(getAllFindingsAsync.rejected, (state, action) => {
        state.loadingAllFindings = false;
        state.errorAllFindings = action.payload;
      })

      .addCase(stopScheduledScanAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(stopScheduledScanAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(stopScheduledScanAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(startScheduledScanAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(startScheduledScanAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(startScheduledScanAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getCurrentYearScansReportAsync.pending, (state) => {
        state.loadingCurrentYearScansReport = true;
        state.errorCurrentYearScansReport = null;
      })
      .addCase(getCurrentYearScansReportAsync.fulfilled, (state, action) => {
        state.loadingCurrentYearScansReport = false;
        state.currentYearScansReport = action.payload;
      })
      .addCase(getCurrentYearScansReportAsync.rejected, (state, action) => {
        state.loadingCurrentYearScansReport = false;
        state.errorCurrentYearScansReport = action.payload;
      });
  },
});

export const { resetScansState, resetScanDetails } = scansSlice.actions;
export default scansSlice.reducer;
