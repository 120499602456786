import React from 'react';
import { List, ListItem, HStack, ListIcon, VStack, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon, ChevronRightIcon } from '@chakra-ui/icons';

const RepositoryList = ({ repos, teal500 }) => {
  if (repos.length === 0) {
    return <Text fontSize="sm">No repositories available.</Text>;
  }

  return (
    <List spacing={3}>
      {repos.map((repo) => (
        <ListItem key={repo.id}>
          <HStack alignItems="start">
            <ListIcon as={ChevronRightIcon} color={teal500} />
            <VStack align="start" spacing={1}>
              <Link
                href={repo.html_url || repo.web_url}
                isExternal
                color="blue.500"
                fontWeight="medium"
              >
                {repo.name} <ExternalLinkIcon mx="2px" />
              </Link>
              {repo.description && (
                <Text fontSize="sm" color="gray.500">
                  {repo.description}
                </Text>
              )}
            </VStack>
          </HStack>
        </ListItem>
      ))}
    </List>
  );
};

export default RepositoryList;
