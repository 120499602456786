import axios from "axios";
import qs from "qs";

const url = process.env.REACT_APP_API_URL;

export const fetchUpgrade = async ({ orgId }, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/v1/organization/${orgId}/upgrade`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching upgrade details:", error.response || error.message);
      return thunkAPI.rejectWithValue({
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      });
    }
  };

export const confirmBilling = async ({ orgId, sid }, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/confirm_billing`,
      null,
      {
        params: { sid },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming billing:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const stripeWebhook = async ({ reqid, stripeSignature }, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/stripe/webhook`,
      null,
      {
        params: { reqid },
        headers: {
          "stripe-signature": stripeSignature,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error processing stripe webhook:", error.response || error.message);
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};
