import React, { useEffect } from "react";
import { Box, Spinner, Center, Text, useColorModeValue } from "@chakra-ui/react";

const StripePricingTable = ({ orgDetails, userDetails }) => {
  const orgId = orgDetails?._id || orgDetails?.organization || "defaultOrgId";
  const userEmail = userDetails?.email || "default@example.com";

  useEffect(() => {
    const scriptId = "stripe-pricing-table-script";
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  if (!orgDetails || !userDetails) {
    return (
      <Center mt={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box
      mt={4}
      p={4}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow="md"
      borderRadius="md"
      textAlign="center"
    >
      <stripe-pricing-table
        pricing-table-id="prctbl_1Or2cwIUulPRwJqXrUDukj9l"
        publishable-key="pk_live_51Or0fmIUulPRwJqX65qblw7uk2s4jhlkVzLVXcM9YpBZY52RUc0CKcv1EP8rQ21TzdEIQB0wNNu7NT0Hj33ybSVo007cl5xTBP"
        client-reference-id={orgId}
        customer-email={userEmail}
      ></stripe-pricing-table>
    </Box>
  );
};

export default StripePricingTable;
