import { createSlice } from "@reduxjs/toolkit";
import {
  installGitLabAsync,
  fetchGitLabRepositoriesAsync,
  initiateBitbucketInstallAsync,
  handleBitbucketCallbackAsync,
  fetchBitbucketRepositoriesAsync,
  deleteBitbucketInstallationAsync,
  installGitHubAsync,
  installJiraAsync,
  uninstallJiraAsync,
  getTicketIntegrationAsync,
  installAzureDevOpsAsync,
  deleteAzureDevOpsInstallationAsync,
  deleteRepoInstallationAsync,
  updateGitLabInstallationAsync,
} from "./integrationsAction";

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    repoInstallation: {
      deletionStatus: null,
      loading: false,
      error: null,
      success: null,
    },
    // GitLab State
    gitLab: {
      installationStatus: null,
      repositories: null,
      groups: null,
      loading: false,
      error: null,
      success: null,
    },

    // Bitbucket State
    bitbucket: {
      authUrl: null,
      callbackData: null,
      repositories: null,
      installationStatus: null,
      loading: false,
      error: null,
      success: null,
    },

    // GitHub State
    github: {
      installationStatus: null,
      repositories: null,
      loading: false,
      error: null,
      success: null,
    },

    // Jira
    jira: {
      installationStatus: null,
      loading: false,
      error: null,
      success: null,
    },

    ticketIntegration: {
      data: null,
      loading: false,
      error: null,
    },

    // Azure DevOps State
    azure: {
      installationStatus: null,
      loading: false,
      error: null,
      success: null,
    },
  },
  reducers: {
    resetGitLabState: (state) => {
      state.gitLab = {
        installationStatus: null,
        repositories: null,
        groups: null,
        loading: false,
        error: null,
        success: null,
      };
    },
    resetBitbucketState: (state) => {
      state.bitbucket = {
        authUrl: null,
        callbackData: null,
        repositories: null,
        installationStatus: null,
        loading: false,
        error: null,
        success: null,
      };
    },

    resetGitHubState: (state) => {
      state.github = {
        installationStatus: null,
        repositories: null,
        loading: false,
        error: null,
        success: null,
      };
    },

    resetJiraState: (state) => {
      state.jira = {
        installationStatus: null,
        loading: false,
        error: null,
        success: null,
      };
    },

    resetTicketIntegrationState: (state) => {
      state.ticketIntegration = {
        data: null,
        loading: false,
        error: null,
      };
    },

    resetAzureState: (state) => {
      state.azure = {
        installationStatus: null,
        loading: false,
        error: null,
        success: null,
      };
    },
  },
  extraReducers: (builder) => {
    // -------------------------------------------------------------------------
    // GITLAB
    // -------------------------------------------------------------------------
    builder
      // 1) Install GitLab
      .addCase(installGitLabAsync.pending, (state) => {
        state.gitLab.loading = true;
        state.gitLab.error = null;
        state.gitLab.success = null;
      })
      .addCase(installGitLabAsync.fulfilled, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.installationStatus = action.payload;
        state.gitLab.success = "GitLab installed successfully.";
        if (action.payload && action.payload.access_output) {
          state.gitLab.groups = action.payload.access_output;
        }
      })
      .addCase(installGitLabAsync.rejected, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.error = action.payload;
      })

      // 2) Fetch GitLab Repositories
      .addCase(fetchGitLabRepositoriesAsync.pending, (state) => {
        state.gitLab.loading = true;
        state.gitLab.error = null;
        state.gitLab.success = null;
      })
      .addCase(fetchGitLabRepositoriesAsync.fulfilled, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.repositories = action.payload;
        state.gitLab.success = "GitLab repositories fetched successfully.";
      })
      .addCase(fetchGitLabRepositoriesAsync.rejected, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.error = action.payload;
      });

    // -------------------------------------------------------------------------
    // BITBUCKET
    // -------------------------------------------------------------------------
    builder
      .addCase(initiateBitbucketInstallAsync.pending, (state) => {
        state.bitbucket.loading = true;
        state.bitbucket.error = null;
        state.bitbucket.success = null;
      })
      .addCase(initiateBitbucketInstallAsync.fulfilled, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.authUrl = action.payload.auth_url;
        state.bitbucket.success =
          "Bitbucket installation initiated successfully.";
      })
      .addCase(initiateBitbucketInstallAsync.rejected, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.error = action.payload;
      })

      .addCase(handleBitbucketCallbackAsync.pending, (state) => {
        state.bitbucket.loading = true;
        state.bitbucket.error = null;
        state.bitbucket.success = null;
      })
      .addCase(handleBitbucketCallbackAsync.fulfilled, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.callbackData = action.payload;
        state.bitbucket.success = "Bitbucket callback handled successfully.";
      })
      .addCase(handleBitbucketCallbackAsync.rejected, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.error = action.payload;
      })

      .addCase(fetchBitbucketRepositoriesAsync.pending, (state) => {
        state.bitbucket.loading = true;
        state.bitbucket.error = null;
        state.bitbucket.success = null;
      })
      .addCase(fetchBitbucketRepositoriesAsync.fulfilled, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.repositories = action.payload;
        state.bitbucket.success =
          "Bitbucket repositories fetched successfully.";
      })
      .addCase(fetchBitbucketRepositoriesAsync.rejected, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.error = action.payload;
      })

      .addCase(deleteBitbucketInstallationAsync.pending, (state) => {
        state.bitbucket.loading = true;
        state.bitbucket.error = null;
        state.bitbucket.success = null;
      })
      .addCase(deleteBitbucketInstallationAsync.fulfilled, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.installationStatus = action.payload;
        state.bitbucket.success =
          "Bitbucket installation deleted successfully.";
      })
      .addCase(deleteBitbucketInstallationAsync.rejected, (state, action) => {
        state.bitbucket.loading = false;
        state.bitbucket.error = action.payload;
      })

      // -----------------------------------------------------------------------------
      // GITHUB
      // -----------------------------------------------------------------------------

      .addCase(installGitHubAsync.pending, (state) => {
        state.github.loading = true;
        state.github.error = null;
        state.github.success = null;
      })
      .addCase(installGitHubAsync.fulfilled, (state, action) => {
        state.github.loading = false;
        state.github.installationStatus = action.payload;
        state.github.success = "GitHub installed successfully.";
        if (action.payload && action.payload.repositories) {
          state.github.repositories = action.payload.repositories;
        }
      })
      .addCase(installGitHubAsync.rejected, (state, action) => {
        state.github.loading = false;
        state.github.error = action.payload;
      })

      // 1) Install Jira
      .addCase(installJiraAsync.pending, (state) => {
        state.jira.loading = true;
        state.jira.error = null;
        state.jira.success = null;
      })
      .addCase(installJiraAsync.fulfilled, (state, action) => {
        state.jira.loading = false;
        state.jira.installationStatus = action.payload;
        state.jira.success = "Jira installed successfully.";
      })
      .addCase(installJiraAsync.rejected, (state, action) => {
        state.jira.loading = false;
        state.jira.error = action.payload;
      })

      // 2) Uninstall Jira
      .addCase(uninstallJiraAsync.pending, (state) => {
        state.jira.loading = true;
        state.jira.error = null;
        state.jira.success = null;
      })
      .addCase(uninstallJiraAsync.fulfilled, (state, action) => {
        state.jira.loading = false;
        state.jira.installationStatus = null;
        state.jira.success = "Jira uninstalled successfully.";
      })
      .addCase(uninstallJiraAsync.rejected, (state, action) => {
        state.jira.loading = false;
        state.jira.error = action.payload;
      })

      .addCase(getTicketIntegrationAsync.pending, (state) => {
        state.ticketIntegration.loading = true;
        state.ticketIntegration.error = null;
      })
      .addCase(getTicketIntegrationAsync.fulfilled, (state, action) => {
        state.ticketIntegration.loading = false;
        state.ticketIntegration.data = action.payload;
      })
      .addCase(getTicketIntegrationAsync.rejected, (state, action) => {
        state.ticketIntegration.loading = false;
        state.ticketIntegration.error = action.payload;
      });

    // -------------------------------------------------------------------------
    // AZURE DEVOPS
    // -------------------------------------------------------------------------
    builder
      .addCase(installAzureDevOpsAsync.pending, (state) => {
        state.azure.loading = true;
        state.azure.error = null;
        state.azure.success = null;
      })
      .addCase(installAzureDevOpsAsync.fulfilled, (state, action) => {
        state.azure.loading = false;
        state.azure.installationStatus = action.payload;
        state.azure.success = "Azure DevOps installed successfully.";
      })
      .addCase(installAzureDevOpsAsync.rejected, (state, action) => {
        state.azure.loading = false;
        state.azure.error = action.payload;
      })
      .addCase(deleteAzureDevOpsInstallationAsync.pending, (state) => {
        state.azure.loading = true;
        state.azure.error = null;
        state.azure.success = null;
      })
      .addCase(
        deleteAzureDevOpsInstallationAsync.fulfilled,
        (state, action) => {
          state.azure.loading = false;
          state.azure.installationStatus = null;
          state.azure.success =
            "Azure DevOps installation deleted successfully.";
        }
      )
      .addCase(deleteAzureDevOpsInstallationAsync.rejected, (state, action) => {
        state.azure.loading = false;
        state.azure.error = action.payload;
      });

    builder
      .addCase(deleteRepoInstallationAsync.pending, (state) => {
        state.repoInstallation.loading = true;
        state.repoInstallation.error = null;
        state.repoInstallation.success = null;
      })
      .addCase(deleteRepoInstallationAsync.fulfilled, (state, action) => {
        state.repoInstallation.loading = false;
        state.repoInstallation.deletionStatus = action.payload;
        state.repoInstallation.success =
          "Repository installation deleted successfully.";
      })
      .addCase(deleteRepoInstallationAsync.rejected, (state, action) => {
        state.repoInstallation.loading = false;
        state.repoInstallation.error = action.payload;
      });

    builder
      .addCase(updateGitLabInstallationAsync.pending, (state) => {
        state.gitLab.loading = true;
        state.gitLab.error = null;
        state.gitLab.success = null;
      })
      .addCase(updateGitLabInstallationAsync.fulfilled, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.installationStatus = action.payload;
        state.gitLab.success = "GitLab installation updated successfully.";
      })
      .addCase(updateGitLabInstallationAsync.rejected, (state, action) => {
        state.gitLab.loading = false;
        state.gitLab.error = action.payload;
      });
  },
});

export const {
  resetGitLabState,
  resetBitbucketState,
  resetGitHubState,
  resetJiraState,
  resetTicketIntegrationState,
  resetAzureState,
  resetRepoInstallationState,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;
