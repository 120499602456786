import axios from "axios";

const url = process.env.REACT_APP_API_URL;


export const fetchGroupsByOrgId = async (orgId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v2/organization/${orgId}/groups?org=${orgId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};


export const fetchGroupById = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};


export const updateGroupById = async (orgId, groupId, groupData, thunkAPI) => {
  try {
    const response = await axios.put(
      `${url}/v1/organization/${orgId}/group/${groupId}`,
      groupData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};


export const deleteGroupById = async (orgId, groupId, thunkAPI) => {
  try {
    await axios.delete(`${url}/v2/organization/${orgId}/group/${groupId}?org=${orgId}&group=${groupId}`, {
      withCredentials: true,
    });
    return groupId;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};


export const addGroup = async (orgId, groupData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group`,
      groupData,
      {
        withCredentials: true,
      }
    );
    return response.data.group;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};
