import React, { useState } from "react";
import {
  SimpleGrid,
  Spinner,
  Text,
  Center,
  Heading,
  Box,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import OrganizationCard from "../components/Orgs/OrganizationCard";
import CreateOrganizationModal from "../components/Orgs/CreateOrganizationModal";

const OrganizationsPage = () => {
  const { organizations, loading, error } = useSelector((state) => state.user);
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const primaryColor = "#005387";
  const hoverBgColor = useColorModeValue("#0077b5", "#0077b5");

  const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState(false);

  const openModal = () => setIsCreateOrgModalOpen(true);
  const closeModal = () => setIsCreateOrgModalOpen(false);

  if (loading) {
    return (
      <Center height="80vh">
        <Spinner size="lg" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center height="80vh">
        <Text color="red.500" fontSize="md">
          {error}
        </Text>
      </Center>
    );
  }

  return (
    <Box bg={bgColor} py={6} px={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Heading size="md" fontWeight="semibold">
          Your Organizations
        </Heading>
        <Button
          bg={primaryColor}
          color="white"
          _hover={{ bg: hoverBgColor }}
          variant="solid"
          onClick={openModal}
        >
          + New Organization
        </Button>
      </Box>

      {organizations.length === 0 ? (
        <Center>
          <Text fontSize="sm">No organizations found.</Text>
        </Center>
      ) : (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
          {organizations.map((org) => (
            <OrganizationCard key={org._id} organization={org} />
          ))}
        </SimpleGrid>
      )}

      <CreateOrganizationModal
        isOpen={isCreateOrgModalOpen}
        onClose={closeModal}
      />
    </Box>
  );
};

export default OrganizationsPage;
