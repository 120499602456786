import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
  Tag,
  Tooltip,
  Divider,
  Select,
  useColorModeValue,
  HStack,
  Link,
  SkeletonText,
  Avatar,
  Button,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { FaGithub, FaGitlab, FaFileArchive } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteProjectAsync, getProjectsAsync } from "../../features/Projects/ProjectAction";
import EditProjectModal from "./EditProjectModal";
import { resetScanDetails } from "../../features/Scans/ScansSlice";
import ProjectDataModal from "./ProjectDataModal";

const ProjectsTable = ({ orgId, groupId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

  const projectsFromStore = useSelector((state) => state.projects.projects) || [];
  const loading = useSelector((state) => state.projects.loading);
  const error = useSelector((state) => state.projects.error);

  const cachedProjectsRef = useRef([]);

  useEffect(() => {
    if (!loading && projectsFromStore.length > 0) {
      cachedProjectsRef.current = projectsFromStore;
    }
  }, [loading, projectsFromStore]);

  const displayedProjects =
    loading && cachedProjectsRef.current.length > 0
      ? cachedProjectsRef.current
      : projectsFromStore;

  const safeDisplayedProjects = Array.isArray(displayedProjects)
    ? displayedProjects
    : [];

  const filteredProjects = safeDisplayedProjects.filter((project) =>
    project.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalFilteredProjects = filteredProjects.length;
  const totalPages = Math.ceil(totalFilteredProjects / limit) || 1;
  const paginatedProjects = filteredProjects.slice((page - 1) * limit, page * limit);

  // Responsive font sizes
  const fontSizeXs = useBreakpointValue({ base: "xs", md: "xs" });
  const fontSizeSm = useBreakpointValue({ base: "xs", md: "sm" });

  const handleDelete = (projectId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      dispatch(deleteProjectAsync({ orgId, groupId, projectId }))
        .unwrap()
        .then(() => {
          dispatch(getProjectsAsync({ orgId, groupId }));
          toast({
            title: "Project Deleted",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: "Deletion Failed",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const handleEdit = (project) => {
    setSelectedProject(project);
    setIsEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedProject(null);
    setIsEditOpen(false);
  };

  const handleProjectNameClick = (project) => {
    setSelectedProject(project);
    setIsProjectModalOpen(true);
  };


  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      border="1px"
      borderColor={useColorModeValue("gray.300", "gray.600")}
      p={4}
      borderRadius="lg"
      shadow="sm"
    >

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Flex mb={{ base: 2, md: 0 }}>
          <Input
            placeholder="Search projects"
            size="sm"
            width="200px"
            bg={useColorModeValue("gray.50", "gray.700")}
            borderColor={useColorModeValue("gray.200", "gray.600")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mr={2}
            borderRadius="md"
          />
          <Tooltip label="Search">
            <IconButton
              icon={<SearchIcon />}
              aria-label="Search"
              size="sm"
              variant="ghost"
            />
          </Tooltip>
        </Flex>
        <HStack spacing={2}>
          <Select
            size="sm"
            width="120px"
            bg={useColorModeValue("gray.50", "gray.700")}
            borderColor={useColorModeValue("gray.200", "gray.600")}
            value={limit}
            onChange={(e) => handleLimitChange(Number(e.target.value))}
            borderRadius="md"
          >
            <option value={20}>20 / page</option>
            <option value={30}>30 / page</option>
            <option value={50}>50 / page</option>
          </Select>
        </HStack>
      </Flex>

      {error && (
        <Flex justifyContent="center" alignItems="center" my={4}>
          <Text color="red.500" fontSize={fontSizeSm}>
            Error: {typeof error === "object" ? error.message : error}
          </Text>
        </Flex>
      )}

      {loading && projectsFromStore.length === 0 ? (
        <SkeletonText mt="4" noOfLines={10} spacing="4" />
      ) : (
        <Box overflowX="auto">
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead bg={useColorModeValue("gray.200", "gray.700")}>
              <Tr>
                <Th fontSize={fontSizeXs}>Name</Th>
                <Th fontSize={fontSizeXs}>Status</Th>
                <Th fontSize={fontSizeXs}>Source Code</Th>
                <Th fontSize={fontSizeXs}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedProjects.length === 0 ? (
                <Tr>
                  <Td colSpan={7} textAlign="center" fontSize={fontSizeSm}>
                    No projects found.
                  </Td>
                </Tr>
              ) : (
                paginatedProjects.map((project) => (
                  <Tr
                    key={project._id}
                    _hover={{ bg: useColorModeValue("gray.50", "gray.700") }}
                  >
                    <Td fontSize={fontSizeXs}>
                      <Flex alignItems="center">
                        <Avatar name={project.name} size="xs" mr={2} />
                        <Text
                          as="button"
                          color="blue.500"
                          textDecoration="underline"
                          onClick={() => handleProjectNameClick(project)}
                          cursor="pointer"
                          fontSize={fontSizeXs}
                        >
                          {project.name}
                        </Text>
                      </Flex>
                    </Td>
                    <Td fontSize={fontSizeXs}>
                      <Flex alignItems="center">
                        <Text>{project.frequency}</Text>
                        <Tag
                          size="sm"
                          colorScheme={project.scanning ? "green" : "yellow"}
                          borderRadius="md"
                          ml={2}
                        >
                          {project.scanning ? "Active" : "Paused"}
                        </Tag>
                      </Flex>
                    </Td>
                    <Td fontSize={fontSizeXs}>
                      {project.git_uri?.includes("github.com") ||
                      project.git_uri?.includes("gitlab.com") ? (
                        <Link href={project.git_uri} isExternal>
                          <Flex
                            alignItems="center"
                            color={
                              project.git_uri.includes("github.com")
                                ? "blue.500"
                                : "orange.500"
                            }
                          >
                            {project.git_uri.includes("github.com") ? (
                              <FaGithub />
                            ) : (
                              <FaGitlab />
                            )}
                            <Text ml={1} fontSize={fontSizeXs}>
                              View
                            </Text>
                          </Flex>
                        </Link>
                      ) : project.git_uri?.includes(".zip") ? (
                        <Flex alignItems="center">
                          <FaFileArchive />
                          <Text ml={1} fontSize={fontSizeXs}>
                            File
                          </Text>
                        </Flex>
                      ) : (
                        <Text fontSize={fontSizeXs}>Source Code</Text>
                      )}
                    </Td>
                    <Td fontSize={fontSizeXs}>
                      <Tooltip label="Edit">
                        <IconButton
                          size="xs"
                          icon={<EditIcon />}
                          variant="ghost"
                          colorScheme="blue"
                          mr={2}
                          onClick={() => handleEdit(project)}
                          aria-label="Edit Project"
                        />
                      </Tooltip>
                      <Tooltip label="Delete">
                        <IconButton
                          size="xs"
                          icon={<DeleteIcon />}
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => handleDelete(project._id)}
                          aria-label="Delete Project"
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>
      )}

      <Divider my={4} />
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize={fontSizeXs}>
          Showing {paginatedProjects.length} of {totalFilteredProjects} projects
        </Text>
        <Flex alignItems="center">
          <IconButton
            size="xs"
            aria-label="Previous Page"
            icon={<ChevronLeftIcon />}
            variant="outline"
            mr={2}
            isDisabled={page === 1}
            onClick={() => handlePageChange(page - 1)}
          />
          <Text fontSize={fontSizeXs} mx={2}>
            Page {page} of {totalPages}
          </Text>
          <IconButton
            size="xs"
            aria-label="Next Page"
            icon={<ChevronRightIcon />}
            variant="outline"
            isDisabled={page === totalPages}
            onClick={() => handlePageChange(page + 1)}
          />
        </Flex>
      </Flex>

      {selectedProject && isEditOpen && (
        <EditProjectModal
          isOpen={isEditOpen}
          onClose={handleCloseEditModal}
          project={selectedProject}
          orgId={orgId}
          groupId={groupId}
        />
      )}

      {selectedProject && isProjectModalOpen && (
        <ProjectDataModal
          isOpen={isProjectModalOpen}
          onClose={() => {
            setIsProjectModalOpen(false);
            setSelectedProject(null);
          }}
          projectId={selectedProject._id}
          orgId={orgId}
          groupId={groupId}
          projectName={selectedProject.name}
          repoUrl={selectedProject.git_uri}
        />
      )}
    </Box>
  );
};

export default ProjectsTable;
