import React from "react";
import { Box, Flex, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import PieChartWithPaddingAngle from "./PieChartWithPaddingAngle";
import BubbleChart from "./TrueFalsePositiveChart";
import FindingsByScannerChart from "./FindingsByScannerChart";

const AllCharts = ({ cweData, tfpData, scannerData }) => {
  const chartWidth = useBreakpointValue({ base: 180, md: 200 });
  const chartHeight = useBreakpointValue({ base: 140, md: 150 });

  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box
      bg={cardBg}
      p={4}
      rounded="md"
      borderWidth="1px"
      borderColor={borderColor}
      mb={4}
    >
      <Flex
        justify="space-between"
        align="flex-start"
        wrap="wrap"
        gap={4}
      >
        {/* Pie Chart */}
        <Box>
          <PieChartWithPaddingAngle
            data={cweData}
            width={chartWidth}
            height={chartHeight}
          />
        </Box>

        {/* Bubble Chart */}
        <Box>
          <BubbleChart data={tfpData} width={chartWidth} height={chartHeight} />
        </Box>

        {/* Findings by Scanner Chart */}
        <Box>
          <FindingsByScannerChart
            width={chartWidth}
            height={chartHeight}
            scannerData={scannerData}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default AllCharts;
