import React from "react";
import {
  Box,
  Badge,
  Text,
  Link,
  VStack,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const SqlFindingsCard = ({ data }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.400", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Box
      borderRadius="md"
      borderColor={borderColor}
      bg={bgColor}
      p={2}
      fontSize="xs" 
      color={textColor}
      overflow="hidden"
    >
      <VStack align="start" spacing={1}>
        <HStack>
          <Text fontWeight="bold">Project:</Text>
          <Link href={data.project_link} color="blue.300" isExternal fontSize="xs">
            {data.project} <ExternalLinkIcon mx="1px" />
          </Link>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Repository:</Text>
          <Link href={data.repository} color="blue.300" isExternal fontSize="xs">
            View Repo <ExternalLinkIcon mx="1px" />
          </Link>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Scan:</Text>
          <Link href={data.scan_link} color="blue.300" isExternal fontSize="xs">
            {data.scan_id} <ExternalLinkIcon mx="1px" />
          </Link>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Branch:</Text>
          <Text fontSize="xs">{data.branch}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Commit:</Text>
          <Text isTruncated maxW="120px" fontSize="xs">
            {data.commit}
          </Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Findings:</Text>
          <Badge colorScheme="green" fontSize="0.65rem">
            {data.total_findings} Total
          </Badge>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Initiated By:</Text>
          <Text fontSize="xs">{data.initiator}</Text>
        </HStack>
        <HStack wrap="wrap" spacing={1}>
          {data.tags.split(",").map((tag) => (
            <Badge key={tag.trim()} colorScheme="blue" fontSize="0.65rem">
              {tag.trim()}
            </Badge>
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

export default SqlFindingsCard;
