import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  useColorModeValue,
  Flex,
  Avatar,
  Button,
  IconButton,
  useToast,
  Link,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { FiThumbsUp, FiThumbsDown, FiCopy } from "react-icons/fi";
import { FaExclamationTriangle } from "react-icons/fa";
import SqlFindingsCard from "./SqlFindingsCard";
import RunningScanCard from "./RunningScanCard";
import QnACard from "./QnACard";
import PaginatedCards from "./PaginatedCards";
import DataTable from "./Datatable";
import Logo from "../../assets/images/aquilax.avif"
import botImage from "../../assets/images/aquilax.avif";
import MarkdownSummary from "../Report/MarkdownSummary";
import { jsPDF } from "jspdf";

function stripMarkdown(markdown) {
  return markdown
    .replace(/(\*\*|__)(.*?)\1/g, "$2")
    .replace(/(\*|_)(.*?)\1/g, "$2")
    .replace(/`(.*?)`/g, "$1")
    .replace(/!\[(.*?)\]\((.*?)\)/g, "$1")
    .replace(/\[(.*?)\]\((.*?)\)/g, "$1")
    .replace(/^\s*[-*+]\s+/gm, "")
    .replace(/#+\s*(.*)/g, "$1")
    .trim();
}

const Typewriter = ({ text, speed = 10, initialDelay = 0, onComplete }) => {
  const [displayed, setDisplayed] = useState("");
  useEffect(() => {
    let i = 0;
    let interval;
    const delayTimeout = setTimeout(() => {
      interval = setInterval(() => {
        i++;
        setDisplayed(text.slice(0, i));
        if (i === text.length) {
          clearInterval(interval);
          if (onComplete) onComplete();
        }
      }, speed);
    }, initialDelay);
    return () => {
      clearTimeout(delayTimeout);
      if (interval) clearInterval(interval);
    };
  }, [text, speed, initialDelay, onComplete]);
  return <span>{displayed}</span>;
};

const DashboardMessage = ({
  msg,
  isTyping,
  onComplete,
  primaryColor,
  textColor,
  botBg,
}) => {
  return (
    <Box
      bg={botBg}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      px={6}
      py={3}
      maxWidth="65%"
      boxShadow="sm"
    >
      <Flex align="center">
        {msg.type === "dashboard-alert" && (
          <FaExclamationTriangle
            size={16}
            color={useColorModeValue("orange.500", "orange.300")}
            style={{ marginRight: "8px" }}
          />
        )}
        <Box fontSize="sm" mr={3} color={textColor}>
          {isTyping ? (
            <Typewriter
              text={msg.text}
              speed={20}
              initialDelay={msg.id === "dashboard-welcome" ? 700 : 0}
              onComplete={onComplete}
            />
          ) : (
            msg.text
          )}
        </Box>
        {!isTyping && msg.link && msg.button_text ? (
          <Button
            size="xs"
            variant="outline"
            color={primaryColor}
            borderColor={primaryColor}
            _hover={{
              bg: useColorModeValue("blue.100", "blue.600"),
              borderColor: useColorModeValue("blue.600", "blue.900"),
            }}
            onClick={() => window.open(msg.link, "_blank")}
          >
            {msg.button_text}
          </Button>
        ) : null}
      </Flex>
    </Box>
  );
};

const ChatMessagesSection = ({
  messages,
  userDetails,
  orgDetails,
  gitIntegrations,
  intelligenceData,
  loading,
  sessionId
}) => {
  const userBg = useColorModeValue("blue.200", "blue.700");
  const botBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.50");
  const primaryColor = useColorModeValue("blue.600", "#005399");

  const dashboardMessages = messages.filter(
    (msg) =>
      msg.type === "dashboard-welcome" || msg.type === "dashboard-alert"
  );
  const otherMessages = messages.filter(
    (msg) =>
      msg.type !== "dashboard-welcome" && msg.type !== "dashboard-alert"
  );
  const [currentDashboardIndex, setCurrentDashboardIndex] = useState(0);
  const toast = useToast();
  const [feedback, setFeedback] = useState({});

  const onDashboardComplete = useCallback(() => {
    setCurrentDashboardIndex((prev) => prev + 1);
  }, []);

  const handleDownloadPDF = (msg) => {
    const doc = new jsPDF();
    const content =
      typeof msg.payload === "string"
        ? msg.payload
        : msg.text || "No content available.";
    const plainText = stripMarkdown(content);
    const lines = doc.splitTextToSize(plainText, 180);
    doc.text(lines, 10, 10);
    doc.save(`${sessionId || "summary_aquilax"}.pdf`);
  };

  const getQuestionForBotMessage = (botMsg) => {
    const index = messages.findIndex((m) => m.id === botMsg.id);
    if (index > 0) {
      for (let i = index - 1; i >= 0; i--) {
        if (messages[i].sender === "user") {
          return messages[i].text;
        }
      }
    }
    return "";
  };

  const handleFeedback = (msg, feedbackType) => {
    if (feedback[msg.id]) return;
    setFeedback((prev) => ({
      ...prev,
      [msg.id]: feedbackType,
    }));
    const question = getQuestionForBotMessage(msg);
    const response =
      typeof msg.payload === "string" ? msg.payload : msg.text || "";
    const model = ["qna", "sql", "tags", "security_assistant"].includes(
      msg.type
    )
      ? msg.type
      : "security_assistant";
    fetch("/api/v1/ai/securitron-feedback-by-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question,
        response,
        feedback: feedbackType === "up",
        model,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Feedback API call failed");
        }
        return res.json();
      })
      .then((data) => {})
      .catch((err) => {
        console.error("Feedback error:", err);
      });
  };

  const handleCopy = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text).then(() => {});
  };

  const renderMessage = (msg) => {
    const isUser = msg.sender === "user";
    const isBot = msg.sender === "bot";
    const isFinished =
      msg.type !== "streaming" ||
      (!loading && msg.type === "streaming") ||
      msg.isFinished;

    let shouldShowPdfButton = false;
    let pdfButtonText = "Download PDF";
  
    if (isBot) {
      const content =
        typeof msg.payload === "string" ? msg.payload : msg.text || "";
  
      if (msg.headers && msg.headers["x-aquilax-downstream"]) {
        try {
          const downstream = JSON.parse(msg.headers["x-aquilax-downstream"]);
          if (downstream.tag === "REPORT EXECUTIVE SUMMARY") {
            shouldShowPdfButton = true;
            pdfButtonText = "Download PDF";
          }
        } catch (error) {
          console.error("Error parsing x-aquilax-downstream header", error);
        }
      }
    }

    return (
      <Flex direction="column" key={msg.id} mb={2}>
        <Flex justify={isUser ? "flex-end" : "flex-start"} align="flex-start">
          {!isUser && <Avatar src={botImage} size="sm" mr={2} />}
          <Box
            bg={isUser ? userBg : botBg}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            px={6}
            py={2}
            maxWidth="65%"
            fontSize="sm"
            color={textColor}
            boxShadow="sm"
          >
            {renderCard(msg)}
          </Box>
          {isUser && (
            <Avatar name="You" src={userDetails?.picture} size="sm" ml={2} />
          )}
        </Flex>
        {isBot && msg.type !== "prompts" && isFinished && !msg.hideFeedback && (
          <Flex mt={2} justify="flex-start" gap={2} pl="2.5rem">
            <IconButton
              size="xs"
              aria-label="Thumbs Up"
              icon={<FiThumbsUp />}
              variant={feedback[msg.id] === "up" ? "solid" : "outline"}
              colorScheme="gray"
              disabled={!!feedback[msg.id]}
              onClick={() => handleFeedback(msg, "up")}
            />
            <IconButton
              size="xs"
              aria-label="Thumbs Down"
              icon={<FiThumbsDown />}
              variant={feedback[msg.id] === "down" ? "solid" : "outline"}
              colorScheme="gray"
              disabled={!!feedback[msg.id]}
              onClick={() => handleFeedback(msg, "down")}
            />
            <IconButton
              size="xs"
              aria-label="Copy"
              icon={<FiCopy />}
              onClick={() => {
                const copyText =
                  typeof msg.payload === "string"
                    ? msg.payload
                    : msg.text || "";
                handleCopy(copyText);
              }}
            />
            {shouldShowPdfButton && (
              <Button
                size="xs"
                variant="outline"
                colorScheme="blue"
                onClick={() => handleDownloadPDF(msg)}
              >
                {pdfButtonText}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    );
  };

  const renderCard = (msg) => {
    switch (msg.type) {
      case "sql":
        if (Array.isArray(msg.payload)) {
          const firstItem = msg.payload[0];
          const isSimpleTableFormat =
            firstItem &&
            typeof firstItem === "object" &&
            ((firstItem.hasOwnProperty("project") &&
              firstItem.hasOwnProperty("branch") &&
              Object.keys(firstItem).length === 2) ||
              (firstItem.hasOwnProperty("org") &&
                firstItem.hasOwnProperty("group") &&
                Object.keys(firstItem).length === 2));
          if (isSimpleTableFormat) {
            return (
              <DataTable
                data={msg.payload}
                columns={
                  firstItem.hasOwnProperty("project")
                    ? [
                        { header: "Project", accessor: "project" },
                        { header: "Branch", accessor: "branch" },
                      ]
                    : [
                        { header: "Organization", accessor: "org" },
                        { header: "Group", accessor: "group" },
                      ]
                }
                itemsPerPage={4}
              />
            );
          } else {
            return (
              <PaginatedCards
                items={msg.payload}
                itemsPerPage={1}
                renderItem={(scan) => <SqlFindingsCard data={scan} />}
              />
            );
          }
        } else if (typeof msg.payload === "string") {
          return renderMarkdown(msg.payload);
        } else {
          return renderMarkdown("Error generating response.");
        }
      case "scan":
        return <RunningScanCard data={msg.payload} />;
      case "qna":
        return <QnACard answer={msg.payload} />;
      case "tags":
        return <QnACard answer={msg.payload} />;
      case "streaming":
        return renderMarkdown(msg.payload);
      default:
        return renderMarkdown(msg.text);
    }
  };

  const renderMarkdown = (text) => (
    <MarkdownSummary
      text={text || ""}
      textColor={textColor}
      fontSize="sm"
      headingColor={primaryColor}
      showReadMore={false}
    />

  );

  return (
    <VStack spacing={4} align="stretch">
      {dashboardMessages.map((msg, idx) => {
        if (idx < currentDashboardIndex) {
          return (
            <Flex key={msg.id} align="flex-start" mb={2}>
              <Avatar
                src={Logo}
                size="sm"
                mr={2}
              />
              <Box
                bg={botBg}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="md"
                px={4}
                py={3}
                maxWidth="65%"
                fontSize="sm"
                color={textColor}
                boxShadow="sm"
              >
                <Flex align="center">
                  {msg.type === "dashboard-alert" && (
                    <FaExclamationTriangle
                      size={16}
                      color={useColorModeValue("orange.500", "orange.300")}
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  <Box fontSize="sm" mr={3}>
                    {msg.text}
                  </Box>
                  {msg.link && msg.button_text ? (
                    <Button
                      size="xs"
                      variant="outline"
                      color={primaryColor}
                      borderColor={primaryColor}
                      _hover={{
                        bg: useColorModeValue("blue.100", "blue.600"),
                        borderColor: useColorModeValue("blue.600", "blue.900"),
                      }}
                      onClick={() => window.open(msg.link, "_blank")}
                    >
                      {msg.button_text}
                    </Button>
                  ) : null}
                </Flex>
              </Box>
            </Flex>
          );
        } else if (idx === currentDashboardIndex) {
          return (
            <Flex key={msg.id} align="flex-start" mb={2}>
              <Avatar
                src={Logo}
                size="sm"
                mr={2}
              />
              <DashboardMessage
                msg={msg}
                isTyping={true}
                primaryColor={primaryColor}
                textColor={textColor}
                botBg={botBg}
                onComplete={onDashboardComplete}
              />
            </Flex>
          );
        } else {
          return null;
        }
      })}
      {currentDashboardIndex >= dashboardMessages.length &&
        otherMessages.map((msg) => renderMessage(msg))}
    </VStack>
  );
};

export default ChatMessagesSection;
