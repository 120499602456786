import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUpgrade, confirmBilling, stripeWebhook } from "../../apis/plans";

export const fetchUpgradeAsync = createAsyncThunk(
    "upgrade/fetchUpgrade",
    async ({ orgId }, thunkAPI) => {
      const response = await fetchUpgrade({ orgId }, thunkAPI);
      return response;
    }
  );

export const confirmBillingAsync = createAsyncThunk(
  "plans/confirmBilling",
  async ({ orgId, sid }, thunkAPI) => {
    const response = await confirmBilling({ orgId, sid }, thunkAPI);
    return response;
  }
);

export const stripeWebhookAsync = createAsyncThunk(
  "plans/stripeWebhook",
  async ({ reqid, stripeSignature }, thunkAPI) => {
    const response = await stripeWebhook({ reqid, stripeSignature }, thunkAPI);
    return response;
  }
);
