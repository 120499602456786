import React from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  Badge,
  VStack,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { toSvg } from "jdenticon";

const OrganizationCard = ({ organization }) => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  
  const handleClick = () => {
    navigate(`/organization/${organization._id}`);
  };

  const generateIdenticon = (value, size = 40) => {
    const svg = toSvg(value, size);
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };

  const avatarSrc = organization.org_pic
    ? organization.org_pic
    : generateIdenticon(organization.name, 80);

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      cursor="pointer"
      onClick={handleClick}
      _hover={{ bg: hoverBg, transform: "scale(1.03)", transition: "all 0.2s" }}
      p={3}
    >
      <Flex direction="column" align="center" textAlign="center">
        <Image
          src={avatarSrc}
          alt={`${organization.name} logo`}
          boxSize="60px"
          objectFit="cover"
          borderRadius="full"
          mb={3}
        />
        <VStack spacing={1} align="stretch" width="100%">
          <Text fontWeight="medium" fontSize="sm" noOfLines={1}>
            {organization.name}
          </Text>
          <Text fontSize="xs" color="gray.500" noOfLines={2}>
            {organization.description}
          </Text>
          <HStack justify="center" spacing={1} mt={1}>
            <Badge fontSize="0.6em" colorScheme="teal">
              {organization.plan}
            </Badge>
            <Badge fontSize="0.6em" colorScheme="purple">
              {organization.usage}
            </Badge>
          </HStack>
          <Text fontSize="xs" color="gray.600" mt={1}>
            Licenses: {organization.used_licenses}/{organization.total_licenses}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default OrganizationCard;
