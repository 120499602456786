export const parseGitUri = (gitUri) => {
    try {
      const url = new URL(gitUri);
      const hostname = url.hostname.toLowerCase();
  
      const pathname = url.pathname.replace(/\.git$/, '');
  
      const pathSegments = pathname.split('/').filter(segment => segment);
  
      if (pathSegments.length < 2) {
        throw new Error('Invalid git URI format.');
      }
  
      const owner = pathSegments[0];
      const repo = pathSegments[1];
  
      return {
        hostname,
        owner,
        repo,
      };
    } catch (error) {
      console.error('Error parsing git URI:', error);
      return null;
    }
  };
  
  const SPECIAL_PATHS = ['SECURITY.md', 'LICENSE', 'README.md'];
  
  export const constructRepoURL = ({ gitUri, commitSha, path, lineNumber }) => {
    const parsed = parseGitUri(gitUri);
  
    if (!parsed) return '#';
  
    const { hostname, owner, repo } = parsed;
  
    const isSpecialPath = SPECIAL_PATHS.some(
      specialPath => specialPath.toLowerCase() === path.toLowerCase()
    );
  
    let url = '#';
  
    switch (hostname) {
      case 'github.com':
        if (isSpecialPath) {
          url = `https://github.com/${owner}/${repo}`;
        } else {
          url = `https://github.com/${owner}/${repo}/blob/${commitSha}${path}`;
          if (lineNumber && lineNumber > 0) {
            url += `#L${lineNumber}`;
          }
        }
        break;
  
      case 'gitlab.com':
        if (isSpecialPath) {
          url = `https://gitlab.com/${owner}/${repo}`;
        } else {
          url = `https://gitlab.com/${owner}/${repo}/-/blob/${commitSha}${path}`;
          if (lineNumber && lineNumber > 0) {
            url += `#L${lineNumber}`;
          }
        }
        break;
  
      case 'bitbucket.org':
        if (isSpecialPath) {
          url = `https://bitbucket.org/${owner}/${repo}`;
        } else {
          url = `https://bitbucket.org/${owner}/${repo}/src/${commitSha}${path}`;
          if (lineNumber && lineNumber > 0) {
            url += `#lines-${lineNumber}`;
          }
        }
        break;
  
      case 'dev.azure.com':
      case 'azure.com':
        if (isSpecialPath) {
          // Azure Repos main page URL pattern
          url = `https://dev.azure.com/${owner}/${repo}/_git/${repo}`;
        } else {
          // Azure Repos file URL pattern
          url = `https://dev.azure.com/${owner}/${repo}/_git/${repo}?path=${path}`;
          if (lineNumber && lineNumber > 0) {
            url += `&line=${lineNumber}`;
          }
        }
        break;
  
      default:
        console.warn(`Unsupported hosting service: ${hostname}`);
        url = '#';
    }
  
    return url;
  };
  