import { createSlice } from "@reduxjs/toolkit";
import {
  updateOrganizationAsync,
  createOrganizationAsync,
  deleteOrganizationAsync,
  inviteMemberAsync,
  manageInvitationAsync,
  removeMemberAsync,
  manageMembershipAsync,
  leaveOrganizationAsync,
  applyLicenseAsync,
} from "./OrgsAction";

const orgsSlice = createSlice({
  name: "orgs",
  initialState: {
    organization: null,
    myProfile: null,
    loading: false,
    error: null,
    updateLoading: false,
    updateError: null,
    updateSuccess: false,
    createLoading: false,
    createError: null,
    createSuccess: false,
    deleteLoading: false,
    deleteError: null,
    deleteSuccess: false,
    inviteLoading: false,
    inviteError: null,
    inviteSuccess: false,
    manageInvitationLoading: false,
    manageInvitationError: null,
    manageInvitationSuccess: false,
    removeMemberLoading: false,
    removeMemberError: null,
    removeMemberSuccess: false,
    manageMembershipLoading: false,
    manageMembershipError: null,
    manageMembershipSuccess: false,
    leaveLoading: false,
    leaveError: null,
    leaveSuccess: false,
    applyLicenseLoading: false,
    applyLicenseError: null,
    applyLicenseSuccess: false,
  },
  reducers: {
    resetOrganizationState: (state) => {
      state.organization = null;
      state.myProfile = null;
      state.loading = false;
      state.error = null;
    },
    resetCreateState: (state) => {
      state.createLoading = false;
      state.createError = null;
      state.createSuccess = false;
    },
    resetUpdateState: (state) => {
      state.updateLoading = false;
      state.updateError = null;
      state.updateSuccess = false;
    },
    resetDeleteState: (state) => {
      state.deleteLoading = false;
      state.deleteError = null;
      state.deleteSuccess = false;
    },
    resetInviteState: (state) => {
      state.inviteLoading = false;
      state.inviteError = null;
      state.inviteSuccess = false;
    },
    resetManageInvitationState: (state) => {
      state.manageInvitationLoading = false;
      state.manageInvitationError = null;
      state.manageInvitationSuccess = false;
    },
    resetRemoveMemberState: (state) => {
      state.removeMemberLoading = false;
      state.removeMemberError = null;
      state.removeMemberSuccess = false;
    },
    resetManageMembershipState: (state) => {
      state.manageMembershipLoading = false;
      state.manageMembershipError = null;
      state.manageMembershipSuccess = false;
    },
    resetLeaveState: (state) => {
      state.leaveLoading = false;
      state.leaveError = null;
      state.leaveSuccess = false;
    },
    resetApplyLicenseState: (state) => {
      state.applyLicenseLoading = false;
      state.applyLicenseError = null;
      state.applyLicenseSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // Removed getOrganizationDetailsAsync reducers

    builder
      .addCase(createOrganizationAsync.pending, (state) => {
        state.createLoading = true;
        state.createError = null;
        state.createSuccess = false;
      })
      .addCase(createOrganizationAsync.fulfilled, (state, action) => {
        state.createLoading = false;
        state.createSuccess = true;
        state.organization = action.payload;
      })
      .addCase(createOrganizationAsync.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.payload;
      });

    builder
      .addCase(updateOrganizationAsync.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
        state.updateSuccess = false;
      })
      .addCase(updateOrganizationAsync.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.updateSuccess = true;
        state.organization = { ...state.organization, ...action.payload.org };
      })
      .addCase(updateOrganizationAsync.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.payload;
      });

    builder
      .addCase(deleteOrganizationAsync.pending, (state) => {
        state.deleteLoading = true;
        state.deleteError = null;
        state.deleteSuccess = false;
      })
      .addCase(deleteOrganizationAsync.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.deleteSuccess = true;
        state.organization = null;
        state.myProfile = null;
      })
      .addCase(deleteOrganizationAsync.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteError = action.payload;
      });

    builder
      .addCase(inviteMemberAsync.pending, (state) => {
        state.inviteLoading = true;
        state.inviteError = null;
        state.inviteSuccess = false;
      })
      .addCase(inviteMemberAsync.fulfilled, (state, action) => {
        state.inviteLoading = false;
        state.inviteSuccess = true;
        state.organization = action.payload;
        if (state.organization && state.organization.users) {
          state.organization.users.push(action.payload.user);
        }
      })
      .addCase(inviteMemberAsync.rejected, (state, action) => {
        state.inviteLoading = false;
        state.inviteError = action.payload;
      });

    builder
      .addCase(manageInvitationAsync.pending, (state) => {
        state.manageInvitationLoading = true;
        state.manageInvitationError = null;
        state.manageInvitationSuccess = false;
      })
      .addCase(manageInvitationAsync.fulfilled, (state, action) => {
        state.manageInvitationLoading = false;
        state.manageInvitationSuccess = true;
      })
      .addCase(manageInvitationAsync.rejected, (state, action) => {
        state.manageInvitationLoading = false;
        state.manageInvitationError = action.payload;
      });

    builder
      .addCase(removeMemberAsync.pending, (state) => {
        state.removeMemberLoading = true;
        state.removeMemberError = null;
        state.removeMemberSuccess = false;
      })
      .addCase(removeMemberAsync.fulfilled, (state, action) => {
        state.removeMemberLoading = false;
        state.removeMemberSuccess = true;
        if (state.organization && state.organization.users) {
          state.organization.users = state.organization.users.filter(
            (member) => member.email !== action.payload.member_email
          );
        }
      })
      .addCase(removeMemberAsync.rejected, (state, action) => {
        state.removeMemberLoading = false;
        state.removeMemberError = action.payload;
      });

    builder
      .addCase(manageMembershipAsync.pending, (state) => {
        state.manageMembershipLoading = true;
        state.manageMembershipError = null;
        state.manageMembershipSuccess = false;
      })
      .addCase(manageMembershipAsync.fulfilled, (state, action) => {
        state.manageMembershipLoading = false;
        state.manageMembershipSuccess = true;
        if (state.organization && state.organization.users) {
          const memberIndex = state.organization.users.findIndex(
            (member) => member.email === action.payload.member_email
          );
          if (memberIndex !== -1) {
            state.organization.users[memberIndex].role = action.payload.new_role;
          }
        }
      })
      .addCase(manageMembershipAsync.rejected, (state, action) => {
        state.manageMembershipLoading = false;
        state.manageMembershipError = action.payload;
      });

    builder
      .addCase(leaveOrganizationAsync.pending, (state) => {
        state.leaveLoading = true;
        state.leaveError = null;
        state.leaveSuccess = false;
      })
      .addCase(leaveOrganizationAsync.fulfilled, (state, action) => {
        state.leaveLoading = false;
        state.leaveSuccess = true;
        state.organization = null;
        state.myProfile = null;
      })
      .addCase(leaveOrganizationAsync.rejected, (state, action) => {
        state.leaveLoading = false;
        state.leaveError = action.payload;
      });

    builder
      .addCase(applyLicenseAsync.pending, (state) => {
        state.applyLicenseLoading = true;
        state.applyLicenseError = null;
        state.applyLicenseSuccess = false;
      })
      .addCase(applyLicenseAsync.fulfilled, (state, action) => {
        state.applyLicenseLoading = false;
        state.applyLicenseSuccess = true;
      })
      .addCase(applyLicenseAsync.rejected, (state, action) => {
        state.applyLicenseLoading = false;
        state.applyLicenseError = action.payload;
      });
  },
});

export const {
  resetOrganizationState,
  resetUpdateState,
  resetCreateState,
  resetDeleteState,
  resetInviteState,
  resetManageInvitationState,
  resetRemoveMemberState,
  resetManageMembershipState,
  resetLeaveState,
  resetApplyLicenseState,
} = orgsSlice.actions;

export default orgsSlice.reducer;
