import { createSlice } from "@reduxjs/toolkit";
import {
  getIntellDataAsync,
  getGitIntegrationsAsync,
  getReportAsync,
  getRunnersAsync,
} from "./AnalyticsAction";

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    intellData: null,
    loading: false,
    error: null,
    success: null,

    gitIntegrations: null,
    gitLoading: false,
    gitError: null,
    gitSuccess: null,

    report: null,
    reportLoading: false,
    reportError: null,
    reportSuccess: null,

    runners: null,
    runnersLoading: false,
    runnersError: null,
    runnersSuccess: null,
  },
  reducers: {
    resetAnalyticsState: (state) => {
      state.intellData = null;
      state.loading = false;
      state.error = null;
      state.success = null;
    },

    resetGitIntegrationsState: (state) => {
      state.gitIntegrations = null;
      state.gitLoading = false;
      state.gitError = null;
      state.gitSuccess = null;
    },

    resetReportState: (state) => {
      state.report = null;
      state.reportLoading = false;
      state.reportError = null;
      state.reportSuccess = null;
    },

    resetRunnersState: (state) => {
      state.runners = null;
      state.runnersLoading = false;
      state.runnersError = null;
      state.runnersSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntellDataAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(getIntellDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.intellData = action.payload;
        state.success = "Intelligence data fetched successfully.";
      })
      .addCase(getIntellDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getGitIntegrationsAsync.pending, (state) => {
        state.gitLoading = true;
        state.gitError = null;
        state.gitSuccess = null;
      })
      .addCase(getGitIntegrationsAsync.fulfilled, (state, action) => {
        state.gitLoading = false;
        state.gitIntegrations = action.payload;
        state.gitSuccess = "Git integrations fetched successfully.";
      })
      .addCase(getGitIntegrationsAsync.rejected, (state, action) => {
        state.gitLoading = false;
        state.gitError = action.payload;
      })

      .addCase(getReportAsync.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
        state.reportSuccess = null;
      })
      .addCase(getReportAsync.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.report = action.payload;
        state.reportSuccess = "Report data fetched successfully.";
      })
      .addCase(getReportAsync.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })

      .addCase(getRunnersAsync.pending, (state) => {
        state.runnersLoading = true;
        state.runnersError = null;
        state.runnersSuccess = null;
      })
      .addCase(getRunnersAsync.fulfilled, (state, action) => {
        state.runnersLoading = false;
        state.runners = action.payload;
        state.runnersSuccess = "Runners data fetched successfully.";
      })
      .addCase(getRunnersAsync.rejected, (state, action) => {
        state.runnersLoading = false;
        state.runnersError = action.payload;
      });
  },
});

export const {
  resetAnalyticsState,
  resetGitIntegrationsState,
  resetReportState,
  resetRunnersState,
} = analyticsSlice.actions;
export default analyticsSlice.reducer;
