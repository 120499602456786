import { createSlice } from "@reduxjs/toolkit";
import {
  getProjectsAsync,
  getProjectCommitsAsync,
  getCommitScansAsync,
  updateProjectAsync,
  deleteProjectAsync,
  createProjectAsync,
  stopScheduledScanAsync,
  startScheduledScanAsync,
} from "./ProjectAction";

const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [],
    commits: {},
    commitScans: {},
    totalProjects: 0,
    currentPage: 1,
    limit: 10,
    loading: false,
    error: null,
    success: null,

    lastFetchedOrgId: null,
    lastFetchedGroupId: null,
    lastFetchedPage: null,
    lastFetchedLimit: null,
  },
  reducers: {
    resetProjectsState: (state) => {
      state.projects = [];
      state.commits = {};
      state.commitScans = {};
      state.totalProjects = 0;
      state.currentPage = 1;
      state.limit = 10;
      state.loading = false;
      state.error = null;
      state.success = null;

      state.lastFetchedOrgId = null;
      state.lastFetchedGroupId = null;
      state.lastFetchedPage = null;
      state.lastFetchedLimit = null;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    // 1. Get Projects
    builder.addCase(getProjectsAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getProjectsAsync.fulfilled, (state, action) => {
      state.loading = false;
      const payload = action.payload || {};
      state.projects = payload.projects || [];
      state.totalProjects = payload.total || 0;
      state.currentPage = payload.page || 1;
      state.limit = payload.limit || 10;
      state.success = "Projects fetched successfully.";

      const { orgId, groupId, page, limit } = action.meta.arg;
      state.lastFetchedOrgId = orgId;
      state.lastFetchedGroupId = groupId;
      state.lastFetchedPage = page;
      state.lastFetchedLimit = limit;
    });
    builder.addCase(getProjectsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to fetch projects.";
    });

    // 2. Get Project Commits
    builder.addCase(getProjectCommitsAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getProjectCommitsAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { projectId, commits, total } = action.payload;
      state.commits[projectId] = { commits, total };
      state.success = `Commits for project ${projectId} fetched successfully.`;
    });
    builder.addCase(getProjectCommitsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to fetch project commits.";
    });

    // 3. Get Commit Scans
    builder.addCase(getCommitScansAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(getCommitScansAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { commitSha, scans } = action.payload;
      state.commitScans[commitSha] = scans;
      state.success = `Scans for commit ${commitSha} fetched successfully.`;
    });
    builder.addCase(getCommitScansAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to fetch commit scans.";
    });

    // 4. Update Project
    builder.addCase(updateProjectAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(updateProjectAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload.message || "Project updated successfully.";
    });
    builder.addCase(updateProjectAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to update project.";
    });

    // 5. Delete Project
    builder.addCase(deleteProjectAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(deleteProjectAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { projectId } = action.payload;
      state.projects = state.projects.filter((proj) => proj._id !== projectId);
      delete state.commits[projectId];
      state.totalProjects -= 1;
      state.success = "Project deleted successfully.";
    });
    builder.addCase(deleteProjectAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to delete project.";
    });

    // 6. Create Project
    builder.addCase(createProjectAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(createProjectAsync.fulfilled, (state, action) => {
      state.loading = false;
      const newProject = action.payload.project;
      state.projects.unshift(newProject);
      state.totalProjects += 1;
      state.success = "Project created successfully.";
    });
    builder.addCase(createProjectAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to create project.";
    });

    // 7. Stop Scheduled Scan
    builder.addCase(stopScheduledScanAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(stopScheduledScanAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { projectId } = action.payload;
      const project = state.projects.find((proj) => proj._id === projectId);
      if (project) {
        project.scanning = false;
      }
      state.success = "Scheduled scan stopped successfully.";
    });
    builder.addCase(stopScheduledScanAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to stop scheduled scan.";
    });

    // 8. Start Scheduled Scan
    builder.addCase(startScheduledScanAsync.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    });
    builder.addCase(startScheduledScanAsync.fulfilled, (state, action) => {
      state.loading = false;
      const { projectId, frequency } = action.payload;
      const project = state.projects.find((proj) => proj._id === projectId);
      if (project) {
        project.scanning = true;
        project.frequency = frequency;
      }
      state.success = "Scheduled scan started successfully.";
    });
    builder.addCase(startScheduledScanAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload?.message || "Failed to start scheduled scan.";
    });
  },
});

export const { resetProjectsState, setPage, setLimit } = projectsSlice.actions;

export default projectsSlice.reducer;
