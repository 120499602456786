import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Badge,
  VStack,
  Text,
  Divider,
  Tag,
  HStack,
  Link,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import { FiExternalLink } from "react-icons/fi";
import { CopyBlock, dracula } from "react-code-blocks";

const RepositoryDrawer = ({ isOpen, onClose, selectedItem }) => {
  if (!selectedItem) return null;

  const drawerHeaderBorderColor = useColorModeValue("gray.200", "gray.600");
  const detailTextColor = useColorModeValue("gray.500", "gray.300");
  const detailValueColor = useColorModeValue("gray.800", "gray.100");
  const boxBg = useColorModeValue("gray.50", "gray.700");
  const boxBorderColor = useColorModeValue("gray.200", "gray.600");
  const sectionHeadingColor = useColorModeValue("gray.700", "gray.200");

  const properties = {
    Severity: selectedItem.severity || "-",
    Scanner: selectedItem.scanner || "-",
    Confidence: selectedItem.confidence || "-",
    Impact: selectedItem.impact || "-",
    Likelihood: selectedItem.likelihood || "-",
    CVEs:
      Array.isArray(selectedItem.cves) && selectedItem.cves.length > 0
        ? selectedItem.cves.join(", ")
        : "-",
    "CVSS Score": selectedItem.cvss_score ?? "-",
    "CVSS Vector": selectedItem.cvss_vector ?? "-",
    "Scan ID": selectedItem.scan_id ?? "-",
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={useColorModeValue("white", "gray.800")}>
        <DrawerCloseButton />
        <DrawerHeader
          fontSize="lg"
          fontWeight="bold"
          borderBottomWidth="1px"
          borderColor={drawerHeaderBorderColor}
          color={sectionHeadingColor}
        >
          {selectedItem.vuln || "No Title Provided"}
        </DrawerHeader>

        <DrawerBody py={4} px={4}>
          <VStack align="stretch" spacing={3} mb={4}>
            <HStack>
              <Text fontSize="md" fontWeight="bold" color={sectionHeadingColor}>
                Vulnerability Details
              </Text>
              {selectedItem.rule_id && <Tag size="sm">{selectedItem.rule_id}</Tag>}
            </HStack>
            <Text fontSize="sm" color={detailValueColor} lineHeight="1.5">
              {selectedItem.message || "No details provided."}
            </Text>
          </VStack>

          <Divider mb={4} borderColor={drawerHeaderBorderColor} />
          {selectedItem.cwe && selectedItem.cwe.length > 0 && (
            <VStack align="stretch" spacing={3} mb={4}>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
              >
                CWEs
              </Text>
              <HStack spacing={2} flexWrap="wrap">
                {selectedItem.cwe.map((cweItem, index) => (
                  <Tag key={index} size="sm" colorScheme="purple">
                    {cweItem}
                  </Tag>
                ))}
              </HStack>
            </VStack>
          )}

          {selectedItem.cwe && selectedItem.cwe.length > 0 && (
            <Divider mb={4} borderColor={drawerHeaderBorderColor} />
          )}

          {selectedItem.code && (
            <>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
                mb={2}
              >
                Code Snippet
              </Text>
              <Box
                bg={boxBg}
                border="1px solid"
                borderColor={boxBorderColor}
                borderRadius="md"
                p={3}
                maxH="200px"
                overflowY="auto"
                mb={4}
              >
                <div style={{ fontSize: "12px", lineHeight: "1.4" }}>
                  <CopyBlock
                    text={selectedItem.code}
                    language="javascript"
                    showLineNumbers={true}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                  />
                </div>
              </Box>
              <Divider mb={4} borderColor={drawerHeaderBorderColor} />
            </>
          )}

          {selectedItem.recommendation && (
            <>
              <Text
                fontSize="sm"
                fontWeight="bold"
                color={sectionHeadingColor}
                mb={2}
              >
                Recommendation
              </Text>
              <Tag fontSize="sm" color={detailValueColor} mb={4}>
                {selectedItem.recommendation}
              </Tag>
              <Divider mb={4} borderColor={drawerHeaderBorderColor} />
            </>
          )}

          <VStack align="stretch" spacing={3}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={sectionHeadingColor}
              mb={3}
            >
              Properties
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={0}
              borderWidth="1px"
              borderColor={boxBorderColor}
              borderRadius="md"
              overflow="hidden"
            >
              {Object.entries(properties).map(([label, value]) => (
                <Box
                  key={label}
                  p={4}
                  borderBottomWidth="1px"
                  borderRightWidth={{ base: "0px", md: "1px" }}
                  borderColor={boxBorderColor}
                  bg={
                    label === "Severity" && value === "HIGH"
                      ? useColorModeValue("red.50", "red.900")
                      : useColorModeValue("gray.50", "gray.700")
                  }
                  _hover={{
                    bg: useColorModeValue("gray.100", "gray.600"),
                  }}
                >
                  {/* Label */}
                  <Text
                    fontSize={label === "Scan ID" ? "xs" : "sm"}
                    fontWeight="bold"
                    color={detailTextColor}
                    whiteSpace="normal"
                    overflowWrap="anywhere"
                  >
                    {label}
                  </Text>

                  {/* Value */}
                  <HStack spacing={2} align="center" mt={2} wrap="wrap">
                    {["Severity", "Impact", "Likelihood"].includes(label) ? (
                      <Badge
                        colorScheme={
                          value === "HIGH"
                            ? "red"
                            : value === "MEDIUM"
                            ? "orange"
                            : "green"
                        }
                        fontSize={label === "Scan ID" ? "xs" : "0.8em"}
                      >
                        {value}
                      </Badge>
                    ) : (
                      <Text
                        fontSize={label === "Scan ID" ? "xs" : "md"}
                        fontWeight="semibold"
                        color={detailValueColor}
                        whiteSpace="normal"
                        overflowWrap="anywhere"
                      >
                        {value}
                      </Text>
                    )}
                  </HStack>
                </Box>
              ))}
            </SimpleGrid>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default RepositoryDrawer;