import React from "react";
import { Flex, Text, Button, useColorModeValue } from "@chakra-ui/react";
import { ScanLine } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Header = ({ title }) => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.900", "white");
  const buttonBorderColor = useColorModeValue("gray.200", "gray.600");
  const buttonBg = useColorModeValue("#003460", "#0076c2");

  const handleScanClick = () => {
    navigate("/new-scan");
  };

  const handleViewClick = () => {
    navigate("/organization");
  };

  return (
    <Flex justifyContent="space-between" alignItems="center" mb={6}>
      <Text fontSize="2xl" color={textColor}>
        {title}
      </Text>
      <Flex alignItems="center" gap={4}>
        <Button variant="outline" onClick={handleViewClick} borderColor={buttonBorderColor} size="sm">
          View Organizations
        </Button>
        <Button
          leftIcon={<ScanLine size={"16"} />}
          color={buttonBg}
          size="sm"
          onClick={handleScanClick}
        >
          Initiate a scan
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;
