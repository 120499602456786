import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Divider,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { installJiraAsync } from "../../../../features/Integrations/integrationsAction";
import { getGitIntegrationsAsync } from "../../../../features/Analytics/AnalyticsAction";

const JiraInstallModal = ({ isOpen, onClose, orgId }) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const { jira } = useSelector((state) => state.integrations);

  const [hostUrl, setHostUrl] = useState("");
  const [username, setUsername] = useState("");
  const [apiToken, setApiToken] = useState("");

  const handleSubmit = async () => {
    if (!hostUrl || !username || !apiToken) {
      toast({
        title: "Missing Information",
        description: "Please fill out all fields.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const urlPattern = /^https?:\/\/[\w.-]+(?:\.[\w\.-]+)+[/#?]?.*$/;
    if (!urlPattern.test(hostUrl)) {
      toast({
        title: "Invalid URL",
        description: "Please enter a valid Jira Host URL.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const data = {
      hosturl: hostUrl,
      username,
      apitoken: apiToken,
    };

    try {
      await dispatch(installJiraAsync({ orgId, data })).unwrap();
      toast({
        title: "Jira Integration Successful",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      dispatch(getGitIntegrationsAsync({ orgId }));
      setHostUrl("");
      setUsername("");
      setApiToken("");
    } catch (error) {
      console.error("Jira installation failed:", error);
      toast({
        title: "Jira Integration Failed",
        description:
          error.message || "An error occurred during Jira integration.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Jira Integration</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <VStack align="start" spacing={4}>
            <Text>
              To achieve integration, the API token belonging to a Jira owner is
              necessary. This allows us to automatically establish a hook,
              ensuring comprehensive coverage. Remember, you can always refine
              your monitoring boundaries per Jira project.
            </Text>
            <Divider />
            <Text fontWeight="bold">To generate an API Token:</Text>
            <Text>
              1. Log in to{" "}
              <a
                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#3182ce", textDecoration: "underline" }}
              >
                https://id.atlassian.com/manage-profile/security/api-tokens
              </a>
              .
            </Text>
            <Text>
              2. Click <strong>Create API token</strong>.
            </Text>
            <Text>
              3. From the dialog that appears, enter a memorable and concise
              Label for your token (example: "Jira - AquilaX") and click Create.
            </Text>
            <Text>
              4. Click <strong>Copy to clipboard</strong>, then paste the token
              to your script, or elsewhere to save.
            </Text>
            <Divider />
            <FormControl id="hosturl" isRequired>
              <FormLabel>Jira Host URL</FormLabel>
              <Input
                placeholder="https://<your-company-name>.atlassian.net"
                value={hostUrl}
                onChange={(e) => setHostUrl(e.target.value)}
              />
            </FormControl>
            <FormControl id="username" isRequired>
              <FormLabel>User Name</FormLabel>
              <Input
                placeholder="Demo"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <FormControl id="apitoken" isRequired>
              <FormLabel>API Token</FormLabel>
              <Input
                type="password"
                placeholder="API Token"
                value={apiToken}
                onChange={(e) => setApiToken(e.target.value)}
              />
            </FormControl>
            <Button
              bg="blue.600"
              color="white"
              _hover={{ bg: "blue.700" }}
              width="full"
              onClick={handleSubmit}
              isLoading={jira.loading}
              loadingText="Installing"
            >
              Install Aquilax on Jira
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JiraInstallModal;
