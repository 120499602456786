import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Tag,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Box,
  useColorModeValue,
  HStack,
  IconButton,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { Copy } from "lucide-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import { DEFAULT_SECURITY_POLICY } from "../../constants/defaultSecurityPolicy";

const GroupModal = ({ isOpen, onClose, groupData, mode, onSave, onDelete }) => {
  const toast = useToast();
  const [groupName, setGroupName] = useState("");
  const [jsonContent, setJsonContent] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  // State to track delete loading
  const [isDeleting, setIsDeleting] = useState(false);

  // Disclosure for the delete confirmation AlertDialog
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  // Ref needed for AlertDialog
  const cancelRef = useRef();

  useEffect(() => {
    if (mode === "create") {
      setGroupName("");
      setJsonContent(JSON.stringify(DEFAULT_SECURITY_POLICY, null, 2));
    } else {
      setGroupName(groupData?.name || "");
      if (groupData?.security_policy) {
        setJsonContent(JSON.stringify(groupData.security_policy, null, 2));
      } else {
        setJsonContent("");
      }
    }
    setIsSaving(false);
    setIsDeleting(false);
  }, [groupData, mode]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const parsedContent = JSON.parse(jsonContent);
      await onSave({ name: groupName, security_policy: parsedContent });
    } catch (error) {
      alert("Invalid JSON format");
    } finally {
      setIsSaving(false);
    }
  };

  const handleCopyId = () => {
    if (groupData?._id) {
      navigator.clipboard.writeText(groupData._id);
      toast({
        title: "Copied",
        description: `Group ID ${groupData._id} copied to clipboard.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete();
      onDeleteClose();
      onClose();
    } catch (error) {
      console.error("Delete error:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const modalBg = useColorModeValue("white", "gray.800");
  const inputBg = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent bg={modalBg}>
         <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pr="50px"
        >
          <Text fontSize="md">
            {mode === "create" ? "Create Group" : "Edit Group"}
          </Text>
          {mode !== "create" && groupData?._id && (
            <HStack spacing={2}>
              <Tag fontSize="sm" fontWeight="bold">
                {groupData._id}
              </Tag>
              <IconButton
                variant="ghost"
                size="sm"
                icon={<Copy size={16} />}
                aria-label="Copy Group ID"
                onClick={handleCopyId}
              />
            </HStack>
          )}
        </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel fontSize="sm">Group Name</FormLabel>
              <Input
                size="sm"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                bg={inputBg}
                placeholder="Enter group name"
              />
            </FormControl>

            <Text fontSize="sm" fontWeight="bold" mb={2}>
              Security Policy (JSON):
            </Text>
            <Box
              border="1px solid"
              borderColor="gray.300"
              rounded="md"
              overflow="hidden"
            >
              <AceEditor
                mode="json"
                theme="monokai"
                name="security-policy-editor"
                fontSize={13}
                value={jsonContent}
                onChange={setJsonContent}
                showPrintMargin={false}
                showGutter
                highlightActiveLine
                width="100%"
                height="350px"
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  showLineNumbers: true,
                  tabSize: 2,
                  useWorker: false,
                }}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            {mode === "create" ? (
              <>
                <Button
                  color="white"
                  bg={useColorModeValue("#003460", "#0077b5")}
                  _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
                  size="sm"
                  onClick={handleSave}
                  isLoading={isSaving}
                  loadingText="Saving..."
                  mr={2}
                >
                  Save
                </Button>
                <Button size="sm" variant="outline" onClick={onClose}>
                  Close
                </Button>
              </>
            ) : (
              <>
                <Button
                  colorScheme="red"
                  size="sm"
                  mr="auto"
                  onClick={onDeleteOpen}
                >
                  Delete
                </Button>
                <Button
                  color="white"
                  bg={useColorModeValue("#003460", "#0077b5")}
                  _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
                  size="sm"
                  onClick={handleSave}
                  isLoading={isSaving}
                  loadingText="Updating..."
                  mr={2}
                >
                  Update
                </Button>
                <Button size="sm" variant="outline" onClick={onClose}>
                  Close
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog for confirm delete */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Delete Group
            </AlertDialogHeader>
            <AlertDialogBody fontSize="sm">
              Are you sure you want to delete the group "
              <Text as="span" fontWeight="bold">
                {groupData?.name}
              </Text>
              "? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose} size="sm">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                ml={3}
                size="sm"
                isLoading={isDeleting}
                loadingText="Deleting..."
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default GroupModal;
