import axios from "axios";

const url = process.env.REACT_APP_API_URL;
const defaultConfig = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Start a New Scan
 */
export const startNewScan = async (orgId, groupId, scanData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v2/scan?org=${orgId}&group=${groupId}`,
      scanData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get All Scans by Group ID
 */
export const getScansByGroupId = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/scans`,
      defaultConfig
    );
    return response.data.scans;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get Scan by ID
 */
export const getScanById = async (orgId, scanId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v2/scan/${scanId}`,
      {
        params: { org: orgId, group: groupId },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get Scans by Project ID
 */
export const getScansByProject = async (orgId, groupId, projectId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/scans`,
      defaultConfig
    );
    return response.data.scans;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Delete Scan
 */
export const deleteScan = async (orgId, groupId, projectId, scanId, thunkAPI) => {
  try {
    const response = await axios.delete(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/scan/${scanId}`,
      defaultConfig
    );
    // Suppose the response gives "message": "Deleted successfully"
    return response.data.message;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get Pending Scans (Admin)
 */
export const getPendingScans = async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/admin/scans/pending`, defaultConfig);
    return response.data.scans;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get Scans Analytics
 */
export const getScansAnalytics = async (orgId, groupId,thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/scan-analytics`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Update Finding Status
 */
export const updateFindingStatus = async (
  orgId,
  scanId,
  findingId,
  statusData,
  thunkAPI
) => {
  try {
    const payload = {
      status: statusData.status,
      reason: statusData.reason,
      remediation_action: statusData.remediation,
    };
    const response = await axios.put(
      `${url}/v1/organization/${orgId}/scan/${scanId}/finding/${findingId}/status`,
      payload,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Repository Branches (POST)
 */
export const getRepoBranches = async (orgId, branchData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/repo/get-branches`,
      branchData,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get Scan PDF Report (GET)
 */
export const getScanPdfReport = async (orgId, scanId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/organization/${orgId}/scan/${scanId}/pdf`, {
      ...defaultConfig,
      responseType: "blob",
    });
    // Convert blob -> object URL
    const pdfUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    return pdfUrl;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const getFindingsByGroupId = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/v1/organization/${orgId}/group/${groupId}/findings`, {
      params: { group_id: groupId },
      withCredentials: true,
    });
    return response.data.findings;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Generate Executive Summary of Scan
 */
export const generateExecutiveSummary = async (orgId, scanId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/scan/${scanId}/generate_executive_summary`,
      defaultConfig
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Generate Executive Summary of Orgs
 */
export const generateExecutiveSummaryOrg = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/summary`,
      defaultConfig
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

/**
 * Get All Findings
 */
export const getAllFindings = async (orgId, groupId, thunkAPI) => {
  try {
    const response = await axios.get(
      `${url}/v1/organization/${orgId}/group/${groupId}/findings`,
      defaultConfig
    );
    return response.data.findings;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const stopScheduledScan = async (orgId, groupId, projectId, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/stop-scheduled-scan`,
      {}, 
      defaultConfig
    );
    return response.data; 
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

export const startScheduledScan = async (orgId, groupId, projectId, frequency, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/v1/organization/${orgId}/group/${groupId}/project/${projectId}/start-scheduled-scan?frequency=${frequency}`,
      {}, 
      defaultConfig
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};


export const getCurrentYearScansReport = async (orgId, groupId, thunkAPI) => {
  try {
    const reportUrl = `${url}/v2/report/scans?org=${orgId}&group=${groupId}`;
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(reportUrl, config);

    if (!response.data || !Array.isArray(response.data)) {
      return [];
    }

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const oneYearScans = response.data.filter((scan) => {
      const scanDate = new Date(scan.created_at * 1000);
      return scanDate >= oneYearAgo;
    });

    return oneYearScans;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
  }
};

