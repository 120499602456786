import React, { useState } from 'react';
import {
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  CheckboxGroup,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Progress,
  Button,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { installGitLabAsync, fetchGitLabRepositoriesAsync } from '../../../../features/Integrations/integrationsAction';
import { getGitIntegrationsAsync } from '../../../../features/Analytics/AnalyticsAction';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import successAnimation from '../../../../assets/lottie/success.json';

const GitLabWizard = ({ isOpen, onClose, orgId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    groups,
    loading: gitLabLoading,
    error: gitLabError,
  } = useSelector((state) => state.integrations.gitLab);

  const [wizardStep, setWizardStep] = useState(1);
  const [gitInstance, setGitInstance] = useState('https://gitlab.com');
  const [gitAccessToken, setGitAccessToken] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleWizardNext = async () => {
    if (wizardStep === 1) {
      const payload = {
        installationId: orgId,
        data: {
          git_name: 'GitLab',
          git_instance: gitInstance,
          git_access_token: gitAccessToken,
        },
      };
      try {
        await dispatch(installGitLabAsync(payload)).unwrap();
        setWizardStep(2);
      } catch (err) {
        console.error('GitLab install error:', err);
      }
    } else if (wizardStep === 2) {
      const payload = {
        installationId: orgId,
        data: {
          git_name: 'GitLab',
          git_instance: gitInstance,
          git_access_token: gitAccessToken,
          groups: selectedGroups,
        },
      };
      try {
        await dispatch(fetchGitLabRepositoriesAsync(payload)).unwrap();
        setWizardStep(3);
      } catch (err) {
        console.error('GitLab fetch repos error:', err);
      }
    } else if (wizardStep === 3) {
      onClose();
    }
  };

  const handleWizardBack = () => {
    if (wizardStep > 1) {
      setWizardStep((prev) => prev - 1);
    } else {
      onClose();
    }
  };

  const handleStartScan = () => {
    dispatch(getGitIntegrationsAsync({ orgId }));
    navigate('/new-scan');
    onClose();
  };

  const renderWizardContent = () => {
    if (gitLabLoading) {
      return (
        <VStack spacing={4}>
          <Text>Processing...</Text>
          <Progress size="sm" isIndeterminate />
        </VStack>
      );
    }

    switch (wizardStep) {
      case 1:
        return (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Step 1: GitLab Credentials
            </Text>
            <FormControl isRequired>
              <FormLabel>Git Instance (e.g., https://gitlab.com)</FormLabel>
              <Input
                value={gitInstance}
                onChange={(e) => setGitInstance(e.target.value)}
                placeholder="https://gitlab.com"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>GitLab Access Token</FormLabel>
              <Input
                type="password"
                value={gitAccessToken}
                onChange={(e) => setGitAccessToken(e.target.value)}
                placeholder="Enter your personal access token"
              />
            </FormControl>
            {gitLabError && (
              <Text color="red.500" fontSize="sm">
                {gitLabError?.data?.detail ||
                  gitLabError?.message ||
                  'Error installing GitLab'}
              </Text>
            )}
          </VStack>
        );
      case 2:
        const gitLabGroups = groups || [];
        return (
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" fontWeight="bold">
              Step 2: Select GitLab Groups
            </Text>
            {gitLabGroups.length === 0 ? (
              <Text>No groups found. Check your token or try again.</Text>
            ) : (
              <CheckboxGroup
                value={selectedGroups}
                onChange={(vals) => setSelectedGroups(vals)}
              >
                <VStack align="start">
                  {gitLabGroups.map((grp) => (
                    <Checkbox key={grp.id} value={`${grp.id}`}>
                      {grp.name} ({grp.full_path})
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            )}
            {gitLabError && (
              <Text color="red.500" fontSize="sm">
                {gitLabError?.data?.detail ||
                  gitLabError?.message ||
                  'Error fetching groups'}
              </Text>
            )}
          </VStack>
        );
      case 3:
        const defaultOptions = {
          loop: false,
          autoplay: true,
          animationData: successAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        };
        return (
          <VStack spacing={6} align="center">
            <Lottie options={defaultOptions} height={200} width={200} />
            <Text fontSize="lg" fontWeight="bold">
              GitLab Integrated Successfully!
            </Text>
            <Button
              bg="blue.600"
              color="white"
              _hover={{ bg: 'blue.700' }}
              onClick={handleStartScan}
              size="md"
            >
              Start Scan
            </Button>
          </VStack>
        );
      default:
        return null;
    }
  };

  const renderWizardFooter = () => {
    if (wizardStep === 3) {
      return null;
    }

    return (
      <>
        {wizardStep > 1 && wizardStep < 3 && (
          <Button variant="ghost" mr={3} onClick={handleWizardBack}>
            Back
          </Button>
        )}
        <Button
          bg="blue.600"
          color="white"
          _hover={{ bg: 'blue.700' }}
          onClick={handleWizardNext}
          isLoading={gitLabLoading}
          isDisabled={
            (wizardStep === 1 && (!gitInstance || !gitAccessToken)) ||
            (wizardStep === 2 && selectedGroups.length === 0)
          }
        >
          {wizardStep === 1 ? 'Next' : 'Finish'}
        </Button>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Install GitLab</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderWizardContent()}</ModalBody>
        <ModalFooter>{renderWizardFooter()}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GitLabWizard;
