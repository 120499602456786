import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const DataTable = ({ data, columns, itemsPerPage = 5 }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    return data.filter((row) =>
      columns.some((col) =>
        String(row[col.accessor])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [data, columns, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <Box  borderRadius="md" overflowX="auto">
      <Input
        placeholder="Search..."
        mb={4}
        size="sm"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            {columns.map((col) => (
              <Th key={col.accessor}>{col.header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {columns.map((col) => (
                  <Td key={col.accessor}>{row[col.accessor]}</Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length}>
                <Text textAlign="center">No data found.</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex justify="space-between" align="center" mt={2}>
        <IconButton
          aria-label="Previous Page"
          icon={<ChevronLeftIcon />}
          size="sm"
          onClick={handlePrevPage}
          isDisabled={currentPage === 1}
        />
        <Text fontSize="sm">
          Page {currentPage} of {totalPages}
        </Text>
        <IconButton
          aria-label="Next Page"
          icon={<ChevronRightIcon />}
          size="sm"
          onClick={handleNextPage}
          isDisabled={currentPage === totalPages || totalPages === 0}
        />
      </Flex>
    </Box>
  );
};

export default DataTable;
