import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Heading,
  useDisclosure,
  useColorModeValue,
  useToast,
  SimpleGrid,
  Avatar,
  VStack,
  HStack,
  Divider,
  Tag,
  Tooltip,
  Wrap,
  WrapItem,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  addGroupAsync,
  updateGroupAsync,
  deleteGroupAsync,
} from "../features/Groups/GroupAction";
import { SquarePlus } from "lucide-react";
import GroupModal from "../components/Groups/GroupModal";

const GroupsPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const orgDetails = useSelector((state) => state.user.selectedOrganization);
  const orgId = orgDetails?._id;
  const userData = useSelector((state) => state.user.userData);
  const myProfile = orgDetails?.users?.find(
    (user) => user.email === userData?.email
  );
  const isViewer = myProfile?.role === "Viewer";

  const groups = useSelector((state) => state.groups.groups || []);
  const isLoading = useSelector((state) => state.groups.loading);
  const error = useSelector((state) => state.groups.error);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentGroup, setCurrentGroup] = useState(null);
  const [mode, setMode] = useState("");

  const handleAction = (group, action) => {
    if (isViewer && (action === "create" || action === "edit")) {
      toast({
        title: "Access Denied",
        description: "Viewers cannot create or edit groups.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setCurrentGroup(group || {});
    setMode(action);
    onOpen();
  };

  const handleSaveGroup = async ({ name, security_policy }) => {
    if (mode === "edit") {
      try {
        await dispatch(
          updateGroupAsync({
            orgId,
            groupId: currentGroup._id,
            groupData: { name, security_policy },
          })
        ).unwrap();
        toast({
          title: "Group Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } catch (err) {
        toast({
          title: "Error",
          description: err || "Failed to update the group.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else if (mode === "create") {
      try {
        await dispatch(
          addGroupAsync({
            orgId,
            groupData: { name, security_policy },
          })
        ).unwrap();
        toast({
          title: "Group Created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } catch (err) {
        toast({
          title: "Error",
          description: err || "Failed to create the group.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeleteGroup = async () => {
    if (!currentGroup || !currentGroup._id) {
      toast({
        title: "Error",
        description: "Invalid group selected for deletion.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    onClose();
    try {
      await dispatch(deleteGroupAsync({ orgId, groupId: currentGroup._id })).unwrap();
      toast({
        title: "Group Deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err || "Failed to delete the group.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const pageBg = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const borderCol = useColorModeValue("gray.200", "gray.700");

  // A simplified card that displays only key information.
  const renderGroupCard = (group) => {
    const avatarSrc = group.security_policy?.avatar || "";
    return (
      <Box
        key={group._id}
        bg={cardBg}
        rounded="md"
        p={4}
        border="1px solid"
        borderColor={borderCol}
        cursor="pointer"
        transition="transform 0.2s"
        _hover={{ transform: "scale(1.02)" }}
        onClick={() => handleAction(group, "edit")}
      >
        <HStack spacing={3} align="center" mb={3}>
          <Avatar size="sm" name={group.name} src={avatarSrc} />
          <Text fontWeight="medium" fontSize="md" noOfLines={1}>
            {group.name}
          </Text>
        </HStack>
        {group.security_policy?.tags && group.security_policy.tags.length > 0 && (
          <HStack spacing={1} wrap="wrap" mb={3}>
            {group.security_policy.tags.map((tag, idx) => (
              <Tag key={idx} size="sm" colorScheme="blue" variant="subtle">
                {tag}
              </Tag>
            ))}
          </HStack>
        )}
        <Divider mb={3} />
        <Flex justify="space-between" align="center">
          <VStack spacing={1} align="start">
            <Text fontSize="xs" color="gray.500">
              Frequency
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {group.security_policy?.frequency || "N/A"}
            </Text>
          </VStack>
          <VStack spacing={1} align="start">
            <Text fontSize="xs" color="gray.500">
              Notify
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {group.security_policy?.notify_on_failure ? "Yes" : "No"}
            </Text>
          </VStack>
        </Flex>
      </Box>
    );
  };

  return (
    <Box bg={pageBg} px={{ base: 2, md: 4 }} py={6}>
      <Flex
        justify="space-between"
        align="center"
        mb={6}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Heading size="md" mb={{ base: 4, md: 0 }}>
          Organization Groups
        </Heading>
        {!isViewer && (
          <Button
            leftIcon={<SquarePlus size={18} />}
            color="white"
            bg={useColorModeValue("#003460", "#0077b5")}
            _hover={{ bg: useColorModeValue("#0077b5", "#003460") }}
            onClick={() => handleAction(null, "create")}
            size="sm"
          >
            New Group
          </Button>
        )}
      </Flex>

      {isLoading ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
          {Array.from({ length: 6 }).map((_, idx) => (
            <Box
              key={idx}
              bg={cardBg}
              rounded="md"
              p={4}
              border="1px solid"
              borderColor={borderCol}
            >
              <VStack align="start" spacing={3} w="100%">
                <Flex w="100%" align="center" justify="space-between">
                  <SkeletonCircle size="6" />
                  <Skeleton height="10px" width="40%" />
                </Flex>
                <Divider />
                <SkeletonText mt="2" noOfLines={3} spacing="3" />
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      ) : error ? (
        <Text fontSize="sm" color="red.500" textAlign="center">
          Error: {error}
        </Text>
      ) : groups.length > 0 ? (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
          {groups.map((group) => renderGroupCard(group))}
        </SimpleGrid>
      ) : (
        <Flex direction="column" align="center" justify="center" minH="200px">
          <Text fontSize="sm" mb={4}>
            No groups found. Create one to start exploring us?
          </Text>
        </Flex>
      )}

      <GroupModal
        isOpen={isOpen}
        onClose={onClose}
        groupData={currentGroup}
        mode={mode}
        onSave={handleSaveGroup}
        onDelete={handleDeleteGroup}
      />
    </Box>
  );
};

export default GroupsPage;
