import React from "react";
import {
  Box,
  Heading,
  Stack,
  useColorModeValue,
  Spinner,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ActivityLogItem from "./ActivityLogItem";
import iconMapping from "../../utils/IconMapping";
import { formatDistanceToNow } from "date-fns";
import { FaSignInAlt } from "react-icons/fa";

const AccountActivity = () => {
  const { activityLogs, loading, error } = useSelector((state) => state.user);

  const getIconByEvent = (event) => {
    return iconMapping[event] || FaSignInAlt;
  };

  const timeAgo = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true });
  };

  const bgCard = useColorModeValue("white", "gray.800");

  return (
    <Box
      p={4}
      bg={bgCard}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      shadow="sm"
      flex="1"
      overflowY="auto"
    >
      <Heading as="h2" size="md" mb={4}>
        Account Activity
      </Heading>
      {loading ? (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="lg" />
        </Flex>
      ) : error ? (
        <Text color="red.500">
          Error: {error.message || "Failed to load activities."}
        </Text>
      ) : activityLogs.length === 0 ? (
        <Text>No recent activities.</Text>
      ) : (
        <Stack spacing={3}>
          {activityLogs.map((log) => (
            <ActivityLogItem
              key={`${log.ts}-${log.event}-${log.email}`}
              icon={getIconByEvent(log.event)}
              activity={
                log.message ||
                log.event.replace("_", " ").toUpperCase()
              }
              description={log.description}
              time={timeAgo(log.ts)}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default AccountActivity;
