import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea
} from '@chakra-ui/react';

const TicketModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Raise a Ticket</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="ticket-title" mb={4}>
            <FormLabel>Title</FormLabel>
            <Input placeholder="Enter ticket title" />
          </FormControl>
          <FormControl id="ticket-description">
            <FormLabel>Description</FormLabel>
            <Textarea placeholder="Enter ticket description" />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TicketModal;
