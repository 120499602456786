import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIntellData, getGitIntegrations, getReport, getRunners } from "../../apis/analytics";

export const getIntellDataAsync = createAsyncThunk(
  "analytics/getIntellData",
  async ({ orgId, groupId }, thunkAPI) => {
    const response = await getIntellData(orgId, groupId, thunkAPI);
    return response;
  }
);

export const getGitIntegrationsAsync = createAsyncThunk(
    "analytics/getGitIntegrations",
    async ({ orgId }, thunkAPI) => {
      const response = await getGitIntegrations(orgId, thunkAPI);
      return response;
    }
  );

export const getReportAsync = createAsyncThunk(
    "analytics/getReport",
    async ({ orgId, groupId }, thunkAPI) => {
      const response = await getReport(orgId, groupId, thunkAPI);
      return response;
    }
  );
  
export const getRunnersAsync = createAsyncThunk(
    "analytics/getRunners",
    async (_, thunkAPI) => {
      const response = await getRunners(thunkAPI);
      return response;
    }
  );