import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  startNewScan,
  getScansByGroupId,
  getScanById,
  getScansByProject,
  deleteScan,
  getPendingScans,
  getScansAnalytics,
  updateFindingStatus,
  getRepoBranches,     
  getScanPdfReport,   
  getFindingsByGroupId,
  generateExecutiveSummary, 
  generateExecutiveSummaryOrg,
  getAllFindings,
  stopScheduledScan,
  startScheduledScan,
  getCurrentYearScansReport, 
} from "../../apis/scans";

export const startNewScanAsync = createAsyncThunk(
  "scans/startNewScan",
  async ({ orgId, groupId, scanData }, thunkAPI) => {
    return await startNewScan(orgId, groupId, scanData, thunkAPI);
  }
);

export const getScansByGroupIdAsync = createAsyncThunk(
  "scans/getScansByGroupId",
  async ({ orgId, groupId }, thunkAPI) => {
    return await getScansByGroupId(orgId, groupId, thunkAPI);
  }
);

export const getScanByIdAsync = createAsyncThunk(
  "scans/getScanById",
  async ({ orgId, scanId, groupId }, thunkAPI) => {
    return await getScanById(orgId, scanId, groupId, thunkAPI);
  }
);

export const getScansByProjectAsync = createAsyncThunk(
  "scans/getScansByProject",
  async ({ orgId, groupId, projectId }, thunkAPI) => {
    return await getScansByProject(orgId, groupId, projectId, thunkAPI);
  }
);

export const deleteScanAsync = createAsyncThunk(
  "scans/deleteScan",
  async ({ orgId, groupId, projectId, scanId }, thunkAPI) => {
    return await deleteScan(orgId, groupId, projectId, scanId, thunkAPI);
  }
);

export const getPendingScansAsync = createAsyncThunk(
  "scans/getPendingScans",
  async (_, thunkAPI) => {
    return await getPendingScans(thunkAPI);
  }
);

export const getScansAnalyticsAsync = createAsyncThunk(
  "scans/getScansAnalytics",
  async ({ orgId, groupId }, thunkAPI) => {
    return await getScansAnalytics(orgId, groupId, thunkAPI);
  }
);

export const updateFindingStatusAsync = createAsyncThunk(
  "scans/updateFindingStatus",
  async ({ orgId, scanId, findingId, statusData }, thunkAPI) => {
    return await updateFindingStatus(orgId, scanId, findingId, statusData, thunkAPI);
  }
);

// Repository Branches Async Thunk
export const getRepoBranchesAsync = createAsyncThunk(
  "scans/getRepoBranches",
  async ({ orgId, platform, owner, repo, git_uri }, thunkAPI) => {
    return await getRepoBranches(orgId, { platform, owner, repo, git_uri }, thunkAPI);
  }
);

// Get Scan PDF Report Async Thunk
export const getScanPdfReportAsync = createAsyncThunk(
  "scans/getScanPdfReport",
  async ({ orgId, scanId }, thunkAPI) => {
    return await getScanPdfReport(orgId, scanId, thunkAPI);
  }
);

export const getFindingsByGroupIdAsync = createAsyncThunk(
  "scans/getFindingsByGroupId",
  async ({ orgId, groupId }, thunkAPI) => {
    return await getFindingsByGroupId(orgId, groupId, thunkAPI);
  }
);

export const generateExecutiveSummaryAsync = createAsyncThunk(
  "scans/generateExecutiveSummary",
  async ({ orgId, scanId }, thunkAPI) => {
    return await generateExecutiveSummary(orgId, scanId, thunkAPI);
  }
);

export const generateExecutiveSummaryOrgAsync = createAsyncThunk(
  "scans/generateExecutiveSummaryOrg",
  async ({ orgId, groupId }, thunkAPI) => {
    return await generateExecutiveSummaryOrg(orgId, groupId, thunkAPI);
  }
);

export const getAllFindingsAsync = createAsyncThunk(
  "scans/getAllFindings",
  async ({ orgId, groupId }, thunkAPI) => {
    return await getAllFindings(orgId, groupId, thunkAPI);
  }
);

export const stopScheduledScanAsync = createAsyncThunk(
  "scans/stopScheduledScan",
  async ({ orgId, groupId, projectId }, thunkAPI) => {
    return await stopScheduledScan(orgId, groupId, projectId, thunkAPI);
  }
);

export const startScheduledScanAsync = createAsyncThunk(
  "scans/startScheduledScan",
  async ({ orgId, groupId, projectId, frequency }, thunkAPI) => {
    return await startScheduledScan(orgId, groupId, projectId, frequency, thunkAPI);
  }
);

export const getCurrentYearScansReportAsync = createAsyncThunk(
  "scans/getCurrentYearScansReport",
  async ({ orgId, groupId }, thunkAPI) => {
    return await getCurrentYearScansReport(orgId, groupId, thunkAPI);
  }
);