import axios from 'axios';
import { updateStreamResponse } from '../features/Securitron/securitronSlice';

const url = process.env.REACT_APP_API_URL;

const API_BASE_URL = `${url}/v1/ai`;
const defaultConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const postSecuritron = async (
  organizationId,
  question,
  groupId,
  sessionId,
  thunkAPI,
  signal
) => {
  try {
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiUrl = `${baseUrl}/v2/securitron?org=${organizationId}&group=${groupId}`;
    const payload = {
      prompt: question,
      session_id: sessionId,
      stream: true,           
    };

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      signal,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Network error: ${response.status}`);
    }

    const contentType = response.headers.get("content-type") || "";

    const headers = {};
    response.headers.forEach((value, key) => {
      headers[key] = value;
    });

    if (contentType.includes("text/event-stream") && response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        if (value) {
          const chunk = decoder.decode(value, { stream: !done });
          thunkAPI.dispatch(updateStreamResponse(chunk));
        }
      }

      return { streaming: true, headers };
    }

    if (contentType.includes("text/markdown")) {
      const data = await response.text();
      const aiInfo = response.headers.get("x-aquilax-ai-info");
      let type = null;
      if (aiInfo) {
        const parts = aiInfo.split("|");
        type = parts[0];
      }
      return {
        streaming: false,
        type,
        payload: data,
        aiInfo,
        classification: response.headers.get("x-aquilax-classification"),
        headers,
      };
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error.name === "AbortError") {
      return thunkAPI.rejectWithValue({
        status: null,
        data: null,
        message: "Request cancelled by user.",
      });
    }

    return thunkAPI.rejectWithValue({
      status: null,
      data: null,
      message: error.message,
    });
  }
};

export const getSecurityAssistantStream = async (
  org_id,
  scan_id,
  finding_id,
  question,
  thunkAPI
) => {
  try {
    const url = `${API_BASE_URL}/organization/${org_id}/scan/${scan_id}/finding/${finding_id}/ask-security-assistant/stream`;

    const config = {
      ...defaultConfig,
      params: {
        question: encodeURIComponent(question),
      },
    };

    const response = await axios.get(url, config);

    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.message || error.message,
      });
    } else if (error.request) {
      return thunkAPI.rejectWithValue({
        status: null,
        data: null,
        message: 'No response received from the server.',
      });
    } else {
      return thunkAPI.rejectWithValue({
        status: null,
        data: null,
        message: error.message,
      });
    }
  }
};

export const getReviewFinding = async (
  org_id,
  scan_id,
  finding_id,
  thunkAPI
) => {
  try {
    const url = `${API_BASE_URL}/organization/${org_id}/scan/${scan_id}/finding/${finding_id}/review`;
    const config = {
      ...defaultConfig,
    };

    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        data: error.response.data,
        message: error.response.data.message || error.message,
      });
    } else if (error.request) {
      return thunkAPI.rejectWithValue({
        status: null,
        data: null,
        message: 'No response received from the server.',
      });
    } else {
      return thunkAPI.rejectWithValue({
        status: null,
        data: null,
        message: error.message,
      });
    }
  }
};
