import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  VStack,
  Link,
  Icon,
  HStack,
  Text,
  Progress,
  Button,
  IconButton,
  Tooltip,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useColorModeValue,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  FiMenu,
  FiFile,
  FiHelpCircle,
  FiLayers,
  FiChevronDown,
  FiCheck,
  FiPlus,
  FiExternalLink,
} from "react-icons/fi";
import {
  LayoutDashboard,
  Bot,
  Package,
  UserPlus,
  ShieldAlert,
  FileScan,
  Book as BookIcon,
  PieChart as PieChartIcon,
  ArrowUpRight as UpgradeIcon,
  Server,
  Layers,
  FileChartPie,
  House,
  MousePointerClick,
  PlusIcon,
  Users,
  ScanSearch,
} from "lucide-react";
import Topbar from "./Topbar";
import AddMemberModal from "../pages/AddMemberModal";
import GroupSelector from "./GroupSelector";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsByOrgIdAsync } from "../features/Groups/GroupAction";
import { setSelectedGroup } from "../features/Groups/GroupsSlice";
import {
  getScansByGroupIdAsync,
  getScansAnalyticsAsync,
  getCurrentYearScansReportAsync,
} from "../features/Scans/ScansAction";
import { resetScansState } from "../features/Scans/ScansSlice";
import { getReportAsync } from "../features/Analytics/AnalyticsAction";
import RunnersStatus from "./Others/RunnerStatus";

import ScanWizardModal from "./NewScans/ScanWizardModal";

const WorkersModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Workers Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RunnersStatus />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            bg="blue.600"
            color="white"
            _hover={{ bg: "blue.700" }}
            size={"sm"}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();

  // UI state
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [isWorkersModalOpen, setIsWorkersModalOpen] = useState(false);
  const [isNewScanModalOpen, setIsNewScanModalOpen] = useState(false);

  const orgDetail = useSelector((state) => state.user.selectedOrganization);
  const userData = useSelector((state) => state.user.userData);
  const myProfile = orgDetail?.users?.find(
    (user) => user.email === userData?.email
  );

  const groups = useSelector((state) => state.groups.groups);
  const selectedGroup = useSelector((state) => state.groups.selectedGroup);

  const orgId = orgDetail?._id;
  const orgName = orgDetail?.name;

  useEffect(() => {
    const saved = localStorage.getItem("selectedGroup");
    if (saved) {
      dispatch(setSelectedGroup(JSON.parse(saved)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (orgId) {
      dispatch(getGroupsByOrgIdAsync(orgId));
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    if (!orgId || groups.length === 0) return;
    const found = groups.find((g) => g._id === selectedGroup?.id);
    if (!found) {
      const first = groups[0];
      const newSel = { id: first._id, name: first.name };
      dispatch(setSelectedGroup(newSel));
      localStorage.setItem("selectedGroup", JSON.stringify(newSel));
    }
  }, [orgId, groups, selectedGroup, dispatch]);

  const lastFetchedGroupRef = useRef(null);
  useEffect(() => {
    if (
      orgId &&
      selectedGroup?.id &&
      groups.length > 0 &&
      groups.find((g) => g._id === selectedGroup.id)
    ) {
      dispatch(resetScansState());
      if (lastFetchedGroupRef.current !== selectedGroup.id) {
        lastFetchedGroupRef.current = selectedGroup.id;
        if (
          location.pathname === "/scans"
        ) {
          dispatch(
            getScansByGroupIdAsync({ orgId, groupId: selectedGroup.id })
          );
        }
        if (location.pathname === "/home") {
          dispatch(getReportAsync({ orgId, groupId: selectedGroup.id }));
        }
      }
    }
  }, [orgId, selectedGroup, groups, dispatch, location.pathname]);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  const openAddMemberModal = () => setIsAddMemberOpen(true);
  const closeAddMemberModal = () => setIsAddMemberOpen(false);
  const openWorkersModal = () => setIsWorkersModalOpen(true);
  const closeWorkersModal = () => setIsWorkersModalOpen(false);

  const openNewScanModal = () => setIsNewScanModalOpen(true);
  const closeNewScanModal = () => setIsNewScanModalOpen(false);

  const handleGroupSelect = (group) => {
    const newSel = { id: group._id, name: group.name };
    dispatch(setSelectedGroup(newSel));
    localStorage.setItem("selectedGroup", JSON.stringify(newSel));
  };

  const plan = orgDetail?.plan || "Free";
  const usedLicenses = orgDetail?.used_licenses || 0;
  const totalLicenses = orgDetail?.total_licenses || 10;
  const licenseUsagePercentage = totalLicenses
    ? (usedLicenses / totalLicenses) * 100
    : 0;

  const pageBg = useColorModeValue("gray.50", "gray.900");
  const primaryColor = useColorModeValue("blue.700", "blue.500");

  return (
    <>
      <Flex direction="column" height="100vh" bg={pageBg}>
        <Topbar />
        <Flex flex="1" overflow="hidden">
          {!isSmallScreen && (
            <SidebarContent
              isCollapsed={isCollapsed}
              toggleCollapse={toggleCollapse}
              groups={groups}
              selectedGroup={selectedGroup}
              myProfile={myProfile}
              handleGroupSelect={handleGroupSelect}
              location={location}
              planName={plan}
              scansUsed={usedLicenses}
              scansTotal={totalLicenses}
              usagePercentage={licenseUsagePercentage}
              onAddMemberClick={openAddMemberModal}
              onOpenWorkersModal={openWorkersModal}
              orgId={orgId}
              orgName={orgName}
              primaryColor={primaryColor}
              openNewScanModal={openNewScanModal}
            />
          )}
          <Box
            flex="1"
            ml={!isSmallScreen ? (isCollapsed ? "64px" : "220px") : "0"}
            mt="64px"
            p="4"
            position="relative"
            overflow="auto" 
            overflowX="hidden"
            transition="margin 0.3s ease"
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {children}
          </Box>
        </Flex>
      </Flex>

      <AddMemberModal
        isOpen={isAddMemberOpen}
        onClose={closeAddMemberModal}
        organizationId={orgId}
      />

      <WorkersModal
        isOpen={isWorkersModalOpen}
        onClose={closeWorkersModal}
        orgId={orgId}
      />

      <ScanWizardModal isOpen={isNewScanModalOpen} onClose={closeNewScanModal} orgId={orgId} />
    </>
  );
};

export default Sidebar;

const SidebarContent = ({
  isCollapsed,
  toggleCollapse,
  groups,
  selectedGroup,
  myProfile,
  handleGroupSelect,
  location,
  planName,
  orgName,
  scansUsed,
  scansTotal,
  usagePercentage,
  onAddMemberClick,
  onOpenWorkersModal,
  orgId,
  primaryColor,
  openNewScanModal, 
}) => {
  const bg = useColorModeValue(
    isCollapsed ? "gray.800" : "gray.100",
    isCollapsed ? "gray.800" : "gray.700"
  );
  const textColor = useColorModeValue(
    isCollapsed ? "gray.200" : "gray.800",
    isCollapsed ? "gray.200" : "gray.200"
  );
  const menuButtonHoverBg = useColorModeValue(
    isCollapsed ? "gray.700" : "gray.200",
    isCollapsed ? "gray.700" : "gray.600"
  );
  const planBorderColor = useColorModeValue("gray.200", "gray.700");
  const navItemActiveBg = useColorModeValue("blue.100", "blue.700");
  const navItemHoverBg = useColorModeValue("gray.200", "gray.600");
  const navItemActiveColor = useColorModeValue("blue.600", "blue.200");
  const navItemHoverColor = useColorModeValue("blue.700", "blue.300");
  const menuListBg = useColorModeValue("white", "gray.700");
  const menuItemHoverBg = useColorModeValue("gray.100", "gray.600");
  const secondaryTextColor = useColorModeValue("gray.500", "gray.400");

  const getPageTitle = (pathname) => {
    if (pathname.startsWith("/home")) return "Home";
    if (pathname.startsWith("/Chatv2")) return "Securitron";
    if (pathname.startsWith("/new-scan")) return "New Scan";
    if (pathname.startsWith("/scans")) return "All Scans";
    if (pathname.startsWith("/projects")) return "Projects";
    if (pathname.startsWith("/report")) return "Report";
    if (pathname.startsWith("/vulnerabilities")) return "Vulnerabilities";
    return "Dashboard";
  };

  return (
    <Flex
      direction="column"
      bg={bg}
      color={textColor}
      w={isCollapsed ? "64px" : "220px"}
      h="calc(100vh - 60px)"
      position="fixed"
      top="60px"
      left="0"
      overflowY="auto"
      overflowX="hidden"
      transition="width 0.3s, background-color 0.3s ease"
      justifyContent="space-between"
      boxShadow="md"
      sx={{
        "&::-webkit-scrollbar": { display: "none" },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box>
        <Flex
          justify={isCollapsed ? "center" : "space-between"}
          align="center"
          p="4"
        >
          {!isCollapsed && (
            <Text fontSize="lg" fontWeight="bold" color={primaryColor}>
              {getPageTitle(location.pathname)}
            </Text>
          )}
          <IconButton
            icon={<FiMenu size={20} />}
            aria-label="Toggle Sidebar"
            onClick={toggleCollapse}
            variant="ghost"
            color={textColor}
            _hover={{ bg: menuButtonHoverBg, color: textColor }}
            size="sm"
          />
        </Flex>
        <Divider />
        <VStack align="stretch" spacing="1" px="2" mt="0">
          <NavSection title="Main" isCollapsed={isCollapsed}>
            <NavItem
              to="/home"
              icon={LayoutDashboard}
              label="Home"
              isActive={location.pathname === "/home"}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
            <NavItem
              to="/Chatv2"
              icon={Bot}
              label="Securitron"
              isActive={location.pathname === "/Chatv2"}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
          </NavSection>
          <NavSection title="App Sec" isCollapsed={isCollapsed}>
            <NavItem
              icon={ScanSearch}
              label="New Scan"
              isActive={false}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
              onClick={openNewScanModal}
            />
            <NavItem
              to="/projects"
              icon={Package}
              label="Projects"
              isActive={location.pathname === "/projects"}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
            <NavItem
              to="/report"
              icon={FileChartPie}
              label="Report"
              isActive={location.pathname === "/report"}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
            <NavItem
              to={
                orgId && selectedGroup?.id
                  ? `/vulnerabilities/${orgId}/findings?group_id=${selectedGroup.id}`
                  : "/vulnerabilities"
              }
              icon={ShieldAlert}
              label="Vulnerabilities"
              isActive={location.pathname.startsWith("/vulnerabilities")}
              isCollapsed={isCollapsed}
              activeBg={navItemActiveBg}
              activeColor={navItemActiveColor}
              hoverBg={navItemHoverBg}
              hoverColor={navItemHoverColor}
            />
          </NavSection>
          <NavSection isCollapsed={isCollapsed}>
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton p={isCollapsed ? "2" : "2"} pl={isCollapsed ? "2" : "4"}>
                  <HStack flex="1" textAlign="left" spacing="2">
                    <Icon as={PlusIcon} boxSize={4} />
                    {!isCollapsed && <Text fontSize="sm">More</Text>}
                  </HStack>
                  {!isCollapsed && <AccordionIcon />}
                </AccordionButton>
                {!isCollapsed && (
                  <AccordionPanel
                    p="0"
                    pl="8"
                    bg={useColorModeValue("gray.100", "gray.700")}
                  >
                    {(myProfile?.role === "Owner" ||
                      myProfile?.role === "Security Manager") && (
                      <NavItem
                        to="#"
                        icon={UserPlus}
                        label="Add Member"
                        isCollapsed={isCollapsed}
                        onClick={onAddMemberClick}
                        activeBg={navItemActiveBg}
                        activeColor={navItemActiveColor}
                        hoverBg={navItemHoverBg}
                        hoverColor={navItemHoverColor}
                      />
                    )}
                    <NavItem
                      to="https://docs.aquilax.ai/"
                      icon={FiExternalLink}
                      label="Documentation"
                      isActive={false}
                      isCollapsed={isCollapsed}
                      activeBg={navItemActiveBg}
                      activeColor={navItemActiveColor}
                      hoverBg={navItemHoverBg}
                      hoverColor={navItemHoverColor}
                      isExternal={true}
                    />
                    <NavItem
                      to="https://developers.aquilax.ai/"
                      icon={FiFile}
                      label="API Docs"
                      isActive={false}
                      isCollapsed={isCollapsed}
                      activeBg={navItemActiveBg}
                      activeColor={navItemActiveColor}
                      hoverBg={navItemHoverBg}
                      hoverColor={navItemHoverColor}
                      isExternal={true}
                    />
                    <NavItem
                      to="https://aquilax.featurebase.app/changelog"
                      icon={FiExternalLink}
                      label="Releases"
                      isActive={false}
                      isCollapsed={isCollapsed}
                      activeBg={navItemActiveBg}
                      activeColor={navItemActiveColor}
                      hoverBg={navItemHoverBg}
                      hoverColor={navItemHoverColor}
                      isExternal={true}
                    />
                    <NavItem
                      to="#"
                      icon={Users}
                      label="Workers"
                      isActive={false}
                      isCollapsed={isCollapsed}
                      onClick={onOpenWorkersModal}
                      activeBg={navItemActiveBg}
                      activeColor={navItemActiveColor}
                      hoverBg={navItemHoverBg}
                      hoverColor={navItemHoverColor}
                    />
                  </AccordionPanel>
                )}
              </AccordionItem>
            </Accordion>
          </NavSection>
        </VStack>
      </Box>
      <Box p="4" borderTop="1px solid" borderColor={planBorderColor}>
        <HStack spacing="2" mb={isCollapsed ? "0" : "2"}>
          <Icon as={MousePointerClick} boxSize={4} color={primaryColor} />
          {!isCollapsed && (
            <Text fontSize="sm" fontWeight="bold" color={primaryColor}>
              {orgName}
            </Text>
          )}
        </HStack>
        {!isCollapsed && (
          <>
            <Text fontSize="xs" mb="1" color={secondaryTextColor}>
              {scansUsed}/{scansTotal} Licenses Used
            </Text>
            <Progress
              value={usagePercentage}
              size="xs"
              color={primaryColor}
              mb="2"
              borderRadius="md"
            />
            <Button
              as={RouterLink}
              to={myProfile?.role === "Owner" ? "/plans" : "#"}
              size="sm"
              bg={primaryColor}
              color="white"
              _hover={
                myProfile?.role === "Owner"
                  ? { bg: useColorModeValue("#005f99", "#005f99") }
                  : {}
              }
              variant="solid"
              width="100%"
              leftIcon={<UpgradeIcon size={16} />}
              borderRadius="md"
              disabled={myProfile?.role !== "Owner"}
            >
              {planName}
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};

const NavSection = ({ title, children, isCollapsed }) => (
  <Box>
    {!isCollapsed && (
      <Text
        fontSize="xs"
        fontWeight="bold"
        color="gray.500"
        px="4"
        mb={1}
        mt={1}
      >
        {title}
      </Text>
    )}
    <VStack align="stretch" spacing="1">
      {children}
    </VStack>
  </Box>
);

const NavItem = ({
  to,
  icon,
  label,
  isActive,
  isCollapsed,
  onClick,
  activeBg,
  activeColor,
  hoverBg,
  hoverColor,
  isExternal,
}) => {
  const Component = isExternal ? Link : RouterLink;
  return (
    <Tooltip label={label} placement="right" isDisabled={!isCollapsed}>
      <Flex
        as={Component}
        href={isExternal ? to : undefined}
        to={!isExternal ? to : undefined}
        target={isExternal ? "_blank" : undefined}
        rel={isExternal ? "noopener noreferrer" : undefined}
        display="flex"
        alignItems="center"
        p="2"
        borderRadius="md"
        fontSize="xs"
        bg={isActive ? activeBg : "transparent"}
        color={isActive ? activeColor : "inherit"}
        _hover={{ bg: hoverBg, color: hoverColor }}
        onClick={onClick}
        aria-label={label}
        w="100%"
      >
        <Icon as={icon} boxSize={4} />
        {!isCollapsed && (
          <Text ml="3" fontSize="xs">
            {label}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
};

export { NavSection, NavItem };
